import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  steps: [
    {
      id: '01',
      name: 'Información Inicial',
      href: '#',
      status: 'current',
    },
    {
      id: '02',
      name: 'Certificado de antecedente',
      href: '#',
      status: 'upcoming',
    },
    { id: '03', name: 'Datos bancarios', href: '#', status: 'upcoming' },
    { id: '04', name: 'Cuestionario', href: '#', status: 'upcoming' },
    { id: '05', name: 'Código Pets-Okey', href: '#', status: 'upcoming' },
  ],
  firstStep: { communes: [], description: '' },
  secondStep: { certificate: false },
  thirdStep: { bank: '', type: '', accountNumber: '' },
  fourthStep: {
    firstAnswer: false,
    secondAnswer: false,
    thirdAnswer: false,
    fourthAnswer: false,
    fifthAnswer: false,
    sixthAnswer: false,
  },
  fifthStep: { accepted: false },
}

const sitterRequest = createSlice({
  name: 'sitterRequest',
  initialState,
  reducers: {
    saveSteps(state, action) {
      const { stepIndex, newStatus } = action.payload
      if (state.steps[stepIndex]) {
        state.steps = state.steps.map((step, index) =>
          index === stepIndex ? { ...step, status: newStatus } : step,
        )
      }
    },
    saveFirstStep(state, action) {
      const payload = action.payload
      return { ...state, firstStep: payload }
    },
    saveSecondStep(state, action) {
      const payload = action.payload
      return { ...state, secondStep: payload }
    },
    saveThirdStep(state, action) {
      const payload = action.payload
      return { ...state, thirdStep: payload }
    },
    saveFourthStep(state, action) {
      const payload = action.payload
      return {
        ...state,
        fourthStep: {
          ...state.fourthStep,
          [payload.key]: payload.value,
        },
      }
    },
    saveFifthStep(state, action) {
      const payload = action.payload
      return { ...state, fifthStep: { accepted: payload } }
    },
  },
})

export const {
  saveSteps,
  saveFirstStep,
  saveSecondStep,
  saveThirdStep,
  saveFourthStep,
  saveFifthStep,
  next,
  prev,
} = sitterRequest.actions
export default sitterRequest.reducer
