import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import CustomTable from '@common/layouts/CustomTable'
import { formatId, formatDate, formatPrice } from '@utils/formatFunctions'
import { statusRenderer, typeRenderer } from '@common/helpers/tableRenderers'
import Error from '@common/error/Error'

const PendingReviews = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)

  const {isPending, isError, error, data} = useQuery({
    queryKey: ['pendingReviews'],
    queryFn: () => getData(`review/pending/${user.id}`),
  })

  const tableProps = [
    {
      key: 'id',
      header: { name: 'ID' },
      formatter: formatId,
    },
    {
      key: 'type',
      header: { name: 'Servicio', style: { textAlign: 'center' } },
      renderer: typeRenderer,
    },
    {
      key: 'sitterFullName',
      header: { name: 'Sitter' },
    },
    {
      key: 'date',
      header: {
        name: 'Fecha',
      },
      formatter: formatDate,
    },
    {
      key: 'price',
      header: { name: 'Precio' },
      formatter: formatPrice,
    },
    {
      key: 'status',
      header: { name: 'Estado', style: { textAlign: 'center' } },
      renderer: statusRenderer,
    },
  ]

  let tableData = []
  if (data) {
    tableData = data.map((record) => {
      return {
        id: record?.id,
        type: record?.type,
        sitterFullName: `${record?.sitter?.name} ${record?.sitter?.lastName}`,
        date: record?.date,
        price: record?.price,
        status: record?.status,
      }
    })
  }

  return (
    <div className="h-full w-full">
      <div className="relative m-4 h-screen overflow-x-auto sm:rounded-lg">
        <CustomTable
          data={tableData}
          tableProps={tableProps}
          rowClickHandler={(record) =>
            navigate(`/user/service-records?recordId=${record.id}`)
          }
        />
        {isPending ? <Loading /> : null}
        {isError ? <Error error={error.message} /> : null}
      </div>
    </div>
  )
}

export default PendingReviews
