/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FaLocationDot, FaPaw, FaSun } from 'react-icons/fa6'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'

function ServiceTypeInput({ selectedType, setSelectedType }) {
  const [isModalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <label
          htmlFor="serviceType"
          className="block pl-1 text-start font-mont-semi text-sm font-medium leading-6 text-grey-base"
        >
          Servicio
        </label>
        <input
          id="serviceType"
          placeholder="Selecciona un servicio"
          value={
            selectedType == 'dogWalking'
              ? 'Paseo'
              : selectedType == 'visit'
                ? 'Visita'
                : selectedType == 'dayCare'
                  ? 'Alojamiento'
                  : ''
          }
          readOnly
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          onClick={openModal}
        />
      </div>
      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        header={
          <ModalHeader
            title="🐾 Servicio"
            subtitle="Selecciona el servicio que necesitas"
          />
        }
        body={
          <ModalBody
            isOpen={isModalOpen}
            onClose={closeModal}
            setSelectedType={setSelectedType}
          />
        }
      />
    </>
  )
}

function ModalBody({ onClose, setSelectedType }) {
  const handleTypeClick = (type) => {
    setSelectedType(type)
    onClose()
  }

  return (
    <div className="space-y-6 text-center">
      <div className="flex w-full flex-col justify-start gap-6 text-left">
        <div
          onClick={() => {
            handleTypeClick('dogWalking')
          }}
          className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border-2 border-grey-base p-4 text-grey-base hover:bg-grey-soft"
        >
          <FaPaw className="text-3xl" />
          <div className="flex flex-col">
            <p className="font-tex-bold">Paseo</p>
            <p className="font-mont text-xs text-grey-base">
              Paseos de una hora.
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            handleTypeClick('visit')
          }}
          className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border-2 border-grey-base p-4 text-grey-base hover:bg-grey-soft"
        >
          <FaLocationDot className="text-3xl" />
          <div className="flex flex-col">
            <p className="font-tex-bold">Visita</p>
            <p className="font-mont text-xs text-grey-base">
              {' '}
              Visita en tu casa, incluye paseo.
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            handleTypeClick('dayCare')
          }}
          className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border-2 border-grey-base p-4 text-grey-base hover:bg-grey-soft"
        >
          <FaSun className="text-3xl" />
          <div className="flex flex-col">
            <p className="font-tex-bold">Alojamiento</p>
            <p className="font-mont text-xs text-grey-base">
              {' '}
              Guardería / Hotel en la casa del sitter.
            </p>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          handleTypeClick(null)
        }}
        className="rounded-lg text-sm text-grey-base underline"
      >
        Limpiar filtro
      </button>
    </div>
  )
}

ServiceTypeInput.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
}

export default ServiceTypeInput
