/* eslint-disable react/prop-types */
import { formatType, formatPrice } from '@utils/formatFunctions'
import { useParams } from 'react-router-dom'
import { useGetRecords } from '@services/servicerecord'
import {
  statusRenderer,
  payoutStatusRenderer,
} from '@common/helpers/tableRenderers'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const SectionCard = ({ title, children }) => (
  <div className="mb-6 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md transition-all duration-300 hover:shadow-lg">
    <div className="px-6 py-4">
      <h2 className="mb-4 border-b border-gray-200 pb-2 text-xl font-semibold text-gray-800">
        {title}
      </h2>
      {children}
    </div>
  </div>
)

const InfoItem = ({ label, value, className = '' }) => (
  <p className={`mb-2 ${className}`}>
    <span className="font-medium text-gray-500">{label}:</span>{' '}
    {value !== null && value !== undefined ? (
      value
    ) : (
      <span className="italic text-grey-soft">No disponible</span>
    )}
  </p>
)

export default function ServiceSummary() {
  const { serviceId } = useParams()
  const { isPending, isError, error, data } = useGetRecords()
  const service = data?.find((record) => record.id === serviceId)

  if (isPending)
    return (
      <div className="flex h-screen items-center justify-center text-xl text-grey-soft">
        Cargando...
      </div>
    )
  if (isError)
    return (
      <div className="flex h-screen items-center justify-center text-xl text-red-base">
        {error.message}
      </div>
    )
  if (!service)
    return (
      <div className="flex h-screen items-center justify-center text-xl text-grey-soft">
        No se encontró información del servicio
      </div>
    )

  return (
    <div className="min-h-screen px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-4xl">
        <h1 className="mb-12 text-center font-tex-bold text-4xl text-grey-base">
          Resumen del Servicio
        </h1>

        <SectionCard title="Registro del Servicio">
          <div className="grid grid-cols-2 gap-4">
            <InfoItem label="Tipo" value={formatType(service.type)} />
            <InfoItem
              label="Estado"
              value={statusRenderer({ value: service.status })}
            />
            <InfoItem
              label="Fecha"
              value={
                service.date
                  ? format(new Date(service.date), 'dd MMMM yyyy HH:mm', {
                      locale: es,
                    })
                  : 'No disponible'
              }
            />
            <InfoItem
              label="Precio"
              value={service.price ? formatPrice(service.price) : null}
            />
          </div>
          <InfoItem label="Mensaje" value={service.message} className="mt-4" />
        </SectionCard>

        {service.user && (
          <SectionCard title="Cliente">
            <InfoItem
              label="Nombre"
              value={`${service.user.name || ''} ${service.user.lastName || ''}`}
            />
            <InfoItem
              label="Teléfono"
              value={
                service.user.phone ? (
                  <a
                    href={`https://wa.me/56${service.user.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {`+56 ${service.user.phone}`}
                  </a>
                ) : (
                  'No disponible'
                )
              }
            />
            <InfoItem
              label="Email"
              value={
                service.user.email ? (
                  <a
                    href={`mailto:${service.user.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {service.user.email}
                  </a>
                ) : (
                  'No disponible'
                )
              }
            />
          </SectionCard>
        )}

        {service.sitter && (
          <SectionCard title="Sitter">
            <InfoItem
              label="Nombre"
              value={`${service.sitter.name} ${service.sitter.lastName}`}
            />
            <InfoItem
              label="Teléfono"
              value={
                <a
                  href={`https://wa.me/56${service.sitter.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {`+56 ${service.sitter.phone}`}
                </a>
              }
            />
            <InfoItem
              label="Email"
              value={
                <a
                  href={`mailto:${service.sitter.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {service.sitter.email}
                </a>
              }
            />
          </SectionCard>
        )}

        {service.address && (
          <SectionCard title="Dirección">
            <InfoItem label="Región" value={service.address.region} />
            <InfoItem label="Comuna" value={service.address.commune} />
            <InfoItem label="Calle" value={service.address.street} />
            <InfoItem label="Departamento" value={service.address.department} />
          </SectionCard>
        )}

        <SectionCard title="Mascotas">
          {service.pets && service.pets.length > 0 ? (
            <div className="grid gap-6 md:grid-cols-2">
              {service.pets.map((pet, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="mb-4 flex items-center">
                    <img
                      src={pet.photo}
                      alt={pet.name}
                      className="mr-4 h-16 w-16 rounded-full object-cover"
                    />
                    <div>
                      <h3 className="text-lg font-semibold">{pet.name}</h3>
                      <p className="text-sm text-gray-600">
                        {pet.type} - {pet.breed}
                      </p>
                    </div>
                  </div>
                  <InfoItem label="Peso" value={pet.weight} />
                  <InfoItem label="Sexo" value={pet.sex} />
                  <InfoItem label="Edad" value={`${pet.age} años`} />
                  <div className="mt-2">
                    <span className="font-medium text-gray-500">
                      Descripciones:
                    </span>
                    {pet.descriptions && pet.descriptions.length > 0 ? (
                      <ul className="mt-1 list-inside list-disc">
                        {pet.descriptions.map((desc, i) => (
                          <li key={i} className="text-sm text-gray-700">
                            {desc}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="mt-1 text-sm italic text-grey-soft">
                        No hay descripciones disponibles
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="italic text-gray-500">
              No hay información de mascotas disponible
            </p>
          )}
        </SectionCard>

        <SectionCard title="Reseña">
          {service.review ? (
            <>
              <div className="mb-2 flex items-center">
                <span className="mr-2 text-2xl font-bold text-yellow-500">
                  {service.review.stars}
                </span>
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className={`h-5 w-5 ${i < Math.floor(service.review.stars) ? 'text-yellow-500' : 'text-gray-300'}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <p className="italic text-gray-700">
                {`"${service.review.description}"`}
              </p>
            </>
          ) : (
            <p className="italic text-gray-500">
              El servicio aún no ha sido evaluado
            </p>
          )}
        </SectionCard>

        {service.payment && (
          <SectionCard title="Pago">
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-700">
                  MercadoPago
                </h3>
                {service.payment?.mercadopago ? (
                  <>
                    <InfoItem
                      label="Estado"
                      value={service.payment?.mercadopago?.status}
                    />
                    <InfoItem
                      label="Método de pago"
                      value={service.payment?.mercadopago?.payment_type_id}
                    />
                  </>
                ) : (
                  <p className="italic text-gray-500">N/A</p>
                )}
              </div>
              <div>
                <h3 className="mb-2 text-lg font-semibold text-gray-700">
                  Transbank
                </h3>
                {service.payment?.transbank ? (
                  <>
                    <InfoItem
                      label="Estado"
                      value={service.payment?.transbank?.status}
                    />
                    <InfoItem
                      label="Método de pago"
                      value={service.payment?.transbank?.method}
                    />
                  </>
                ) : (
                  <p className="italic text-gray-500">N/A</p>
                )}
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <InfoItem
                label="Precio"
                value={
                  service.payment.price
                    ? new Intl.NumberFormat('es-CL', {
                        style: 'currency',
                        currency: 'CLP',
                      }).format(service.payment.price.toString())
                    : 'No disponible'
                }
              />
              <InfoItem
                label="Antes del descuento"
                value={
                  service.payment.priceBeforeDiscount ? (
                    <span className="line-through">
                      {new Intl.NumberFormat('es-CL', {
                        style: 'currency',
                        currency: 'CLP',
                      }).format(service.payment.priceBeforeDiscount.toString())}
                    </span>
                  ) : (
                    'No disponible'
                  )
                }
              />
            </div>
          </SectionCard>
        )}

        <SectionCard title="Pago al Cuidador">
          {service.payout ? (
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <InfoItem
                  label="Fecha"
                  value={format(new Date(service.payout.date), 'dd MMMM yyyy', {
                    locale: es,
                  })}
                />
                <InfoItem
                  label="Monto"
                  value={
                    service.payout.amount
                      ? formatPrice(service.payout.amount)
                      : null
                  }
                />
                <InfoItem
                  label="Estado"
                  value={payoutStatusRenderer(service.payout.status)}
                />
              </div>
              <div>
                <InfoItem
                  label="Comentario"
                  value={
                    <span className="whitespace-pre-wrap text-sm italic text-grey-sec">
                      {service.payout?.details?.message ?? 'N/A'}
                    </span>
                  }
                />
                <InfoItem
                  label="Error"
                  value={
                    <span className="whitespace-pre-wrap text-sm italic text-grey-sec">
                      {service.payout?.details?.error ?? 'N/A'}
                    </span>
                  }
                />
              </div>
            </div>
          ) : (
            <p className="italic text-gray-500">
              El pago al cuidador aún no se ha procesado
            </p>
          )}
        </SectionCard>
      </div>
    </div>
  )
}
