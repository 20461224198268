import { useSelector } from 'react-redux'
import { classNames } from '@utils/formatFunctions'
import smWhite from '@assets/images/dogs/smWhite.png'
import mdWhite from '@assets/images/dogs/mdWhite.png'
import lgWhite from '@assets/images/dogs/lgWhite.png'
import xlWhite from '@assets/images/dogs/xlWhite.png'
import smGrey from '@assets/images/dogs/smGrey.png'
import mdGrey from '@assets/images/dogs/mdGrey.png'
import lgGrey from '@assets/images/dogs/lgGrey.png'
import xlGrey from '@assets/images/dogs/xlGrey.png'
import { useUpdateUser } from '@services/user'

function PetSize() {
  const user = useSelector((state) => state.user)
  const { mutate: updateUser } = useUpdateUser()
  const sizes = [
    {
      title: 'Pequeño',
      subtitle: '0 - 6 Kg',
      value: user?.petSize?.sm,
      imgWhite: smWhite,
      imgGrey: smGrey,
      className: 'w-8',
      change: { sm: !user?.petSize?.sm },
    },
    {
      title: 'Mediano',
      subtitle: '7 - 18 Kg',
      value: user?.petSize?.md,
      imgWhite: mdWhite,
      imgGrey: mdGrey,
      className: 'w-10',
      change: { md: !user?.petSize?.md },
    },
    {
      title: 'Grande',
      subtitle: '19 - 45 Kg',
      value: user?.petSize?.lg,
      imgWhite: lgWhite,
      imgGrey: lgGrey,
      className: 'w-14',
      change: { lg: !user?.petSize?.lg },
    },
    {
      title: 'Gigante',
      subtitle: '45+ Kg',
      value: user?.petSize?.xl,
      imgWhite: xlWhite,
      imgGrey: xlGrey,
      className: 'w-14',
      change: { xl: !user?.petSize?.xl },
    },
  ]

  const onUpdate = (change) => {
    const body = {
      petSize: {
        ...user.petSize,
        ...change,
      },
    }
    updateUser(body)
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center gap-2 font-tex text-xl">
        <h1>Tamaños</h1>
        <p>🐾</p>
      </div>
      <div className="grid grid-cols-2 gap-2 xl:grid-cols-4">
        {sizes.map((size, index) => (
          <div
            key={index}
            className={classNames(
              'flex cursor-pointer flex-row items-center justify-center gap-4 rounded-lg p-3 text-sm shadow-md transition-transform hover:scale-105',
              size.value && 'bg-blue-ter text-white',
              !size.value && 'border border-grey-sec text-grey-sec',
            )}
            onClick={() => onUpdate(size.change)}
          >
            <div className="space-y-2">
              <p>{size.title}</p>
              <p
                className={classNames(
                  'rounded-full px-1 text-center text-[0.5rem]',
                  size.value && 'bg-white text-blue-ter',
                  !size.value && 'bg-grey-sec text-white',
                )}
              >
                {size.subtitle}
              </p>
            </div>
            <img
              src={size.value ? size.imgWhite : size.imgGrey}
              alt={size.title}
              className={size.className}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PetSize
