/* eslint-disable react/prop-types */
import { FaHouse, FaPlus } from 'react-icons/fa6'
import {
  FaBuilding,
  FaSmoking,
  FaSmokingBan,
  FaBabyCarriage,
  FaDog,
  FaCat,
  FaPaw,
  FaBed,
  FaCouch,
} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import MultiSelect from '@common/inputs/MultiSelect'
import { useUpdateUser } from '@services/user'

function AboutHouse() {
  const user = useSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)
  const { mutate: updateUser } = useUpdateUser()

  const options = [
    {
      id: 1,
      value: 'house',
      label: 'Tengo casa',
      icon: <FaHouse />,
      isSelected: user?.aboutSitterHouse?.house,
    },
    {
      id: 2,
      value: 'department',
      label: 'Tengo departamento',
      icon: <FaBuilding />,
      isSelected: user?.aboutSitterHouse?.department,
    },
    {
      id: 3,
      value: 'smoking',
      label: 'Fumo',
      icon: <FaSmoking />,
      isSelected: user?.aboutSitterHouse?.smoking,
    },
    {
      id: 4,
      value: 'noSmoking',
      label: 'No fumo',
      icon: <FaSmokingBan />,
      isSelected: user?.aboutSitterHouse?.noSmoking,
    },
    {
      id: 5,
      value: 'dog',
      label: 'Tengo perro',
      icon: <FaDog />,
      isSelected: user?.aboutSitterHouse?.dog,
    },
    {
      id: 6,
      value: 'noDog',
      label: 'No tengo perro',
      icon: <FaDog />,
      isSelected: user?.aboutSitterHouse?.noDog,
    },
    {
      id: 7,
      value: 'cat',
      label: 'Tengo gato',
      icon: <FaCat />,
      isSelected: user?.aboutSitterHouse?.cat,
    },
    {
      id: 8,
      value: 'noCat',
      label: 'No tengo gato',
      icon: <FaCat />,
      isSelected: user?.aboutSitterHouse?.noCat,
    },
    {
      id: 9,
      value: 'otherPets',
      label: 'Tengo otras mascotas (distintas a perro / gato)',
      icon: <FaPaw />,
      isSelected: user?.aboutSitterHouse?.otherPets,
    },
    {
      id: 10,
      value: 'noOtherPets',
      label: 'No tengo otras mascotas (distintas a perro / gato)',
      icon: <FaPaw />,
      isSelected: user?.aboutSitterHouse?.noOtherPets,
    },
    {
      id: 11,
      value: 'children',
      label: 'Tengo niños menores de 5 años',
      icon: <FaBabyCarriage />,
      isSelected: user?.aboutSitterHouse?.children,
    },
    {
      id: 12,
      value: 'noChildren',
      label: 'No tengo niños menores de 5 años',
      icon: <FaBabyCarriage />,
      isSelected: user?.aboutSitterHouse?.noChildren,
    },
    {
      id: 13,
      value: 'bed',
      label: 'Se permiten los perros arriba de la cama',
      icon: <FaBed />,
      isSelected: user?.aboutSitterHouse?.bed,
    },
    {
      id: 14,
      value: 'noBed',
      label: 'No se permiten perros arriba de la cama',
      icon: <FaBed />,
      isSelected: user?.aboutSitterHouse?.noBed,
    },
    {
      id: 15,
      value: 'couch',
      label: 'Se permiten perros arriba del sillón',
      icon: <FaCouch />,
      isSelected: user?.aboutSitterHouse?.couch,
    },
    {
      id: 16,
      value: 'noCouch',
      label: 'No se permiten perros arriba del sillón',
      icon: <FaCouch />,
      isSelected: user?.aboutSitterHouse?.noCouch,
    },
  ]

  const actualOptions = options.filter((option) => option.isSelected)
  const [selectedOptions, setSelectedOptions] = useState(actualOptions)

  const onClose = () => {
    const selectedOptionsObject = selectedOptions.reduce((acc, option) => {
      acc[option.value] = true
      return acc
    }, {})
    const body = {
      aboutSitterHouse: selectedOptionsObject,
    }
    updateUser(body)
    setIsOpen(false)
  }

  return (
    <>
      <div className="space-y-4">
        <h2 className="font-tex text-xl">
          Acerca de mi casa 🏡{' '}
          <span className="text-xs text-grey-sec">Servicio alojamiento</span>
        </h2>
        <div
          className="flex cursor-pointer flex-wrap gap-4 text-[0.6rem] text-white transition-transform hover:scale-105"
          onClick={() => setIsOpen(true)}
        >
          {actualOptions.length > 0 ? (
            actualOptions.map((option) => (
              <div
                key={option.id}
                className="flex cursor-pointer flex-row items-center gap-2 rounded-full bg-blue-ter px-2 py-1"
              >
                {option.icon}
                <div>
                  <p>{option.label}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-2 py-1">
              <FaPlus size={10} />
              <p> Agrega información sobre tu casa</p>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualiza la información de tu casa"
            subtitle="Agrega información sobre tu casa para el servicio de alojamiento"
          />
        }
        body={
          <ModalBody
            options={options}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onClose={onClose}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
        overflowy="overflow-y-visible"
      />
    </>
  )
}

const ModalBody = ({
  options,
  selectedOptions,
  setSelectedOptions,
  onClose,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full space-y-4 sm:w-[32rem]">
        <MultiSelect
          options={options}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          placeholder="Selecciona las opciones"
        />
      </div>
      <button
        className="inline-block rounded-lg bg-blue-soft px-8 py-3 text-sm font-medium text-white transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-blue-600"
        type="button"
        onClick={onClose}
      >
        Guardar cambios!
      </button>
    </div>
  )
}

export default AboutHouse
