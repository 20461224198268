import PropTypes from 'prop-types'

const StatsInfo = ({ texts }) => {
  return (
    <div className="my-2 space-y-1 rounded-lg bg-black bg-opacity-60 p-2 text-xs text-white">
      {texts.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  )
}

StatsInfo.propTypes = {
  texts: PropTypes.array.isRequired,
}

export default StatsInfo
