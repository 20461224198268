import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getData } from '@services/requests'
import { useSearchParams } from 'react-router-dom'

const useSelectedService = (user, role = 'user') => {
  const [searchParams] = useSearchParams()
  const [selectedService, setSelectedService] = useState(null)

  const { isPending, isError, error, isSuccess, data, refetch } = useQuery({
    queryKey: [`${role}ServiceRecords`, user.id],
    queryFn: () => getData(`service-record/${role}-service-records/${user.id}`),
  })

  useEffect(() => {
    refetch()
  }, [user.id, role, refetch])

  useEffect(() => {
    if (isSuccess && data.length) {
      const serviceRecordId = searchParams.get('recordId')
      if (serviceRecordId) {
        const service = data.find(
          (service) => service.record.id === serviceRecordId,
        )
        if (service) {
          setSelectedService(service)
          return
        }
      }
      const today = new Date().toISOString().split('T')[0]
      let closestServiceIndex = data.findIndex(
        (service) => service.record.date >= today,
      )
      if (closestServiceIndex === -1) {
        closestServiceIndex = data.length - 1
      }
      setSelectedService(data[closestServiceIndex])
    }
  }, [data, searchParams, isSuccess])

  return {
    selectedService,
    setSelectedService,
    isPending,
    isError,
    error,
    data,
  }
}

export default useSelectedService
