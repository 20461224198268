import PropTypes from 'prop-types'
import { FaPaw, FaLocationDot, FaSun } from 'react-icons/fa6'
import dayjs from 'dayjs'
import { classNames } from '@utils/formatFunctions'
import 'dayjs/locale/es'

function MiniServiceCard({ service, selected, setSelectedService, role }) {
  dayjs.locale('es')

  const statusColor = {
    pending: 'bg-yellow-sec',
    accepted: 'bg-green-base',
    denied: 'bg-red-base',
    cancelled: 'bg-red-base',
    finished: 'bg-blue-ter',
    waiting: 'bg-yellow-sec',
  }

  return (
    <div
      onClick={() => setSelectedService(service)}
      className={classNames(
        'relative flex cursor-pointer flex-col items-center gap-1 rounded-lg border p-2',
        !selected && 'border-grey-soft bg-white',
        service.record.type === 'dogWalking' &&
          selected &&
          'border-yellow-sec bg-yellow-100',
        service.record.type === 'visit' &&
          selected &&
          'border-blue-ter bg-blue-100',
        service.record.type === 'dayCare' &&
          selected &&
          'border-purple-base bg-purple-100',
      )}
    >
      <span className="absolute left-2 top-2 flex h-3 w-3">
        <span
          className={classNames(
            'absolute inline-flex h-full w-full animate-ping rounded-full opacity-85',
            service.record.status == 'waiting' &&
              statusColor[service.record.status],
            service.record.status == 'finished' &&
              !service.review &&
              statusColor[service.record.status],
          )}
        ></span>
        <span
          className={classNames(
            'relative inline-flex h-3 w-3 rounded-full',
            statusColor[service.record.status],
          )}
        ></span>
      </span>

      {service.record.type === 'dogWalking' && (
        <FaPaw className="text-2xl text-yellow-sec" />
      )}
      {service.record.type === 'visit' && (
        <FaLocationDot className="text-2xl text-blue-ter" />
      )}
      {service.record.type === 'dayCare' && (
        <FaSun className="text-2xl text-purple-base" />
      )}
      <p className="text- slate-900 font-mont-semi text-xs text-slate-900">
        {service.record.type === 'dogWalking'
          ? 'Paseo'
          : service.record.type === 'visit'
            ? 'Visita'
            : service.record.type === 'dayCare'
              ? 'Alojamiento'
              : ''}{' '}
      </p>
      <p
        className={classNames(
          'rounded-full px-3 py-1 text-xs',
          service.record.type === 'dogWalking' &&
            selected &&
            'bg-yellow-sec text-white',
          service.record.type === 'dogWalking' &&
            'border border-yellow-sec bg-yellow-100 text-sm text-slate-900',
          service.record.type === 'visit' &&
            selected &&
            'bg-blue-ter text-white',
          service.record.type === 'visit' &&
            'border border-blue-ter bg-blue-100 text-sm text-slate-900',
          service.record.type === 'dayCare' &&
            selected &&
            'bg-purple-base text-white',
          service.record.type === 'dayCare' &&
            'border border-purple-base bg-purple-100 text-sm text-slate-900',
          'whitespace-nowrap text-center',
        )}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {dayjs(service.record.date).format('DD [de] MMMM')}
      </p>
      {role === 'user' && (
        <p className="text-[0.6rem]">
          {service.sitter?.name} {service.sitter?.lastName}
        </p>
      )}
      {role === 'sitter' && (
        <p className="text-[0.6rem]">
          {service.user?.name} {service.user?.lastName}
        </p>
      )}
    </div>
  )
}

MiniServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  setSelectedService: PropTypes.func,
  role: PropTypes.string,
}

export default MiniServiceCard
