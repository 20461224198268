/* eslint-disable quotes */
import { Link } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { WPP } from '@assets/constants'

const faqs = [
  {
    question: '¿Qué servicios tienen y cuales son los valores?',
    answer: `Paseo: de entre 40 minutos y 1 hora, el Paseador pasa a buscar a tu perro, lo pasea y lo pasa a dejar. Cada Paseador tiene sus tarifas, pero en general cuestan $8.000 por paseo.\n\nVisita: si tu perro queda solo en casa, un cuidador va a darle comida y agua, limpia la zona del baño y lo saca a pasear. En este caso es importante coordinar un “Meet & Greet”, una visita previa sin costo para conocer al Sitter, le presenten a la mascota y le den las indicaciones. Cada Sitter tiene sus tarifas, pero en general cuestan $15.000 por paseo.\n\nAlojamiento: este es un servicio de Guardería/Hotel, donde dejas a tu mascota en la casa del Sitter, quién cuidará de ella por el tiempo que sea acordado, ocupándose de la alimentación, paseos y los cuidados que necesite. Cada Sitter tiene sus tarifas, pero en general cuestan desde $25.000 las 24hrs.`,
  },
  {
    question: '¿Cómo puedo postular a ser Sitter?',
    answer: '',
  },
  {
    question:
      '¿Cómo puedo confiar en la persona que irá a mi casa o paseará a mi perro mientras yo no esté?',
    answer:
      'Todos nuestros Sitters pasan por un proceso de evaluación y revisión de antecedentes realizado por nosotros mismos. Además, cada Sitter cuenta con evaluaciones y calificaciones de los mismos clientes, lo que te permitirá tener una mayor visibilidad sobre la persona que estarías contratando.',
  },
  {
    question:
      '¿Cómo puedo contratar un servicio recurrente o por tiempo indefinido?',
    answer: '',
  },
  {
    question:
      '¿Qué pasa si no logro encontrar un Paseador/Sitter para las fechas que necesito?',
    answer: '',
  },
]

function HomeFour() {
  return (
    <div className="w-full bg-white pb-36 pt-10">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:w-[60%]">
        <h2 className="mb-4 text-center font-tex-bold text-2xl text-grey-base sm:mb-24 sm:text-3xl">
          ¿Preguntas <span className="text-yellow-base">Frecuentes</span>?
        </h2>
        <div className="mx-auto max-w-6xl divide-y divide-grey-base/10">
          <dl className="mt-10 space-y-4 divide-y divide-grey-base/10 sm:space-y-12">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-grey-base">
                        <span className="pb-2 font-mont-semi text-base font-medium leading-7 sm:text-lg">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center font-mont-semi">
                          {open ? (
                            <MinusSmallIcon
                              className="h-8 w-8"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-8 w-8"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      {faq.question === '¿Cómo puedo postular a ser Sitter?' ? (
                        <p className="whitespace-pre-line text-sm leading-7 text-gray-500 sm:text-base">
                          Si quieres unirte como Sitter Pets-okey debes{' '}
                          <Link
                            to="/sitter-request"
                            className="text-blue-500 hover:text-blue-600"
                          >
                            entra a este enlace
                          </Link>{' '}
                          y completar la información requerida, como experiencia
                          y certificado de antecedentes. Luego te contactaremos
                          para una entrevista y posterior autorización de tu
                          postulación.
                        </p>
                      ) : faq.question ===
                        '¿Cómo puedo contratar un servicio recurrente o por tiempo indefinido?' ? (
                        <p className="whitespace-pre-line text-sm leading-7 text-gray-500 sm:text-base">
                          Si necesitas contratar un servicio recurrente, por
                          ejemplo: 3 paseos a la semana, cada semana,
                          contáctanos en{' '}
                          <a
                            href={`https://wa.me/${WPP}`}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            este enlace.
                          </a>
                        </p>
                      ) : faq.question ===
                        '¿Qué pasa si no logro encontrar un Paseador/Sitter para las fechas que necesito?' ? (
                        <p className="whitespace-pre-line text-sm leading-7 text-gray-500 sm:text-base">
                          Si tienes problemas para contratar un servicio, por
                          favor comunícate con{' '}
                          <a
                            href={`https://wa.me/${WPP}`}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            nosotros{' '}
                          </a>
                          y te asistiremos para coordinar con el Paseador/Sitter
                          que se adecúe a tus requerimientos.{' '}
                        </p>
                      ) : (
                        <p className="whitespace-pre-line text-sm leading-7 text-gray-500 sm:text-base">
                          {faq.answer}
                        </p>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default HomeFour
