import { useLocation } from 'react-router-dom'
import ChangePasswordForm from '@common/forms/ChangePassword'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
function ChangePassword() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const navigate = useNavigate()

  return (
    <>
      <ToastContainer />
      <div className="mx-auto my-8 sm:min-h-screen">
        <div className="rounded-lg bg-white p-4 shadow-md">
          <h1 className="mb-4 font-tex text-2xl font-bold text-grey-base">
            Cambiar contraseña
          </h1>
          <ChangePasswordForm token={token} onSubmit={() => navigate('/')} />
        </div>
      </div>
    </>
  )
}

export default ChangePassword
