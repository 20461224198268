import { useState } from 'react'
import PropType from 'prop-types'

const ChatImage = ({ photo }) => {
  const [showPreview, setShowPreview] = useState(false)

  const togglePreview = () => {
    setShowPreview(!showPreview)
  }

  return (
    <div className="relative rounded-lg w-[65%]">
      <img
        src={photo}
        alt="Chat Photo"
        className="rounded-lg cursor-pointer text-white shadow-sm"
        onClick={togglePreview}
      />

      {showPreview && (
        <div
          className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50"
          onClick={togglePreview}
        >
          <div className="relative w-full max-w-xl m-2">
            <img
              src={photo ? photo : null}
              alt="Chat Photo Preview"
              className="h-auto w-full rounded-xl"
              onClick={(e) => e.stopPropagation()}
            />
            <button
              className="absolute right-4 top-4 text-2xl text-white rounded-full p-1 bg-slate-900 bg-opacity-40 h-12 w-12 hover:bg-opacity-70"
              onClick={togglePreview}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

ChatImage.propTypes = {
  photo: PropType.string,
}

export default ChatImage
