/* eslint-disable react/prop-types */
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { classNames } from '@utils/formatFunctions'
import ChatImage from './ChatImage'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

function ChatMessages({ record, messages }) {
  dayjs.locale('es')
  const user = useSelector((state) => state.user)
  const endOfMessagesRef = useRef(null)

  const formatDate = (date) => {
    return dayjs(date).format('DD [de] MMMM [de] YYYY')
  }

  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const date = formatDate(message.createdAt)
      if (!acc[date]) acc[date] = []
      acc[date].push(message)
      return acc
    }, {})
  }

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [messages])

  const groupedMessages = groupMessagesByDate(messages)

  return (
    <div className="relative mb-2 h-[26rem] max-h-[26rem] overflow-y-auto p-4">
      {Object.keys(groupedMessages).map((date, index) => (
        <div key={index}>
          <div className="mb-4 flex items-center justify-center">
            <hr className="w-full border-t border-gray-300" />
            <span className="w-96 px-2 text-center text-xs text-gray-500">
              {date}
            </span>
            <hr className="w-full border-t border-gray-300" />
          </div>
          <div className="space-y-2">
            {groupedMessages[date].map((message) =>
              message.senderId == user.id ? (
                <MyMessage
                  key={message.id}
                  type={message.type}
                  content={message.content}
                  recordType={record.type}
                />
              ) : (
                <OtherMessage
                  key={message.id}
                  type={message.type}
                  content={message.content}
                />
              ),
            )}
          </div>
          <div ref={endOfMessagesRef} />
        </div>
      ))}
    </div>
  )
}

function MyMessage({ type, content, recordType }) {
  return (
    <div className="flex items-start justify-end space-x-2 text-xs">
      {type == 'text' ? (
        <div
          className={classNames(
            'rounded-lg p-2 text-white shadow-sm',
            recordType == 'dogWalking' && 'bg-yellow-sec',
            recordType == 'visit' && 'bg-blue-ter',
            recordType == 'dayCare' && 'bg-purple-base',
          )}
        >
          <p>{content}</p>
        </div>
      ) : type == 'photo' ? (
        <ChatImage photo={content} />
      ) : null}
    </div>
  )
}

function OtherMessage({ type, content }) {
  return (
    <div className="flex items-start space-x-2 text-xs">
      {type == 'text' ? (
        <div className="rounded-lg bg-white p-2 shadow-sm">
          <p>{content}</p>
        </div>
      ) : type == 'photo' ? (
        <ChatImage photo={content} />
      ) : null}
    </div>
  )
}

ChatMessages.propTypes = {
  record: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
}

export default ChatMessages
