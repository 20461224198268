import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Sitters from './components/Sitters'
import SelectionFilter from './components/SelectionFilter'

function SitterSelection() {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' })

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (isSmallScreen ? 375 : 150)) {
        setIsCollapsed(true)
      } else {
        setIsCollapsed(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="mx-auto w-full px-6 py-4 sm:w-[90%] md:w-[80%] lg:w-[70%]">
        <SelectionFilter
          isCollapsed={isCollapsed}
          isSmallScreen={isSmallScreen}
        />
        <Sitters />
      </div>
    </>
  )
}

export default SitterSelection
