import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const sitterSlice = createSlice({
  name: 'sitters',
  initialState,
  reducers: {
    setSitters(state, action) {
      return action.payload
    },
    saveSitter(state, action) {
      return state.push(action.payload)
    },
  },
})

export const { setSitters, saveSitter } = sitterSlice.actions
export default sitterSlice.reducer
