import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { classNames } from '@utils/formatFunctions'
import 'dayjs/locale/es'

function RequestsCalendar({
  calendarRef,
  data,
  selectedDate,
  setSelectedDate,
}) {
  dayjs.locale('es')
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'))
  const [days, setDays] = useState([])

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    if (selectedDate === formattedDate) {
      setSelectedDate(null)
      return
    }
    setSelectedDate(formattedDate)
  }

  useEffect(() => {
    const startOfMonth = currentMonth.startOf('month').startOf('week')
    const endOfMonth = currentMonth.endOf('month').endOf('week')
    const daysArray = []
    let day = startOfMonth

    while (day.isBefore(endOfMonth)) {
      daysArray.push({
        date: day.format('YYYY-MM-DD'),
        isCurrentMonth: day.month() === currentMonth.month(),
        isToday: day.isSame(dayjs(), 'day'),
      })
      day = day.add(1, 'day')
    }
    setDays(daysArray)
  }, [currentMonth])

  const handlePreviousMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'))
  }

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'))
  }

  return (
    <div ref={calendarRef} className="w-full rounded-xl bg-white p-4 shadow-xl">
      <div className="flex items-center p-4">
        <h2 className="flex-auto font-mont-semi text-2xl font-semibold text-grey-base">
          {currentMonth.format('MMMM YYYY')}
        </h2>
        <button
          type="button"
          onClick={handlePreviousMonth}
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Mes anterior</span>
          <ChevronLeftIcon className="h-10 w-10" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Mes siguiente</span>
          <ChevronRightIcon className="h-10 w-10" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-base leading-6 text-gray-500">
        <div>Lunes</div>
        <div>Martes</div>
        <div>Miércoles</div>
        <div>Jueves</div>
        <div>Viernes</div>
        <div>Sábado</div>
        <div>Domingo</div>
      </div>
      <div className="mt-4 grid grid-cols-7 text-base">
        {days.map((day, dayIdx) => (
          <div
            key={day.date}
            className={classNames(
              dayIdx > 6 && 'border-t border-gray-200',
              'py-6',
            )}
          >
            <button
              type="button"
              onClick={() => handleDateChange(day.date)}
              className={classNames(
                'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
                (() => {
                  const selected = selectedDate === day.date
                  if (data[day.date]) {
                    switch (data[day.date][0].record.type) {
                      case 'dogWalking':
                        return `border border-yellow-sec ${selected ? 'bg-yellow-sec' : 'bg-yellow-100'} hover:bg-yellow-sec`
                      case 'visit':
                        return `border border-blue-ter ${selected ? 'bg-blue-ter' : 'bg-blue-100'} hover:bg-blue-ter`
                      default:
                        return `border border-purple-base ${selected ? 'bg-purple-base' : 'bg-purple-100'} hover:bg-purple-base`
                    }
                  }
                  return `hover:bg-grey-soft ${selected ? 'bg-grey-soft' : 'bg-white'}`
                })(),
              )}
            >
              <div className="gap-0.2 flex flex-col">
                <time dateTime={day.date}>
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                {day.isToday && (
                  <span className="px-0.1 rounded-full bg-grey-soft py-0.5"></span>
                )}
              </div>
              {data[day.date] && data[day.date].length > 1 && (
                <div className="relative">
                  <div className="bg-grey-sec absolute -right-6 -top-6 flex h-6 w-6 items-center justify-center rounded-full text-xs text-white">
                    +{data[day.date].length - 1}
                  </div>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

RequestsCalendar.propTypes = {
  calendarRef: PropTypes.object,
  data: PropTypes.object,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
}

export default RequestsCalendar
