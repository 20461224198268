import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import {
  StarsRenderer,
  debtRenderer,
  numberRenderer,
} from '@common/helpers/tableRenderers'

function SitterList() {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['adminSitters'],
    queryFn: () => getData('user/sitters'),
  })

  const filteredData = data
    ? data.filter(
        (sitter) =>
          (sitter.name + ' ' + sitter.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          sitter.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          sitter.phone
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          ('pendiente'.includes(searchTerm.toLowerCase()) && sitter.debt > 0) ||
          ('pagado'.includes(searchTerm.toLowerCase()) && sitter.debt === 0),
      )
    : null

  if (isPending) return <Loading />

  const tableProps = [
    {
      key: 'userFullName',
      header: { name: 'Nombre' },
    },
    {
      key: 'email',
      header: { name: 'Email' },
    },
    {
      key: 'phone',
      header: { name: 'Teléfono' },
    },
    {
      key: 'stars',
      header: { name: 'Estrellas', style: { textAlign: 'center' } },
      renderer: StarsRenderer,
    },
    {
      key: 'finishedRecords',
      header: { name: 'Servicios', style: { textAlign: 'center' } },
      renderer: numberRenderer,
    },
    {
      key: 'debt',
      header: {
        name: 'Estado de Pago',
        style: { textAlign: 'center' },
      },
      renderer: debtRenderer,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((request) => {
      return {
        ...request,
        userFullName: `${request.name} ${request.lastName}`,
      }
    })
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Buscar sitter'}
      />
      <CustomTable
        data={tableData}
        tableProps={tableProps}
        rowClickHandler={(sitter) =>
          navigate(`/admin/sitter-view/${sitter.id}`, {
            state: { sitter: sitter },
          })
        }
      />
      {isError ? <Error message={error.message} /> : null}
    </div>
  )
}

export default SitterList
