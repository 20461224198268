import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getData, postData, deleteData, putData } from '@services/requests'
import { toast } from 'react-toastify'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useGetByUser = () => {
  return useQuery({
    queryKey: ['pets'],
    queryFn: async () => await getData('pet'),
  })
}

export const useDeletePet = (petId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => await deleteData(`pet/${petId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pets'] })
      notifySuccess('Mascota eliminada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdatePet = () => {
  const queryClient = useQueryClient()

  const updatePetFn = async ({ id, values }) => {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (key === 'descriptions') {
        formData.append(key, JSON.stringify(value))
      } else if (key === 'photo') {
        if (value instanceof File) {
          formData.append('photo', value)
        }
      } else if (value !== null && value !== undefined) {
        formData.append(key, value)
      }
    })

    return await putData(`pet/${id}`, formData, {
      'Content-Type': 'multipart/form-data',
    })
  }

  return useMutation({
    mutationFn: updatePetFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pets'] })
      notifySuccess('Mascota actualizada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const usePostPet = () => {
  const queryClient = useQueryClient()

  const postPetFn = async (values) => {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (key === 'descriptions') {
        formData.append(key, JSON.stringify(value))
      } else if (key === 'photo' && value instanceof File) {
        formData.append('photo', value)
      } else if (value !== null && value !== undefined) {
        formData.append(key, value)
      }
    })

    return postData('pet', formData, {
      'Content-Type': 'multipart/form-data',
    })
  }

  return useMutation({
    mutationFn: postPetFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pets'] })
      notifySuccess('Mascota creada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
