/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux'
import { useState, useMemo } from 'react'
import { useForm } from '@tanstack/react-form'
import { yupValidator } from '@tanstack/yup-form-adapter'
import * as yup from 'yup'

import CustomModal from '@common/layouts/CustomModal'
import FieldError from '@common/error/FieldError'
import FormButton from '@common/buttons/FormButton'
import { useUpdateUser } from '@services/user'

function PersonalInfo() {
  const user = useSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const { isPending: updateIsPending, mutate: updateUser } = useUpdateUser()

  const fields = useMemo(
    () => [
      {
        index: 0,
        name: 'name',
        label: 'Nombre',
        value: user.name,
        header: 'Actualiza tu Nombre',
        subHeader: '¡Cambia tu nombre para que todos te conozcan! 🐶',
        type: 'text',
        yup: yup.string().required('Debes ingresar un nombre'),
      },
      {
        index: 1,
        name: 'lastName',
        label: 'Apellidos',
        value: user.lastName,
        header: 'Cambia tu Apellido',
        subHeader: '¡Cambia tu apellido para que todos te conozcan!  🐾',
        type: 'text',
        yup: yup.string().required('Debes ingresar un apellido'),
      },
      {
        index: 2,
        name: 'email',
        label: 'Email',
        value: user.email,
        header: 'Modifica tu Email',
        subHeader:
          '¡Cambia tu correo electrónico para que puedas recibir notificaciones! 📬',
        type: 'email',
        yup: yup
          .string()
          .email('Debe ser una dirección de correo electrónico válida')
          .required('Debes ingresar un correo electrónico'),
      },
      {
        index: 3,
        name: 'phone',
        label: 'Teléfono',
        value: `${user.phone}`,
        header: 'Cambia tu Teléfono',
        subHeader:
          '¡Cambia tu número de teléfono para que puedas recibir notificaciones! 📞',
        type: 'tel',
        yup: yup
          .string()
          .matches(
            /^9(\s*\d{4}\s*\d{4}|\d{4}\s*\d{4}|\s*\d{6})$/,
            'El Teléfono debe contener solo números',
          )
          .required('Debes ingresar un teléfono'),
      },
    ],
    [user],
  )

  const modalContent = useMemo(
    () => ({
      header: (
        <div className="space-y-2">
          <p className="font-tex-bold text-lg text-grey-base">
            {fields[index].header}
          </p>
          <p className="text-xs text-grey-sec">{fields[index].subHeader}</p>
        </div>
      ),
      body: (
        <UpdateFieldForm
          formOpts={{
            defaultValues: {
              [fields[index].name]: fields[index].value,
            },
          }}
          fieldName={fields[index].name}
          fieldYup={fields[index].yup}
          fieldType={fields[index].type}
          updateUser={updateUser}
          updateIsPending={updateIsPending}
          onClose={() => setIsOpen(false)}
        />
      ),
    }),
    [index, fields, updateUser, updateIsPending],
  )

  return (
    <div>
      <h2 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
        Perfil
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        ¡Aquí está tu perfil! Puedes actualizar tu información personal. 🐶
      </p>

      <dl className="mt-6 space-y-6 divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6">
        {fields.map((field) => (
          <div key={field.name} className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {field.label}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{field.value}</div>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(true)
                  setIndex(field.index)
                }}
                className="font-semibold text-blue-soft hover:text-blue-600"
              >
                Actualizar
              </button>
            </dd>
          </div>
        ))}
      </dl>
      <CustomModal
        header={modalContent.header}
        body={modalContent.body}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

const UpdateFieldForm = ({
  formOpts,
  fieldName,
  fieldYup,
  fieldType,
  updateUser,
  updateIsPending,
  onClose,
}) => {
  const form = useForm({
    ...formOpts,
    onSubmit: ({ value }) => {
      updateUser(value)
      onClose()
    },
    validatorAdapter: yupValidator(),
  })

  return (
    <form
      className="space-y-4"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <form.Field
        name={fieldName}
        validators={{
          onChange: fieldYup,
        }}
      >
        {(field) => (
          <>
            <input
              className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
              id={fieldName}
              name={fieldName}
              value={field.state.value}
              type={fieldType}
              onChange={(e) => field.handleChange(e.target.value)}
            />
            <div>
              {field.state.meta.isTouched && field.state.meta.errors.length ? (
                <FieldError message={field.state.meta.errors.join(', ')} />
              ) : null}
            </div>
          </>
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || updateIsPending}
            isDisabled={!canSubmit}
            text={'Actualizar'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

export default PersonalInfo
