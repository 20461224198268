import PropTypes from 'prop-types'
import { useForm } from '@tanstack/react-form'
import { yupValidator } from '@tanstack/yup-form-adapter'
import * as yup from 'yup'
import FormButton from '@common/buttons/FormButton'
import FormInput from '@common/inputs/FormInput'
import FormComboBox from '@common/inputs/FormComboBox'
import { usePostAdminAddress } from '@services/address'
import { REGIONS } from '@assets/constants'
import { getData } from '@services/requests'
import { useQuery } from '@tanstack/react-query'

const validations = {
  userId: yup.string().required('Se debe incluir el ID del usuario'),
  region: yup.string().required('Debes ingresar una región'),
  commune: yup.string().required('Debes ingresar una comuna'),
  street: yup.string().required('Debes ingresar una calle'),
  department: yup.string().required('Debes ingresar un departamento'),
}

function CreateAddress({ onSubmit }) {
  const { isPending, mutate: postAddress } = usePostAdminAddress()
  const form = useForm({
    onSubmit: ({ value }) => {
      const { userId, ...rest } = value
      const data = {
        userId: userId,
        body: rest,
      }
      postAddress(data)
      onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      region: '',
      commune: '',
      street: '',
      department: '',
    },
  })

  const { data: usersData } = useQuery({
    queryKey: ['adminUsers'],
    queryFn: () => getData('user/'),
  })

  const usersOptions =
    usersData?.map((user) => ({
      value: user.id,
      label: `${user.name} ${user.lastName}`,
    })) || []

  return (
    <form
      className="space-y-4"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <div className="w-full">
        <form.Field name="userId">
          {(field) => (
            <FormComboBox
              data={usersOptions || []}
              field={field}
              name="userId"
              label="Usuario"
            />
          )}
        </form.Field>
      </div>

      <form.Field
        name="region"
        validators={{
          onChange: validations.region,
        }}
      >
        {(field) => (
          <FormComboBox
            data={REGIONS}
            field={field}
            name="region"
            label="Región"
          />
        )}
      </form.Field>
      <form.Subscribe selector={(state) => state.values.region}>
        {(selectedRegion) => {
          const communes =
            REGIONS.find(
              (region) => region.value === selectedRegion,
            )?.communes.map((c, i) => ({ id: i, value: c })) || []

          return (
            <form.Field name="commune">
              {(field) => (
                <FormComboBox
                  data={communes}
                  field={field}
                  name="commune"
                  label="Comuna"
                />
              )}
            </form.Field>
          )
        }}
      </form.Subscribe>
      <form.Field
        name="street"
        validators={{
          onChange: validations.street,
        }}
      >
        {(field) => <FormInput field={field} name="street" label="Calle" />}
      </form.Field>
      <form.Field
        name="department"
        validators={{
          onChange: validations.department,
        }}
      >
        {(field) => (
          <FormInput field={field} name="department" label="Departamento" />
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPending}
            isDisabled={!canSubmit}
            text={'Agregar dirección'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

CreateAddress.propTypes = {
  onSubmit: PropTypes.func,
}

export default CreateAddress
