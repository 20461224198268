import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { classNames } from '@utils/formatFunctions'

function PaymentButton({
  handleSubmit,
  disabled,
  price,
  visible,
  pets,
  dates,
  type,
}) {
  return ReactDOM.createPortal(
    <div
      className={classNames(
        'fixed bottom-2 left-0 right-0 z-50 mx-auto flex w-[97%] flex-row items-center justify-between rounded-lg border border-grey-soft bg-white px-4 py-3 transition duration-200 ease-in-out md:mr-4 md:w-[30%]',
        visible ? 'opacity-100' : 'opacity-0',
      )}
    >
      <div className="space-y-0.5 text-xs text-grey-base">
        <p className="font-mont-semi text-xl text-slate-900">
          $ {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} CLP
        </p>
        {dates.length > 0 && (
          <p className="ml-2">
            {dates.length}{' '}
            {type == 'dogWalking'
              ? 'Paseo'
              : type == 'visit'
                ? 'Visita'
                : type == 'dayCare'
                  ? 'Dia'
                  : ''}
            {dates.length > 1 && 's'}
          </p>
        )}
        {pets.length > 0 && (
          <p className="ml-2">
            {pets.length} Mascota{pets.length > 1 && 's'}
          </p>
        )}
      </div>
      <button
        onClick={handleSubmit}
        disabled={disabled}
        className={classNames(
          'flex w-1/2 justify-center rounded-lg p-2 text-sm shadow-sm',
          disabled
            ? 'cursor-not-allowed bg-grey-soft text-white-base'
            : 'bg-blue-soft text-white-base hover:bg-blue-600',
        )}
      >
        Pagar
      </button>
    </div>,
    document.body,
  )
}

PaymentButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  price: PropTypes.number,
  visible: PropTypes.bool,
  pets: PropTypes.array,
  dates: PropTypes.array,
  type: PropTypes.string,
  isSummary: PropTypes.bool,
}

export default PaymentButton
