import Partner from './components/PartnerCard'
import aboutUs from '@assets/images/home/aboutUs.jpg'
import ST from '@assets/images/people/ST.jpg'
import SD from '@assets/images/people/SD.jpg'
import PD from '@assets/images/people/PD.png'
import SR from '@assets/images/people/SR.jpg'
import JM from '@assets/images/people/JM.jpg'

function AboutUs() {
  return (
    <div className="flex justify-center px-4 pb-36 pt-8">
      <div className="flex h-1/4 w-full flex-col rounded-xl bg-white shadow-xl lg:w-1/2 xl:w-1/2">
        <img className="rounded-md" src={aboutUs} alt="Dog" />
        <div className="gap-8xl:p-12 flex flex-col gap-8 p-8 lg:p-12">
          <h1 className="text-left text-3xl text-slate-900 lg:text-4xl xl:text-4xl">
            Acerca de nosotros
          </h1>
          <p className="text-xl text-slate-700 lg:text-2xl xl:text-2xl">
            Creemos que todos merecen el amor incondicional de una mascota y en
            Pets-Okey, nuestra misión es hacer que sea más fácil experimentar
            ese amor. Pets-Okey conecta a los padres de perros, gatos y mascotas
            en general, con cariñosos cuidadores y paseadores en tu barrio.
          </p>
          <p className="text-xl text-slate-700 lg:text-2xl xl:text-2xl">
            Capacitamos a nuestra comunidad de cuidadores y paseadores para
            darte la total confianza de que tus mascotas están bajo el mejor
            cuidado. Ayudamos a nuestra comunidad a generar un gran negocio, que
            les permita generar ingresos adicionales, financiar sus sueños o
            simplemente vivir de lo que más quieren.
          </p>
          <h2 className="text-left text-3xl text-slate-900 lg:text-4xl xl:text-4xl">
            Nuestro equipo
          </h2>
          <div className="flex flex-wrap justify-center gap-12">
            <Partner image={ST} name="Sebastián Torrealba" position="CCO" />
            <Partner
              image={SD}
              name="Sofía Daly"
              position="Socia / Comercial"
            />
            <Partner image={PD} name="Patricio Daly" position="Socio / Legal" />
            <Partner image={SR} name="Sebastián Rodríguez" position="CTO" />
            <Partner image={JM} name="Javier Maturana" position="COO" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
