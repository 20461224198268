/* eslint-disable quotes */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useForm } from '@tanstack/react-form'
import FormInput from '@common/inputs/FormInput'
import { FaCirclePlus } from 'react-icons/fa6'

const validations = {
  daysHours: yup
    .array()
    .of(
      yup.object().shape({
        day: yup
          .number()
          .min(1, 'El día debe ser un número entre 1 y 7')
          .max(7, 'El día debe ser un número entre 1 y 7')
          .required('Cada día debe tener una propiedad day'),
        hour: yup
          .number()
          .min(0, 'La hora debe ser un número entre 0 y 23')
          .max(23, 'La hora debe ser un número entre 0 y 23')
          .required('Cada hora debe tener una propiedad hour'),
      }),
    )
    .min(1, 'Se debe incluir al menos un día y hora')
    .required('Se debe incluir al menos un día y hora'),
}

const PlanDaysHoursForm = ({ daysHours, setDaysHours }) => {

  const form = useForm({
    validatorAdapter: yupValidator(),
    defaultValues: {
      daysHours,
    },
  })

  const values = form.useStore((state) => state.values)

  useEffect(() => {
    setDaysHours(values.daysHours)
  }, [values, setDaysHours])

  const handleClick = () => {
    setDaysHours([...daysHours, { day: '', hour: '' }])
  }

  return (
    <form
      className="flex flex-col"
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <div className="w-full pb-2 pl-2 pt-4 font-semibold text-grey-base">
        Agrega los días y horas
      </div>

      {daysHours?.map((dayHour, index) => (
        <div key={index} className="flex gap-2">
          <div className="w-3/7">
            <form.Field
              name={`daysHours.${index}.day`}
              validators={validations.daysHours}
            >
              {(field) => (
                <FormInput
                  field={field}
                  name={`daysHours.${index}.day`}
                  label="Día (1-7)"
                  min={1}
                  max={7}
                  type="number"
                />
              )}
            </form.Field>
          </div>
          <div className="w-3/7">
            <form.Field
              name={`daysHours.${index}.hour`}
              validators={validations.daysHours}
            >
              {(field) => (
                <FormInput
                  field={field}
                  name={`daysHours.${index}.hour`}
                  label="Hora (0-23)"
                  min={0}
                  max={23}
                  type="number"
                />
              )}
            </form.Field>
          </div>

          <div className="w-1/7 h-full">
            <button
              type="button"
              className="pt-auto ml-2 mt-8 flex items-center justify-center rounded-md bg-blue-sec p-2 text-white"
              onClick={() => handleClick()}
            >
              <FaCirclePlus />
            </button>
          </div>
        </div>
      ))}
    </form>
  )
}

PlanDaysHoursForm.propTypes = {
  daysHours: PropTypes.array.isRequired,
  setDaysHours: PropTypes.func.isRequired,
}

export default PlanDaysHoursForm
