import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useForm } from '@tanstack/react-form'
import FormButton from '@common/buttons/FormButton'
import FormInput from '@common/inputs/FormInput'
import { usePostDiscount, useUpdateDiscount } from '@services/discount'

const validations = {
  code: yup.string().required('Código es requerido'),
  amount: yup
    .number()
    .typeError('El descuento es requerido')
    .required('El descuento es requerido')
    .min(1, 'Debe ser mayor a 0'),
  expireDate: yup.date().required('La fecha de expiración es requerida'),
  usesCounter: yup
    .number()
    .typeError('El descuento es requerido')
    .required('Usos actuales es requerido')
    .min(0, 'Debe ser mayor o igual a 0'),
  maxUses: yup
    .number()
    .typeError('El descuento es requerido')
    .required('Usos máximos es requerido')
    .min(1, 'Debe ser mayor o igual a 1'),
  oneTime: yup.boolean().required('Especifica si es de uso único'),
}

const DiscountForm = ({ onSubmit, discount }) => {
  const { isPending: isPostPending, mutate: createDiscount } = usePostDiscount()
  const { isPending: isPutPending, mutate: updateDiscount } =
    useUpdateDiscount()
  const form = useForm({
    onSubmit: ({ value }) => {
      discount
        ? updateDiscount({ id: discount.id, body: value })
        : createDiscount(value)
      onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      code: discount?.code || '',
      amount: discount?.amount || '',
      expireDate: discount?.expireDate || '',
      usesCounter: discount?.usesCounter || 0,
      maxUses: discount?.maxUses || 1,
      oneTime: discount?.onetime || false,
    },
  })

  return (
    <form
      className="grid grid-cols-1 gap-4 sm:grid-cols-2"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <form.Field
        name="code"
        validators={{
          onChange: validations.code,
        }}
      >
        {(field) => <FormInput field={field} name="code" label="Código" />}
      </form.Field>
      <form.Field
        name="amount"
        validators={{
          onChange: validations.amount,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="amount"
            label="Descuento (%)"
            type="number"
          />
        )}
      </form.Field>
      <form.Field
        name="expireDate"
        validators={{
          onChange: validations.expireDate,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="expireDate"
            label="Fecha de expiración"
            type="date"
          />
        )}
      </form.Field>
      <form.Field
        name="usesCounter"
        validators={{
          onChange: validations.usesCounter,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="usesCounter"
            label="Usos actuales"
            type="number"
          />
        )}
      </form.Field>
      <form.Field
        name="maxUses"
        validators={{
          onChange: validations.maxUses,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="maxUses"
            label="Usos máximos"
            type="number"
          />
        )}
      </form.Field>
      <form.Field
        name="oneTime"
        validators={{
          onChange: validations.oneTime,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="oneTime"
            label="Uso único"
            type="checkbox"
          />
        )}
      </form.Field>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPostPending || isPutPending}
            isDisabled={!canSubmit}
            text={discount ? 'Actualizar descuento' : 'Crear descuento'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

DiscountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  discount: PropTypes.object,
}

export default DiscountForm
