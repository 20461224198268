import { useNavigate } from 'react-router-dom'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { RiPassPendingLine } from 'react-icons/ri'
import { FaCheck } from 'react-icons/fa'
import { TiCancel } from 'react-icons/ti'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { putData, deleteData, getData } from '@services/requests'
import profilePhoto from '@assets/images/others/profile.png'
import LoadingButton from '@common/buttons/LoadingButton'
import Error from '@common/error/Error'
import Loading from '@common/utils/Loading'

function AdminSitterRequest() {
  const { requestId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['sitterRequestCard', requestId],
    queryFn: () => getData(`sitter-request/${requestId}`),
  })

  // TODO: arreglar ruta servidor
  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateRequest,
  } = useMutation({
    mutationFn: async (body) => {
      const data = await putData(
        `sitter-request/${requestId}`,
        body,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sitterRequestCard', requestId],
      })
    },
  })

  // TODO: arreglar ruta servidor
  const {
    isPending: deleteIsPending,
    isError: deleteIsError,
    error: deleteError,
    mutate: deleteRequest,
  } = useMutation({
    mutationFn: () =>
      deleteData(`/sitter-request/${requestId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sitterRequestCard', requestId],
      })
      navigate('/admin')
    },
  })

  if (isPending) return <Loading />

  return (
    <div className="mx-auto p-2 sm:p-4">
      <div className="space-y-2 rounded-lg bg-white p-2 shadow-lg sm:space-y-12 sm:p-8">
        <div className="space-y-4">
          <div className="flex flex-row items-center justify-between px-4 sm:px-0">
            <div className="">
              <h3 className="font-tex-bold text-base leading-7 text-gray-900">
                Postulación Sitter
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Detalles personales y de postulación.
              </p>
            </div>
            <p
              className={`ml-4 h-8 px-2 sm:px-4 ${
                data?.status == 'pending'
                  ? 'bg-yellow-100 text-yellow-base'
                  : data?.status == 'accepted'
                    ? 'bg-green-100 text-green-base'
                    : 'bg-red-100 text-red-base'
              } flex flex-row items-center justify-center gap-2 rounded-full text-sm sm:text-base`}
            >
              {data?.status == 'pending' ? (
                <RiPassPendingLine />
              ) : data?.status == 'accepted' ? (
                <FaCheck />
              ) : (
                <TiCancel />
              )}
              {data?.status == 'pending'
                ? 'Pendiente'
                : data?.status == 'accepted'
                  ? 'Aceptado'
                  : 'Rechazado'}
            </p>
          </div>
          <div className="w-full px-4 sm:w-[25%] sm:px-0">
            {' '}
            {data?.status === 'pending' ? (
              <div className="flex w-full flex-row gap-4">
                <LoadingButton
                  loading={updateIsPending}
                  textColor={'white'}
                  bgColor={'green'}
                  circleColor={'white'}
                  fillColor={'green'}
                  text={'Aceptar'}
                  onClick={() => updateRequest({ status: 'accepted' })}
                />
                <LoadingButton
                  loading={updateIsPending}
                  textColor={'white'}
                  bgColor={'red'}
                  circleColor={'white'}
                  fillColor={'red'}
                  text={'Rechazar'}
                  onClick={() => updateRequest({ status: 'denied' })}
                />
              </div>
            ) : (
              <LoadingButton
                loading={deleteIsPending}
                textColor={'white'}
                bgColor={'red'}
                circleColor={'white'}
                fillColor={'red'}
                text={'Eliminar Solicitud'}
                onClick={() => deleteRequest()}
              />
            )}
          </div>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Postulante
              </dt>
              <dd className="mt-1 flex flex-row items-center gap-4 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <img
                  className="w-12 rounded-full"
                  src={data?.user.photoUrl ? data?.user.photoUrl : profilePhoto}
                  alt="Profile photo"
                />
                {data?.user.name} {data?.user.lastName}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                RUT
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.user.rut}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Dirección Email
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.user.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Teléfono
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                +56 {data?.user.phone}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Sobre {data?.user.name}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.description}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Adjunto
              </dt>
              <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul
                  role="list"
                  className="divide-y divide-gray-100 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {data?.certificateUrl
                            ? 'Certificado de antecedentes'
                            : 'Sin adjunto'}
                        </span>
                        <span className="flex-shrink-0 text-gray-400">
                          {data?.certificateUrl ? 'PDF' : null}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={
                          data?.certificateUrl ? data?.certificateUrl : null
                        }
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        {data?.certificateUrl ? 'Revisar' : null}
                      </a>
                    </div>
                  </li>
                  {data?.certificateUrl ? (
                    <iframe
                      src={data?.certificateUrl}
                      width="100%"
                      height="400px"
                    ></iframe>
                  ) : null}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
        {updateIsError ? <Error message={updateError.message} /> : null}
        {deleteIsError ? <Error message={deleteError.message} /> : null}
        {isError ? <Error message={error.message} /> : null}
      </div>
    </div>
  )
}

export default AdminSitterRequest
