'use client'

import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import { X } from 'lucide-react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function FormMultipleComboBox({
  name,
  label,
  data,
  field,
  placeholder,
  isActiveAddOption = true,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  const toggleOption = (optionValue) => {
    const currentValue = field.state.value || []
    field.handleChange(
      currentValue.includes(optionValue)
        ? currentValue.filter((value) => value !== optionValue)
        : [...currentValue, optionValue],
    )
    setSearchTerm('')
    inputRef.current?.focus()
    setHighlightedIndex(-1)
  }

  const addCustomOption = () => {
    const currentValue = field.state.value || []
    field.handleChange([...currentValue, searchTerm])
    setSearchTerm('')
    setIsOpen(false)
  }

  const removeOption = (optionValue, e) => {
    e.stopPropagation()
    field.handleChange(
      field.state.value.filter((value) => value !== optionValue),
    )
    inputRef.current?.focus()
  }

  const filteredData = data.filter(
    (option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !(field.state.value || []).includes(option.value),
  )

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (highlightedIndex >= 0 && filteredData[highlightedIndex]) {
        toggleOption(filteredData[highlightedIndex].value)
      } else if (searchTerm.trim() !== '') {
        addCustomOption()
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredData.length - 1 ? prevIndex + 1 : 0,
      )
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredData.length - 1,
      )
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
        setHighlightedIndex(-1)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      <label
        htmlFor={name}
        className="mb-2 block font-mont-semi text-sm leading-6 text-grey-base"
      >
        {label}
      </label>
      <div
        className={
          'flex cursor-text flex-wrap items-center rounded-lg border border-gray-300 bg-white py-0.5 pr-2 focus-within:ring-1 focus-within:ring-blue-base focus-within:ring-offset-2'
        }
        onClick={() => {
          setIsOpen(true)
          inputRef.current?.focus()
        }}
      >
        {(field.state.value || []).map((optionValue) => (
          <span
            key={optionValue}
            onClick={(e) => removeOption(optionValue, e)}
            className="m-1 flex cursor-pointer items-center rounded-full bg-blue-ter px-2 py-1 text-xs font-medium text-white"
          >
            {data.find((item) => item.value === optionValue)?.label ||
              optionValue ||
              ''}
            <X className="ml-1 h-4 w-4" />
          </span>
        ))}
        <input
          ref={inputRef}
          type="text"
          className="flex-grow border-none bg-transparent text-xs text-grey-base outline-none placeholder:text-grey-soft focus:ring-0"
          placeholder={
            (field.state.value || []).length === 0 ? placeholder : ''
          }
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setIsOpen(true)
            setHighlightedIndex(0)
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
        />
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
      {isOpen && (filteredData.length > 0 || searchTerm.trim() !== '') && (
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {filteredData.map((option, index) => (
            <li
              key={option.value}
              className={`flex cursor-pointer items-center p-2 text-sm text-grey-base ${
                index === highlightedIndex ? 'bg-gray-200' : 'hover:bg-gray-100'
              }`}
              onClick={() => toggleOption(option.value)}
            >
              {option.label}
            </li>
          ))}
          {searchTerm.trim() !== '' &&
            isActiveAddOption &&
            !filteredData.some((option) => option.label === searchTerm) && (
              <li
                className="flex cursor-pointer items-center p-2 text-sm text-blue-500 hover:bg-gray-100"
                onClick={addCustomOption}
              >
                Agregar &quot;{searchTerm}&quot;
              </li>
            )}
        </ul>
      )}
    </div>
  )
}

FormMultipleComboBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  isActiveAddOption: PropTypes.bool,
}

FormMultipleComboBox.defaultProps = {
  placeholder: 'Selecciona o ingresa opciones...',
}
