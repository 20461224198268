import PropTypes from 'prop-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'
import mercadopago from '@assets/images/payment/mercadopago.png'
import transbank from '@assets/images/payment/transbank.png'
import webpay from '@assets/images/payment/webpay.png'
import oneclick from '@assets/images/payment/oneclick.png'
import LoadingCircle from '@common/utils/LoadingCircle'
import { useSessionStorage } from '@common/context/sessionStorageContext'
import {
  useCreateWpTransaction,
  useCreateOcInscription,
  useCommitOcInscription,
  useAuthorizeOcInscription,
} from '@services/payment'
import DiscountButton from './DiscountButton'

import { usePaymentHandler } from '@common/hooks/usePaymentHandler'

const urlBase = window.location.origin
function MethodSelection({ isOpen, onOpen, onClose }) {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [searchParams] = useSearchParams()
  const [step, setStep] = useState(0)
  const [tokenWp, setTokenWp] = useState(null)
  const [urlWp, setUrlWp] = useState(null)
  const [tokenOc, setTokenOc] = useState(null)
  const [urlOc, setUrlOc] = useState(null)
  const { mutate: createWpTransaction } = useCreateWpTransaction()
  const { mutate: createOcInscription } = useCreateOcInscription()
  const { mutate: commitOcInscription } = useCommitOcInscription()
  const { isPending, mutate: authorizeOcTransaction } =
    useAuthorizeOcInscription()
  const [serviceRecordInfo] = useSessionStorage('serviceRecordInfo', null)

  const { paymentAmount, userId, paymentEntityType } = usePaymentHandler()

  useEffect(() => {
    const TBK_TOKEN = searchParams.get('TBK_TOKEN')
    if (TBK_TOKEN) {
      commitOcInscription(
        { token: TBK_TOKEN },
        {
          onSuccess: () => {
            createOcInscription({ returnUrl: window.location.href })
            setStep(2)
            onOpen()
          },
          onError: () => {
            setStep(0)
            createOcInscription({ returnUrl: window.location.href })
            createWpTransaction()
            onOpen()
          },
        },
      )
      searchParams.delete('TBK_TOKEN')
    }
    window.history.replaceState({}, document.title, window.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleTbkClick = () => {
    setStep(1)
    const body = {
      amount: paymentAmount,
      returnUrl: `${urlBase}/payment/webpay/commit`,
      userId: userId,
    }
    createWpTransaction(body, {
      onSuccess: (data) => {
        setTokenWp(data.token)
        setUrlWp(data.url)
      },
    })
  }

  const handleOcClick = () => {
    setStep(2)
    createOcInscription(
      {
        returnUrl: window.location.href,
      },
      {
        onSuccess: (data) => {
          setTokenOc(data.token)
          setUrlOc(data.url_webpay)
        },
      },
    )
  }

  return (
    <div
      className={`fixed ${
        isOpen ? 'flex' : 'hidden'
      } inset-0 z-50 items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-50"> </div>
      <div className="relative mx-2 w-full overflow-y-auto rounded-lg bg-white p-6 shadow-lg sm:max-w-md sm:overflow-y-visible">
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-row justify-between">
            {step !== 0 && (
              <p
                className="ghost absolute left-3 top-3 rounded-full p-2 text-lg hover:bg-slate-100 focus:outline-none"
                onClick={
                  step === 1
                    ? () => {
                        setStep(0)
                      }
                    : step == 2
                      ? () => {
                          setStep(1)
                        }
                      : null
                }
              >
                {' '}
                <FaArrowLeft />
              </p>
            )}
            <p className="text-start font-mont-semi text-grey-base">
              Monto a pagar: CLP $
              {paymentAmount
                ? paymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                : 0}
            </p>
            <button
              type="button"
              onClick={() => {
                setStep(0)
                onClose()
              }}
              className="ghost absolute right-2 top-2 rounded-full px-2 text-4xl hover:bg-slate-100 focus:outline-none"
            >
              &times;
            </button>
          </div>
          {step === 0 && (
            <div className="w-full space-y-4">
              <div
                onClick={() => handleTbkClick()}
                className="flex h-36 cursor-pointer items-center rounded-lg border-2 border-grey-soft p-12 hover:bg-slate-100"
              >
                <img src={transbank} alt="Transbank" />
              </div>
              <div
                onClick={() => {
                  navigate('/payment/mercadopago')
                  setStep(0)
                  onClose()
                }}
                className="flex h-36 cursor-pointer items-center rounded-lg border-2 border-grey-soft p-12 hover:bg-slate-100"
              >
                <img src={mercadopago} alt="MercadoPago" />
              </div>
              {paymentEntityType === 'serviceRecord' && <DiscountButton />}
            </div>
          )}
          {step == 1 && !tokenWp && !urlWp && (
            <div className="flex w-full items-center justify-center">
              <LoadingCircle size="10" />
            </div>
          )}
          {step == 1 && tokenWp && urlWp && (
            <div className="flex w-full flex-col items-center gap-4">
              <form method="post" action={urlWp} className="w-full">
                <input type="hidden" name="token_ws" value={tokenWp} />
                <div className="flex h-40 w-full items-center justify-center rounded-lg border-2 border-grey-soft hover:bg-slate-100">
                  <img
                    src={webpay}
                    alt="Webpay Plus"
                    className="absolute h-24 w-80"
                  />
                  <input
                    type="submit"
                    className="z-10 h-40 w-full cursor-pointer opacity-0"
                  />
                </div>
              </form>
              {paymentEntityType === 'serviceRecord' && (
                <div
                  onClick={() => handleOcClick()}
                  className="flex h-40 cursor-pointer items-center rounded-lg border-2 border-grey-soft p-12 hover:bg-slate-100"
                >
                  <img src={oneclick} alt="WebPay One Click" />
                </div>
              )}
            </div>
          )}
          {step === 2 && !tokenOc && !urlOc && (
            <div className="flex w-full items-center justify-center">
              <LoadingCircle size="10" />
            </div>
          )}
          {step === 2 && urlOc && tokenOc && (
            <div className="flex w-full flex-col items-center gap-4">
              <img src={oneclick} alt="MercadoPago" className="h-24" />
              {user.tbkOcInfo && (
                <button
                  onClick={() => authorizeOcTransaction({ serviceRecordInfo })}
                  className="my-2 flex w-full items-center justify-center rounded-md bg-blue-soft px-3.5 py-2.5 text-sm text-white-base shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  {isPending ? (
                    <svg
                      aria-hidden="true"
                      className="h-6 w-6 animate-spin fill-blue-soft text-white-base"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <p>
                      Pagar con{' '}
                      <span className="font-mont-semi">
                        {user.tbkOcInfo.cardType}
                      </span>{' '}
                      terminada en {user.tbkOcInfo.cardNumber.slice(-4)}
                    </p>
                  )}
                </button>
              )}

              <hr className="w-full" />
              <form method="post" action={urlOc} className="w-full">
                <input type="hidden" name="TBK_TOKEN" value={tokenOc} />
                <input
                  type="submit"
                  value={
                    user.tbkOcInfo
                      ? 'Pagar con otra tarjeta'
                      : 'Inscribir tarjeta'
                  }
                  className="w-full cursor-pointer rounded-md p-2 font-mont-semi hover:bg-slate-100"
                />
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MethodSelection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MethodSelection
