/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useEffect } from 'react'

const SessionStorageContext = createContext()

export function SessionStorageProvider({ children }) {
  const [storageValues, setStorageValues] = useState(() => {
    const values = {}
    Object.keys(window.sessionStorage).forEach((key) => {
      try {
        values[key] = JSON.parse(window.sessionStorage.getItem(key))
      } catch (err) {
        console.error('Error parsing sessionStorage value', key, err)
        values[key] = null
      }
    })
    return values
  })

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key) {
        try {
          const newValue = event.newValue ? JSON.parse(event.newValue) : null
          setStorageValues((prevValues) => ({
            ...prevValues,
            [event.key]: newValue,
          }))
        } catch (err) {
          console.error('Error parsing sessionStorage value', err)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const setValue = (key, newValue) => {
    try {
      const valueToStore =
        newValue instanceof Function ? newValue(storageValues[key]) : newValue
      setStorageValues((prevValues) => ({
        ...prevValues,
        [key]: valueToStore,
      }))
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      window.dispatchEvent(new Event('storage'))
    } catch (err) {
      console.error('Error setting sessionStorage key', key, err)
    }
  }

  return (
    <SessionStorageContext.Provider value={{ storageValues, setValue }}>
      {children}
    </SessionStorageContext.Provider>
  )
}

export function useSessionStorage(key, defaultValue) {
  const { storageValues, setValue } = useContext(SessionStorageContext)
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(key)
      return value !== null ? JSON.parse(value) : defaultValue
    } catch (err) {
      console.error('Error reading sessionStorage key', key, err)
      return defaultValue
    }
  })

  useEffect(() => {
    setStoredValue(
      storageValues[key] !== undefined ? storageValues[key] : defaultValue,
    )
  }, [key, storageValues, defaultValue])

  const setSessionValue = (newValue) => setValue(key, newValue)

  return [storedValue, setSessionValue]
}
