import PropTypes from 'prop-types'
import {
  selectedContainerStyle,
  unselectedContainerStyle,
} from '@assets/css/styles'

function MoreInfoInput({
  sitter,
  selectedMoreInfo,
  setSelectedMoreInfo,
  step,
  setStep,
}) {
  const handleClick = () => {
    if (step !== 4) {
      setStep(4)
    }
  }

  if (step !== 4)
    return (
      <div
        onClick={handleClick}
        className={selectedContainerStyle(false, step === 4)}
      >
        <h2 className="font-mont-semi text-2xl text-grey-base">Mensaje</h2>
        {selectedMoreInfo ? (
          <p className="rounded-full bg-grey-soft px-6 py-4 text-sm text-grey-base sm:text-lg">
            {selectedMoreInfo}
          </p>
        ) : (
          <p className="text-sm text-gray-400 sm:text-lg">
            No hay información adicional :(
          </p>
        )}
      </div>
    )

  return (
    <div
      onClick={handleClick}
      className={unselectedContainerStyle(false, step === 4)}
    >
      <div>
        <h2 className="font-mont-semi text-xl text-grey-base">Mensaje</h2>
        <p className="text-gray-400">
          Comparte un poco de información sobre tu mascota con {sitter.name}
        </p>
      </div>
      <textarea
        onChange={(e) => setSelectedMoreInfo(e.target.value)}
        rows={4}
        name="info"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-soft focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
        defaultValue={selectedMoreInfo}
        placeholder="Hola me gustaría saber si puedes cuidar a mi mascota durante el fin de semana. Es un perro de raza pequeña y es muy amigable."
      />
    </div>
  )
}

MoreInfoInput.propTypes = {
  sitter: PropTypes.object.isRequired,
  selectedMoreInfo: PropTypes.any,
  setSelectedMoreInfo: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default MoreInfoInput
