import PropType from 'prop-types'
import { FaExclamationCircle } from 'react-icons/fa'

function FieldError({ message }) {
  return (
    <div className="ml-1 mt-2  flex flex-row items-center justify-start gap-2 rounded-lg text-xs text-red-base">
      <FaExclamationCircle size={16} />
      <p>{message}</p>
    </div>
  )
}

FieldError.propTypes = {
  message: PropType.string.isRequired,
}

export default FieldError
