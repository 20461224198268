/* eslint-disable react/prop-types */
import { createContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { resetUser } from '../../store/reducers/userSlice'
export const AuthContext = createContext(undefined)

export function AuthProvider({ children }) {
  const dispatch = useDispatch()

  const setAuthToken = (token) => {
    localStorage.setItem('authToken', token)
  }

  const getAuthToken = useCallback(() => {
    const token = localStorage.getItem('authToken') || null
    return token
  }, [])

  const removeAuthToken = useCallback(() => {
    localStorage.removeItem('authToken')
    dispatch(resetUser())
    window.location.href = '/signin'
  }, [dispatch])

  axios.interceptors.request.use(
    (config) => {
      const token = getAuthToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        const token = getAuthToken()
        if (token) {
          console.log('Unauthorized - Token invalid')
          removeAuthToken()
        }
      }
      return Promise.reject(error)
    },
  )

  return (
    <AuthContext.Provider
      value={{ setAuthToken, getAuthToken, removeAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}
