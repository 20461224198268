import firstDogAlternative from '../assets/images/test/firstDogAlternative.png'
import secondDogAlternative from '../assets/images/test/secondDogAlternative.png'
import thirdDogAlternative from '../assets/images/test/thirdDogAlternative.png'
import fourthDogAlternative from '../assets/images/test/fourthDogAlternative.png'

export default {
  code: {
    title: 'Habilidades básicas para ser sitter en Pets-okey',
    options: [
      {
        title: 'Buena comunicación con los tutores de las mascotas',
        subTitle:
          'Es fundamental mantener una buena comunicación con los tutores de las mascotas. Hacer todas las preguntas necesarias sobre la mascota antes de realizar los servicios, para estar alerta en caso de observaciones que levante el tutor. Nunca dejes de ser puntual en tu trabajo. Sigue las rutinas descritas por el tutor y compártale oportunamente reportes de la mascota. Compartir vídeos y fotos del paseo/cuidado con regularidad al tutor cultivará su confianza en ti. Mantener contento al tutor de la mascota lo impulsaría a contratarte como paseador repetidamente y conseguir buenas calificaciones.',
      },
      {
        title:
          'Conocimiento de las técnicas básicas de manejo de perros u otras mascotas',
        subTitle:
          'Un paseador de perros debe tener un amor genuino por los animales y debe sentirse cómodo trabajando con ellos. Son muy importantes las buenas prácticas de manejo de mascotas, como el refuerzo positivo, palabras de apoyo, órdenes de voz comunes, persuasión y más. Un paseador de perros también debe tener conocimientos adecuados sobre el lenguaje corporal del perro y saber interpretar las distintas señales que éste nos transmite durante el paseo, tales como inseguridad, miedo, incomodidas, entre otros. La investigación y la experiencia adecuadas pueden desarrollar su capacidad para identificar problemas imprevistos en el trabajo. Aprender técnicas te diferenciará del resto y te orientará hacia una carrera profesional.',
      },
      {
        title: 'Capacidad para manejar situaciones estresantes',
        subTitle:
          'A veces, un paseador de perros puede verse expuesto a situaciones estresantes imprevistas en ausencia de los dueños de la mascota. Se espera que el paseador de perros mantenga la calma y maneje la situación de manera responsable. Aquí, su aprendizaje y comprensión esenciales le ayudarán a encontrar una solución práctica al problema. Se espera que los paseadores de perros adquieran conocimientos sobre problemas de salud comunes y los reconozcan a tiempo. Los perros pueden demostrar algún comportamiento inusual y dan señales que el paseador de perros debe interpretar.',
      },
    ],
    response:
      'Debe aceptar que cuenta con las habilidades básicas señaladas para ser sitter',
  },
  firstQuestion: {
    title:
      '¿Cuál de los siguientes perros corresponde a un Vizla o Braco Húngaro?',
    options: [
      {
        image: firstDogAlternative,
        correct: false,
        response: 'La primera foto corresponde a un Rottwiler',
      },
      {
        image: secondDogAlternative,
        correct: true,
        response: null,
      },
      {
        image: thirdDogAlternative,
        correct: false,
        response: 'La tercera foto corresponde a un Labrador',
      },
      {
        image: fourthDogAlternative,
        correct: false,
        response: 'La cuarta foto corresponde a un Rhodesian Ridgeback',
      },
    ],
  },
  secondQuestion: {
    title:
      'El sitter debe estar al tanto de cualquier problema médico del perro y tener el contacto de su tutor y algún veterinario de emergencia.',
    options: [
      {
        text: 'Verdadero',
        correct: true,
        response: null,
      },
      {
        text: 'Falso',
        correct: false,
        response:
          'Es muy importante contar con el contacto del Tutor del perro y también algún veterinario en caso de emergencias',
      },
    ],
  },
  thirdQuestion: {
    title:
      '¿Cual de las definiciones a continuación describe a un perro REACTIVO?',
    options: [
      {
        text: 'Agresividad antes otros perros, personas u otros estímulos.',
        correct: false,
        response:
          'La agresividad, es una amenaza intencional de daño hacia un individuo, humano o animal',
      },
      {
        text: 'Intensidad de reacción frente a la aparición de ciertos estímulos externos.',
        correct: true,
        response: null,
      },
      {
        text: 'Ladrar a cualquier estímulo externo que se aproxime.',
        correct: false,
        response:
          'Un perro puede ladrar por diferentes motivos, no necesariamente por que sea reactivo',
      },
      {
        text: 'Todas las anteriores.',
        correct: false,
        response: null,
      },
    ],
  },
  fourthQuestion: {
    title: 'Que un perro mueva la cola, puede significar:',
    options: [
      {
        text: 'Está contento.',
        correct: false,
        response: 'Es correcto, pero no es el único significado',
      },
      {
        text: 'Intenta comunicar “simpatía”.',
        correct: false,
        response: 'Es correcto, pero no es el único significado',
      },
      {
        text: 'Está alerta.',
        correct: false,
        response: 'Es correcto, pero no es el único significado',
      },
      {
        text: 'Todas las anteriores.',
        correct: true,
        response: null,
      },
    ],
  },
  fifthQuestion: {
    title: 'Un sitter puede soltar al perro en el paseo:',
    options: [
      {
        text: 'Cuando los dueños nos indican que lo puede soltar.',
        correct: false,
        response:
          'Independiente de que los dueños indiquen que podemos soltar al perro, no sabemos con qué otros perros o situaciones nos toparemos en el paseo, por eso nunca debemos soltarlo.',
      },
      {
        text: 'Cuando nos damos cuenta de que es un perro tranquilo y no tendrá problemas.',
        correct: false,
        response:
          'Independiente de que se vea un perro tranquilo y obediente, no sabemos con qué otros perros o situaciones nos toparemos en el paseo, por eso nunca debemos soltarlo.',
      },
      {
        text: 'Cuando entramos a un Canil.',
        correct: false,
        response:
          'A pesar de que los caniles son lugares de mayor control para andar con perros sueltos, no sabemos con qué otros perros o situeaciones nos toparemos al interior del canil, por eso nunca debemos soltarlo.',
      },
      {
        text: 'Por seguridad, nunca debo soltar al perro, a pesar que se cumplan A, B y C.',
        correct: true,
        response: null,
      },
    ],
  },
  sixthQuestion: {
    title:
      ' Para tener una buena interpretación del lenguaje corporal de un perro debo:',
    options: [
      {
        text: 'Mirar principalmente la posición de sus orejas.',
        correct: false,
        response:
          'La posición de sus orejas nos puede ayudar bastante a interpretar lo que el perro quiere comunicar, pero es importante considerar su cuerpo completo para tener una mejor interpretación.',
      },
      {
        text: 'Enfocarme sólo en la posición y movimiento de su cola, eso me dará toda la información que necesito.',
        correct: false,
        response:
          'La posición y movimiento de su cola nos puede ayudar bastante a interpretar lo que el perro quiere comunicar, pero es importante considerar su cuerpo completo para tener una mejor interpretación.',
      },
      {
        text: 'Debo mirar al perro en su conjunto, no sólo su cola y orejas, si no también su postura y su cuerpo en su totalidad.',
        correct: true,
        response: null,
      },
      {
        text: 'Centrarme en su mirada y tratar de interpretar qué el lo que está viendo.',
        correct: false,
        response:
          'Difícilmente podremos interpretar el lenguaje de un perro sólo con su mirada, si bien es parte importante de su lenguaje corporal, es importante observar su cuerpo completo.',
      },
    ],
  },
}
