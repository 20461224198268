import PropTypes from 'prop-types'
import {
  format,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSameDay,
  isToday,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { FaCheck, FaPlus } from 'react-icons/fa'

export default function WeekView({
  currentDate,
  data,
  postAvailability,
  deleteAvailability,
  handleDayClick,
}) {
  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 })
  const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 })
  const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd })

  const hours = Array.from({ length: 18 }, (_, i) => i + 6)

  return (
    <div className="flex h-[calc(60vh)] flex-col overflow-hidden rounded-lg bg-white shadow">
      <div className="sticky top-0 z-10 grid grid-cols-8 border bg-white">
        <div className="flex items-center justify-center border-r border-gray-200 text-xs font-semibold text-gray-700">
          Hora
        </div>
        {weekDays.map((day, index) => (
          <div
            key={index}
            onClick={() => handleDayClick(day)}
            className="flex cursor-pointer items-center justify-center border-r bg-gray-50 p-1 text-xs font-medium text-gray-700 hover:bg-gray-100"
          >
            <span className={isToday(day) ? 'text-blue-soft' : ''}>
              {format(day, 'EEE d', { locale: es })}
            </span>
          </div>
        ))}
      </div>

      <div className="grid flex-1 grid-cols-8 overflow-y-auto">
        <div className="border-x bg-gray-50 text-xs text-gray-500">
          {hours.map((hour) => (
            <div
              key={hour}
              className="flex h-[calc(57vh/18)] items-center justify-center border-b"
            >
              {`${hour}:00 ${hour < 12 ? 'AM' : 'PM'}`}
            </div>
          ))}
        </div>

        {weekDays.map((day, dayIndex) => (
          <div key={dayIndex} className="border-r border-gray-200">
            {hours.map((hour) => {
              const availability = data?.find((availability) => {
                const date = new Date(availability.date)
                return isSameDay(date, day) && date.getHours() === hour
              })

              const hourIsChecked = Boolean(availability)

              return (
                <div
                  key={hour}
                  className="flex h-[calc(57vh/18)] cursor-pointer items-center justify-center rounded border-b border-gray-200 p-0.5"
                >
                  {hourIsChecked ? (
                    <div
                      onClick={() => deleteAvailability(availability.id)}
                      className="flex h-full w-full transform items-center justify-center rounded bg-blue-100 text-xs text-blue-ter transition duration-200 hover:scale-105 hover:bg-blue-300"
                    >
                      <FaCheck />
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        postAvailability({
                          date: new Date(day.setHours(hour, 0, 0, 0)),
                        })
                      }
                      className="flex h-full w-full transform items-center justify-center rounded bg-gray-100 text-xs text-gray-300 transition duration-200 hover:scale-105 hover:bg-gray-200"
                    >
                      <FaPlus />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

WeekView.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.array,
  postAvailability: PropTypes.func.isRequired,
  deleteAvailability: PropTypes.func.isRequired,
  handleDayClick: PropTypes.func.isRequired,
}
