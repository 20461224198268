import PropTypes from 'prop-types'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useSelector } from 'react-redux'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useState } from 'react'
import { FaPaw } from 'react-icons/fa'
import { FaDog, FaCat } from 'react-icons/fa6'
import { useDeletePet } from '@services/pet'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import PetForm from '@common/forms/PetForm'

const PetCard = ({ pet }) => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector((state) => state.user)

  const { mutate, isPending } = useDeletePet(pet.id, user.id)

  return (
    <>
      <div className="space-y-4">
        <div className="relative h-[20rem] w-full rounded-lg">
          {pet.photoUrl ? (
            <img
              src={pet.photoUrl}
              alt={`Foto de mascota ${pet.name}`}
              className="h-full w-full rounded-lg object-cover"
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center rounded-lg border-2 border-white bg-slate-900 text-gray-300">
              <FaPaw size={96} />
            </div>
          )}
          <div className="absolute left-0 top-0 z-10 h-full w-full rounded-lg bg-gradient-to-t from-black to-transparent"></div>
          <div className="absolute left-4 top-4 z-10">
            {pet.type === 'Perro' ? (
              <FaDog size={32} color="white" />
            ) : (
              <FaCat size={32} color="white" />
            )}
          </div>
          <div className="absolute right-4 top-4 z-10">
            <Menu as="div" className="relative">
              <MenuButton className="flex items-center rounded-lg text-white hover:bg-black hover:bg-opacity-50">
                <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
              </MenuButton>
              <MenuItems className="absolute right-0 z-20 mt-1 w-40 origin-top-right rounded-md bg-white text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <button
                    onClick={() => {
                      setIsOpen(true)
                    }}
                    className="block w-full rounded px-4 py-2 text-left data-[focus]:bg-grey-soft"
                  >
                    <p>Editar</p>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    onClick={() => {
                      mutate()
                    }}
                    disabled={isPending}
                    className="block w-full rounded px-4 py-2 text-left data-[focus]:bg-grey-soft"
                  >
                    Eliminar
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
          <div className="absolute bottom-3 z-10 space-y-4">
            <div>
              <p className="ml-4 w-fit font-tex-bold text-2xl text-white">
                {pet.name}
              </p>
              <p className="ml-4 w-fit text-base text-white">{pet.breed}</p>
            </div>

            <div className="mx-4 flex flex-row items-center gap-1 text-sm  md:w-1/3 text-white">
              <p className="flex items-center justify-center whitespace-nowrap">
                {pet.sex ? pet.sex : '-'}
              </p>
              <p>-</p>
              <p className="flex items-center justify-center whitespace-nowrap">
                {pet.weight ? pet.weight : '-'} Kilos
              </p>
              <p>-</p>
              <p className="flex items-center justify-center whitespace-nowrap">
                {pet.age ? pet.age : '-'} años
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-start gap-3">
          {pet.descriptions && pet.descriptions.length !== 0
            ? pet.descriptions.map((desc, index) => (
                <p
                  key={index}
                  className="w-fit rounded-full bg-blue-ter px-4 py-0.5 text-xs text-white"
                >
                  {desc}
                </p>
              ))
            : null}
        </div>
      </div>

      <CustomModal
        header={
          <ModalHeader
            title="Actualizar mascota"
            subtitle="Actualiza los datos de tu mascota 🐾"
          />
        }
        body={<PetForm pet={pet} onSubmit={() => setIsOpen(false)} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

PetCard.propTypes = {
  pet: PropTypes.object.isRequired,
}

export default PetCard
