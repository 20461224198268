/* eslint-disable react/prop-types */
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useState, useRef, useEffect } from 'react'
import useFilter from '@zustand/filter'
import { useGetAddresses } from '@services/address'

const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

function ServiceAddressInput() {
  const { selectedAddress, isAddressModalOpen, setIsAddressModalOpen } =
    useFilter()
  const openModal = () => setIsAddressModalOpen(true)
  const closeModal = () => setIsAddressModalOpen(false)

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <label
          htmlFor="serviceAddress"
          className="block font-mont-semi text-sm leading-6 text-grey-base"
        >
          Dirección
        </label>
        <input
          id="serviceAddress"
          placeholder="Selecciona una dirección"
          value={selectedAddress ? selectedAddress.formatted_address : ''}
          readOnly
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          onClick={openModal}
        />
      </div>
      <CustomModal
        isOpen={isAddressModalOpen}
        onClose={closeModal}
        header={
          <ModalHeader
            title="📍 Dirección"
            subtitle="Selecciona donde necesitas el servicio"
          />
        }
        body={<ModalBody isOpen={isAddressModalOpen} onClose={closeModal} />}
      />
    </>
  )
}

function ModalBody({ isOpen, onClose }) {
  const [isDropOpen, setIsDropOpen] = useState(false)
  const dropdownRef = useRef(null)

  const { data } = useGetAddresses()

  const { selectedAddress ,setSelectedAddress } = useFilter()
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: googleMapsApiKey,
      options: { componentRestrictions: { country: 'CL' }, types: ['address'] },
    })

  const [inputValue, setInputValue] = useState(selectedAddress?.formatted_address || '')

  const handleClick = (place) => {
    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      (place, status) => {
        if (status === 'OK') {
          const address = {
            formatted_address: place.formatted_address,
            geometry: {
              location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
            },
          }
          setSelectedAddress(address)
          setInputValue(address.formatted_address)
          getPlacePredictions({ input: '' })
          setIsDropOpen(false)
          onClose()
        }
      },
    )
  }

  const handleSavedAddressClick = (address) => {
    const place = {
      formatted_address: address.formattedAddress,
      geometry: {
        location: {
          lat: address.location.lat,
          lng: address.location.lng,
        },
      },
    }

    setSelectedAddress(place)
    setInputValue(place.formatted_address)
    getPlacePredictions({ input: '' })
    setIsDropOpen(false)
    onClose()
  }

  const handleInputChange = (evt) => {
    const value = evt.target.value
    setIsDropOpen(true)
    setInputValue(value)
    if (value) {
      getPlacePredictions({ input: value })
    }
  }

  const handleClean = () => {
    setSelectedAddress(null)
    onClose()
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const renderDropdown = () => {
    if (!isDropOpen || (!inputValue && (!data || data.length === 0)))
      return null

    const inputRect = dropdownRef.current?.getBoundingClientRect()

    return (
      <div
        className="fixed z-[9999] overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg"
        style={{
          top: `${inputRect?.bottom + window.scrollY - 20}px`,
          left: `${inputRect?.left + window.scrollX}px`,
          width: `${inputRect?.width}px`,
          maxHeight: '240px',
        }}
      >
        {inputValue &&
          placePredictions.map((item, index) => (
            <div key={index}>
              <button
                onClick={() => handleClick(item)}
                className="w-full px-4 py-2 text-left text-sm text-grey-base hover:bg-grey-soft hover:text-slate-900 focus:bg-grey-soft focus:outline-none"
              >
                {item.description}
              </button>
              {index < placePredictions.length - 1 && (
                <hr className="my-1 border-gray-200" />
              )}
            </div>
          ))}

        {!inputValue &&
          data &&
          data.length > 0 &&
          data.map((address, index) => (
            <div key={index}>
              <button
                onClick={() => handleSavedAddressClick(address)}
                className="w-full px-4 py-2 text-left text-sm text-grey-base hover:bg-grey-soft hover:text-slate-900 focus:bg-grey-soft focus:outline-none"
              >
                {address.formattedAddress}
              </button>
              {index < data.length - 1 && (
                <hr className="my-1 border-gray-200" />
              )}
            </div>
          ))}
      </div>
    )
  }

  if (!isOpen) return null

  return (
    <div className="relative w-full max-w-md" ref={dropdownRef}>
      <input
        placeholder="Selecciona una dirección"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsDropOpen(true)}
        className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
      />
      {renderDropdown()}
      <div className="mt-2 flex justify-center">
        <button
          onClick={handleClean}
          className="rounded-lg text-sm text-grey-base underline"
        >
          Limpiar filtro
        </button>
      </div>
    </div>
  )
}

export default ServiceAddressInput
