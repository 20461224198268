import { createSlice } from '@reduxjs/toolkit'
import { eventsCleaner, singleEventCleaner } from '../../utils/eventsCleaner'

const initialState = []

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents(state, action) {
      const cleanEvents = eventsCleaner(action.payload)
      return cleanEvents
    },
    saveEvent(state, action) {
      const event = action.payload
      const cleanEvent = singleEventCleaner(event)
      const newState = [...state, cleanEvent]
      return newState
    },

    deleteEvent(state, action) {
      const id = action.payload
      return state.filter((event) => event.id !== id)
    },
  },
})

export const { setEvents, saveEvent, deleteEvent } = eventSlice.actions
export default eventSlice.reducer
