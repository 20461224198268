/* eslint-disable react-refresh/only-export-components */
import {
  FaCrown,
  FaPerson,
  FaMoneyBill,
  FaFileInvoiceDollar,
} from 'react-icons/fa6'
import { FaStar, FaStarHalf } from 'react-icons/fa'
import ReactStars from 'react-rating-stars-component'
import CountUp from 'react-countup'
import { formatStatus, formatType } from '@utils/formatFunctions'

export const statusRenderer = (params) => {
  const statusStyle = {
    pending: 'bg-yellow-100 text-yellow-sec border border-yellow-sec',
    accepted: 'bg-green-100 text-green-sec border border-green-sec',
    cancelled: 'bg-red-100 text-red-sec border border-red-sec',
    denied: 'bg-red-100 text-red-sec border border-red-sec',
    finished: 'bg-blue-100 text-blue-ter border border-blue-ter',
    waiting: 'bg-yellow-100 text-yellow-sec border border-yellow-sec',
  }
  return (
    <ul
      className={`${statusStyle[params.value]} rounded-xl px-4 py-1 text-center text-xs`}
    >
      {formatStatus(params.value)}
    </ul>
  )
}

export const typeRenderer = (params) => {
  const typeStyle = {
    dogWalking: 'bg-yellow-100 text-yellow-sec border border-yellow-sec',
    visit: 'bg-blue-100 text-blue-ter border border-blue-ter',
    dayCare: 'bg-purple-100 text-purple-base border border-purple-base',
  }
  return (
    <ul
      className={`${typeStyle[params.value]} rounded-xl px-4 py-1 text-center text-xs`}
    >
      {formatType(params.value)}
    </ul>
  )
}

export const payoutStatusRenderer = (status) => {
  const statusStyle = {
    Pendiente: 'bg-yellow-100 text-yellow-sec border border-yellow-sec',
    Pagado: 'bg-green-100 text-green-sec border border-green-sec',
    Rechazado: 'bg-red-100 text-red-sec border border-red-sec',
  }

  const statusFormatted = (() => {
    switch (status) {
      case 'paid':
        return 'Pagado'
      case 'failed':
        return 'Rechazado'
      default:
        return 'Pendiente'
    }
  })()

  return (
    <ul
      className={`${statusStyle[statusFormatted]} rounded-xl px-4 py-1 text-center text-xs`}
    >
      {statusFormatted}
    </ul>
  )
}

export const isSuperSitterRenderer = (isSuperSitter) => (
  <td className="px-6 py-4 text-xs">
    <div
      className={`text-center ${
        isSuperSitter ? 'text-yellow-base' : 'text-slate-500'
      }`}
    >
      <p
        className={`flex flex-row items-center justify-center gap-2 rounded-full px-3 py-2 ${
          isSuperSitter ? 'bg-yellow-100' : 'bg-slate-100'
        }`}
      >
        {isSuperSitter ? <FaCrown size={16} /> : <FaPerson size={16} />}
        {isSuperSitter ? 'Super Sitter' : 'Sitter'}
      </p>
    </div>
  </td>
)

export const debtRenderer = (debt) => (
  <td className="px-6 py-4 text-xs">
    <div className="flex items-center justify-center">
      <p
        className={`flex flex-row items-center justify-center gap-2 rounded-full px-3 py-2 ${
          debt ? 'bg-red-100 text-red-base' : 'bg-green-100 text-green-base'
        }`}
      >
        <FaFileInvoiceDollar size={16} />
        {debt ? 'Pendiente' : 'Pagado'}
      </p>
    </div>
  </td>
)

export const StarsRenderer = (value) => (
  <td className="px-6 py-4 text-xs">
    <div className="flex items-center justify-center">
      <ReactStars
        count={5}
        value={value}
        size={24}
        isHalf={true}
        edit={false}
        filledIcon={<FaStar />}
        halfIcon={<FaStarHalf />}
        emptyIcon={<FaStar />}
        color={'#D9D9D9'}
      />
    </div>
  </td>
)

export const descriptionRenderer = (value, maxLength = 60) => {
  const truncateText = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return text ?? ''
    }
    return text.substring(0, maxLength) + '...'
  }

  const truncatedValue = truncateText(value, maxLength)

  return (
    <td className="px-6 py-4 text-center text-xs" title={value}>
      {truncatedValue}
    </td>
  )
}

export const paymentStatusRenderer = (value) => (
  <td className="whitespace-nowrap px-6 py-4 text-xs">
    <div className="flex flex-row items-center justify-center gap-4">
      <div
        className={`text-center ${value ? 'text-green-base' : 'text-red-base'}`}
      >
        <p
          className={`flex flex-row items-center justify-center gap-2 rounded-full px-3 py-2 ${
            value ? 'bg-green-100' : 'bg-red-100'
          }`}
        >
          {value ? (
            <FaMoneyBill size={16} />
          ) : (
            <FaFileInvoiceDollar size={16} />
          )}
          {value ? 'Pagado' : 'Pendiente'}
        </p>
      </div>
    </div>
  </td>
)

export const userTypeRenderer = (isSitter) => (
  <td className="px-6 py-4 text-xs">
    <div
      className={`text-center ${
        isSitter ? 'text-yellow-base' : 'text-slate-500'
      }`}
    >
      <p
        className={`flex flex-row items-center justify-center gap-2 rounded-full px-3 py-2 ${
          isSitter ? 'bg-yellow-100' : 'bg-slate-100'
        }`}
      >
        <FaPerson size={16} />
        {isSitter ? 'Sitter' : 'Usuario'}
      </p>
    </div>
  </td>
)

export const isActiveUserRenderer = (isActive) => (
  <td className="px-6 py-4 text-xs">
    <div
      className={`text-center ${
        isActive ? 'text-green-base' : 'text-red-base'
      }`}
    >
      <p
        className={`flex flex-row items-center justify-center gap-2 rounded-full px-3 py-2 ${
          isActive ? 'bg-green-100' : 'bg-red-100'
        }`}
      >
        <FaPerson size={16} />
        {isActive ? 'Activo' : 'Inactivo'}
      </p>
    </div>
  </td>
)

export const numberRenderer = (value) => (
  <td className="px-6 py-4 text-xs">
    <div className="flex items-center justify-center">
      <CountUp end={value} duration={1} />
    </div>
  </td>
)

export const textCenterRenderer = (value) => (
  <td className="px-6 py-4 text-center text-xs">{value}</td>
)
