import { useState, useEffect, useRef } from 'react'
import { MdNotifications, MdNotificationsActive } from 'react-icons/md'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getData, deleteData } from '@services/requests'
import NotificationCard from './NotificationCard'
import Loading from '@common/utils/Loading'
import ErrorNoCard from '@common/error/ErrorNoCard'

const NotificationDropdown = () => {
  const queryClient = useQueryClient()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownRef = useRef(null)

  const {
    isPending,
    isError,
    error,
    isSuccess,
    data: notifications,
  } = useQuery({
    queryKey: ['notifications'],
    queryFn: () => getData('notifications'),
  })

  const { mutate: deleteNotification } = useMutation({
    mutationFn: async (id) => await deleteData(`notifications/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] })
    },
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [])

  if (isPending) <Loading />

  return (
    <div className="flex items-center justify-center">
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="relative z-10 block rounded-md bg-white p-2 focus:outline-none"
        >
          {isSuccess && notifications.length > 0 ? (
            <div className="relative">
              <MdNotificationsActive className="h-5 w-5 text-grey-base" />
              <div className="absolute right-0 top-0 h-3 w-3 rounded-full bg-red-500"></div>
            </div>
          ) : (
            <MdNotifications className="h-5 w-5 text-grey-base" />
          )}
        </button>

        {dropdownOpen && (
          <div
            className="absolute right-0 z-20 mt-3 overflow-hidden rounded-md bg-white shadow-lg"
            style={{ width: '20rem' }}
          >
            {isSuccess && (
              <div className="py-2">
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                      handleDeleteClick={() =>
                        deleteNotification(notification.id)
                      }
                    />
                  ))
                ) : (
                  <div className="p-4 text-center text-sm text-grey-base">
                    No tienes notificaciones
                  </div>
                )}
              </div>
            )}
            {isError && <ErrorNoCard message={error.message} />}

            <a
              href="#"
              className="block bg-blue-base py-2 text-center font-bold text-white"
            ></a>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationDropdown
