import { MdOutlinePets } from 'react-icons/md'

function FallBack() {
  return (
    <div className="flex h-screen items-center justify-center bg-white-base">
      <div className="flex flex-col items-center justify-center gap-8 rounded-lg bg-red-base p-16 shadow-lg">
        <h1 className="text-center text-5xl font-bold text-white">
          ¡Ups! Ha ocurrido un error
        </h1>
        <div className="flex flex-col gap-4 text-center">
          <p className="text-lg text-white">
            Lo sentimos, algo salió mal en nuestro lado.
          </p>
          <p className="text-lg text-white">
            Por favor, inténtalo de nuevo más tarde.
          </p>
        </div>
        <MdOutlinePets size={72} color={'#F8F6F2'} />
      </div>
    </div>
  )
}
export default FallBack
