import PropTypes from 'prop-types'

function PartnerCard({ image, name, position }) {
  return (
    <div className="flex flex-col items-center justify-center gap-2 text-center">
      <img
        className="w-32 rounded-full lg:w-44 xl:w-44"
        src={image}
        alt={name}
      />
      <p className="text-base text-slate-900 lg:text-xl xl:text-2xl">{name}</p>
      <p className="text-sm text-slate-400 lg:text-lg xl:text-xl">{position}</p>
    </div>
  )
}

PartnerCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
}

export default PartnerCard
