import { useSessionStorage } from '@common/context/sessionStorageContext'
import { useCreateServiceRecords } from '@services/servicerecord'
import { useUpdatePlanBilling } from '@services/plan'
import { useCallback } from 'react'

export const usePaymentHandler = () => {
  const { mutate: createServiceRecord } = useCreateServiceRecords()
  const { mutate: updatePlan } = useUpdatePlanBilling()
  const [serviceRecordInfo, setServiceRecordInfo] = useSessionStorage(
    'serviceRecordInfo',
    null,
  )
  const [planInfo, setPlanInfo] = useSessionStorage('planInfo', null)

  const paymentAmount = serviceRecordInfo?.price ?? planInfo?.price ?? 0

  const paymentEntityType = serviceRecordInfo ? 'serviceRecord' : 'plan'

  const paymentAmountBeforeDiscount =
    serviceRecordInfo?.priceBeforeDiscount ?? 0

  const getHandleSuccessFunction = useCallback(() => {
    if (serviceRecordInfo) {
      return (paymentId) => {
        createServiceRecord({
          serviceRecordInfo,
          paymentId,
        })
        setServiceRecordInfo(null)
      }
    }
    if (planInfo) {
      const maxPlanBilling = planInfo.billings.reduce((prev, current) =>
        prev.servicesUntil > current.servicesUntil ? prev : current,
      )
      return (paymentId) => {
        updatePlan({
          id: maxPlanBilling.id,
          body: {
            paymentId,
          },
        })
        setPlanInfo(null)
      }
    }
  }, [
    serviceRecordInfo,
    planInfo,
    createServiceRecord,
    updatePlan,
    setServiceRecordInfo,
    setPlanInfo,
  ])

  return {
    getHandleSuccessFunction,
    paymentAmount,
    userId: planInfo?.userId,
    paymentEntityType,
    paymentAmountBeforeDiscount,
  }
}
