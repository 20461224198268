import PropTypes from 'prop-types'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale-subtle.css'
import { Tooltip } from 'react-tippy'

function WeightToolTip({ InfoToShow, image }) {
  return (
    <Tooltip
      html={InfoToShow}
      position="bottom"
      trigger="mouseenter"
      animation="scale-subtle"
    >
      {image}
    </Tooltip>
  )
}

WeightToolTip.propTypes = {
  InfoToShow: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
}

export default WeightToolTip
