import {
  FaAmbulance,
  FaHotel,
  FaPhoneAlt,
  FaBalanceScale,
  FaFirstAid,
  FaShieldAlt,
} from 'react-icons/fa'

import { useNavigate } from 'react-router-dom'
import bciLogo from '@assets/images/home/bci/bciLogo.png'
import logo from '@assets/images/home/bci/logo.png'

const Banner = () => {
  const navigate = useNavigate()
  const title = 'Seguro Mascotas 2024'
  const subtitle = {
    0: 'Asegura a tu perro o gato y',
    1: 'disfruta de los beneficios',
    2: 'de nuestro seguro',
  }

  const icons = [
    { icon: <FaAmbulance />, text: 'Traslado Medico' },
    {
      icon: <FaHotel />,
      text: 'Hotel para mascota por hospitalización o viaje del dueño',
    },
    { icon: <FaPhoneAlt />, text: 'Orientación veterinaria telefónica 24/7' },
    { icon: <FaBalanceScale />, text: 'Orientación legal telefónica' },
    { icon: <FaFirstAid />, text: 'Urgencias en caso de accidente' },
    {
      icon: <FaShieldAlt />,
      text: 'Responsabilidad civil por daños y perjuicios causados por mascotas',
    },
  ]

  return (
    <div className="container mx-auto py-16 sm:py-20 w-[80%] sm:w-[70%] space-y-16">
      <div className="flex flex-col-reverse sm:flex-row items-center justify-center gap-12">
        <div className="flex flex-col items-center justify-center gap-4 sm:w-[35%]">
          <div className="flex flex-row gap-4 items-center px-2">
            <img src={logo} alt="Pets-Okey Logo" className="sm:h-10 h-14" />
            <div className="h-16 sm:h-12 w-[2px] rounded-lg bg-black"></div>
            <img src={bciLogo} alt="Logo bciLogo" className="sm:h-10 h-14" />
          </div>

          <button
            className="w-full rounded-md bg-pink-base px-4 py-2 text-lg font-bold text-white shadow-lg hover:bg-opacity-80"
            onClick={() => navigate('/bci')}
          >
            Contratar
          </button>
        </div>
        <div className="flex flex-col justify-between gap-6">
          <h1 className="font-tex-bold text-2xl sm:text-4xl text-grey-base text-center sm:text-left">{title}</h1>
          <p className="text-base sm:text-2xl text-gray-700 text-center sm:text-left">
            {subtitle[0]} <span className='text-yellow-base font-mont-semi'>{subtitle[1]}</span> {subtitle[2]}
          </p>
        </div>
      </div>

      <div className="grid gap-8 text-center sm:grid-cols-2">
        {icons.map((item, index) => (
          <div key={index} className="flex items-center">
            <div className="rounded-full border-2 border-gray-200 p-3 text-3xl text-pink-base">
              {item.icon}
            </div>
            <p className="mx-4 text-left text-sm text-gray-700">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Banner
