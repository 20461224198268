import { putData, postData, getData } from '@services/requests'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useCreatePlan = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body) => {
      const data = await postData('plan', body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plans'] })
      notifySuccess('Plan creado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdatePlan = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, body }) => {
      const data = await putData(`plan/${id}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plans'] })
      notifySuccess('Plan actualizado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdatePlanBilling = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, body }) => {
      const data = await putData(`plan/billing/paid/${id}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plans'] })
      notifySuccess('Plan actualizado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetPlans = () => {
  return useQuery({
    queryKey: ['plans'],
    queryFn: () => getData('plan'),
  })
}

export const useGetPlan = (id) => {
  return useQuery({
    queryKey: ['plan', id],
    enabled: !!id,
    queryFn: () => getData(`plan/${id}`),
  })
}