import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useForm } from '@tanstack/react-form'
import FormButton from '@common/buttons/FormButton'
import FormInput from '@common/inputs/FormInput'
import FormComboBox from '@common/inputs/FormComboBox'
import FormPhotoInput from '@common/inputs/FormPhotoInput'
import FormMultipleComboBox from '@common/inputs/FormMultipleComboBox'
import { usePostPet, useUpdatePet } from '@services/pet'
import {
  DOG_BREEDS,
  CAT_BREEDS,
  TYPES,
  PET_DESCRIPTIONS,
  PET_SEX,
} from '@assets/constants'

const validations = {
  age: yup
    .number()
    .typeError('Edad es requerida')
    .required('Edad es requerida'),
  breed: yup.string().required('Raza es requerida'),
  photo: yup.mixed().optional().nullable(),
  descriptions: yup.array().optional(),
  name: yup.string().required('Nombre es requerido'),
  sex: yup.string().required('Sexo es requerido'),
  type: yup.string().required('Tipo es requerido'),
  weight: yup
    .number()
    .typeError('Peso es requerido')
    .required('Peso es requerido'),
}

const PetForm = ({ onSubmit, pet }) => {
  const { isPending: isPostPending, mutate: createPet } = usePostPet()
  const { isPending: isPutPending, mutate: updatePet } = useUpdatePet()

  const form = useForm({
    onSubmit: ({ value }) => {
      pet ? updatePet({ id: pet.id, values: value }) : createPet(value)
      onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      age: pet?.age || null,
      breed: pet?.breed || '',
      descriptions:
        Array.isArray(pet?.descriptions) &&
        pet.descriptions.length &&
        pet.descriptions[0] !== null
          ? pet.descriptions
          : [],
      photo: pet?.photo || null,
      name: pet?.name || '',
      sex: pet?.sex || '',
      type: pet?.type || '',
      weight: pet?.weight || null,
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="flex max-w-96 flex-col gap-2"
    >
      <form.Field name="photo" validators={{ onChange: validations.photo }}>
        {(field) => <FormPhotoInput name="photo" field={field} />}
      </form.Field>

      <div className="w-full">
        <form.Field name="name" validators={{ onChange: validations.name }}>
          {(field) => (
            <FormInput
              field={field}
              name="name"
              label="Nombre"
              placeholder="Nombre de tu mascota"
            />
          )}
        </form.Field>
      </div>

      <div className="flex space-x-4">
        <div className="w-1/2">
          <form.Field name="type" validators={{ onChange: validations.type }}>
            {(field) => (
              <FormComboBox
                data={TYPES.map((c, i) => ({ id: i, value: c }))}
                field={field}
                name="type"
                label="Tipo"
                placeholder="Selecciona un tipo"
              />
            )}
          </form.Field>
        </div>
        <div className="w-1/2">
          <form.Field name="sex" validators={{ onChange: validations.sex }}>
            {(field) => (
              <FormComboBox
                data={PET_SEX.map((c, i) => ({ id: i, value: c }))}
                field={field}
                name="sex"
                label="Sexo"
                placeholder="Selecciona un sexo"
              />
            )}
          </form.Field>
        </div>
      </div>

      <div className="w-full">
        <form.Subscribe selector={(state) => state.values.type}>
          {(selectedType) => {
            const breeds =
              selectedType === 'Perro'
                ? DOG_BREEDS.map((c, i) => ({ id: i, value: c }))
                : CAT_BREEDS.map((c, i) => ({ id: i, value: c }))
            return (
              <form.Field
                name="breed"
                validators={{ onChange: validations.breed }}
              >
                {(field) => (
                  <FormComboBox
                    data={breeds}
                    field={field}
                    name="breed"
                    label="Raza"
                    placeholder="Selecciona una raza"
                  />
                )}
              </form.Field>
            )
          }}
        </form.Subscribe>
      </div>

      <div className="w-full">
        <form.Field
          name="descriptions"
          validators={{ onChange: validations.descriptions }}
        >
          {(field) => (
            <FormMultipleComboBox
              data={PET_DESCRIPTIONS}
              field={field}
              name="descriptions"
              label="Descripciones"
              placeholder="Selecciona o escribe descripciones..."
            />
          )}
        </form.Field>
      </div>

      <div className="flex space-x-4">
        <div className="w-1/2">
          <form.Field
            name="weight"
            validators={{ onChange: validations.weight }}
          >
            {(field) => (
              <FormInput
                field={field}
                name="weight"
                label="Peso (Kg)"
                type="number"
                placeholder="Peso de tu mascota"
              />
            )}
          </form.Field>
        </div>
        <div className="w-1/2">
          <form.Field name="age" validators={{ onChange: validations.age }}>
            {(field) => (
              <FormInput
                field={field}
                name="age"
                label="Edad"
                type="number"
                placeholder="Edad de tu mascota"
              />
            )}
          </form.Field>
        </div>
      </div>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPostPending || isPutPending}
            isDisabled={!canSubmit}
            text={pet ? 'Actualizar Mascota' : 'Crear Mascota'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

PetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pet: PropTypes.object,
}

export default PetForm
