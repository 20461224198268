/* eslint-disable quotes */
import { useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'
import {
  statusRenderer,
  typeRenderer,
  payoutStatusRenderer,
} from '@common/helpers/tableRenderers'
import { formatStatus, formatType, formatPrice } from '@utils/formatFunctions'
import { useGetRecords, useUpdateRecords } from '@services/servicerecord'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import ChangeSitter from '../updates/UpdateSitter'
import ChangeDate from '../updates/UpdateDate'

function RecordList() {
  const { isPending, data } = useGetRecords()
  const { mutate } = useUpdateRecords()
  const [changeSitterModal, setChangeSitterModal] = useState(false)
  const [changeDateModal, setChangeDateModal] = useState(false)
  const [params, setParams] = useState(null)
  const navigate = useNavigate()

  const columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: true,
      },
      {
        field: 'type',
        headerName: 'Servicio',
        cellRenderer: typeRenderer,
        filterValueGetter: (params) => formatType(params.data.type),
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Paseo', 'Visita', 'Alojamiento'],
        },
        onCellValueChanged: (params) => {
          const dataMap = {
            Paseo: 'dogWalking',
            Visita: 'visit',
            Alojamiento: 'dayCare',
          }
          const newType = dataMap[params.newValue]
          mutate({ id: params.data.id, body: { type: newType } })
        },
      },
      {
        valueGetter: (p) => p.data?.user?.name + ' ' + p.data?.user?.lastName,
        headerName: 'Cliente',
      },
      {
        headerName: 'Sitter',
        valueGetter: (p) =>
          p.data?.sitter?.name + ' ' + p.data?.sitter?.lastName,
        onCellClicked: (params) => {
          setChangeSitterModal(true)
          setParams(params)
        },
      },
      {
        field: 'date',
        headerName: 'Fecha',
        valueFormatter: (params) =>
          format(new Date(params.value), 'dd/MM/yyyy HH:mm', {
            locale: es,
          }),
        filterValueGetter: (params) =>
          format(new Date(params.data.date), 'dd/MM/yyyy HH:mm', {
            locale: es,
          }),
        onCellClicked: (params) => {
          setChangeDateModal(true)
          setParams(params)
        },
      },
      {
        field: 'price',
        headerName: 'Precio',
        valueFormatter: (params) => formatPrice(params.value),
        filterValueGetter: (params) => params.data.price,
        editable: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 5000000,
        },
        onCellValueChanged: (params) => {
          mutate({
            id: params.data.id,
            body: { price: params.data.price },
          })
        },
      },
      {
        field: 'status',
        headerName: 'Estado',
        cellRenderer: statusRenderer,
        filterValueGetter: (params) => formatStatus(params.data.status),
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [
            'Pendiente',
            'Aceptado',
            'Rechazado',
            'Cancelado',
            'En espera',
            'Finalizado',
          ],
        },
        onCellValueChanged: (params) => {
          const statusMap = {
            Pendiente: 'pending',
            Aceptado: 'accepted',
            Rechazado: 'rejected',
            Cancelado: 'cancelled',
            'En espera': 'waiting',
            Finalizado: 'finished',
          }
          const newStatus = statusMap[params.newValue]
          mutate({ id: params.data.id, body: { status: newStatus } })
        },
      },
      {
        field: 'payout.id',
        headerName: 'Pago Sitter',
        filter: 'agSetColumnFilter',
        cellRenderer: (params) =>
          payoutStatusRenderer(params.data?.payout?.status),
        valueGetter: (params) => params.data?.payout?.status,
      },
      {
        headerName: 'Resumen',
        valueGetter: () => 'Ver Resumen 📊',
        onCellClicked: (params) => {
          navigate(`/admin/service-summary/${params?.data?.id}`)
        },
      },
    ],
    [navigate, mutate],
  )

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
    }),
    [],
  )

  return (
    <>
      <div
        className="ag-theme-quartz"
        style={{ height: '75vh', width: '100%' }}
      >
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={(params) => params.api.sizeColumnsToFit()}
          pagination={true}
          paginationAutoPageSize={true}
          loading={isPending}
        />
      </div>
      <CustomModal
        isOpen={changeSitterModal}
        onClose={() => setChangeSitterModal(false)}
        header={
          <ModalHeader
            title="Cambiar Sitter"
            subtitle="Selecciona el sitter que estará a cargo del servicio 🐶"
          />
        }
        body={
          <ChangeSitter
            params={params}
            onClose={() => setChangeSitterModal(false)}
          />
        }
      />
      <CustomModal
        isOpen={changeDateModal}
        onClose={() => setChangeDateModal(false)}
        header={
          <ModalHeader
            title="Cambiar Fecha"
            subtitle="Selecciona la fecha y hora del servicio 🐶"
          />
        }
        body={
          <ChangeDate
            params={params}
            onClose={() => setChangeDateModal(false)}
          />
        }
      />
    </>
  )
}

export default RecordList
