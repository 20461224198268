import { FaPaw } from 'react-icons/fa'

const SuperSitterBadge = () => {
  return (
    <div className="mx-auto my-4 max-w-lg rounded-lg bg-yellow-base bg-opacity-95 p-4 text-sm text-yellow-700">
      <div className="flex flex-col">
        <div className="mb-2 flex flex-row items-center gap-4 font-mont-bold text-lg sm:text-2xl">
          <p>¡Super Sitter!</p>
          <FaPaw />
        </div>
        <p className="mb-2 text-sm sm:text-base">
          Un Super Sitter hace todo lo posible por brindar un excelente
          servicio. Se destaca por su experiencia, <br />
          profesionalismo y seriedad a la hora de realizar sus servicios. Los
          puedes identificar fácilmente por el color{' '}
          <br className="hidden sm:flex" />
          amarillo y la insignia que aparece en su anuncio y perfil.
        </p>
      </div>
    </div>
  )
}

export default SuperSitterBadge
