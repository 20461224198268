import { useEffect, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import PropTypes from 'prop-types'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComboBox({
  elements,
  title,
  onSelect,
  startValue,
  onReset,
  placeholder,
  allowAddition,
  textAddition,
  onAddition,
}) {
  const [query, setQuery] = useState('')
  const [selectedElement, setSelectedElement] = useState(
    startValue ? startValue : null,
  )

  const filteredElements =
    query === ''
      ? elements
      : elements.filter((element) => {
          return element.toLowerCase().includes(query.toLowerCase())
        })

  useEffect(() => {
    if (startValue) {
      setSelectedElement(startValue)
    }
  }, [startValue])

  useEffect(() => {
    if (onReset) {
      setQuery('')
      setSelectedElement(null)
    }
  }, [onReset])

  return (
    <Combobox
      as="div"
      className="w-full"
      value={selectedElement}
      onChange={(selectedValue) => {
        setSelectedElement(selectedValue)
        onSelect(selectedValue)
      }}
    >
      <div className="ml-1 flex max-w-md flex-row justify-between">
        <Combobox.Label
          htmlFor={title}
          className="block font-mont-semi text-sm font-medium leading-6 text-grey-base"
        >
          {title}
        </Combobox.Label>
        {allowAddition && (
          <button
            type="button"
            className="rounded-md px-2 py-0.5 text-xs text-blue-500 hover:bg-white-base hover:text-blue-700 focus:outline-none"
            onClick={onAddition}
          >
            {textAddition}
          </button>
        )}
      </div>
      <div className="relative mt-1">
        <Combobox.Input
          id={title}
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(element) => element}
          placeholder={placeholder}
          autoComplete="off"
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredElements.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredElements.map((element, index) => (
              <Combobox.Option
                key={index}
                value={element}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-500 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {element}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-500',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

ComboBox.propTypes = {
  elements: PropTypes.array,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  startValue: PropTypes.string,
  onReset: PropTypes.bool,
  placeholder: PropTypes.string,
  allowAddition: PropTypes.bool,
  textAddition: PropTypes.string,
  onAddition: PropTypes.func,
}
