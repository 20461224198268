import PropTypes from 'prop-types'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import FieldError from '@common/error/FieldError'

export default function FormSelect({ data, field, name, label }) {
  const onChange = (value) => {
    event.preventDefault()
    event.stopPropagation()
    field.handleChange(value)
  }

  return (
    <>
      <Listbox value={field.state.value} onChange={(e) => onChange(e)}>
        <div className="relative mt-2">
          <label
            htmlFor={name}
            className="block font-mont-semi text-sm leading-6 text-grey-base mb-2"
          >
            {label}
          </label>
          <ListboxButton
            className={`relative w-full cursor-default rounded-lg border border-grey-soft bg-white py-1.5 pl-3 pr-10 text-left text-sm shadow-sm focus:border-grey-base focus:ring-1 ${
              field.state.value ? 'ring-blue-base ring-offset-2' : 'ring-0'
            } min-h-[2.5rem] sm:leading-6`}
          >
            <span className="block truncate">{field.state.value}</span>{' '}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </ListboxButton>

          <ListboxOptions
            transition="true"
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {data.map((item) => (
              <ListboxOption
                key={item.id}
                value={item.value}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-soft data-[focus]:text-white"
              >
                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                  {item.value}
                </span>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-soft group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
      <div>
        {field.state.meta.isTouched && field.state.meta.errors.length ? (
          <FieldError message={field.state.meta.errors.join(', ')} />
        ) : null}
      </div>
    </>
  )
}

FormSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  field: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
