import PropTypes from 'prop-types'
import { FaQuestion } from 'react-icons/fa'
import StatsToolTip from '@common/tooltip/StatsToolTip'
import StatsInfo from '@common/tooltip/StatsInfo'
import PieChart from '@common/stats/PieChart'
import BarChart from '@common/stats/BarChart'

function Graphs({ stats }) {
  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      {' '}
      <div className="flex sm:w-1/2 flex-col items-center">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">
            Tipo de servicios realizados
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Muestra los tipos de servicios realizados por el sitter.',
                  'Los servicios pueden ser de Paseo, Visita o Alojamiento.',
                  'Se consideran todos los servicios, no solo aquellos que se encuentran terminados.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <PieChart
          data={[
            {
              value: stats.dogWalking || 0,
              name: 'Paseo',
              itemStyle: { color: '#87B9FF' },
            },
            {
              value: stats.visit || 0,
              name: 'Visita',
              itemStyle: { color: '#1148AC' },
            },
            {
              value: stats.dayCare || 0,
              name: 'alojamiento',
              itemStyle: { color: '#3BAAF6' },
            },
          ]}
        />
      </div>
      <div className="flex sm:w-1/2 flex-col items-center">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">
            Estado de los servicios de trabajo
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Muestra el estado de los servicios de trabajo realizados por el sitter.',
                  'Los estados pueden ser Aceptados, Pendientes, Cancelados,',
                  ' Terminados, Rechazados o En espera.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <BarChart
          data={[
            {
              value: stats.accepted || 0,
              name: 'Aceptados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: stats.pending || 0,
              name: 'Pendientes',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: stats.cancelled || 0,
              name: 'Cancelados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: stats.finished || 0,
              name: 'Finalizados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: stats.denied || 0,
              name: 'Rechazados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: stats.waiting || 0,
              name: 'En espera',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
          ]}
        />
      </div>
    </div>
  )
}

Graphs.propTypes = {
  stats: PropTypes.object.isRequired,
}

export default Graphs
