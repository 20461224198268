import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { classNames } from '@utils/formatFunctions'
import { getData } from '@services/requests'
import SitterSummary from './components/hire_sitter/SitterSummary'
import ServiceForm from './components/hire_sitter/ServiceForm'
import Loading from '@common/utils/Loading'
import PageError from '@common/error/PageError'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useFilter from '@zustand/filter'

function HireSitter() {
  const [sitterSummary, setSitterSummary] = useState(true)
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const { sitterId } = useParams()
  const { selectedAddress } = useFilter()

  const selectedGeoLocation = selectedAddress?.geometry?.location
    ? `${selectedAddress.geometry.location.lat},${selectedAddress.geometry.location.lng}`
    : ''

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['hireSitter', sitterId],
    enabled: !!sitterId,
    queryFn: () =>
      getData(
        `user/sitter-info/${sitterId}?selectedAddress=${selectedGeoLocation}`,
      ),
  })

  if (isPending) return <Loading />

  if (isError)
    return (
      <div className="flex min-h-screen items-center justify-center">
        <PageError message={error.message} />
      </div>
    )

  if (isMediumScreen)
    return (
      <>
        <ToastContainer />
        <div className="container relative mx-auto my-6">
          <div className="relative mx-auto flex w-[70%] flex-row rounded-full bg-grey-soft">
            <button
              onClick={() => setSitterSummary(true)}
              className={classNames(
                'z-10 w-1/2 py-2 text-center transition-colors duration-300',
                sitterSummary ? 'text-white' : 'text-grey-base',
              )}
            >
              Perfil
            </button>
            <button
              onClick={() => setSitterSummary(false)}
              className={classNames(
                'z-10 w-1/2 py-2 text-center transition-colors duration-300',
                !sitterSummary ? 'text-white' : 'text-grey-base',
              )}
            >
              Reservar
            </button>
            <div
              className={classNames(
                'absolute z-0 h-full w-1/2 rounded-full bg-pink-base transition-all duration-300 ease-in-out',
                sitterSummary ? 'left-0' : 'left-1/2',
              )}
            ></div>
          </div>
          <div
            className={classNames(
              'transition-opacity duration-300',
              sitterSummary ? 'opacity-100' : 'absolute opacity-0',
              'w-full',
            )}
          >
            {data && <SitterSummary sitter={data} />}
          </div>
          <div
            className={classNames(
              'transition-opacity duration-300',
              !sitterSummary ? 'opacity-100' : 'absolute opacity-0',
              'w-full',
            )}
          >
            {data && <ServiceForm sitter={data} isSummary={sitterSummary} />}
          </div>
        </div>
      </>
    )

  return (
    <>
      <ToastContainer />
      <div className="m-4 flex h-screen flex-grow justify-center gap-4">
        <div className="w-[40%]">
          {data ? <SitterSummary sitter={data} /> : null}
        </div>
        <div className="w-[60%]">
          {data ? <ServiceForm sitter={data} /> : null}
        </div>
      </div>
    </>
  )
}
export default HireSitter
