import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FaCheckCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { saveSteps } from '@store/reducers/sitterRequest'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import ErrorNoCard from '@common/error/ErrorNoCard'
import handleStepChange from '@utils/handleStepChange'
import Steps from './components/Steps'
import FirstStep from './components/FirstStep'
import SecondStep from './components/SecondStep'
import ThirdStep from './components/ThirdStep'
import FourthStep from './components/FourthStep'
import FifthStep from './components/FifthStep'

function SitterApply() {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const user = useSelector((state) => state.user)
  const sitterRequest = useSelector((state) => state.sitterRequest)
  const [requestError, setRequestError] = useState(false)
  const [hasPendingRequest, setHasPendingRequest] = useState(false)

  const { isPending, isError, error, isSuccess, data } = useQuery({
    queryKey: ['sitterRequest'],
    queryFn: () => getData(`sitter-request/user/${user.id}`),
  })

  useEffect(() => {
    if (isSuccess) {
      const hasPending = data.some((request) => request.status === 'pending')
      setHasPendingRequest(hasPending)
    }
  }, [data, isSuccess])

  const handlePrev = () => {
    handleStepChange(
      sitterRequest.steps[1].status == 'current'
        ? 0
        : sitterRequest.steps[2].status == 'current'
          ? 1
          : sitterRequest.steps[3].status == 'current'
            ? 2
            : sitterRequest.steps[4].status == 'current'
              ? 3
              : 0,
      dispatch,
      sitterRequest,
      setRequestError,
      saveSteps,
    )
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleNext = () => {
    handleStepChange(
      sitterRequest.steps[0].status == 'current'
        ? 1
        : sitterRequest.steps[1].status == 'current'
          ? 2
          : sitterRequest.steps[2].status == 'current'
            ? 3
            : sitterRequest.steps[3].status == 'current'
              ? 4
              : 0,
      dispatch,
      sitterRequest,
      setRequestError,
      saveSteps,
    )
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (isPending) {
    return <Loading />
  }

  if (user.isSitter) {
    return (
      <div className="container mx-auto flex w-full items-start justify-center sm:my-6 sm:w-[90%] md:min-h-screen md:w-[80%] lg:w-[70%]">
        <div className="mt-0 flex w-full flex-col items-center justify-center gap-4 rounded-xl bg-white p-6 shadow-xl md:mt-36 lg:w-1/3 xl:w-1/3">
          <FaCheckCircle className="text-5xl text-green-500" />
          <h1 className="text-center font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
            ¡Ya eres Sitter!
          </h1>
          <p className="text-center text-lg text-slate-900">
            Como miembro de nuestra comunidad de sitters, ¡ya eres parte de
            nuestro equipo! Si necesitas realizar alguna acción específica o
            tienes alguna pregunta, no dudes en ponerte en contacto con
            nosotros.
          </p>
        </div>
      </div>
    )
  }

  if (hasPendingRequest) {
    return (
      <div className="align-center xl:m-18 m-8 flex justify-center sm:m-10 md:m-14 lg:m-16">
        <div className="flex w-full flex-col items-center justify-center gap-4 rounded-xl bg-white p-6 shadow-xl lg:w-1/3 xl:w-1/3">
          <FaCheckCircle className="text-5xl text-green-500" />
          <h1 className="text-center font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
            ¡Gracias por postularte como Sitter!
          </h1>
          <p className="text-center text-lg text-slate-900">
            ¡Gracias por postularte! Estamos revisando tu perfil y nos pondremos
            en contacto contigo pronto. Si tienes preguntas, ¡no dudes en
            contactarnos! ¡Gracias por unirte a nuestra comunidad!
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-auto my-8 flex w-[90%] items-center justify-center pb-24 sm:my-12 sm:pb-32">
      <div className="flex flex-col items-center justify-center gap-2 rounded-lg bg-white p-8 shadow-lg sm:w-auto">
        <Steps setRequestError={setRequestError} />
        {sitterRequest.steps[0].status === 'current' ? (
          <FirstStep />
        ) : sitterRequest.steps[1].status === 'current' ? (
          <SecondStep />
        ) : sitterRequest.steps[2].status === 'current' ? (
          <ThirdStep />
        ) : sitterRequest.steps[3].status === 'current' ? (
          <FourthStep />
        ) : sitterRequest.steps[4].status === 'current' ? (
          <FifthStep
            refetch={() =>
              queryClient.invalidateQueries({ queryKey: ['sitterRequest'] })
            }
          />
        ) : null}
        <div className="flex flex-1 justify-between sm:justify-end">
          {sitterRequest.steps[0].status == 'current' ? null : (
            <a
              onClick={handlePrev}
              className="relative inline-flex cursor-pointer items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Anterior
            </a>
          )}
          {sitterRequest.steps[4].status === 'current' ? null : (
            <a
              onClick={handleNext}
              className="relative ml-3 inline-flex cursor-pointer items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Siguiente
            </a>
          )}
        </div>
        {requestError && <ErrorNoCard message={requestError} />}
        {isError && <ErrorNoCard message={error} />}
      </div>
    </div>
  )
}

export default SitterApply
