import { useState, useEffect, useContext } from 'react'
import {
  FaDog,
  FaClipboardList,
  FaStar,
  FaUserPlus,
  FaDownload,
  FaCalendarAlt,
} from 'react-icons/fa'
import { ToastContainer } from 'react-toastify'
import { RiCoupon3Fill } from 'react-icons/ri'
import { FaPerson } from 'react-icons/fa6'
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import DiscountForm from '@common/forms/DiscountForm'
import UserForm from '@common/forms/UserForm'
import downloadExcel from '@utils/downloadExcel'
import SittersRequests from './components/admin_center/SittersRequestsList'
import RecordList from './components/admin_center/RecordList'
import ReviewList from './components/admin_center/ReviewList'
import SitterList from './components/admin_center/SittersList'
import UserList from './components/admin_center/UserList'
import DiscountList from './components/admin_center/DiscountList'
import PlanList from './components/admin_center/PlanList'
import Error from '@common/error/Error'
import { AuthContext } from '@common/context/AuthContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  {
    name: 'Sitters',
    icon: FaDog,
  },
  {
    name: 'Solicitudes de servicio',
    icon: FaClipboardList,
  },
  {
    name: 'Reviews',
    icon: FaStar,
  },
  {
    name: 'Solicitudes de Sitter',
    icon: FaUserPlus,
  },
  {
    name: 'Usuarios',
    icon: FaPerson,
  },
  {
    name: 'Descuentos',
    icon: RiCoupon3Fill,
  },
  {
    name: 'Planes',
    icon: FaCalendarAlt,
  },
]

function AdminCenter() {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [showAddDiscount, setShowAddDiscount] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)
  const { getAuthToken } = useContext(AuthContext)
  const [selectedTab, setSelectedTab] = useState(() => {
    return localStorage.getItem('selectedTab') || 'Sitters'
  })

  const handleSelectChange = (event) => {
    setSelectedTab(event.target.value)
  }

  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab)
  }, [selectedTab])

  const handleDownloadReport = async () => {
    const token = getAuthToken()

    switch (selectedTab) {
      case 'Sitters':
        await downloadReport(token, 'user/sitters-report', 'Listado de Sitters')
        break
      case 'Solicitudes de servicio':
        await downloadReport(
          token,
          'service-record/report',
          'Solicitudes de Servicio',
        )
        break
      case 'Reviews':
        await downloadReport(token, 'review/report', 'Listado de Reviews')
        break
      case 'Solicitudes de Sitter':
        await downloadReport(
          token,
          'sitter-request/report',
          'Listado de Solicitudes de Sitter',
        )
        break
      case 'Usuarios':
        await downloadReport(token, 'user/report', 'Listado de Usuarios')
        break
      default:
        break
    }
  }

  const downloadReport = async (token, endpoint, filename) => {
    try {
      await downloadExcel(token, endpoint, filename)
    } catch (error) {
      setError('Hubo un error al descargar el reporte')
    }
  }

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="flex min-h-screen flex-col p-4 sm:mt-4">
        <div>
          <div className="w-full sm:hidden">
            <div className="mb-2 flex flex-row justify-end gap-2">
              {selectedTab === 'Descuentos' && (
                <button
                  onClick={() => setShowAddDiscount(true)}
                  className="bg-green-ter flex rounded-md p-4 text-base text-white hover:bg-green-base"
                >
                  <FaPlus /> Agregar Descuento
                </button>
              )}

              <button
                onClick={handleDownloadReport}
                className="flex rounded-md bg-blue-ter p-4 text-base text-white hover:bg-blue-base"
              >
                <FaDownload />
              </button>
            </div>
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-ter focus:outline-none focus:ring-blue-ter sm:text-sm"
              onChange={handleSelectChange}
              value={selectedTab}
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.name}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden justify-between sm:flex sm:flex-row">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href="#"
                    className={classNames(
                      selectedTab === tab.name
                        ? 'border-blue-base text-blue-base'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'flex items-center whitespace-nowrap border-b-2 px-1 py-4 font-mont-semi text-sm',
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      setSelectedTab(tab.name)
                    }}
                  >
                    <tab.icon className="mr-2 inline-block text-lg" />
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="mb-2 flex flex-row gap-2">
              {selectedTab === 'Descuentos' && (
                <button
                  onClick={() => setShowAddDiscount(true)}
                  className="flex items-center gap-2 rounded-md bg-blue-ter p-2 text-base text-white hover:bg-blue-base"
                >
                  <FaPlus /> Agregar Descuento
                </button>
              )}

              {selectedTab === 'Usuarios' && (
                <button
                  onClick={() => setShowAddUser(true)}
                  className="flex items-center gap-2 rounded-md bg-blue-ter p-2 text-base text-white hover:bg-blue-base"
                >
                  <FaPlus /> Agregar Usuario
                </button>
              )}

              {selectedTab === 'Planes' && (
                <button
                  onClick={() => navigate('/plan/new')}
                  className="flex items-center gap-2 rounded-md bg-blue-ter p-2 text-base text-white hover:bg-blue-base"
                >
                  <FaPlus /> Agregar Plan
                </button>
              )}
              <button
                onClick={handleDownloadReport}
                className="flex rounded-md bg-blue-ter p-4 text-base text-white hover:bg-blue-base"
              >
                <FaDownload />
              </button>
            </div>
          </div>
        </div>
        {selectedTab === 'Sitters' && <SitterList />}
        {selectedTab === 'Solicitudes de servicio' && <RecordList />}
        {selectedTab === 'Reviews' && <ReviewList />}
        {selectedTab === 'Solicitudes de Sitter' && <SittersRequests />}
        {selectedTab === 'Usuarios' && <UserList />}
        {selectedTab === 'Descuentos' && <DiscountList />}
        {selectedTab === 'Planes' && <PlanList />}
        {error && <Error message={error} />}
      </div>

      <CustomModal
        header={
          <ModalHeader
            title="Crear descuento"
            subtitle="Crea un descuento para los clientes"
          />
        }
        body={<DiscountForm onSubmit={() => setShowAddDiscount(false)} />}
        isOpen={showAddDiscount}
        onClose={() => setShowAddDiscount(false)}
      />

      <CustomModal
        header={
          <ModalHeader
            title="Crear usuario"
            subtitle="Agrega un nuevo usuario"
          />
        }
        body={<UserForm onClose={() => setShowAddUser(false)} />}
        isOpen={showAddUser}
        onClose={() => setShowAddUser(false)}
      />
    </>
  )
}

export default AdminCenter
