import PropTypes from 'prop-types'
import { FaHouse } from 'react-icons/fa6'
import {
  FaBuilding,
  FaSmoking,
  FaSmokingBan,
  FaBabyCarriage,
  FaDog,
  FaCat,
  FaPaw,
  FaBed,
  FaCouch,
} from 'react-icons/fa'

function AboutSitterHouse({ data, sitterName }) {
  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="flex flex-col items-start justify-center gap-2">
        <div className="flex flex-col gap-1">
          <h2 className="font-mont-semi text-slate-900 sm:text-lg">
            Acerca del hogar de {sitterName}
          </h2>
          <p className="text-sm text-grey-soft">Servicio de Alojamiento</p>
        </div>
        <div className="mt-4 flex w-full flex-col items-center justify-center gap-2 text-sm text-grey-soft">
          <FaHouse className="h-12 w-12" />
          <p className="w-64 text-center">
            No hay información disponible sobre la casa de {sitterName}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <div className="flex flex-col gap-1">
        <h2 className="font-mont-semi text-slate-900 sm:text-lg">
          Acerca del hogar de {sitterName}
        </h2>
        <p className="text-sm text-grey-soft">Servicio de Alojamiento</p>
      </div>
      <div className="flex flex-wrap gap-4 p-2 text-sm">
        {data && data.house && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            <FaHouse />
            <p>Casa</p>
          </div>
        )}
        {data && data.department && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaBuilding />
            <p>Departamento</p>
          </div>
        )}
        {data && data.smoking && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaSmoking />
            <p>Fumador</p>
          </div>
        )}
        {data && data.noSmoking && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaSmokingBan />
            <p>No fumador</p>
          </div>
        )}
        {data && data.dog && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaDog />
            <p>Hay perros en casa</p>
          </div>
        )}
        {data && data.noDog && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaDog />
            <p>No hay perros en casa</p>
          </div>
        )}
        {data && data.cat && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaCat />
            <p>Hay gatos en casa</p>
          </div>
        )}
        {data && data.noCat && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaCat />
            <p>No hay gatos en casa</p>
          </div>
        )}
        {data && data.otherPets && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaPaw />
            <p>Hay otro tipo de mascotas en casa</p>
          </div>
        )}
        {data && data.noOtherPets && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaPaw />
            <p>No hay otro tipo de mascotas en casa</p>
          </div>
        )}
        {data && data.children && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaBabyCarriage />
            <p>Niños menores de 5 años</p>
          </div>
        )}
        {data && data.noChildren && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaBabyCarriage />
            <p>No hay menores de 5 años</p>
          </div>
        )}
        {data && data.bed && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaBed />
            <p>Se permite subirse a la cama</p>
          </div>
        )}
        {data && data.noBed && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaBed />
            <p>No se permite subirse a la cama</p>
          </div>
        )}
        {data && data.couch && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaCouch />
            <p>Se permite subirse al sofá</p>
          </div>
        )}
        {data && data.noCouch && (
          <div className="flex h-8 flex-row items-center justify-center gap-2 rounded-full bg-blue-ter px-4 py-1 text-white">
            {' '}
            <FaCouch />
            <p>No se permite subirse al sofá</p>
          </div>
        )}
      </div>
    </div>
  )
}

AboutSitterHouse.propTypes = {
  data: PropTypes.object.isRequired,
  sitterName: PropTypes.string.isRequired,
}

export default AboutSitterHouse
