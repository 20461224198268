/* eslint-disable quotes */
import PropTypes from 'prop-types'
import { FaArrowRight, FaExclamationCircle } from 'react-icons/fa'
import Calendar from './Calendar'
import {
  selectedContainerStyle,
  unselectedContainerStyle,
} from '@assets/css/styles'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { classNames } from '@utils/formatFunctions'
import DayCareCalendar from './DayCareCalendar'

function CalendarInput({
  selectedType,
  serviceDates,
  setServiceDates,
  step,
  setStep,
  services,
}) {
  const handleClick = () => {
    if (step !== 1) {
      setStep(1)
    }
  }

  if (!selectedType)
    return (
      <div className={selectedContainerStyle(false, step === 1)}>
        <h2 className="font-mont-semi text-2xl text-grey-base">Fechas</h2>
        <div
          className={classNames(
            'flex flex-row items-center gap-4 text-sm sm:text-lg',
            step > 0 ? 'text-red-base' : 'text-gray-400',
          )}
        >
          <FaExclamationCircle className={step < 1 && 'hidden'} size={16} />
          <p> primero debes seleccionar un servicio</p>
        </div>
      </div>
    )

  if (step !== 1)
    return (
      <div
        onClick={handleClick}
        className={selectedContainerStyle(false, step === 1)}
      >
        <h2 className="font-mont-semi text-2xl text-grey-base">Fechas</h2>
        {serviceDates.length === 0 && (
          <div
            className={classNames(
              'flex flex-row items-center gap-4 text-sm sm:text-lg',
              step > 0 ? 'text-red-base' : 'text-gray-400',
            )}
          >
            <FaExclamationCircle className={step < 1 && 'hidden'} size={16} />
            <p> Selecciona una fecha</p>
          </div>
        )}
        {serviceDates.length > 0 && (
          <ol className="flex w-full flex-wrap gap-4">
            {serviceDates.slice(0, 3).map((date, index) => (
              <li
                key={index}
                className={classNames(
                  'rounded-full px-3 py-2 text-white sm:text-xl',
                  selectedType === 'dogWalking' && 'bg-yellow-sec',
                  selectedType === 'visit' && 'bg-blue-ter',
                  selectedType === 'dayCare' && 'bg-purple-base',
                )}
              >
                <p>
                  {selectedType === 'dayCare'
                    ? format(date.date, "dd 'de' MMMM", {
                        locale: es,
                      })
                    : format(date.date, "dd 'de' MMMM HH:mm", { locale: es })}
                </p>
              </li>
            ))}
            {serviceDates.length > 3 && (
              <li className="flex items-center text-xl text-grey-base">
                <p>+ {serviceDates.length - 3} más</p>
              </li>
            )}
          </ol>
        )}
      </div>
    )

  return (
    <div
      onClick={handleClick}
      className={unselectedContainerStyle(false, step === 1)}
    >
      <div className="flex flex-row items-center justify-center gap-4">
        <h2 className="font-mont-semi text-2xl text-grey-base">
          Fechas para{' '}
          {selectedType === 'dogWalking'
            ? 'Paseo'
            : selectedType === 'visit'
              ? 'Visita'
              : selectedType === 'dayCare'
                ? 'Alojamiento'
                : ''}
        </h2>
        <button
          onClick={() => setStep(2)}
          className={classNames(
            serviceDates.length === 0 && 'hidden',
            serviceDates.length > 0 &&
              'rounded-full bg-blue-soft p-3 text-xl text-white-base hover:bg-blue-600',
          )}
        >
          <FaArrowRight />
        </button>
      </div>
      {selectedType === 'dayCare' ? (
        <DayCareCalendar
          serviceDates={serviceDates}
          setServiceDates={setServiceDates}
          services={services}
        />
      ) : (
        <Calendar
          serviceDates={serviceDates}
          setServiceDates={setServiceDates}
          selectedType={selectedType}
        />
      )}
    </div>
  )
}

CalendarInput.propTypes = {
  selectedType: PropTypes.string,
  serviceDates: PropTypes.array.isRequired,
  setServiceDates: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
}

export default CalendarInput
