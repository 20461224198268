import { FaLocationDot, FaPaw, FaSun, FaCheck } from 'react-icons/fa6'
import { PiLockKeyFill } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import ServiceInfo from './ServiceInfoCard'
import dogHomeTwo from '@assets/images/home/dogHomeTwo.png'
import { useContext } from 'react'
import { AuthContext } from '@common/context/AuthContext'

function HomeTwo() {
  const { getAuthToken } = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <div className="z-10 flex w-full bg-white py-20 sm:justify-center sm:py-32">
      <div className="mx-auto flex w-[85%] flex-col items-center justify-center gap-12 sm:w-[70%] sm:gap-32">
        <h2 className="text-center font-tex-bold text-2xl text-grey-base sm:text-3xl">
          ¿Cómo funciona <span className="text-yellow-base">cada servicio</span>
          ?
        </h2>
        <div className="flex flex-col items-center gap-20 sm:flex-row sm:gap-2">
          <div className="flex flex-col gap-8 sm:w-1/2 sm:gap-20">
            <ServiceInfo
              icon={<FaPaw className="text-5xl text-grey-base" />}
              title="Paseo"
              mainText="Paseo exclusivo de tu mascota, con una duración de entre 40 minutos y una hora."
              secondaryText="(Desde $6.000)"
            />
            <ServiceInfo
              icon={<FaLocationDot className="text-5xl text-grey-base" />}
              title="Visita"
              mainText="Un sitter vendrá a tu casa para darle comida y agua a tu mascota, limpiar su área y sacarlo a pasear."
              secondaryText="(Desde $12.000)"
            />

            <ServiceInfo
              icon={<FaSun className="text-5xl text-grey-base" />}
              title="Alojamiento"
              mainText="Un sitter recibirá a tu mascota en su casa y se hará cargo de ella durante los días acordados."
              secondaryText="(Desde $30.000/24 hrs)"
            />
          </div>
          <div className="relative z-20 mx-auto flex w-full flex-col items-center justify-center gap-6 rounded-[4rem] border-[0.4rem] border-yellow-base px-8 pb-12 pt-8 sm:w-[33%]">
            <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-[60%] transform">
              <PiLockKeyFill className="text-8xl text-blue-soft" />
            </div>
            <h4 className="px-4 py-4 font-mont-semi text-xl font-medium text-grey-base sm:px-2">
              Seguridad y confianza
            </h4>
            <div className="flex flex-col gap-14 pb-10">
              <div className="flex w-full flex-row items-center gap-4 sm:gap-6">
                <p className="text-sm font-light text-grey-base sm:text-sm">
                  Todos los sitters pasan por un control de identidad.
                </p>
                <p className="text-3xl text-blue-soft">
                  <FaCheck />
                </p>
              </div>
              <div className="flex w-full flex-row items-center gap-4 sm:gap-6">
                <p className="text-sm font-light text-grey-base sm:text-sm">
                  Todos los sitters muestran un perfil detallado y su
                  información personal.
                </p>
                <p className="text-3xl text-blue-soft">
                  <FaCheck />
                </p>
              </div>
              <div className="flex w-full flex-row items-center gap-4 sm:gap-6">
                <p className="text-sm font-light text-grey-base sm:text-sm">
                  Todos los sitter pasan por un proceso de selección y son
                  aprobados por nuestro equipo.
                </p>
                <p className="text-3xl text-blue-soft">
                  <FaCheck />
                </p>
              </div>
            </div>

            <div className="absolute bottom-0 left-1/2 z-20 w-[60%] -translate-x-1/2 -translate-y-[-50%] transform">
              {getAuthToken() ? (
                <button
                  onClick={() => navigate('/sitter-request')}
                  className="w-full rounded-xl bg-blue-soft py-2 font-mont-semi text-base text-white hover:bg-blue-600"
                >
                  ¡Postula como sitter!
                </button>
              ) : (
                <button
                  onClick={() => navigate('/signup')}
                  className="w-full rounded-xl bg-blue-soft py-2 font-mont-semi text-base text-white hover:bg-blue-600"
                >
                  ¡Regístrate!
                </button>
              )}
            </div>
            <img
              src={dogHomeTwo}
              alt="catHomeOne"
              className="absolute bottom-[0%] right-[0%] z-20 hidden h-[12rem] -translate-x-[-30%] -translate-y-[-40%] md:block md:h-[16rem] md:-translate-x-[-80%]"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeTwo
