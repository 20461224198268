import { StatusScreen } from '@mercadopago/sdk-react'
import { Link, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'


function PaymentMpStatus() {
  const location = useLocation()

  const paymentId = location?.state?.paymentId

  const initialization = {
    paymentId: paymentId ? paymentId : null,
  }

  return (
    <div className="mx-auto w-3/4 py-16 md:w-1/2">
      <ToastContainer position="top-center" />
      <div className="flex flex-col items-center justify-center gap-8">
        <StatusScreen initialization={initialization} />
        <div>
          <Link
            className="focus-blue:outline-[#00B1EA] w-1/2 rounded-md bg-[#00B1EA] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#1EB9FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            to={'/'}
          >
            Volver al inicio
          </Link>
        </div>
      </div>
    </div>
  )
}
export default PaymentMpStatus
