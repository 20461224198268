import PropTypes from 'prop-types'
import StarsCard from './StarsCard'
import DeniedCard from './DeniedCard'
import ProfileCard from './ProfileCard'

function StatusLights({ data }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
        <p className="text-lg">
          Tu puntuación actual como sitter es{' '}
          <span className="font-mont-semi">
            {data.statusLightColor == 0
              ? 'roja'
              : data.statusLightColor == 1
                ? 'naranja'
                : data.statusLightColor == 2
                  ? 'amarilla'
                  : data.statusLightColor == 3
                    ? 'verde claro'
                    : 'verde'}
          </span>
        </p>
        <div className="flex flex-row items-center justify-between space-x-4">
          <div
            className={`${
              data.statusLightColor == 0
                ? 'bg-red-base'
                : 'bg-red-100 bg-opacity-80'
            } h-3 w-1/3 rounded-sm`}
          ></div>
          <div
            className={`${
              data.statusLightColor == 1
                ? 'bg-[#f97316]'
                : 'bg-orange-100 bg-opacity-80'
            } h-3 w-1/3 rounded-sm`}
          ></div>
          <div
            className={`${
              data.statusLightColor == 2
                ? 'bg-yellow-base'
                : 'bg-yellow-100 bg-opacity-80'
            } h-3 w-1/3 rounded-sm`}
          ></div>
          <div
            className={`${
              data.statusLightColor == 3
                ? 'bg-[#dff77b]'
                : 'bg-[#ecfccb] bg-opacity-80'
            } h-3 w-1/3 rounded-sm`}
          ></div>
          <div
            className={`${
              data.statusLightColor == 4
                ? 'bg-green-base'
                : 'bg-green-100 bg-opacity-80'
            } h-3 w-1/3 rounded-sm`}
          ></div>
        </div>
        {data.statusLightColor === 6 ? (
          <p>
            ¡Bien hecho! Tienes la máxima puntuación que puedes obtener como
            Sitter de Pets-Okey.
          </p>
        ) : (
          <p>
            Para mejorar tu puntuación como sitter, mejora tu puntuación
            específica de {data.starsColor !== 2 && 'estrellas'}
            {data.starsColor !== 2 && data.deniedColor !== 2 && ' y '}
            {data.deniedColor !== 2 && 'rechazos'}
            {(data.starsColor !== 2 || data.deniedColor !== 2) &&
              data.profileColor !== 2 &&
              ' y '}
            {data.profileColor !== 2 && 'perfil'}
          </p>
        )}
      </div>
      <div className="flex flex-col justify-between space-y-4 sm:grid sm:grid-cols-3 sm:space-x-2 sm:space-y-0">
        <StarsCard stars={data.stars} starsColor={data.starsColor} />
        <DeniedCard
          deniedPercentage={data.deniedPercentage}
          deniedColor={data.deniedColor}
        />
        <ProfileCard
          profileColor={data.profileColor}
          profileStatus={data.profileStatus}
        />
      </div>
    </div>
  )
}

StatusLights.propTypes = {
  data: PropTypes.object,
}

export default StatusLights
