import { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import PropType from 'prop-types'

const BarChart = ({ data }) => {
  const chartRef = useRef(null)
  let myChart = null

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      myChart = echarts.init(chartRef.current)

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: data.map((item) => item.name),
          axisLabel: {
            interval: 0, // Muestra todas las etiquetas en el eje x
            rotate: 30, // Rota las etiquetas para mejorar la legibilidad
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: data.map((item) => ({
              value: item.value,
              itemStyle: {
                color:
                  item.itemStyle && item.itemStyle.color
                    ? item.itemStyle.color
                    : '#000',
              },
            })),
            type: 'bar',
            barWidth: '40%', // Ajusta el ancho de las barras según tus preferencias
          },
        ],
      }

      myChart.setOption(option)
    }

    return () => {
      if (myChart != null) {
        myChart.dispose()
      }
    }
  }, [data])

  return <div ref={chartRef} className="h-64 w-full" />
}

BarChart.propTypes = {
  data: PropType.arrayOf(
    PropType.shape({
      name: PropType.string,
      value: PropType.number,
      itemStyle: PropType.shape({
        color: PropType.string,
      }),
    }),
  ).isRequired,
}

export default BarChart
