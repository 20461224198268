function handleStepChange(
  stepIndex,
  dispatch,
  sitterRequest,
  setRequestError,
  saveSteps,
) {
  setRequestError(null)
  if (sitterRequest.steps[0].status === 'current') {
    if (stepIndex === 1) {
      if (sitterRequest.firstStep.communes.length === 0) {
        setRequestError('Debes seleccionar al menos una comuna')
      } else if (sitterRequest.firstStep.description === '') {
        setRequestError('Debes ingresar una descripción')
      } else {
        dispatch(saveSteps({ stepIndex: 0, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 1, newStatus: 'current' }))
      }
    } else if (stepIndex === 2 || stepIndex === 3 || stepIndex === 4) {
      setRequestError('Debes completar los pasos anteriores antes de continuar')
    }
  } else if (sitterRequest.steps[1].status === 'current') {
    if (stepIndex === 0) {
      dispatch(saveSteps({ stepIndex: 0, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'upcoming' }))
    } else if (stepIndex === 2) {
      if (!sitterRequest.secondStep.certificate) {
        setRequestError('Debes subir tu certificado de antecedentes')
      } else {
        dispatch(saveSteps({ stepIndex: 1, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 2, newStatus: 'current' }))
      }
    } else if (stepIndex === 3 || stepIndex === 4) {
      setRequestError('Debes completar los pasos anteriores antes de continuar')
    }
  } else if (sitterRequest.steps[2].status === 'current') {
    if (stepIndex === 0) {
      dispatch(saveSteps({ stepIndex: 0, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
    } else if (stepIndex === 1) {
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
    } else if (stepIndex === 3) {
      if (sitterRequest.thirdStep.bank === '') {
        setRequestError('Debes seleccionar un banco')
      } else if (sitterRequest.thirdStep.type === '') {
        setRequestError('Debe ingresar un tipo de cuenta')
      } else if (sitterRequest.thirdStep.accountNumber === '') {
        setRequestError('Debes ingresar un número de cuenta')
      } else {
        dispatch(saveSteps({ stepIndex: 0, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 1, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 2, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 3, newStatus: 'current' }))
      }
    } else if (stepIndex === 4) {
      setRequestError('Debes completar los pasos anteriores antes de continuar')
    }
  } else if (sitterRequest.steps[3].status === 'current') {
    if (stepIndex === 0) {
      dispatch(saveSteps({ stepIndex: 0, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
    } else if (stepIndex === 1) {
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
    } else if (stepIndex === 2) {
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
    } else if (stepIndex === 4) {
      if (
        !sitterRequest.fourthStep.firstAnswer.correct ||
        !sitterRequest.fourthStep.secondAnswer.correct ||
        !sitterRequest.fourthStep.thirdAnswer.correct ||
        !sitterRequest.fourthStep.fourthAnswer.correct ||
        !sitterRequest.fourthStep.fifthAnswer.correct ||
        !sitterRequest.fourthStep.sixthAnswer.correct
      ) {
        setRequestError(
          'Debes tener todas las respuestas del cuestionario correctas',
        )
      } else {
        dispatch(saveSteps({ stepIndex: 0, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 1, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 2, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 3, newStatus: 'complete' }))
        dispatch(saveSteps({ stepIndex: 4, newStatus: 'current' }))
      }
    }
  } else if (sitterRequest.steps[4].status === 'current') {
    if (stepIndex === 0) {
      dispatch(saveSteps({ stepIndex: 0, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 4, newStatus: 'upcoming' }))
    } else if (stepIndex === 1) {
      dispatch(saveSteps({ stepIndex: 1, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 4, newStatus: 'upcoming' }))
    } else if (stepIndex === 2) {
      dispatch(saveSteps({ stepIndex: 2, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'upcoming' }))
      dispatch(saveSteps({ stepIndex: 4, newStatus: 'upcoming' }))
    } else if (stepIndex === 3) {
      dispatch(saveSteps({ stepIndex: 3, newStatus: 'current' }))
      dispatch(saveSteps({ stepIndex: 4, newStatus: 'upcoming' }))
    }
  }
}

export default handleStepChange
