import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from 'lucide-react'
import { useUpdateRecords } from '@services/servicerecord'
import { toast } from 'react-toastify'
export default function ChangeDate({ params, onClose }) {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedHour, setSelectedHour] = useState(12)
  const [selectedMinute, setSelectedMinute] = useState(0)
  const { mutate } = useUpdateRecords()

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate()
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  ).getDay()

  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    )
  }

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    )
  }

  const selectDate = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day),
    )
  }

  const renderCalendarDays = () => {
    const days = []
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-10"></div>)
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected =
        selectedDate &&
        selectedDate.getDate() === day &&
        selectedDate.getMonth() === currentDate.getMonth() &&
        selectedDate.getFullYear() === currentDate.getFullYear()
      days.push(
        <button
          key={day}
          onClick={() => selectDate(day)}
          className={`flex h-10 w-10 items-center justify-center rounded-full transition-colors ${
            isSelected ? 'bg-blue-500 text-white' : 'hover:bg-blue-100'
          }`}
        >
          {day}
        </button>,
      )
    }
    return days
  }

  const incrementHour = () => setSelectedHour((prevHour) => (prevHour + 1) % 24)
  const decrementHour = () =>
    setSelectedHour((prevHour) => (prevHour - 1 + 24) % 24)
  const incrementMinute = () =>
    setSelectedMinute((prevMinute) => (prevMinute + 1) % 60)
  const decrementMinute = () =>
    setSelectedMinute((prevMinute) => (prevMinute - 1 + 60) % 60)

  const handleSubmit = () => {
    if (selectedDate) {
      const dateTime = new Date(selectedDate)
      dateTime.setHours(selectedHour)
      dateTime.setMinutes(selectedMinute)
      if (!params.data.id || !dateTime) {
        toast.error('Seleccione una fecha y hora válidos 🚨')
        return
      }
      mutate({ id: params.data.id, body: { date: dateTime } })
      onClose()
    }
  }

  return (
    <div className="mx-auto mt-10 max-w-lg">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={prevMonth}
          className="rounded-full p-2 hover:bg-gray-200"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <h2 className="text-xl font-semibold">
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>
        <button
          onClick={nextMonth}
          className="rounded-full p-2 hover:bg-gray-200"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="mb-4 grid grid-cols-7 gap-1">
        {['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'].map((day) => (
          <div key={day} className="text-center font-medium text-gray-500">
            {day}
          </div>
        ))}
        {renderCalendarDays()}
      </div>
      <div className="mt-6 flex items-center justify-center space-x-4">
        <div className="flex flex-col items-center">
          <button
            onClick={incrementHour}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <ChevronUpIcon className="h-5 w-5" />
          </button>
          <span className="w-12 text-center text-2xl font-semibold">
            {selectedHour.toString().padStart(2, '0')}
          </span>
          <button
            onClick={decrementHour}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <ChevronDownIcon className="h-5 w-5" />
          </button>
        </div>
        <span className="text-2xl font-semibold">:</span>
        <div className="flex flex-col items-center">
          <button
            onClick={incrementMinute}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <ChevronUpIcon className="h-5 w-5" />
          </button>
          <span className="w-12 text-center text-2xl font-semibold">
            {selectedMinute.toString().padStart(2, '0')}
          </span>
          <button
            onClick={decrementMinute}
            className="rounded-full p-1 hover:bg-gray-200"
          >
            <ChevronDownIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="mt-6 flex justify-center">
        <button
          onClick={handleSubmit}
          disabled={!selectedDate}
          className="w-full rounded-md bg-blue-500 px-4 py-2 text-white transition-all hover:scale-105 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:opacity-50"
        >
          Submit
        </button>
      </div>
      {selectedDate && (
        <p className="mt-4 text-center text-sm text-gray-600">
          Fecha y hora seleccionada: {selectedDate.toLocaleDateString()}{' '}
          {selectedHour.toString().padStart(2, '0')}:
          {selectedMinute.toString().padStart(2, '0')}
        </p>
      )}
    </div>
  )
}

ChangeDate.propTypes = {
  params: PropTypes.object,
  onClose: PropTypes.func,
}
