import PropTypes from 'prop-types'
import { FaQuestion } from 'react-icons/fa'
import CountUp from 'react-countup'
import StatsToolTip from '@common/tooltip/StatsToolTip'
import StatsInfo from '@common/tooltip/StatsInfo'

function BasicStats({ sitter, stats }) {
  return (
    <div className="flex flex-col gap-8 sm:gap-4 sm:flex-row">
      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">Servicios</h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Cantidad total de servicios solicitados.',
                  'Se consideran todos los servicios solicitados, independiente de su estado',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <CountUp
          end={stats.recordsQty ? stats.recordsQty : 0}
          duration={1.5}
          className="w-full text-center text-5xl font-bold text-grey-base"
        />
      </div>

      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">
            Servicios rechazados
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Porcentaje de servicios rechazados respecto al total de servicios solicitados.',
                  'Ejemplo: Si se solicitan 10 servicios y se rechazan 2, el porcentaje es de 20%.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <p className="w-full text-center text-5xl font-bold text-grey-base">
          <CountUp
            end={stats.deniedPercentage ? stats.deniedPercentage : 0}
            suffix=" %"
            duration={1.5}
          />
        </p>
      </div>

      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">
            Comunas habilitadas
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo texts={sitter.communes ? sitter.communes : []} />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <CountUp
          end={sitter.communes ? sitter.communes.length : 0}
          duration={1.5}
          className="w-full text-center text-5xl font-bold text-grey-base"
        />
      </div>
    </div>
  )
}

BasicStats.propTypes = {
  sitter: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
}

export default BasicStats
