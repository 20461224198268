import PropTypes from 'prop-types'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useState } from 'react'
import { usePostAddress, useUpdateAddress } from '@services/address'
import { ChevronDown } from 'lucide-react'

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

function PostAddress({ onSubmit, address }) {
  const { mutate: postAddress } = usePostAddress()
  const { mutate: putAddress } = useUpdateAddress()
  const [isOpen, setIsOpen] = useState(false)
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: API_KEY,
      options: { componentRestrictions: { country: 'CL' }, types: ['address'] },
    })

  const [selectedPlace, setSelectedPlace] = useState(null)
  const [inputValue, setInputValue] = useState(address?.formattedAddress || '')
  const [department, setDepartment] = useState(address?.department || '')

  const handleClick = (place) => {
    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      (place, status) => {
        if (status === 'OK') {
          setSelectedPlace(place)
          setInputValue(place.formatted_address)
          getPlacePredictions({ input: '' })
        }
      },
    )
    setIsOpen(false)
  }

  const handleInputChange = (evt) => {
    const value = evt.target.value
    setInputValue(value)
    setIsOpen(true)
    if (value) {
      getPlacePredictions({ input: value })
    }
  }

  const handleSubmit = () => {
    let body = {
      department: department,
    }
    if (selectedPlace) {
      body.formattedAddress = selectedPlace.formatted_address
      body.location = {
        lat: selectedPlace.geometry.location.lat(),
        lng: selectedPlace.geometry.location.lng(),
      }
      body.googleId = selectedPlace.place_id

      if (!address) {
        postAddress(body)
      }
    }
    if (address) {
      putAddress({ id: address.id, body: body })
    }
    onSubmit()
  }

  return (
    <div className="mx-auto max-w-md">
      <div className="relative mb-4">
        <div className="relative">
          <label
            htmlFor="address"
            className="mb-1 block font-mont-semi text-sm leading-6 text-grey-base"
          >
            Dirección
          </label>
          <input
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
            placeholder="Selecciona una dirección"
            value={inputValue}
            onChange={handleInputChange}
          />
          <ChevronDown className="absolute right-3 top-[70%] h-5 w-5 -translate-y-1/2 text-gray-400" />
        </div>
        {!isPlacePredictionsLoading && isOpen && placePredictions.length > 0 && (
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
            {placePredictions.map((item, index) => (
              <div key={index}>
                <button
                  onClick={() => handleClick(item)}
                  className="w-full px-4 py-2 text-left text-sm text-grey-base hover:bg-grey-soft hover:text-slate-900 focus:bg-grey-soft focus:outline-none"
                >
                  {item.description}
                </button>
                {index < placePredictions.length - 1 && (
                  <hr className="mx-4 border-gray-200" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mb-4">
        <label
          htmlFor="department"
          className="mb-1 block font-mont-semi text-sm leading-6 text-grey-base"
        >
          Departamento
        </label>
        <input
          id="department"
          type="text"
          placeholder="Ingresa el departamento"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="mt-4 flex w-full items-center justify-center rounded-md bg-blue-ter px-3.5 py-2.5 text-sm text-white-base shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      >
        {address ? 'Actualizar' : 'Añadir dirección'}
      </button>
    </div>
  )
}

PostAddress.propTypes = {
  onSubmit: PropTypes.func,
  address: PropTypes.object,
}

export default PostAddress
