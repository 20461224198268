import { useSearchParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import Loading from '@common/utils/Loading'
import Voucher from './components/payment/Voucher'
import { useCommitWpTransaction } from '@services/payment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { usePaymentHandler } from '@common/hooks/usePaymentHandler'

function CommitWpTransaction() {
  const [searchParams] = useSearchParams()
  const tokenWs = searchParams.get('token_ws')

  const {
    getHandleSuccessFunction,
    paymentAmount,
    paymentAmountBeforeDiscount,
  } = usePaymentHandler()

  const handleSuccessFunction = getHandleSuccessFunction()
  const hasMutated = useRef(false)

  const { isPending, mutateAsync, data } = useCommitWpTransaction(
    handleSuccessFunction,
  )

  useEffect(() => {
    const commitTransaction = async () => {
      if (tokenWs && paymentAmount && !hasMutated.current) {
        const body = {
          tokenWs,
          price: paymentAmount,
          priceBeforeDiscount: paymentAmountBeforeDiscount,
        }
        await mutateAsync(body)
        hasMutated.current = true
      }
    }
    commitTransaction()
    // eslint-disable-next-line
  }, [])

  if (isPending || !data) return <Loading />

  return (
    <div className="w-full p-4 md:mx-auto md:my-8 md:max-w-md">
      <ToastContainer />
      {data?.transbank && <Voucher tbkInfo={data?.transbank} />}
    </div>
  )
}

export default CommitWpTransaction
