import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const petSlice = createSlice({
  name: 'pets',
  initialState,
  reducers: {
    setPets(state, action) {
      return action.payload
    },
    savePet(state, action) {
      return state.push(action.payload)
    },
  },
})

export const { setPets, savePet } = petSlice.actions
export default petSlice.reducer
