import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import DiscountForm from '@common/forms/DiscountForm'
import {
  numberRenderer,
  textCenterRenderer,
} from '@common/helpers/tableRenderers'

const DiscountList = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedDiscount, setSelectedDiscountId] = useState(null)

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['adminDiscounts'],
    queryFn: () => getData('discount'),
  })

  const openModal = (discountId) => {
    setIsOpenModal(true)
    setSelectedDiscountId(discountId)
  }

  const filteredData = data
    ? data.filter(
        (discount) =>
          discount.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
          discount.amount.toString().includes(searchTerm) ||
          discount.maxUses.toString().includes(searchTerm) ||
          discount.expireDate.includes(searchTerm),
      )
    : null

  if (isPending) return <Loading />

  const tableProps = [
    {
      key: 'code',
      header: { name: 'Código' },
    },
    {
      key: 'amount',
      header: { name: 'Descuento (%)', style: { textAlign: 'center' } },
      renderer: numberRenderer,
    },
    {
      key: 'usesCounter',
      header: { name: 'Usos Actuales', style: { textAlign: 'center' } },
      renderer: numberRenderer,
    },
    {
      key: 'maxUses',
      header: { name: 'Usos Máximos', style: { textAlign: 'center' } },
      renderer: numberRenderer,
    },
    {
      key: 'oneTime',
      header: { name: 'Uso Único', style: { textAlign: 'center' } },
      formatter: (oneTime) => (oneTime ? 'Sí' : 'No'),
      renderer: textCenterRenderer,
    },
    {
      key: 'expireDate',
      header: { name: 'Fecha de Expiración', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((discount) => ({
      ...discount,
      expireDate: new Date(discount.expireDate).toLocaleDateString(),
    }))
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Buscar descuento'}
      />
      <CustomTable
        data={tableData}
        tableProps={tableProps}
        rowClickHandler={(discount) => openModal(discount)}
      />
      <CustomModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        header={
          <ModalHeader
            title="Editar descuento"
            subtitle={`Actualiza el descuento ${selectedDiscount?.code}`}
          />
        }
        body={
          <DiscountForm
            onSubmit={() => setIsOpenModal(false)}
            discount={selectedDiscount}
          />
        }
      />
      {isError ? <Error message={error.message} /> : null}
    </div>
  )
}

export default DiscountList
