import PropTypes from 'prop-types'
import SitterMainInfo from './SitterMainInfo'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

function SitterSummary({ sitter, stats }) {
  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-4 shadow-xl">
      <SitterMainInfo sitter={sitter} />

      <div className="flex flex-row items-center justify-between space-x-4">
        <div
          className={`${
            stats.statusLightColor == 0
              ? 'bg-red-base'
              : 'bg-red-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats.statusLightColor == 1
              ? 'bg-[#f97316]'
              : 'bg-orange-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats.statusLightColor == 2
              ? 'bg-yellow-base'
              : 'bg-yellow-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats.statusLightColor == 3
              ? 'bg-[#dff77b]'
              : 'bg-[#ecfccb] bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats.statusLightColor == 4
              ? 'bg-green-base'
              : 'bg-green-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
      </div>

      <div className="flex flex-wrap gap-4 text-sm">
        {Object.keys(stats.profileStatus).map((key, index) => {
          return (
            <p key={index} className="flex flex-row items-center gap-2">
              {stats.profileStatus[key] ? (
                <FaCheckCircle className="text-green-base" />
              ) : (
                <FaTimesCircle className="text-red-base" />
              )}
              {key == 'description'
                ? 'Descripción'
                : key == 'photo'
                  ? 'Foto de perfil'
                  : key == 'prices'
                    ? 'Precios'
                    : key == 'communes'
                      ? 'Comunas'
                      : key == 'petSize'
                        ? 'Tamaño de perros'
                        : key == 'bankAccount'
                          ? 'Información bancaria'
                          : key == 'profilePhotos'
                            ? 'Fotos acerca de su perfil'
                            : key == 'aboutSitterHouse'
                              ? 'Sobre su casa'
                              : 'Calendario actualizado'}{' '}
            </p>
          )
        })}
      </div>
    </div>
  )
}

SitterSummary.propTypes = {
  sitter: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
}

export default SitterSummary
