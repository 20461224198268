import { Navigate, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '@common/context/AuthContext'

const GuestRoutes = () => {
  const { getAuthToken } = useContext(AuthContext)
  return getAuthToken() ? <Navigate to={'/'} /> : <Outlet />
}

export default GuestRoutes
