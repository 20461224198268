import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import { formatDate, formatPrice } from '@utils/formatFunctions'
import {
  statusRenderer,
  typeRenderer,
  payoutStatusRenderer
} from '@common/helpers/tableRenderers'

function FinishedRecords() {
  const location = useLocation()
  const { records } = location.state
  const [searchTerm, setSearchTerm] = useState('')

  const filteredData = records
    ? records.filter(
        (record) =>
          record.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          record.price
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          record.id.toString().includes(searchTerm),
      )
    : null

  const tableProps = [
    {
      key: 'type',
      header: { name: 'Servicio', style: { textAlign: 'center' } },
      renderer: (type) => typeRenderer({ value: type }),
    },
    {
      key: 'date',
      header: {
        name: 'Fecha',
      },
      formatter: formatDate,
    },
    {
      key: 'price',
      header: { name: 'Total' },
      formatter: formatPrice,
    },
    {
      key: 'debt',
      header: { name: 'Sitter' },
      formatter: formatPrice,
    },
    {
      key: 'fee',
      header: { name: 'Fee' },
      formatter: formatPrice,
    },
    {
      key: 'status',
      header: { name: 'Estado', style: { textAlign: 'center' } },
      renderer: (status) => statusRenderer({ value: status }),
    },
    {
      key: 'payout',
      header: {
        name: 'Fecha pago',
      },
      formatter: (payout) => formatDate(payout.date),
    },
    {
      key: 'payout',
      header: { name: 'Estado pago', style: { textAlign: 'center' } },
      renderer: (payout) => payoutStatusRenderer(payout.status),
    },
  ]

  return (
    <div className="min-h-screen p-4">
      <div className="w-full rounded-lg bg-white p-2 shadow-md">
        <SearchInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={'Buscar servicio...'}
        />
        <CustomTable data={filteredData} tableProps={tableProps} />
      </div>
    </div>
  )
}

export default FinishedRecords
