import PropTypes from 'prop-types'
import { useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import PhotoAlbum from 'react-photo-album'

const PhotoGallery = ({ data }) => {
  const [showAll, setShowAll] = useState(false)
  const photosToShow = showAll
    ? data.profilePhotos
    : data.profilePhotos.slice(0, 5)

  return (
    <div
      className={`flex flex-col items-start justify-center gap-2 ${showAll ? 'h-full' : 'h-auto'}`}
    >
      <h2 className="font-mont-semi text-slate-900 sm:text-lg">
        Galería de fotos de {data.name}
      </h2>
      {data.profilePhotos && data.profilePhotos.length === 0 ? (
        <div className="mt-4 flex w-full flex-col items-center justify-center gap-2 text-sm text-grey-soft">
          <FaCamera className="h-12 w-12" />
          <p>No hay fotos disponibles</p>
        </div>
      ) : (
        <div className="h-full w-full">
          <PhotoAlbum
            layout="columns"
            photos={photosToShow}
            renderPhoto={({
              imageProps: { src, alt, style, ...restImageProps },
            }) => (
              <img
                src={src}
                alt={alt}
                style={style}
                {...restImageProps}
                className="rounded-lg shadow-lg"
              />
            )}
          />
          {data.profilePhotos.length > 5 && (
            <button
              onClick={() => setShowAll(!showAll)}
              className="mt-4 text-sm text-grey-soft underline"
            >
              {showAll ? 'Ver menos' : 'Ver más'}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

PhotoGallery.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PhotoGallery
