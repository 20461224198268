import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function PageError({ message }) {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/')
  }

  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-200 px-16 md:px-0">
      <div className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white px-4 py-8 shadow-2xl md:px-8 lg:px-24">
        <p className="mt-4 text-2xl font-bold tracking-wider text-grey-base md:text-3xl lg:text-5xl">
          {message}
        </p>
        <p className="mt-4 border-b-2 pb-4 text-center text-grey-base">
          Lo sentimos, la informacion que estás buscando no se pudo encontrar.
        </p>
        <a
          href="#"
          className="mt-6 flex items-center space-x-2 rounded bg-blue-base px-4 py-2 text-gray-100 transition duration-150 hover:bg-blue-sec"
          title="Volver al Inicio"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <button onClick={handleClick}>Volver al Inicio</button>
        </a>
      </div>
    </div>
  )
}

PageError.propTypes = {
  message: PropTypes.string.isRequired,
}
