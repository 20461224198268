import PropTypes from 'prop-types'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale-subtle.css'
import { Tooltip } from 'react-tippy'

function StatsToolTip({ InfoToShow, style, icon }) {
  return (
    <Tooltip
      html={InfoToShow}
      position="bottom"
      trigger="mouseenter"
      animation="scale-subtle"
    >
      <button className={style}>{icon}</button>
    </Tooltip>
  )
}

StatsToolTip.propTypes = {
  InfoToShow: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  style: PropTypes.string,
}

export default StatsToolTip
