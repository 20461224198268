import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// Utils
import ScrollToTop from '@common/utils/ScrollToTop'
// Auth Routes
import PrivateRoutes from '@common/routes_auth/PrivateRoutes'
import GuestRoutes from '@common/routes_auth/GuestRoutes'
import AdminRoutes from '@common/routes_auth/AdminRoutes'
import SitterRoutes from '@common/routes_auth/SitterRoutes'
// Layouts
import Navbar from '@common/layouts/Navbar'
import Footer from '@common/layouts/Footer'
// General Routes
import Home from '@pages/home/Home'
import AboutUs from '@pages/home/AboutUs'
import PetEducation from '@pages/home/PetEducation'
import PageNotFound from '@common/error/PageNotFound'
import Bci from '@pages/home/Bci'
import Blog from '@pages/blog/Blog'
import BlogPost from '@pages/blog/BlogPost'
// Admin Routes
import AdminCenter from '@pages/admin/AdminCenter'
import AdminSitterRequest from '@pages/admin/AdminSitterRequest'
import AdminSitterStats from '@pages/admin/AdminSitterStats'
import ServiceSummary from '@pages/admin/ServiceSummary'
// Guest Routes
import SignUp from '@pages/auth/SignUp'
import SignIn from '@pages/auth/SignIn'
import ForgetPassword from '@pages/auth/ForgetPassword'
import ChangePassword from '@pages/auth/ChangePassword'
import PlanPaymentPage from '@pages/plan/PlanPaymentPage'
// Sitter Routes
import SitterStats from '@pages/sitter/SitterStats'
import OfferServices from '@pages/sitter/OfferServices'
import FinishedRecords from '@pages/sitter/FinishedRecords'
import ServiceRequests from '@pages/sitter/ServiceRequests'
import SitterServiceRecords from '@pages/sitter/ServiceRecords'
// Private Routes
import SitterSelection from '@pages/sitter_selection/SitterSelection'
import HireSitter from '@pages/hire_sitter/HireSitter'
import CreateMpPayment from '@pages/hire_sitter/CreateMpPayment'
import PaymentMpStatus from '@pages/hire_sitter/PaymentMpStatus'
import CommitWpTransaction from '@pages/hire_sitter/CommitWpTransaction'
import CommitOcTransaction from '@pages/hire_sitter/CommitOcTransaction'
import SitterRequest from '@pages/sitter_request/SitterRequest'
import Profile from '@pages/user/Profile'
import PendingReviews from '@pages/user/PendingReviews'
import Settings from '@pages/user/Settings'
import UserServiceRecords from '@pages/user/ServiceRecords'
import CreatePlanPage from '@pages/plan/CreatePlanPage'

function App() {
  return (
    <div className="flex flex-col bg-white-base font-mont">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/pet-education" element={<PetEducation />} />
          <Route path="/sitter-selection" element={<SitterSelection />} />
          <Route path="/bci" element={<Bci />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route
              path="/external/plan/:planId"
              element={<PlanPaymentPage />}
            />
            <Route
              path="/payment/webpay/commit"
              element={<CommitWpTransaction />}
            />
            <Route path="/payment/mercadopago" element={<CreateMpPayment />} />
            <Route
              path="/payment/mercadopago/status"
              element={<PaymentMpStatus />}
            />
          <Route exact path="/" element={<AdminRoutes />}>
            <Route path="/admin" element={<AdminCenter />} />
            <Route
              path="/admin/sitter-request/:requestId"
              element={<AdminSitterRequest />}
            />
            <Route
              path="/admin/sitter-view/:sitterId"
              element={<AdminSitterStats />}
            />
            <Route
              path="/admin/service-summary/:serviceId"
              element={<ServiceSummary />}
            />
          </Route>
          <Route exact path="/" element={<GuestRoutes />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ChangePassword />} />
          </Route>
          <Route exact path="/" element={<SitterRoutes />}>
            <Route path="/sitter/offer-services" element={<OfferServices />} />
            <Route path="/sitter/stats" element={<SitterStats />} />
            <Route
              path="/sitter/service-requests"
              element={<ServiceRequests />}
            />
            <Route
              path="/sitter/service-records"
              element={<SitterServiceRecords />}
            />
            <Route
              path="/sitter/finished-records"
              element={<FinishedRecords />}
            />
          </Route>
          <Route exact path="/" element={<PrivateRoutes />}>
            <Route path="/hire-sitter/:sitterId" element={<HireSitter />} />
            <Route
              path="/hire-sitter/transbank-oc-commit"
              element={<CommitOcTransaction />}
            />
            <Route path="/sitter-request" element={<SitterRequest />} />
            <Route path="/user/:id" element={<Profile />} />
            <Route path="/user/pending-reviews" element={<PendingReviews />} />
            <Route path="/user/settings" element={<Settings />} />
            <Route
              path="/user/service-records"
              element={<UserServiceRecords />}
            />
            <Route path="/plan/new" element={<CreatePlanPage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
