import PlanFormCreate from '../../common/forms/PlanFormCreate'

export default function CreatePlanPage() {
  return (
    <div className="my-6 flex min-h-screen flex-col items-center justify-start gap-4">
      <h1 className="font-tex-bold text-3xl text-slate-800 md:text-4xl">
        Crear un plan
      </h1>
      <PlanFormCreate />
    </div>
  )
}
