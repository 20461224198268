/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import { Textarea } from '@headlessui/react'
import { useUpdateUser } from '@services/user'

function AboutMe() {
  const user = useSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)
  const [newDescription, setNewDescription] = useState(user.description)
  const { mutate: updateUser } = useUpdateUser()

  const onClose = () => {
    if (newDescription !== user.description) {
      updateUser({ description: newDescription })
    }
    setIsOpen(false)
  }

  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-row items-center gap-2 font-tex text-xl">
          <h1>Sobre mi</h1>
          <p>📝</p>
        </div>
        <p
          className="line-clamp-3 cursor-pointer text-xs text-grey-base transition-transform hover:scale-105"
          onClick={() => setIsOpen(true)}
        >
          {user.description ??
            'No tienes ninguna descripción sobre ti. Agrega una para mejorar tu visibilidad. 📈'}
        </p>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualiza tu descripción"
            subtitle="Agrega una descripción para mejorar tu visibilidad"
          />
        }
        body={
          <ModalBody
            newDescription={newDescription}
            setNewDescription={setNewDescription}
            onClose={onClose}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

const ModalBody = ({ newDescription, setNewDescription, onClose }) => {
  return (
    <div className="flex flex-col gap-4">
      <Textarea
        className="w-full rounded-md border-grey-sec text-xs placeholder:text-grey-soft focus:border-blue-soft focus:ring-transparent md:w-[32rem]"
        placeholder="Escribe algo interesante sobre ti acerca de tu experiencia con mascotas..."
        rows={12}
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
      />
      <button
        className="inline-block rounded-lg bg-blue-soft px-8 py-3 text-sm font-medium text-white transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-blue-600"
        type="button"
        onClick={onClose}
      >
        Guardar cambios!
      </button>
    </div>
  )
}

export default AboutMe
