/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { getData } from '@services/requests'
import StatusLights from '@common/stats/StatusLights'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import Stats from './components/sitter_stats/Stats'
import Debt from './components/sitter_stats/Debt'
import { useContext } from 'react'
import { AuthContext } from '@common/context/AuthContext'

function MyStats() {
  const { getAuthToken } = useContext(AuthContext)
  const user = useSelector((state) => state.user)

  const {
    isPending: isPendingStats,
    isError: isErrorStats,
    error: errorStats,
    data: stats,
  } = useQuery({
    queryKey: ['profileStats', user.id],
    queryFn: () => getData(`service-record/sitter-stats/${user.id}`),
  })

  const {
    isPending: isPendingDebt,
    isError: isErrorDebt,
    error: errorDebt,
    data: debt,
  } = useQuery({
    queryKey: ['profileDebt', user.id],
    queryFn: () => getData(`service-record/debt/${user.id}`),
  })

  if (isPendingStats || isPendingDebt) return <Loading />

  return (
    <div className="flex w-full flex-col gap-2 p-2 sm:flex-row sm:p-4 md:min-h-screen">
      <div className="w-full sm:w-1/3">
        <Stats data={stats} />
      </div>
      <div className="flex w-full flex-col gap-2 sm:w-2/3">
        <Debt debtData={debt} />
        <StatusLights data={stats} />
      </div>

      {isErrorStats && <Error message={errorStats.message} />}
      {isErrorDebt && <Error message={errorDebt.message} />}
    </div>
  )
}

export default MyStats
