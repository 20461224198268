import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ReactStars from 'react-rating-stars-component'
import { FaStar, FaStarHalf } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { postData } from '@services/requests'
import Button from '@common/buttons/Button'
import ErrorNoCard from '@common/error/ErrorNoCard'

function Review({ service }) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const user = useSelector((state) => state.user)
  const queryClient = useQueryClient()
  const [stars, setStars] = useState(0)
  const [description, setDescription] = useState('')

  const {
    isPending: postIsPending,
    isError: postIsError,
    error: postError,
    mutate: postReview,
  } = useMutation({
    mutationFn: async () => {
      const body = {
        stars: stars,
        description: description,
      }
      const data = await postData(
        `review/${service.record.id}`,
        body,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['serviceRecords', user.id] })
      setSearchParams({ recordId: service.record.id })
    },
  })

  return (
    <div className="flex flex-col items-center gap-2 text-sm">
      <p className="text-center">
        ¿Que te pareció el servicio de{' '}
        <span className="font-mont-semi">
          {service.sitter?.name} {service.sitter?.lastName}
        </span>
        ?
      </p>
      <form className="flex w-full flex-col items-center gap-4">
        <ReactStars
          count={5}
          size={40}
          filledIcon={<FaStar />}
          emptyIcon={<FaStar />}
          halfIcon={<FaStarHalf />}
          value={0}
          color="#D9D9D9"
          edit={true}
          onChange={(newRating) => setStars(newRating)}
        />
        <textarea
          className="w-full rounded-lg border border-gray-300 p-2 placeholder:text-sm placeholder:text-grey-soft"
          placeholder={`Agrega tus comentarios sobre el servicio de ${service.sitter?.name} ...`}
          rows={5}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <Button
          text="Evaluar servicio"
          bgColor="blue-soft"
          textColor="white"
          hoverColor="blue-600"
          circleColor="white"
          fillColor="blue-soft"
          onClick={postReview}
          loading={postIsPending}
        />
      </form>
      {postIsError && <ErrorNoCard message={postError.message} />}
    </div>
  )
}

Review.propTypes = {
  service: PropTypes.object.isRequired,
}

export default Review
