import PropType from 'prop-types'

const SearchInput = ({ searchTerm, setSearchTerm, placeholder }) => {
  return (
    <div className="bg-white p-4">
      <label htmlFor="search" className="sr-only">
        Buscar
      </label>
      <input
        type="text"
        id="search"
        className="block w-full rounded-md border-gray-300 focus:border-blue-ter focus:ring-blue-ter sm:text-sm"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  )
}

SearchInput.propTypes = {
  searchTerm: PropType.string,
  setSearchTerm: PropType.func,
  placeholder: PropType.string,
}

export default SearchInput
