import PropTypes from 'prop-types'
import { FaStar, FaStarHalf, FaRegStar } from 'react-icons/fa'

const StarsRating = ({ count, value, size, color, activeColor, isHalf }) => {
  const stars = []

  for (let i = 1; i <= count; i++) {
    if (value >= i) {
      stars.push(<FaStar key={i} size={size} color={activeColor} />)
    } else if (isHalf && value + 0.5 >= i) {
      stars.push(<FaStarHalf key={i} size={size} color={activeColor} />)
    } else {
      stars.push(<FaRegStar key={i} size={size} color={color} />)
    }
  }

  return <div className='flex flex-row'>{stars}</div>
}

StarsRating.propTypes = {
  count: PropTypes.number,
  value: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isHalf: PropTypes.bool,
}

export default StarsRating
