import { useState, useRef } from 'react'
import { ChevronDown } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isToday,
  isSameDay,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { useUpdateAvailability } from '@services/availability'

/* eslint-disable react/prop-types */
function Availability({ selectedDate, setIsOpen }) {
  const [dates, setDates] = useState([selectedDate])
  const [hours, setHours] = useState([])
  const { mutate: updateAvailability } = useUpdateAvailability()

  const hoursArray = Array.from({ length: 18 }, (_, i) => i + 6)

  const handleUpdateAvailability = () => {
    updateAvailability({
      dates,
      hours,
    })
    setIsOpen(false)
  }

  return (
    <div className="space-y-4">
      <ul className="flex max-w-xl flex-wrap justify-center gap-4">
        {hoursArray.map((hour) => (
          <li key={hour} className="relative flex items-center justify-center">
            <input
              type="checkbox"
              name={`${hour}:00`}
              id={`checkbox-${hour}`}
              value={hour}
              className="peer hidden"
              onChange={(e) => {
                if (e.target.checked) {
                  setHours([...hours, e.target.value])
                } else {
                  setHours(hours.filter((h) => h !== e.target.value))
                }
              }}
            />
            <label
              htmlFor={`checkbox-${hour}`}
              className="inline-flex w-full cursor-pointer items-center justify-between rounded-full border border-grey-base bg-gray-100 px-4 py-1.5 text-center text-grey-base hover:bg-grey-soft hover:text-slate-900 peer-checked:border-pink-base peer-checked:bg-pink-base peer-checked:text-white"
            >
              <p className="w-full text-center text-sm">
                {`${hour}:00 ${hour < 12 ? 'AM' : 'PM'}`}
              </p>
            </label>
          </li>
        ))}
      </ul>
      <AddDates selectedDate={selectedDate} dates={dates} setDates={setDates} />
      <button
        className="inline-block w-full rounded-lg bg-blue-soft px-8 py-3 text-sm font-medium text-white transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-blue-600"
        type="button"
        onClick={() => handleUpdateAvailability()}
      >
        Guardar cambios!
      </button>
    </div>
  )
}

const AddDates = ({ selectedDate, dates, setDates }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef(null)
  const weekStart = startOfWeek(selectedDate, { weekStartsOn: 1 })
  const weekEnd = endOfWeek(selectedDate, { weekStartsOn: 1 })
  const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd })
  const weekDayNames = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ]

  const isDateSelected = (day) => {
    return dates.some((d) => isSameDay(d, day))
  }

  return (
    <div className="mx-auto w-full max-w-2xl pt-4">
      <button
        className="mb-4 flex w-full items-center justify-center text-lg font-medium text-gray-600"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-controls="schedule-options"
      >
        <div className="flex items-center justify-center text-sm">
          <span>Copiar para otros días</span>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            className="ml-2"
          >
            <ChevronDown className="h-6 w-6" />
          </motion.div>
        </div>
      </button>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            id="schedule-options"
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            variants={{
              expanded: { height: 'auto', opacity: 1 },
              collapsed: { height: 0, opacity: 0 },
            }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="overflow-visible"
          >
            <motion.div
              variants={{
                expanded: { opacity: 1, y: 0 },
                collapsed: { opacity: 0, y: -20 },
              }}
              transition={{ duration: 0.3, delay: 0.1 }}
              ref={contentRef}
            >
              <ul className="flex max-w-xl flex-wrap justify-center gap-4">
                {weekDays.map((day, i) => (
                  <li
                    key={i}
                    className="relative flex flex-col items-center justify-center gap-1"
                  >
                    <input
                      type="checkbox"
                      name={`${i}:00`}
                      id={`date-${i}`}
                      className="peer hidden"
                      disabled={isToday(day)}
                      checked={isDateSelected(day)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates([...dates, day])
                        } else {
                          setDates(dates.filter((d) => !isSameDay(d, day)))
                        }
                      }}
                    />
                    <p className="text-sm text-grey-base">
                      {format(day, 'dd/MM/yyyy', {
                        locale: es,
                      })}
                    </p>
                    <label
                      htmlFor={`date-${i}`}
                      className="inline-flex w-full cursor-pointer items-center justify-between rounded-full border border-grey-base bg-gray-100 px-4 py-1.5 text-center text-grey-base hover:bg-grey-soft hover:text-slate-900 peer-checked:border-pink-base peer-checked:bg-pink-base peer-checked:text-white"
                    >
                      <p className="w-full text-center text-sm">
                        {weekDayNames[i]}
                      </p>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="pt-6">
                <input
                  type="checkbox"
                  id={'allDates'}
                  className="peer hidden"
                  checked={dates.length === weekDays.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDates(weekDays)
                    } else {
                      setDates([])
                    }
                  }}
                />
                <label
                  htmlFor={'allDates'}
                  className="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-grey-base bg-gray-100 px-4 py-3 text-center text-grey-base hover:bg-grey-soft hover:text-slate-900 peer-checked:border-pink-base peer-checked:bg-pink-base peer-checked:text-white"
                >
                  <p className="w-full text-center text-sm">
                    Agregar semana completa
                  </p>
                </label>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Availability
