import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FaPaw } from 'react-icons/fa'
import { classNames } from '@utils/formatFunctions'

function Pets({ pets, recordType, role = 'user' }) {
  const styles = {
    dogWalking: 'bg-yellow-sec',
    visit: 'bg-blue-ter',
    dayCare: 'bg-purple-base',
  }

  return (
    <Swiper
      spaceBetween={4}
      slidesPerView={role == 'sitter' ? 1 : 2}
      freeMode={true}
      scrollbar={{ draggable: true }}
      className="swiper-container"
    >
      {pets &&
        pets.map((pet) => (
          <SwiperSlide key={pet?.id} style={{ width: 'auto' }}>
            <div className="flex h-32 flex-row items-start gap-2 rounded-lg">
              <div
                className={classNames(
                  styles[recordType],
                  'h-32 rounded-lg text-center text-white flex flex-col',
                )}
              >
                {pet?.photoUrl && (
                  <img
                    className="flex h-24 w-24 items-center justify-center rounded-t-md bg-grey-sec object-cover"
                    src={pet?.photoUrl}
                    alt={pet?.name}
                  />
                )}
                {!pet?.photoUrl && (
                  <div className="flex h-24 w-24 items-center justify-center rounded-t-md bg-white-base">
                    <FaPaw className="text-2xl text-grey-soft" />
                  </div>
                )}
                <p className="capitalize mt-1">{pet.name}</p>
              </div>

              {role == 'sitter' && (
                <div
                  className={classNames(
                    styles[recordType],
                    'h-32 space-y-2 rounded-lg p-2 text-white w-full',
                  )}
                >
                  <div>
                    {' '}
                    <p className="font-mont-semi">{pet?.breed}</p>
                    <p className="text-xs font-thin">
                      {pet?.sex} - {pet?.weight}Kg - {pet?.age} año(s)
                    </p>
                  </div>
                  <div className="flex max-w-sm flex-wrap gap-2 sm:text-xs text-[0.6rem]">
                    {pet?.descriptions?.length !== 0 && (
                      <p className="rounded-md bg-black bg-opacity-40 px-2 py-0.5 text-white">
                        No hay descripciones
                      </p>
                    )}
                    {pet?.descriptions?.map((description, index) => (
                      <p
                        key={index}
                        className="rounded-md bg-black bg-opacity-40 px-2 py-0.5 text-white"
                      >
                        {description}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

Pets.propTypes = {
  pets: PropTypes.array.isRequired,
  recordType: PropTypes.string,
  role: PropTypes.string,
}

export default Pets
