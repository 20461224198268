import PropTypes from 'prop-types'

const ServiceInfo = ({ texts, bgColor }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-2 ${bgColor} mx-2 my-2 rounded-lg bg-opacity-80 p-2 text-sm text-white sm:mx-12`}
    >
      {texts.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  )
}

ServiceInfo.propTypes = {
  texts: PropTypes.array.isRequired,
  bgColor: PropTypes.string.isRequired,
}

export default ServiceInfo
