import PropTypes from 'prop-types'
import { CARDS } from '@assets/constants'
import { FaCreditCard } from 'react-icons/fa'

const CreditCard = ({ cardNumber, type }) => {
  const maskedNumber = cardNumber.slice(0, -4).replace(/./g, '•')
  const lastFourDigits = cardNumber.slice(-4)

  const cardType = CARDS.find((card) => card.name === type)

  return (
    <div className="flex items-center justify-center bg-white">
      <div className="relative m-auto h-48 w-80 transform rounded-xl bg-red-100 text-white shadow-2xl transition-transform hover:scale-110">
        <img
          className="relative h-full w-full rounded-xl object-cover"
          src="https://i.imgur.com/kGkSg1v.png"
        />

        <div className="absolute top-8 w-full px-8">
          <div className="flex h-full justify-between">
            <div className="flex flex-col pt-6">
              <img
                src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                alt="Smart card"
                className="w-12"
              />
              <div className="text-md px-2 pt-6 text-left font-tex-bold">
                {maskedNumber} {lastFourDigits}
              </div>
            </div>
            {cardType ? (
              <img
                src={cardType.logoSrc}
                alt={cardType.name}
                className={cardType.className || 'h-12 w-12'}
              />
            ) : (
              <FaCreditCard className="h-8 w-8 text-white" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CreditCard.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default CreditCard
