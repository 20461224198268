import { addDays } from 'date-fns'

const eventsCleaner = (events) => {
  const auxArray = []
  events.forEach((event) => {
    const principalColor =
      event.type == 'dogWalking'
        ? '#fef3c7'
        : event.type == 'visit'
          ? '#dbeafe'
          : '#f3e8ff'
    const textColor =
      event.type == 'dogWalking'
        ? '#FED162'
        : event.type == 'visit'
          ? '#165163'
          : '#651552'
    let endDate = new Date(event.endDate)
    endDate = addDays(endDate, 1)
    const cleanEvent = {
      id: event.id,
      title:
        event.type == 'dogWalking'
          ? 'Paseo'
          : event.type == 'visit'
            ? 'Visita'
            : event.type == 'dayCare'
              ? 'Alojamiento'
              : null,
      type: event.type,
      start: event.startDate,
      end: endDate,
      startHour: event.startHour,
      endHour: event.endHour,
      allDay: true,
      backgroundColor: principalColor,
      borderColor: principalColor,
      textColor: textColor,
      editable: false,
    }
    auxArray.push(cleanEvent)
  })
  return auxArray
}

const singleEventCleaner = (event) => {
  const principalColor =
    event.type == 'dogWalking'
      ? '#fef3c7'
      : event.type == 'visit'
        ? '#dbeafe'
        : '#f3e8ff'
  const textColor =
    event.type == 'dogWalking'
      ? '#FED162'
      : event.type == 'visit'
        ? '#165163'
        : '#651552'
  let endDate = new Date(event.endDate)
  endDate = addDays(endDate, 1)
  const cleanEvent = {
    id: event.id,
    title: event.type
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase()),
    type: event.type,
    start: event.startDate,
    end: endDate,
    startHour: event.startHour,
    endHour: event.endHour,
    allDay: true,
    backgroundColor: principalColor,
    borderColor: principalColor,
    textColor: textColor,
    editable: false,
  }
  return cleanEvent
}

export { eventsCleaner, singleEventCleaner }
