import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'
import MiniServiceCard from './MiniServiceCard'

function ServicesSwipper({
  initialSlide,
  data,
  selectedService,
  setSelectedService,
  role,
}) {
  return (
    <div className="relative">
      <Swiper
        spaceBetween={16}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={{ draggable: true }}
        className="swiper-container pl-6" 
        initialSlide={initialSlide}
      >
        {data.map((service) => (
          <SwiperSlide key={service.record.id} style={{ width: 'auto' }}>
            <MiniServiceCard
              service={service}
              selected={selectedService.record.id === service.record.id}
              setSelectedService={setSelectedService}
              role={role}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="pointer-events-none absolute left-0 top-0 z-10 h-28 w-14 bg-gradient-to-l from-transparent to-white-base"></div>
      <div className="pointer-events-none absolute right-0 top-0 z-10 h-28 w-14 bg-gradient-to-r from-transparent to-white-base"></div>
    </div>
  )
}

ServicesSwipper.propTypes = {
  initialSlide: PropTypes.number,
  data: PropTypes.array,
  selectedService: PropTypes.object,
  setSelectedService: PropTypes.func,
  role: PropTypes.string,
}

export default ServicesSwipper
