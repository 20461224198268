import PropTypes from 'prop-types'
import { IoSend } from 'react-icons/io5'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { postData } from '@services/requests'
import { classNames } from '@utils/formatFunctions'
import ErrorNoCard from '@common/error/ErrorNoCard'

function ChatInput({ record, chatId }) {
  const user = useSelector((state) => state.user)
  const queryClient = useQueryClient()

  const {
    isPending: postIsPending,
    isError: postIsError,
    error: postError,
    mutate: postMessage,
  } = useMutation({
    mutationFn: async ({ message }) => {
      const body = { type: 'text', content: message }
      const data = await postData(
        `chat/${user.id}/${chatId}/${record.id}`,
        body,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['chat', record.id] })
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const message = e.target[0].value
    if (!message.trim()) return
    postMessage({ message })
    e.target[0].value = ''
  }

  return (
    <div className="relative bg-gray-50">
      <form onSubmit={handleSubmit} className="flex items-center gap-2 p-4">
        <input
          type="text"
          placeholder="Escribe tu mensaje..."
          className={classNames(
            'flex-1 rounded-full border border-gray-300 px-4 py-2 text-sm placeholder:text-grey-soft focus:border-2 focus:ring-0',
            record.type == 'dogWalking' && 'focus:border-yellow-sec',
            record.type == 'visit' && 'focus:border-blue-ter',
            record.type == 'dayCare' && 'focus:border-purple-base',
          )}
        />
        <button
          type="submit"
          className={classNames(
            'relative rounded-full p-3 text-white focus:outline-none',
            record.type == 'dogWalking' && 'bg-yellow-sec hover:bg-opacity-90',
            record.type == 'visit' && 'bg-blue-ter hover:bg-opacity-90',
            record.type == 'dayCare' && 'bg-purple-base hover:bg-opacity-90',
          )}
          disabled={postIsPending}
        >
          {postIsPending ? (
            <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-600 border-t-transparent" />
          ) : (
            <IoSend className="h-4 w-4" />
          )}
        </button>
      </form>
      {postIsError && <ErrorNoCard message={postError.message} />}
    </div>
  )
}

ChatInput.propTypes = {
  record: PropTypes.object.isRequired,
  chatId: PropTypes.string.isRequired,
}

export default ChatInput
