export default async function convertURLToFile(fileURL) {
  if (!fileURL) {
    console.error('URL no proporcionado para convertir a File')
    return null
  }

  try {
    const response = await fetch(fileURL)
    const blob = await response.blob()
    const filename = 'certificado_antecedentes.pdf'
    const file = new File([blob], filename, { type: 'application/pdf' })
    return file
  } catch (err) {
    console.error('Error al convertir el URL a File:', err)
    return null
  }
}
