import PropTypes from 'prop-types'
import { useState, Fragment } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FaPaw, FaStar, FaStarHalf } from 'react-icons/fa'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import ReactStars from 'react-rating-stars-component'
import { getData } from '@services/requests'
import profilePhoto from '@assets/images/others/profile.png'
import SuperSitterBadge from '@common/tooltip/SupperSitterBadge'
import InfoToolTip from '@common/tooltip/InfoToolTip'
import ReviewList from '@common/modals/ReviewList'
import { formatDateReview, classNames } from '@utils/formatFunctions'
import DeleteSitterModal from './DeleteSitterModal'
import MakeSuperSitterModal from './MakeSuperSitterModal'

function SitterMainInfo({ sitter }) {
  const [openReviews, setOpenReviews] = useState(false)
  const [deleteSitterModal, setDeleteSitterModal] = useState(false)
  const [makeSuperSitterModal, setMakeSuperSitterModal] = useState(false)

  const { isSuccess: isSuccessReviews, data: reviews } = useQuery({
    queryKey: ['adminSitterReviews', sitter.id],
    queryFn: () => getData(`review/${sitter.id}`),
  })

  const { isSuccess: isSuccessAddresses, data: addresses } = useQuery({
    queryKey: ['adminSitterAddresses', sitter.id],
    queryFn: () => getData(`address/${sitter.id}`),
  })

  const openModal = (modalName) => {
    if (modalName === 'delete') {
      setDeleteSitterModal(true)
    } else {
      setMakeSuperSitterModal(true)
    }
    document.body.classList.add('overflow-hidden')
  }

  const closeModal = (modalName) => {
    if (modalName === 'delete') {
      setDeleteSitterModal(false)
    } else {
      setMakeSuperSitterModal(false)
    }
    document.body.classList.remove('overflow-hidden')
  }

  const handleOpenReviews = () => {
    setOpenReviews(true)
    document.body.classList.add('overflow-hidden')
  }

  const handleCloseReviews = () => {
    setOpenReviews(false)
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <div className="relative flex flex-col items-start gap-2">
      <Menu as="div" className="absolute right-0">
        <div>
          <Menu.Button className="flex items-center rounded-lg bg-white text-slate-400 hover:bg-slate-100 hover:text-slate-600">
            <span className="sr-only"></span>
            <EllipsisHorizontalIcon className="h-7 w-7" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => openModal('super')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm',
                  )}
                >
                  {sitter.isSuperSitter
                    ? 'Eliminar Super Sitter'
                    : 'Ascender a Super Sitter'}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => openModal('delete')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm',
                  )}
                >
                  Eliminar Sitter
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <div className="flex flex-row items-center gap-4">
        <img
          className="object-fit h-24 w-24 rounded-full"
          src={sitter.photoUrl ? sitter.photoUrl : profilePhoto}
          alt="Foto de sitter"
        />
        <div className="space-y-2">
          <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
            <h1 className="font-tex-bold text-xl text-slate-900">
              {sitter.name ? sitter.name : null}{' '}
              {sitter.lastName ? sitter.lastName : null}
            </h1>
            {sitter.isSuperSitter && (
              <InfoToolTip
                InfoToShow={<SuperSitterBadge />}
                text="Super Sitter"
                icon={<FaPaw />}
                style={
                  'flex flex-row gap-2 items-center text-yellow-700 bg-yellow-base text-sm px-2 py-1 rounded-full'
                }
              />
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            <ReactStars
              count={5}
              value={sitter.stars}
              size={24}
              isHalf={true}
              edit={false}
              filledIcon={<FaStar />}
              halfIcon={<FaStarHalf />}
              emptyIcon={<FaStar />}
              color={'#D9D9D9'}
            />
            {isSuccessReviews && (
              <button
                onClick={handleOpenReviews}
                className="text-xs text-grey-base underline sm:text-sm"
              >
                Basado en {reviews.length} reviews
              </button>
            )}
          </div>
          <p className="text-xs text-grey-sec">
            Usuario hace {formatDateReview(sitter.createdAt)}
          </p>
        </div>
      </div>

      <div className="my-2 flex flex-wrap justify-center gap-4">
        {isSuccessAddresses &&
          addresses.length > 0 &&
          addresses.map((address) => (
            <div
              key={address.id}
              className={
                'flex flex-col items-center justify-center gap-2 rounded-full bg-grey-soft px-2 py-1 text-xs'
              }
            >
              <p className={'text-[0.6rem] text-grey-base'}>
                {address.region}
                {', '}
                {address.commune}
                {', '}
                {address.street}
                {', '}
                {address.department}
              </p>
            </div>
          ))}
      </div>
      <DeleteSitterModal
        sitter={sitter}
        isOpen={deleteSitterModal}
        onClose={() => closeModal('delete')}
      />
      <MakeSuperSitterModal
        sitter={sitter}
        isOpen={makeSuperSitterModal}
        onClose={() => closeModal('super')}
      />
      <ReviewList
        isOpen={openReviews}
        onClose={handleCloseReviews}
        reviews={reviews}
      />
    </div>
  )
}

SitterMainInfo.propTypes = {
  sitter: PropTypes.object.isRequired,
}

export default SitterMainInfo
