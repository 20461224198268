import PropTypes from 'prop-types'
import ReactStars from 'react-rating-stars-component'
import { FaStar, FaStarHalf } from 'react-icons/fa'

function StarsCard({ stars, starsColor }) {
  return (
    <div className="flex h-min flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between space-x-4">
        <div
          className={`${
            starsColor == 0 ? 'bg-red-base' : 'bg-red-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            starsColor == 1 ? 'bg-yellow-base' : 'bg-yellow-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            starsColor == 2 ? 'bg-green-base' : 'bg-green-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-lg">Estrellas</p>
        <div className="flex flex-row justify-between">
          <ReactStars
            count={5}
            value={stars}
            size={24}
            isHalf={true}
            edit={false}
            filledIcon={<FaStar />}
            halfIcon={<FaStarHalf />}
            emptyIcon={<FaStar />}
            color={'#D9D9D9'}
          />
          <p className="text-grey-sec">{stars ?? 0} de 5</p>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-sm">
        <p>
          {starsColor == 0
            ? '¡Cuidado!'
            : starsColor == 1
              ? '¡Mejoremos!'
              : '¡Bien Hecho!'}
        </p>
        <p>
          Tu puntuación de estrellas es{' '}
          <span className="font-mont-semi">
            {starsColor == 0
              ? 'roja.'
              : starsColor == 1
                ? 'amarilla.'
                : 'verde.'}{' '}
          </span>
          {starsColor == 0
            ? '¡Mejora tu relación con los clientes para subir tu puntuación en este aspecto!'
            : starsColor == 1
              ? '¡Ten en cuenta las recomendaciones de los clientes para mejorar tu puntuación!'
              : '¡Sigue así, estas haciendo un gran trabajo!'}
        </p>
      </div>
    </div>
  )
}

StarsCard.propTypes = {
  stars: PropTypes.number,
  starsColor: PropTypes.number,
}

export default StarsCard
