import PropTypes from 'prop-types'
import FieldError from '@common/error/FieldError'
import { classNames } from '@utils/formatFunctions'
import { format, clean } from 'rut.js'

function FormInput({ field, name, label, type, placeholder, isRut, isPhone }) {
  const handleInput = (value) => {
    if (isRut) {
      const rawValue = value
      const truncatedValue = rawValue.slice(0, 12)
      const formattedValue = format(clean(truncatedValue))
      if (truncatedValue.length <= 12) {
        return field.handleChange(formattedValue)
      }
    }
    return field.handleChange(value)
  }

  return (
    <div className="space-y-2">
      <label
        htmlFor={name}
        className="block font-mont-semi text-sm leading-6 text-grey-base"
      >
        {label}
      </label>
      <div className="flex flex-row items-center gap-2">
        {isPhone && (
          <select
            id="country"
            name="country"
            autoComplete="country"
            className="h-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-7 text-xs shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          >
            <option>+56</option>
          </select>
        )}
        <input
          className={classNames(
            'border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 text-xs text-grey-base placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6',
            type === 'checkbox' && 'min-h-8 max-w-8',
          )}
          id={name}
          name={name}
          value={field.state?.value ?? ''}
          type={type}
          onChange={(e) => handleInput(e.target?.value)}
          placeholder={placeholder}
        />
      </div>

      {field?.state?.meta?.isTouched && field?.state?.meta?.errors?.length ? (
        <FieldError message={field?.state?.meta?.errors?.join(', ')} />
      ) : null}
    </div>
  )
}

FormInput.propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isRut: PropTypes.bool,
  isPhone: PropTypes.bool,
  isPassword: PropTypes.bool,
}

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
  isRut: false,
  isPhone: false,
}

export default FormInput
