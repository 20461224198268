import PropType from 'prop-types'
import { FaQuestion } from 'react-icons/fa'
import CountUp from 'react-countup'
import PieChart from '@common/stats/PieChart'
import BarChart from '@common/stats/BarChart'
import StatsToolTip from '@common/tooltip/StatsToolTip'
import StatsInfo from '@common/tooltip/StatsInfo'

function Stats({ data }) {
  return (
    <div className="flex w-full flex-col gap-8 rounded-lg bg-white p-4 shadow-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-lg text-gray-500">
            Total de servicios realizados
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Cantidad total de servicios solicitados.',
                  'Se consideran todos los servicios solicitados, independiente de su estado',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <CountUp
          end={data?.recordsQty ? data.recordsQty : 0}
          duration={3}
          className="text-center text-7xl font-bold text-slate-900"
        />
      </div>
      <div className="flex flex-col items-start">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-lg text-gray-500">
            Tipo de servicios realizados
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Muestra los tipos de servicios realizados por el sitter.',
                  'Los servicios pueden ser de Paseo, Visita o Alojamiento.',
                  'Se consideran todos los servicios, no solo aquellos que se encuentran terminados.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <PieChart
          data={[
            {
              value: data?.dogWalking || 0,
              name: 'Paseo',
              itemStyle: { color: '#87B9FF' },
            },
            {
              value: data?.visit || 0,
              name: 'Visita',
              itemStyle: { color: '#1148AC' },
            },
            {
              value: data?.dayCare || 0,
              name: 'Alojamiento',
              itemStyle: { color: '#3BAAF6' },
            },
          ]}
        />
      </div>
      <div className="flex flex-col items-start">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-lg text-gray-500">
            Estado de las ordenes de trabajo
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Muestra el estado de los servicios de trabajo realizados por el sitter.',
                  'Los estados pueden ser Aceptados, Pendientes, Cancelados,',
                  ' Terminados, Rechazados o En espera.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <BarChart
          data={[
            {
              value: data?.accepted || 0,
              name: 'Aceptados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: data?.pending || 0,
              name: 'Pendientes',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: data?.cancelled || 0,
              name: 'Cancelados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: data?.finished || 0,
              name: 'Finalizados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: data?.denied || 0,
              name: 'Rechazados',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
            {
              value: data?.waiting || 0,
              name: 'En espera',
              itemStyle: { color: 'rgb(59 130 246)' },
            },
          ]}
        />
      </div>
    </div>
  )
}

Stats.propTypes = {
  data: PropType.object.isRequired,
}

export default Stats
