import PropTypes from 'prop-types'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

function ProfileCard({ profileStatus, profileColor }) {
  return (
    <div className="flex h-min flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between space-x-4">
        <div
          className={`${
            profileColor == 0 ? 'bg-red-base' : 'bg-red-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            profileColor == 1 ? 'bg-yellow-base' : 'bg-yellow-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            profileColor == 2 ? 'bg-green-base' : 'bg-green-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-lg">Completitud de perfil</p>
      </div>
      <div className="flex flex-col gap-2 text-sm">
        <p>
          {profileColor == 0
            ? '¡Cuidado!'
            : profileColor == 1
              ? '¡Mejoremos!'
              : '¡Bien Hecho!'}
        </p>
        <p>
          Tu puntuación de perfil es{' '}
          <span className="font-mont-semi">
            {profileColor == 0
              ? 'roja.'
              : profileColor == 1
                ? 'amarilla.'
                : 'verde.'}{' '}
          </span>
        </p>
        {Object.keys(profileStatus).map((key, index) => {
          return (
            <p key={index} className="flex flex-row items-center gap-2">
              {profileStatus[key] ? (
                <FaCheckCircle className="text-green-base" />
              ) : (
                <FaTimesCircle className="text-red-base" />
              )}
              {key == 'description'
                ? 'Descripción'
                : key == 'photo'
                  ? 'Foto de perfil'
                  : key == 'prices'
                    ? 'Precios'
                    : key == 'communes'
                      ? 'Comunas'
                      : key == 'petSize'
                        ? 'Tamaño de perros'
                        : key == 'bankAccount'
                          ? 'Información bancaria'
                          : key == 'profilePhotos'
                            ? 'Fotos sobre ti'
                            : key == 'aboutSitterHouse'
                              ? 'Sobre tu casa'
                              : 'Calendario actualizado'}{' '}
            </p>
          )
        })}
      </div>
    </div>
  )
}

ProfileCard.propTypes = {
  profileStatus: PropTypes.object,
  profileColor: PropTypes.number,
}

export default ProfileCard
