import PropTypes from 'prop-types'
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  isToday,
  startOfWeek,
  endOfWeek,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { FaCalendarCheck, FaCalendarPlus } from 'react-icons/fa'

export default function MonthView({
  currentDate,
  data,
  handleDayClick,
}) {
  // Obtener el inicio y fin del mes actual
  const monthStart = startOfMonth(currentDate)
  const monthEnd = endOfMonth(monthStart)

  // Calcular el inicio y fin de la semana en la que cae el inicio y fin del mes
  const weekStart = startOfWeek(monthStart, { weekStartsOn: 1 })
  const weekEnd = endOfWeek(monthEnd, { weekStartsOn: 1 })

  // Obtener todos los días entre el inicio de la primera semana y el fin de la última semana del mes actual
  const allDays = eachDayOfInterval({ start: weekStart, end: weekEnd })

  // Nombres de los días de la semana
  const weekDayNames = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']


  return (
    <div className="grid grid-cols-7 gap-px overflow-hidden rounded-lg border border-gray-200 bg-gray-200">
      {weekDayNames.map((day) => (
        <div
          key={day}
          className="bg-gray-50 p-1 text-center text-xs font-medium text-grey-base"
        >
          {day}
        </div>
      ))}

      {allDays.map((day, index) => (
        <div
          key={index}
          className={`h-16 bg-white p-2 md:h-24 ${
            !isSameMonth(day, currentDate) ? 'text-grey-sec' : ''
          } ${
            isSameDay(day, currentDate)
              ? 'border border-blue-200 bg-blue-100'
              : ''
          }`}
        >
          <div className={`text-xs ${isToday(day) ? 'text-blue-soft' : ''}`}>
            {format(day, 'd', { locale: es })}
          </div>

          {/* Indicador de disponibilidad */}
          <div className="cursor-pointer" onClick={() => handleDayClick(day)}>
            {(() => {
              const dayIsChecked = data?.some((availability) =>
                isSameDay(new Date(availability.date), day),
              )
              return dayIsChecked ? (
                <div className="mx-auto flex size-8 transform items-center justify-center rounded-full bg-blue-100 text-xs text-blue-ter transition duration-200 hover:scale-105 hover:bg-blue-300 md:size-12">
                  <FaCalendarCheck />
                </div>
              ) : (
                <div className="mx-auto flex size-8 transform items-center justify-center rounded-full bg-gray-100 text-xs text-gray-300 transition duration-200 hover:scale-105 hover:bg-gray-200 md:size-12">
                  <FaCalendarPlus />
                </div>
              )
            })()}
          </div>
        </div>
      ))}
    </div>
  )
}

MonthView.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
  handleDayClick: PropTypes.func.isRequired,
}
