import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveSecondStep } from '@store/reducers/sitterRequest'
import DropzoneFileUpload from '@common/inputs/DropzoneFileUpload'
import Error from '@common/error/Error'

function SecondStep() {
  const { secondStep } = useSelector((state) => state.sitterRequest)
  const dispatch = useDispatch()
  const [error, setError] = useState(null)

  const handleFileChange = async (event) => {
    setError(null)
    const file = event.target.files[0]
    if (file && file.type === 'application/pdf') {
      try {
        const fileURL = URL.createObjectURL(file)
        localStorage.setItem('certificateURL', fileURL)
        dispatch(saveSecondStep({ certificate: true }))
      } catch (err) {
        setError(
          'Error al guardar la URL del certificado en el almacenamiento local',
        )
      }
    } else {
      setError('El archivo debe ser un PDF')
    }
  }

  const handleFileRemove = () => {
    localStorage.removeItem('certificateURL')
    dispatch(saveSecondStep({ certificate: false }))
  }

  return (
    <div
      aria-current="step"
      className="flex flex-col items-center gap-4 p-4 text-center"
    >
      <div className="flex flex-col items-center gap-6 text-center">
        <h1 className="text-center0 font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
          Certificado de antecedentes
        </h1>
        <h2>
          En el siguiente{' '}
          <a
            href="https://www.chileatiende.gob.cl/fichas/3442-certificado-de-antecedentes-y-consulta-de-inhabilidades"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            link
          </a>{' '}
          tienes información sobre cómo obtener tu certificado de antecedentes.
        </h2>
        {secondStep.certificate ? (
          <div className="relative">
            <button
              onClick={handleFileRemove}
              className="absolute right-5 top-5 rounded-full bg-grey-soft px-2 text-3xl opacity-60 hover:opacity-100 focus:outline-none"
            >
              &times;
            </button>
            <iframe
              src={localStorage.getItem('certificateURL')}
              width="100%"
              height="400px"
            ></iframe>
          </div>
        ) : (
          <DropzoneFileUpload
            handleFileChange={handleFileChange}
            text="Certificado de antecedentes (PDF)"
            accept=".pdf"
          />
        )}
      </div>
      {error && <Error message={error} />}
    </div>
  )
}

export default SecondStep
