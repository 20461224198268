import dogWorks from '@assets/images/others/dogWorks.jpeg'
import proCannes from '@assets/images/others/proCannes.jpeg'
import IC from '@assets/images/people/IC.jpeg'
import IG from '@assets/images/people/IG.jpeg'
import petsEducation from '@assets/images/home/petsEducation.jpeg'

function PetEducation() {
  return (
    <div className="mx-auto mb-8 flex w-[90%] flex-col gap-6 sm:gap-12 md:min-h-screen md:w-[75%]">
      <div className="relative w-full">
        <img
          src={petsEducation}
          className="h-60 w-full rounded-b-lg object-cover md:h-full"
        ></img>
        <p className="absolute left-[5%] top-[70%] font-tex-bold text-3xl text-white-base md:text-3xl lg:text-5xl">
          Educación canina
        </p>
      </div>
      <h1 className="font-tex-bold text-xl text-grey-base sm:text-4xl">
        La educación canina{' '}
        <span className="text-yellow-base">mejora el bienestar </span>
        de tu mascota y de tu hogar
      </h1>
      <h2 className="text-grey-base sm:text-lg">
        Educar a tu perro es esencial para su bienestar y para fortalecer el
        vínculo entre ambos. Un perro bien entrenado es más feliz, seguro y
        sociable, lo que mejora la convivencia en el hogar y en la comunidad.
        Descubre cómo nuestra educación canina puede transformar la vida de tu
        mascota y la tuya.
      </h2>
      <div className="mt-4 flex flex-col gap-8">
        <h3 className="font-tex-bold text-3xl text-grey-base sm:text-4xl">
          Nuestros expertos
        </h3>
        <div className="flex flex-col gap-12 sm:gap-24 md:flex-row">
          <div className="flex w-full flex-col gap-6 md:w-[50%]">
            <img
              src={IC}
              alt="Isabel Cambiasso"
              className="h-72 rounded-md object-cover"
            ></img>
            <h4 className="text-3xl text-grey-base sm:text-4xl">
              Isabel Cambiaso
            </h4>
            <p className="text-grey-base sm:text-lg">
              Isabel Cambiaso es una experta educadora canina que ayuda a
              integrar cachorros en el hogar. Se especializa en socialización
              temprana, hábitos de higiene, y seguridad entre niños y cachorros.
              Promueve la comunicación y confianza, el enriquecimiento
              ambiental, y enseña comandos esenciales como acudir al llamado y
              pasear sin tirones. También ofrece consejos para viajes seguros
              con el perro
            </p>
            <div className="flex w-full flex-col gap-6 md:flex-row">
              <img
                src={proCannes}
                alt="Pro Cannes"
                className="h-32 rounded-md object-cover md:w-[50%]"
              ></img>
              <ul className="list-disc space-y-2 pl-5">
                <li>@pro.canes</li>
                <li>+56995330725</li>
                <li>isacambiaso@hotmail.com</li>
              </ul>
            </div>
            <button
              onClick={() =>
                (window.location.href = 'https://wa.me/+56995330725')
              }
              className="mt-4 w-full rounded-md bg-blue-soft px-3.5 py-3.5 font-mont-semi text-base text-white-base shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Contactar
            </button>
          </div>
          <div className="flex w-full flex-col gap-6 md:w-[50%] md:justify-between">
            <img
              src={IG}
              alt="Isabel Garcia"
              className="h-72 rounded-md object-cover"
            ></img>
            <h4 className="text-3xl text-grey-base sm:text-4xl">
              Isabel Garcia
            </h4>
            <p className="text-grey-base sm:text-lg">
              Isabel García es una experta educadora canina especializada en
              abordar miedos y agresiones en perros. Promueve la socialización
              adecuada y ofrece técnicas para manejar el miedo y la ansiedad por
              separación. También ayuda a corregir comportamientos destructivos
              y enseña comandos esenciales como acudir al llamado y pasear sin
              tirones. Además, Isabel brinda consejos para hacer que los viajes
              con perros sean seguros y sin estrés
            </p>
            <div className="flex w-full flex-col gap-6 md:flex-row">
              <img
                src={dogWorks}
                alt="Dog Works"
                className="h-32 rounded-md object-cover md:w-[50%]"
              ></img>
              <ul className="list-disc space-y-2 pl-5">
                <li>@dogworkschile</li>
                <li>+56975276362</li>
                <li>dogworkschile@gmail.com</li>
              </ul>
            </div>
            <button
              onClick={() =>
                (window.location.href = 'https://wa.me/+56975276362')
              }
              className="mt-4 w-full rounded-md bg-blue-soft px-3.5 py-3.5 font-mont-semi text-base text-white-base shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Contactar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PetEducation
