import PropTypes from 'prop-types'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  FaPlus,
  FaPaw,
  FaCheck,
  FaArrowRight,
  FaExclamationCircle,
} from 'react-icons/fa'
import {
  selectedContainerStyle,
  unselectedContainerStyle,
} from '@assets/css/styles'
import { classNames } from '@utils/formatFunctions'
import { useGetByUser } from '@services/pet'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import PetForm from '@common/forms/PetForm'

function PetInput({ selectedPets, setSelectedPets, step, setStep }) {
  const queryClient = useQueryClient()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { isSuccess, data } = useGetByUser()

  const handlePetClick = (petId) => {
    if (selectedPets.includes(petId)) {
      setSelectedPets(selectedPets.filter((id) => id !== petId))
    } else {
      setSelectedPets([...selectedPets, petId])
    }
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
    document.body.classList.add('overflow-hidden')
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    queryClient.invalidateQueries({ queryKey: ['petInput'] })
    document.body.classList.remove('overflow-hidden')
  }

  const handleClick = () => {
    if (step !== 2) {
      setStep(2)
    }
  }

  if (step !== 2)
    return (
      <div
        onClick={handleClick}
        className={selectedContainerStyle(isOpenModal, step === 2)}
      >
        <h2 className="font-mont-semi text-2xl text-grey-base">Mascotas</h2>
        {selectedPets.length === 0 && (
          <div
            className={classNames(
              'flex flex-row items-center gap-4 text-sm sm:text-lg',
              step > 2 ? 'text-red-base' : 'text-gray-400',
            )}
          >
            <FaExclamationCircle className={step < 2 && 'hidden'} size={16} />
            <p> Selecciona una mascota</p>
          </div>
        )}
        <div className="flex flex-wrap gap-2 sm:gap-4">
          {selectedPets.length > 0 &&
            data.map(
              (pet) =>
                selectedPets.includes(pet.id) &&
                (pet.photoUrl ? (
                  <img
                    key={pet.id}
                    className="h-16 w-16 rounded-full object-cover sm:h-24 sm:w-24"
                    src={pet.photoUrl}
                    alt="Foto de sitter"
                  />
                ) : (
                  <div
                    key={pet.id}
                    className="flex h-16 w-16 items-center justify-center rounded-full bg-grey-soft object-cover text-5xl text-grey-base sm:h-24 sm:w-24"
                  >
                    <FaPaw />
                  </div>
                )),
            )}
        </div>
      </div>
    )

  return (
    <div
      onClick={handleClick}
      className={unselectedContainerStyle(isOpenModal, step === 2)}
    >
      <div className="flex flex-row items-center justify-center gap-4">
        <h2 className="font-mont-semi text-xl text-grey-base">Mascotas </h2>
        <button
          onClick={() => setStep(3)}
          className={classNames(
            selectedPets.length === 0 && 'hidden',
            selectedPets.length > 0 &&
              'rounded-full bg-blue-soft p-3 text-xl text-white-base hover:bg-blue-600',
          )}
        >
          <FaArrowRight />
        </button>
      </div>
      <div className="flex flex-wrap justify-center gap-8">
        {isSuccess &&
          data.map((pet) => (
            <div key={pet.id} className="flex flex-col items-center gap-2">
              {pet.photoUrl ? (
                <div
                  onClick={() => handlePetClick(pet.id)}
                  className="relative cursor-pointer"
                >
                  <img
                    className="h-28 w-28 rounded-full object-cover"
                    src={pet.photoUrl}
                    alt={pet.name}
                  />
                  <div className="absolute right-0 top-0 h-28 w-28 rounded-full hover:bg-black hover:bg-opacity-50 hover:text-white"></div>
                  {selectedPets.includes(pet.id) && (
                    <div className="absolute right-0 top-0 flex h-28 w-28 items-center justify-center rounded-full bg-black bg-opacity-70 hover:bg-opacity-80">
                      <FaCheck className="text-4xl text-white opacity-70" />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => handlePetClick(pet.id)}
                  className="relative cursor-pointer"
                >
                  <div className="flex h-28 w-28 items-center justify-center rounded-full bg-grey-soft object-cover text-5xl text-grey-base">
                    <FaPaw />
                  </div>
                  <div className="absolute right-0 top-0 h-28 w-28 rounded-full hover:bg-black hover:bg-opacity-50 hover:text-white"></div>
                  {selectedPets.includes(pet.id) && (
                    <div className="absolute right-0 top-0 flex h-28 w-28 items-center justify-center rounded-full bg-black bg-opacity-70 hover:bg-opacity-80">
                      <FaCheck className="text-4xl text-white opacity-70" />
                    </div>
                  )}
                </div>
              )}
              <p
                className={`text-lg ${
                  selectedPets.includes(pet.id)
                    ? 'text-grey-base'
                    : 'text-grey-soft'
                }`}
              >
                {pet.name}
              </p>
            </div>
          ))}
        <div className="flex flex-col items-center justify-center gap-2">
          <div
            onClick={handleOpenModal}
            className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-grey-soft text-3xl text-[#BDBDBD] hover:bg-blue-soft hover:bg-opacity-50 hover:text-white"
          >
            <FaPlus />
          </div>
          <p className="text-lg text-grey-soft">Agregar</p>
        </div>
      </div>
      <span className="font-mont text-xs text-grey-soft">
        Cada mascota extra obtiene un descuento del 25%
      </span>

      <CustomModal
        header={
          <ModalHeader
            title="Crear mascota"
            subtitle="Añade los datos de tu mascota 🐾"
          />
        }
        body={<PetForm onSubmit={handleCloseModal} />}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      />
    </div>
  )
}

PetInput.propTypes = {
  selectedPets: PropTypes.array.isRequired,
  setSelectedPets: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default PetInput
