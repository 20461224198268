/* eslint-disable react/prop-types */
import { useState } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share'
import PropTypes from 'prop-types'
import { FacebookIcon, WhatsappIcon } from 'react-share'
import { useSelector } from 'react-redux'
import GmailIcon from '@assets/icons/GmailIcon'
import InstagramIcon from '@assets/icons/InstagramIcon'
import TwitterIcon from '@assets/icons/TwitterIcon'
import { PAGE_LINK } from '@assets/constants'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'

const ShareProfile = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="relative w-full text-center">
      <button
        onClick={() => setIsOpen(true)}
        className="w-full rounded-md bg-blue-500 py-1.5 text-white hover:bg-blue-600"
      >
        Compartir Perfil
      </button>

      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={
          <ModalHeader
            title="Compartir Perfil"
            subtitle="Comparte tu perfil para conseguir cliente 🤳"
          />
        }
        body={<ModalBoy />}
      />
    </div>
  )
}

const ModalBoy = () => {
  const user = useSelector((state) => state.user)
  const [copied, setCopied] = useState(false)
  const url = `${PAGE_LINK}hire-sitter/${user.id}`
  const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(url)}`
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }
  return (
    <div className="mt-8 flex flex-col gap-6">
      <div className="flex flex-wrap justify-between">
        <FacebookShareButton url={url} className="w-1/5">
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton url={url} className="w-1/5">
          <TwitterIcon size={48} round />
        </TwitterShareButton>
        <WhatsappShareButton url={url} className="w-1/5">
          <WhatsappIcon size={48} round />
        </WhatsappShareButton>
        <EmailShareButton
          url={url}
          subject="¡Mira mi perfil!"
          body="¡Hola! Te invito a ver mi perfil en Pets-Okey."
          className="w-1/5"
        >
          <GmailIcon size={48} />
        </EmailShareButton>
        <a
          href={instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="w-1/5"
        >
          <InstagramIcon size={48} />
        </a>
      </div>

      <div className="flex items-center justify-between">
        <span
          onClick={copyToClipboard}
          className="cursor-pointer text-left text-xs text-grey-sec hover:underline"
        >
          {url}
        </span>
        <button
          onClick={copyToClipboard}
          className="ml-4 flex items-center rounded-full bg-blue-500 px-4 py-0.5 text-sm font-light text-white hover:bg-blue-600"
        >
          Copiar
        </button>
      </div>

      {copied && (
        <div className="absolute left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded bg-gray-800 p-2 text-sm text-white shadow-lg">
          Copiado al portapapeles
        </div>
      )}
    </div>
  )
}

ShareProfile.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  userId: PropTypes.string,
}

export default ShareProfile
