import axios from 'axios'
import * as Sentry from '@sentry/react'

const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api'

const ERROR_MESSAGES = {
  NETWORK:
    'No hay conexión a internet. Por favor, verifica tu conexión y vuelve a intentar.',
  SERVER_ERROR:
    'Ups! Algo salió mal en el servidor, por favor intenta nuevamente.',
  DEFAULT: 'Ups! Algo salió mal, por favor intenta nuevamente.',
}

const axiosConfig = {
  timeout: 30000,
  baseURL: apiUrl,
}

class RequestError extends Error {
  constructor(error) {
    const message =
      error?.response?.data?.message ||
      (error?.code === 'ERR_NETWORK'
        ? ERROR_MESSAGES.NETWORK
        : error?.response?.status >= 500
          ? ERROR_MESSAGES.SERVER_ERROR
          : error?.message || ERROR_MESSAGES.DEFAULT)

    const data = error?.response?.data || {}
    const status = error?.response?.status || 500
    super(message)
    this.name = 'RequestError'
    this.data = data
    this.status = status
  }
}

const handleRequestError = (error, context) => {
  if (error?.response?.status >= 500 || !error?.response) {
    Sentry.captureException(error, {
      extra: {
        context,
        requestData: {
          url: error?.config?.url,
          method: error?.config?.method,
          status: error?.response?.status,
        },
      },
    })
  }
  throw new RequestError(error)
}

const getData = async (route) => {
  try {
    const response = await axios.get(`/${route}`, axiosConfig)
    return response.data
  } catch (error) {
    handleRequestError(error, 'getData')
    return error?.response?.data
  }
}

const deleteData = async (route) => {
  try {
    const response = await axios.delete(`/${route}`, axiosConfig)
    return response.data
  } catch (error) {
    handleRequestError(error, 'deleteData')
    return error?.response?.data
  }
}

const putData = async (route, body = {}, headers = {}) => {
  try {
    const response = await axios.put(`/${route}`, body, {
      ...axiosConfig,
      headers: {
        ...headers,
      },
    })
    return response.data
  } catch (error) {
    handleRequestError(error, 'putData')
    return error?.response?.data
  }
}

const postData = async (route, body = {}, headers = {}) => {
  try {
    const response = await axios.post(`/${route}`, body, {
      ...axiosConfig,
      headers: {
        ...headers,
      },
    })
    return response.data
  } catch (error) {
    handleRequestError(error, 'postData')
    return error?.response?.data
  }
}

export { postData, getData, putData, deleteData }
