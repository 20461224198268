import PropType from 'prop-types'

function TestimonialCard({ body, image, name, username }) {
  return (
    <figure className="mb-16 rounded-2xl bg-white-base p-4 shadow-lg ring-1 ring-grey-base/5">
      <figcaption className="mt-6 flex items-center gap-x-4">
        <img
          className="h-12 w-12 rounded-full bg-gray-50 object-cover"
          src={image}
          alt=""
        />
        <div>
          <div className="font-mont-bold text-sm">{name}</div>
          <div className="text-sm text-grey-base">{`${username}`}</div>
        </div>
      </figcaption>
      <blockquote className="mx-4 my-8 text-xs text-grey-base">
        <p>{`“${body}”`}</p>
      </blockquote>
    </figure>
  )
}

TestimonialCard.propTypes = {
  body: PropType.string.isRequired,
  image: PropType.string.isRequired,
  name: PropType.string.isRequired,
  username: PropType.string.isRequired,
}

export default TestimonialCard
