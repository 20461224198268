import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import CountUp from 'react-countup'
import { classNames } from '@utils/formatFunctions'

function Debt({ debtData }) {
  return (
    <div className="w-full space-y-5 rounded-xl bg-white p-4 shadow-xl">
      <div className="relative space-y-1">
        <Menu as="div" className="absolute right-0">
          <div>
            <Menu.Button className="flex items-center rounded-lg bg-white text-slate-400 hover:bg-slate-100 hover:text-slate-600">
              <span className="sr-only"></span>
              <EllipsisHorizontalIcon className="h-7 w-7" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/sitter/finished-records"
                    state={{ records: debtData.records }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Detalles
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <p className="font-tex-bold text-xl text-slate-900">
          Resumen ganancias
        </p>

        <p className="text-sm text-grey-sec">
          Ganancias generadas por servicios realizados
        </p>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Monto total</span>
        <CountUp
          end={debtData?.totalAmount ? debtData.totalAmount : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-grey-base"
        />
      </div>
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Pets-Okey Fee</span>
        <CountUp
          end={debtData?.totalFee ? debtData.totalFee : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-red-base"
        />
      </div>
      <hr />
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Ganancias</span>
        <CountUp
          end={debtData?.totalDebt ? debtData.totalDebt : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-green-base"
        />
      </div>
    </div>
  )
}

Debt.propTypes = {
  debtData: PropTypes.object,
  sitter: PropTypes.object,
}

export default Debt
