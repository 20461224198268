/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  subMonths,
  addMonths,
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { classNames } from '@utils/formatFunctions'
import { useGetAvailability } from '@services/availability'
import Loading from '@common/utils/Loading'

const Calendar = ({ serviceDates, setServiceDates }) => {
  const { sitterId } = useParams()
  const { isPending, data } = useGetAvailability(sitterId)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [calendarDays, setCalendarDays] = useState([])
  const [availability, setAvailability] = useState({})

  const getDayStyle = (day) => {
    const isSelected = serviceDates.some((selectedDay) =>
      isSameDay(selectedDay.date, day),
    )
    const isAvailable = !!availability[format(day, 'yyyy-MM-dd')]

    if (isSelected) {
      return isAvailable
        ? 'bg-purple-base text-white hover:opacity-80'
        : 'bg-gray-200 text-gray-400 hover:bg-gray-200'
    } else {
      return isAvailable
        ? 'bg-purple-100 border border-purple-base hover:bg-purple-200'
        : 'hover:bg-gray-50'
    }
  }

useEffect(() => {
  if (!data) return

  const firstDayOfMonth = startOfMonth(currentDate)
  const lastDayOfMonth = endOfMonth(currentDate)

  const days = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  })

  const startDayOfWeek = firstDayOfMonth.getDay()
  const daysFromPrevMonth = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1

  const prevMonthDays = eachDayOfInterval({
    start: subMonths(firstDayOfMonth, 1),
    end: endOfMonth(subMonths(firstDayOfMonth, 1)),
  }).slice(-daysFromPrevMonth)

  setCalendarDays([...prevMonthDays, ...days])

  const availObj = {}
  data.forEach((item) => {
    const date = new Date(item.date)
    const dateKey = format(date, 'yyyy-MM-dd')
    if (!availObj[dateKey]) availObj[dateKey] = []
    availObj[dateKey].push(item)
  })
  setAvailability(availObj)
}, [currentDate, data])

  if (isPending) return <Loading />

  const toggleDay = (day) => {
    const isSelected = serviceDates.some((selectedDay) =>
      isSameDay(selectedDay.date, day),
    )

    if (isSelected) {
      setServiceDates((prev) =>
        prev.filter((selectedDay) => !isSameDay(selectedDay.date, day)),
      )
    } else {
      setServiceDates((prev) => [
        ...prev,
        { date: day, id: format(day, 'yyyy-MM-dd') },
      ])
    }
  }

  const renderCalendar = () => (
    <div className="rounded-lg border border-gray-200 bg-white p-4">
      <div className="flex w-full items-center justify-between">
        <button
          onClick={() => setCurrentDate(subMonths(currentDate, 1))}
          className="rounded-md p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Previous"
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
        <span className="h-full flex-1 text-center text-sm font-medium text-grey-base">
          {format(currentDate, 'MMMM yyyy', { locale: es })}
        </span>
        <button
          onClick={() => setCurrentDate(addMonths(currentDate, 1))}
          className="rounded-md p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Next"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>

      <div className="my-4 mb-2 grid grid-cols-7 gap-1">
        {['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'].map((day) => (
          <div
            key={day}
            className="text-center text-xs font-medium text-grey-sec"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {calendarDays.map((day, index) => (
          <div
            key={index}
            onClick={() => toggleDay(day)}
            className={classNames(
              'transform cursor-pointer rounded-md p-3 text-center transition duration-100 hover:scale-110',
              isSameDay(day, new Date()) && 'text-blue-soft',
              getDayStyle(day),
            )}
          >
            <div className="text-sm">{format(day, 'd')}</div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderSelectedSlots = () => (
    <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4">
      <h2 className="text-base text-grey-base">Días seleccionados 📆</h2>
      <div className="flex flex-wrap gap-2">
        {serviceDates
          .sort((a, b) => a.date - b.date)
          .map((item) => (
            <div
              key={item.id}
              onClick={() => toggleDay(item.date)}
              className="transform cursor-pointer rounded-md border border-purple-base bg-purple-100 px-3 py-1 text-sm text-slate-900 transition duration-200 hover:scale-105 hover:bg-purple-200"
            >
              {format(item.date, "dd 'de' MMMM", { locale: es })}
            </div>
          ))}
      </div>
    </div>
  )

  return (
    <div className="container mx-auto space-y-2">
      {renderCalendar()}
      {renderSelectedSlots()}
    </div>
  )
}

export default Calendar
