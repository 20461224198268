import { useState } from 'react'
import { useSelector } from 'react-redux'
import sitterRequestTest from '@assets/sitterRequestData'
import QuestionTemplate from './QuestionTemplate'

function FourthStep() {
  const [isVerifying, setIsVerifying] = useState(false)
  const { fourthStep } = useSelector((state) => state.sitterRequest)
  const handleVerify = () => {
    setIsVerifying(true)
    setTimeout(() => {
      setIsVerifying(false)
    }, 1000)
    if (
      !fourthStep.firstAnswer.correct ||
      !fourthStep.secondAnswer.correct ||
      !fourthStep.thirdAnswer.correct ||
      !fourthStep.fourthAnswer.correct ||
      !fourthStep.fifthAnswer.correct ||
      !fourthStep.sixthAnswer.correct
    ) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  return (
    <div className="flex flex-col items-center gap-4 p-4 text-center sm:w-[75%]">
      <div className="flex flex-col gap-6 text-left">
        <h1 className="text-center font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
          Cuestionario
        </h1>
        <div className="mb-12 flex flex-col gap-12">
          {Object.keys(sitterRequestTest).map(
            (key) =>
              key !== 'code' && (
                <div key={key}>
                  <QuestionTemplate
                    title={sitterRequestTest[key].title}
                    options={sitterRequestTest[key].options}
                    isImageQuestion={key === 'firstQuestion'}
                    isVerifying={isVerifying}
                    reduxKey={
                      key == 'firstQuestion'
                        ? 'firstAnswer'
                        : key == 'secondQuestion'
                          ? 'secondAnswer'
                          : key == 'thirdQuestion'
                            ? 'thirdAnswer'
                            : key == 'fourthQuestion'
                              ? 'fourthAnswer'
                              : key == 'fifthQuestion'
                                ? 'fifthAnswer'
                                : 'sixthAnswer'
                    }
                  />
                </div>
              ),
          )}
        </div>
        <button
          onClick={handleVerify}
          className="mx-auto w-full rounded-md bg-blue-500 px-3.5 py-2.5 font-mont-bold text-sm text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 sm:w-[50%]"
        >
          Verificar Respuestas
        </button>
      </div>
    </div>
  )
}

export default FourthStep
