import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import ReactDOM from 'react-dom'

const CustomModal = ({
  header,
  body,
  isOpen,
  onClose,
  overflowy = 'overflow-y-auto',
}) => {
  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose()
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''
    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80">
      <div
        ref={modalRef}
        className={`relative z-10 ${overflowy} rounded-xl bg-white p-4 shadow-lg`}
        style={{
          maxHeight: 'calc(100vh - 30px)',
          maxWidth: 'calc(100vw - 30px)',
          minWidth: '400px',
        }}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-lg font-bold text-grey-base"
        >
          <FaTimes />
        </button>
        <div className="space-y-2">
          {header ?? null}
          <div>{body}</div>
        </div>
      </div>
    </div>,
    document.body,
  )
}

// eslint-disable-next-line react/prop-types
export const ModalHeader = ({ title, subtitle }) => (
  <div className="space-y-1">
    <p className="font-tex-bold text-lg text-grey-base">{title}</p>
    <p className="text-xs text-grey-sec">{subtitle}</p>
  </div>
)

CustomModal.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  overflowy: PropTypes.string,
}

export default CustomModal
