import PropTypes from 'prop-types'
import { FaQuestion } from 'react-icons/fa'
import CountUp from 'react-countup'
import StatsToolTip from '@common/tooltip/StatsToolTip'
import StatsInfo from '@common/tooltip/StatsInfo'

function MoneyStats({ stats }) {
  return (
    <div className="flex flex-col gap-8 sm:flex-row sm:gap-4">
      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">Dinero generado</h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Dinero total generado por el sitter en servicios realizados.',
                  'Se consideran unicamente si el sitter da por terminado el servicio.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <CountUp
          end={stats.totalRevenue ? stats.totalRevenue : 0}
          duration={3}
          prefix="$"
          className="w-full text-center text-5xl font-bold text-green-base"
        />
      </div>

      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">Precio promedio</h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Precio promedio de los servicios realizados por el sitter.',
                  'Cuenta unicamente aquellos servicios que han sido finalizados.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <p className="w-full text-center text-5xl font-bold text-grey-base">
          <CountUp
            end={stats.averagePrice ? stats.averagePrice : 0}
            duration={2}
          />
        </p>
      </div>

      <div className="flex sm:w-1/3 flex-col items-center gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-left text-sm text-gray-500">
            Ingresos no obtenidos
          </h2>
          <StatsToolTip
            InfoToShow={
              <StatsInfo
                texts={[
                  'Dinero que el sitter no ha concretado.',
                  'Se consideran los servicios rechazados y cancelados.',
                ]}
              />
            }
            icon={<FaQuestion size={8} />}
            style="flex items-center justify-center p-1 text-white bg-slate-700 rounded-full"
          />
        </div>
        <CountUp
          end={stats.moneyNotConcreted ? stats.moneyNotConcreted : 0}
          duration={2}
          prefix="$"
          className="w-full text-center text-5xl font-bold text-red-base"
        />
      </div>
    </div>
  )
}

MoneyStats.propTypes = {
  sitter: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
}

export default MoneyStats
