'use client'

import { useState } from 'react'
import {
  format,
  addMonths,
  subMonths,
  addDays,
  subDays,
  addWeeks,
  subWeeks,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { useMediaQuery } from 'react-responsive'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import Availability from './Availability'
import {
  useGetAvailability,
  usePostAvailability,
  useDeleteAvailability,
} from '@services/availability'
import { useSelector } from 'react-redux'
import Loading from '@common/utils/Loading'

// Componentes auxiliares
import CalendarControls from './calendar/CalendarControls'
import MonthView from './calendar/MonthView'
import WeekView from './calendar/WeekView'
import DayView from './calendar/DayView'

export default function Calendar() {
  const user = useSelector((state) => state.user)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const { data, isPending } = useGetAvailability(user.id)
  const { mutate: postAvailability } = usePostAvailability()
  const { mutate: deleteAvailability } = useDeleteAvailability()

  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [viewType, setViewType] = useState(isSmallScreen ? 'day' : 'week')
  const [isOpen, setIsOpen] = useState(false)

  // Métodos de navegación de fechas
  const goToToday = () => {
    const today = new Date()
    setCurrentDate(today)
    setSelectedDate(today)
  }

  // Función para manejar el clic en un día
  const handleDayClick = (day) => {
    setSelectedDate(day)
    setIsOpen(true)
  }

  const changeDate = (direction) => {
    const update = (fn) => {
      setCurrentDate((prevDate) => fn(prevDate, 1))
    }

    switch (viewType) {
      case 'month':
        update(direction === 'next' ? addMonths : subMonths)
        break
      case 'week':
        update(direction === 'next' ? addWeeks : subWeeks)
        break
      case 'day':
        update(direction === 'next' ? addDays : subDays)
        break
      default:
        break
    }
  }

  // Vista condicional según el tipo de vista (mes, semana, día)
  const renderView = () => {
    switch (viewType) {
      case 'month':
        return (
          <MonthView
            currentDate={currentDate}
            data={data}
            setIsOpen={setIsOpen}
            setSelectedDate={setSelectedDate}
            handleDayClick={handleDayClick}
          />
        )
      case 'week':
        return (
          <WeekView
            currentDate={currentDate}
            data={data}
            postAvailability={postAvailability}
            deleteAvailability={deleteAvailability}
            handleDayClick={handleDayClick}
          />
        )
      case 'day':
        return (
          <DayView
            currentDate={currentDate}
            data={data}
            postAvailability={postAvailability}
            deleteAvailability={deleteAvailability}
          />
        )
      default:
        return null
    }
  }

  if (isPending) {
    return <Loading />
  }

  return (
    <>
      <div className="mx-auto w-full max-w-6xl overflow-hidden rounded-lg bg-white shadow-xl">
        <div className="p-4">
          <CalendarControls
            currentDate={currentDate}
            viewType={viewType}
            goToToday={goToToday}
            changeDate={changeDate}
            setViewType={setViewType}
            setIsOpen={setIsOpen}
          />
          <div className="overflow-hidden">{renderView()}</div>
        </div>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title={format(selectedDate, 'EEEE d MMMM yyyy', {
              locale: es,
            }).toString()}
            subtitle="Marcar disponibilidad es clave para que puedan reservar tus servicios."
          />
        }
        body={
          <Availability selectedDate={selectedDate} setIsOpen={setIsOpen} />
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
