import PropTypes from 'prop-types'
import { FaInbox, FaCalendar } from 'react-icons/fa'

function VoidServices({ role = 'user' }) {
  return (
    <div className="m-2 flex items-center justify-center flex-col h-screen space-y-4 text-grey-soft">
      {role == 'user' && (
        <>
          <FaCalendar className="text-6xl" />
          <p className="text-lg">No tienes reservas programados</p>
        </>
      )}
      {role == 'sitter' && (
        <>
          <FaInbox className="text-6xl" />
          <p className="text-lg">No tienes servicios programados</p>
        </>
      )}
    </div>
  )
}

VoidServices.propTypes = {
  role: PropTypes.string,
}

export default VoidServices
