import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useFilter = create(
  persist(
    (set) => ({
      selectedAddress: null, // Inicializa la dirección
      selectedDates: [], // Inicializa la fecha
      selectedType: 'dogWalking', // Inicializa el tipo
      isAddressModalOpen: false, // Inicializa el estado del modal de dirección

      // Funciones para establecer los valores
      setSelectedAddress: (newAddress) => set({ selectedAddress: newAddress }),
      setSelectedDates: (newDate) => set({ selectedDates: newDate }),
      setSelectedType: (newType) => set({ selectedType: newType }),
      setIsAddressModalOpen: (isOpen) => set({ isAddressModalOpen: isOpen }),
    }),
    {
      name: 'filter-storage', // Nombre clave para `localStorage`
      getStorage: () => localStorage, // Usa `localStorage` para la persistencia
    },
  ),
)

export default useFilter
