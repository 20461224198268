import { ToastContainer } from 'react-toastify'
import Prices from './components/offer_services/Prices'
import PetSize from './components/offer_services/PetSize'
import AboutMe from './components/offer_services/AboutMe'
import AboutHouse from './components/offer_services/AboutHouse'
import ProfilePhotos from './components/offer_services/ProfilePhotos'
import Calendar from './components/offer_services/Calendar'
import Addresses from './components/offer_services/Addresses'

export default function OfferServices() {
  return (
    <>
      <ToastContainer position="top-center" />
      <div className="mx-auto flex max-w-full flex-col gap-4 p-6 lg:p-8">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <Calendar />
          <div className="space-y-4">
            <div className="flex flex-col gap-6 rounded-xl bg-white p-6 shadow-xl">
              <Prices />
              <Addresses />
            </div>
            <div className="flex flex-col gap-6 rounded-xl bg-white p-6 shadow-xl">
              <PetSize />
              <AboutMe />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="rounded-xl bg-white p-6 shadow-xl lg:w-[30%]">
            <AboutHouse />
          </div>
          <div className="h-fit rounded-xl bg-white p-6 pb-2 shadow-xl lg:w-[70%]">
            <ProfilePhotos />
          </div>
        </div>
      </div>
    </>
  )
}
