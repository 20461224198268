import PropTypes from 'prop-types'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { formatDate } from '@utils/formatFunctions'

const paymentTypeCodeDecoder = (code) => {
  if (code == 'VD') return 'Venta Débito'
  if (code == 'VN') return 'Venta Normal'
  if (code == 'VC') return 'Venta en cuotas'
  if (code == 'SI') return '3 cuotas sin interés'
  if (code == 'S2') return '2 cuotas sin interés'
  if (code == 'NC') return 'N cuotas sin interés'
  if (code == 'VP') return 'Venta Prepago'
}

function amountFormatter(numero) {
  const formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  })

  return formatter.format(numero)
}

function Voucher({ tbkInfo }) {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-6 text-grey-base shadow-lg">
      <div className="flex flex-col items-center gap-2">
        {tbkInfo.status == 'AUTHORIZED' ? (
          <div className="flex flex-col gap-4">
            <FaCheckCircle className="mx-auto text-6xl text-green-base" />
            <h1 className="text-center text-2xl font-semibold">
              Transacción aprobada
            </h1>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <FaTimesCircle className="mx-auto text-6xl text-red-base" />
            <h1 className="text-center text-2xl font-semibold">
              Problemas con la transacción
            </h1>
          </div>
        )}
        <p>
          ¡Tu pago de{' '}
          <span className="font-mont-semi">
            {tbkInfo.amount ? amountFormatter(tbkInfo.amount) : '$0'}{' '}
          </span>
          {tbkInfo.status == 'AUTHORIZED' && tbkInfo.response_code == 0
            ? 'fue realizado con éxito'
            : 'no pudo ser procesado'}
          !
        </p>
      </div>
      <hr />
      <div className="flex flex-col gap-1">
        <p>Fecha</p>
        <p className="font-mont-semi">
          {tbkInfo.transaction_date
            ? formatDate(tbkInfo.transaction_date)
            : 'No disponible'}
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p>Monto</p>
        <p className="font-mont-semi">
          {tbkInfo.amount ? amountFormatter(tbkInfo.amount) : 'No disponible'}
        </p>
      </div>
      <hr />
      <div className="flex flex-col gap-1">
        <p>Tipo de pago</p>
        <p className="font-mont-semi">
          {tbkInfo.payment_type_code
            ? paymentTypeCodeDecoder(tbkInfo.payment_type_code)
            : 'No disponible'}
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p>N° de tarjeta</p>
        <p className="font-mont-semi">
          {tbkInfo.card_detail?.card_number
            ? '**** **** **** ' + tbkInfo.card_detail?.card_number
            : 'No disponible'}
        </p>
      </div>
      <hr />
      <Link
        className="focus-blue:outline-[#00B1EA] w-full rounded-md bg-[#00B1EA] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#1EB9FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        to={'/'}
      >
        Volver al inicio
      </Link>
    </div>
  )
}

Voucher.propTypes = {
  tbkInfo: PropTypes.object,
}

export default Voucher
