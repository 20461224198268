import PropTypes from 'prop-types'
import { useState } from 'react'
import { FaPlus, FaExclamationCircle } from 'react-icons/fa'
import {
  selectedContainerStyle,
  unselectedContainerStyle,
} from '@assets/css/styles'
import { classNames } from '@utils/formatFunctions'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import AddressForm from '@common/forms/AddressForm'
import { useGetAddresses } from '@services/address'

function AddressInput({
  userAddress,
  setUserAddress,
  step,
  setStep,
  selectedType,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, isSuccess } = useGetAddresses()

  const handleSelectAddress = (address) => {
    setUserAddress(address)
    setStep(4)
  }

  const handleClick = () => {
    if (step !== 3) {
      setStep(3)
    }
  }

  if (step !== 3)
    return (
      <div
        onClick={handleClick}
        className={selectedContainerStyle(isOpenModal, step === 3)}
      >
        <h2 className="font-mont-semi text-2xl text-grey-base">Dirección</h2>
        {!userAddress && (
          <div
            className={classNames(
              'flex flex-row items-center gap-4 text-sm sm:text-lg',
              step > 3 ? 'text-red-base' : 'text-gray-400',
            )}
          >
            <FaExclamationCircle className={step < 3 && 'hidden'} size={16} />
            <p> Selecciona una dirección</p>
          </div>
        )}
        {userAddress && (
          <p
            className={classNames(
              'flex flex-row items-center gap-4 rounded-full px-3 py-2 text-[0.5rem] text-white sm:text-xl',
              selectedType === 'dogWalking' && 'bg-yellow-sec',
              selectedType === 'visit' && 'bg-blue-ter',
              selectedType === 'dayCare' && 'bg-purple-base',
            )}
          >
            {' '}
            {userAddress.formattedAddress}
          </p>
        )}
      </div>
    )

  return (
    <div
      onClick={handleClick}
      className={unselectedContainerStyle(isOpenModal, step === 3)}
    >
      <h2 className="font-mont-semi text-2xl text-grey-base">Dirección</h2>
      <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
        {isSuccess &&
          data.map((address) => (
            <div
              key={address.id}
              onClick={() => handleSelectAddress(address)}
              className={
                'flex cursor-pointer flex-col items-center justify-center gap-2 rounded-full bg-grey-soft px-4 py-2 hover:bg-black hover:bg-opacity-30'
              }
            >
              <p className={'text-[0.6rem] text-grey-base sm:text-xs'}>
                {address.formattedAddress}
              </p>
            </div>
          ))}
        <div className="flex flex-col items-center gap-2">
          <div
            onClick={() => setIsOpenModal(true)}
            className="flex cursor-pointer items-center justify-center gap-2 rounded-full bg-grey-soft px-4 py-2 text-sm text-[#BDBDBD] hover:bg-blue-soft hover:bg-opacity-70 hover:text-white"
          >
            <FaPlus />
            <p>Agregar</p>
          </div>
        </div>
      </div>

      <CustomModal
        header={
          <ModalHeader
            title="Agrega una dirección"
            subtitle="Indícanos dónde quieres que cuidemos a tus mascotas 🏡"
          />
        }
        body={<AddressForm onSubmit={() => setIsOpenModal(false)} />}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  )
}

AddressInput.propTypes = {
  userAddress: PropTypes.object,
  setUserAddress: PropTypes.func,
  step: PropTypes.number,
  setStep: PropTypes.func,
  selectedType: PropTypes.string,
}

export default AddressInput
