import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    type: null,
    commune: null,
    dates: [],
    small: '',
    medium: '',
    large: '',
    giant: '',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    saveFilter(state, action) {
      return { ...state, ...action.payload }
    },
    // eslint-disable-next-line no-unused-vars
    resetFilter(state, action) {
      return initialState
    },
  },
})

export const { saveFilter, resetFilter } = filterSlice.actions
export default filterSlice.reducer
