import PropTypes from 'prop-types'
import { format, isSameDay, isToday } from 'date-fns'
import { es } from 'date-fns/locale'
import { FaCheck, FaPlus } from 'react-icons/fa'

export default function DayView({
  currentDate,
  data,
  postAvailability,
  deleteAvailability,
}) {
  // Generar las horas del día (6:00 AM a 11:00 PM)
  const hours = Array.from({ length: 18 }, (_, i) => i + 6)

  return (
    <div className="flex h-[calc(60vh)] flex-col overflow-hidden rounded-lg bg-white shadow">
      {/* Cabecera con la fecha actual */}
      <div className="sticky top-0 z-10 grid grid-cols-5 border bg-white">
        <div className="col-span-1 flex items-center justify-center border-r border-gray-200 text-xs font-semibold text-gray-700">
          Hora
        </div>
        <div className="col-span-4 flex cursor-pointer items-center justify-center border-r bg-gray-50 p-1 text-xs font-medium text-gray-700 hover:bg-gray-100">
          <span className={isToday(currentDate) ? 'text-blue-soft' : ''}>
            {format(currentDate, 'EEEE d MMMM', { locale: es })}
          </span>
        </div>
      </div>

      {/* Contenido de las horas del día */}
      <div className="grid flex-1 grid-cols-5 overflow-y-auto">
        {/* Columna de las horas */}
        <div className="col-span-1 border-x bg-gray-50 text-xs text-gray-500">
          {hours.map((hour) => (
            <div
              key={hour}
              className="flex h-[calc(57vh/18)] items-center justify-center border-b"
            >
              {`${hour}:00 ${hour < 12 ? 'AM' : 'PM'}`}
            </div>
          ))}
        </div>

        {/* Columna de disponibilidad por horas */}
        <div className="col-span-4 border-r border-gray-200">
          {hours.map((hour) => {
            const availability = data?.find((availability) => {
              const date = new Date(availability.date)
              return isSameDay(date, currentDate) && date.getHours() === hour
            })

            const hourIsChecked = Boolean(availability)

            return (
              <div
                key={hour}
                className="flex h-[calc(57vh/18)] cursor-pointer items-center justify-center rounded border-b border-gray-200 p-0.5"
              >
                {hourIsChecked ? (
                  <div
                    onClick={() => deleteAvailability(availability.id)}
                    className="flex h-full w-full transform items-center justify-center rounded bg-blue-100 text-xs text-blue-ter transition duration-200 hover:scale-105 hover:bg-blue-300"
                  >
                    <FaCheck />
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      postAvailability({
                        date: new Date(currentDate.setHours(hour, 0, 0, 0)),
                      })
                    }
                    className="flex h-full w-full transform items-center justify-center rounded bg-gray-100 text-xs text-gray-300 transition duration-200 hover:scale-105 hover:bg-gray-200"
                  >
                    <FaPlus />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

DayView.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  postAvailability: PropTypes.func.isRequired,
  deleteAvailability: PropTypes.func.isRequired,
}
