/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  subMonths,
  addMonths,
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  getHours,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { classNames } from '@utils/formatFunctions'
import { useGetAvailability } from '@services/availability'
import Loading from '@common/utils/Loading'

const Calendar = ({ selectedType, serviceDates, setServiceDates }) => {
  const { sitterId } = useParams()
  const { isPending, data } = useGetAvailability(sitterId)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [calendarDays, setCalendarDays] = useState([])
  const [availability, setAvailability] = useState({})
  const [selectedDate, setSelectedDate] = useState(new Date())

  const getDayStyle = (day) => {
    const isSelected = selectedDate && isSameDay(day, selectedDate)
    const isAvailable = !!availability[format(day, 'yyyy-MM-dd')]

    if (isSelected) {
      if (isAvailable) {
        switch (selectedType) {
          case 'dogWalking':
            return 'bg-yellow-base text-white hover:opacity-80'
          case 'visit':
            return 'bg-blue-base text-white hover:opacity-80'
          case 'dayCare':
            return 'bg-purple-base text-white hover:opacity-80'
        }
      } else {
        return 'bg-gray-200 text-gray-400 hover:bg-gray-200'
      }
    } else {
      if (isAvailable) {
        switch (selectedType) {
          case 'dogWalking':
            return 'bg-yellow-100 border border-yellow-sec hover:bg-yellow-200'
          case 'visit':
            return 'bg-blue-100 border border-blue-ter hover:bg-blue-200'
          case 'dayCare':
            return 'bg-purple-100 border border-purple-base hover:bg-purple-200'
        }
      } else {
        return 'hover:bg-gray-50'
      }
    }
  }

  const getHourStyle = (hour) => {
    const isSelected = serviceDates.some(
      (item) =>
        getHours(item.date) === hour && isSameDay(item.date, selectedDate),
    )

    if (isSelected) {
      switch (selectedType) {
        case 'dogWalking':
          return 'bg-yellow-base text-white'
        case 'visit':
          return 'bg-blue-ter text-white'
        case 'dayCare':
          return 'bg-purple-base text-white'
      }
    } else {
      switch (selectedType) {
        case 'dogWalking':
          return 'bg-yellow-100 border border-yellow-sec hover:bg-yellow-200'
        case 'visit':
          return 'bg-blue-100 border border-blue-ter hover:bg-blue-200'
        case 'dayCare':
          return 'bg-purple-100 border border-purple-base hover:bg-purple-200'
      }
    }
  }

useEffect(() => {
  if (!data) return

  const firstDayOfMonth = startOfMonth(currentDate)
  const lastDayOfMonth = endOfMonth(currentDate)

  const days = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  })

  const startDayOfWeek = firstDayOfMonth.getDay()
  const daysFromPrevMonth = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1

  const prevMonthDays = eachDayOfInterval({
    start: subMonths(firstDayOfMonth, 1),
    end: endOfMonth(subMonths(firstDayOfMonth, 1)),
  }).slice(-daysFromPrevMonth)

  setCalendarDays([...prevMonthDays, ...days])

  const availObj = {}
  data.forEach((item) => {
    const date = new Date(item.date)
    const dateKey = format(date, 'yyyy-MM-dd')
    if (!availObj[dateKey]) availObj[dateKey] = []
    availObj[dateKey].push(item)
  })
  setAvailability(availObj)
}, [currentDate, data])

  if (isPending) return <Loading />

  const toggleHour = (item) => {
    const isIncluded = serviceDates.some(
      (selectedItem) => selectedItem.id === item.id,
    )
    if (isIncluded) {
      setServiceDates((prev) =>
        prev.filter((prevItem) => prevItem.id !== item.id),
      )
    } else {
      setServiceDates((prev) => [...prev, item])
    }
  }

  const renderCalendar = () => (
    <div className="rounded-lg border border-gray-200 bg-white p-4">
      <div className="flex w-full items-center justify-between">
        <button
          onClick={() => setCurrentDate(subMonths(currentDate, 1))}
          className="rounded-md p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Previous"
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
        <span className="h-full flex-1 text-center text-sm font-medium text-grey-base">
          {format(currentDate, 'MMMM yyyy', { locale: es })}
        </span>
        <button
          onClick={() => setCurrentDate(addMonths(currentDate, 1))}
          className="rounded-md p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Next"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>

      <div className="my-4 mb-2 grid grid-cols-7 gap-1">
        {['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'].map((day) => (
          <div
            key={day}
            className="text-center text-xs font-medium text-grey-sec"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {calendarDays.map((day, index) => (
          <div
            key={index}
            onClick={() => setSelectedDate(day)}
            className={classNames(
              'transform cursor-pointer rounded-md p-3 text-center transition duration-100 hover:scale-110',
              isSameDay(day, new Date()) && 'text-blue-soft',
              getDayStyle(day),
            )}
          >
            <div className="text-sm">{format(day, 'd')}</div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderHours = () => {
    if (!selectedDate) return null
    const availableHours =
      availability[format(selectedDate, 'yyyy-MM-dd')] || []

    return (
      <div className="h-full flex-1 items-center justify-center space-y-4 rounded-lg border border-gray-200 bg-white p-4">
        <h2 className="text-base text-grey-base">
          Horarios para el{' '}
          {format(selectedDate, "dd 'de' MMMM", {
            locale: es,
          })}
        </h2>

        {availableHours.length === 0 && (
          <div className="flex h-[80%] items-center justify-center p-8 sm:p-4">
            <div className="text-center">
              <p className="text-xs font-semibold">
                ¡Oh no! No hay disponibilidad para este día 😢
              </p>
              <p className="mt-2 text-[0.5rem] text-gray-500">
                ¿Qué tal si pruebas con otra fecha? Seguro encontramos una que
                funcione para ti y tu mascota 🐾
              </p>
            </div>
          </div>
        )}

        <div className="flex flex-wrap gap-4">
          {availableHours.map((item) => (
            <button
              key={item.id}
              onClick={() => toggleHour(item)}
              className={classNames(
                'transform rounded-md px-3 py-1.5 text-center text-sm transition duration-200 hover:scale-110',
                getHourStyle(getHours(item.date)),
              )}
            >
              {`${getHours(item.date)}:00`}
            </button>
          ))}
        </div>
      </div>
    )
  }

  const renderSelectedSlots = () => (
    <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4">
      <h2 className="text-base text-grey-base">Horarios seleccionados 📆</h2>
      <div className="flex flex-wrap gap-2">
        {serviceDates
          .sort((a, b) => a.date - b.date)
          .map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => toggleHour(item)}
                className={classNames(
                  'transform cursor-pointer rounded-md px-3 py-1 text-sm transition duration-200 hover:scale-105',
                  selectedType === 'dogWalking' &&
                    'border border-yellow-sec bg-yellow-100 text-slate-900 hover:bg-yellow-200',
                  selectedType === 'visit' &&
                    'border border-blue-ter bg-blue-100 text-slate-900 hover:bg-blue-200',
                  selectedType === 'dayCare' &&
                    'border border-purple-base bg-purple-100 text-slate-900 hover:bg-purple-200',
                )}
              >
                {format(item.date, "dd 'de' MMMM", {
                  locale: es,
                })}{' '}
                a las {getHours(new Date(item.date))}:00
              </div>
            )
          })}
      </div>
    </div>
  )

  return (
    <div className="container mx-auto space-y-2">
      <div className="flex flex-col gap-2 md:flex-row">
        <div className="md:w-1/2">{renderCalendar()}</div>
        <div className="md:w-1/2">{renderHours()}</div>
      </div>
      {renderSelectedSlots()}
    </div>
  )
}

export default Calendar
