import PropTypes from 'prop-types'
import BasicStats from './BasicStats'
import Graphs from './Graphs'
import MoneyStats from './MoneyStats'

function SitterStats({ stats, sitter }) {
  return (
    <div className="flex flex-col gap-8 rounded-xl p-6 bg-white shadow-xl">
      <BasicStats sitter={sitter} stats={stats} />
      <MoneyStats sitter={sitter} stats={stats} />
      <Graphs stats={stats} />
    </div>
  )
}

SitterStats.propTypes = {
  stats: PropTypes.object.isRequired,
  sitter: PropTypes.object.isRequired,
}

export default SitterStats
