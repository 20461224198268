import {
  FaAmbulance,
  FaHotel,
  FaPhoneAlt,
  FaBalanceScale,
  FaFirstAid,
  FaShieldAlt,
} from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa6'
import { BCI_SEGUROS } from '@assets/constants'
import banner from '@assets/images/home/bci/banner.png'
import bciLogoWhite from '@assets/images/home/bci/bciLogoWhite.png'
import logoWhite from '@assets/images/home/bci/logoWhite.png'

function Bci() {
  const benefits = [
    { icon: <FaAmbulance />, text: 'Traslado Medico' },
    {
      icon: <FaHotel />,
      text: 'Hotel para mascota por hospitalización o viaje del dueño',
    },
    { icon: <FaPhoneAlt />, text: 'Orientación veterinaria telefónica 24/7' },
    { icon: <FaBalanceScale />, text: 'Orientación legal telefónica' },
    { icon: <FaFirstAid />, text: 'Urgencias en caso de accidente' },
    {
      icon: <FaShieldAlt />,
      text: 'Responsabilidad civil por daños y perjuicios causados por mascotas',
    },
  ]

  const coverage = [
    {
      title: 'Urgencia veterinaria por enfermedad',
      descriptions: [
        'Máximo 2 eventos por año.',
        'Limite: UF 3 por evento.',
        'Carencia: 60 días.',
      ],
    },
    {
      title: 'Traslado en caso de accidente',
      descriptions: [
        'Máximo 3 eventos por año.',
        'Limite: UF 3 por evento.',
        'Carencia: No aplica.',
      ],
    },
    {
      title: 'Urgencia veterinaria por accidente',
      descriptions: [
        'Máximo 2 eventos por año.',
        'Limite: UF 3 por evento.',
        'Carencia: No aplica.',
      ],
    },
    {
      title: 'Orientación veterinaria telefónica 24/7',
      descriptions: ['Sin limites.', 'Carencia: No aplica.'],
    },
    {
      title: 'Orientación legal telefónica',
      descriptions: ['Sin limites.', 'Carencia: No aplica.'],
    },
    {
      title: 'Servicio de cremación para mascotas',
      descriptions: ['Máximo 1 evento.', 'Limite: UF 3.', 'Carencia: 60 días.'],
    },
  ]

  const others = [
    {
      title:
        'Responsabilidad civil por daños y perjuicios causados por mascotas',
      descriptions: [
        'Limite máximo de indemnización anual: UF 300.',
        'Número de eventos anuales: Ilimitado.',
        'Carencia: No aplica.',
      ],
    },
    {
      title: 'Hotel para mascota por hospitalización o viaje del dueño',
      descriptions: [
        'Máximo 2 evento por año.',
        'Limite: UF 1 diaria. Max 5 días.',
        'Carencia: 60 días.',
      ],
    },
  ]

  return (
    <div className="container mx-auto w-[90%] space-y-16 pb-16 sm:w-[75%]">
      <div className="relative mx-auto">
        <div className="absolute z-10 h-full w-full rounded-lg bg-black bg-opacity-45"></div>
        <div className="absolute z-20 flex h-full w-full flex-row items-center justify-center gap-16">
          <img src={logoWhite} alt="Pets-Okey Logo" className="h-1/3" />
          <div className="h-1/3 w-[2px] rounded-lg bg-white"></div>
          <img src={bciLogoWhite} alt="Logo bciLogo" className="h-1/3" />
        </div>
        <img src={banner} alt="banner" />
      </div>

      <div className="space-y-16">
        <div className="space-y-4">
          <h1 className="font-mont-semi text-4xl text-grey-base">
            Seguro Mascotas 2024
          </h1>
          <h2 className="text-xl text-grey-base">
            Asegura a tu perro o gato y{' '}
            <span className="font-mont-semi text-yellow-base">
              disfruta de los beneficios
            </span>{' '}
            de nuestro seguro
          </h2>
        </div>
        <div className="space-y-12">
          <p className="border-l-2 border-pink-base pl-2 font-mont-semi text-sm uppercase text-grey-base">
            El seguro de mascotas para perros y gatos te brinda los siguientes
            beneficios y coberturas:
          </p>
          <div className="grid gap-8 text-center sm:grid-cols-3">
            {benefits.map((item, index) => (
              <div key={index} className="flex items-center">
                <div className="rounded-full border-2 border-gray-200 p-3 text-3xl text-pink-base">
                  {item.icon}
                </div>
                <p className="mx-4 text-left font-mont-bold text-sm text-gray-700">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
          <div className="flex w-full flex-row items-center justify-end gap-8">
            <p className="font-mont-semi text-grey-base">
              Protege a tus mascotas{' '}
              <span className="text-yellow-base">ahora mismo</span>
            </p>
            <a
              href={BCI_SEGUROS}
              target="_blank"
              className="rounded-md bg-pink-base px-8 py-1.5 text-white"
              rel="noreferrer"
            >
              Contratar
            </a>
          </div>
        </div>
      </div>

      <div className="space-y-8">
        <p className="border-l-2 border-pink-base pl-2 font-mont-semi text-sm uppercase text-grey-base">
          ¿Qué cubre el seguro para mascotas?
        </p>
        <div className="grid gap-8 text-center sm:grid-cols-3">
          {coverage.map((item, index) => (
            <div key={index} className="flex flex-col gap-4 text-left">
              <h4 className="font-mont-semi text-xs uppercase text-grey-base">
                {item.title}
              </h4>
              <hr className="border-grey-soft" />
              <div className="grid gap-2">
                {item.descriptions.map((description, index) => (
                  <p key={index} className="text-xs text-grey-base">
                    <FaChevronRight
                      className="mr-1 inline text-pink-base"
                      size={12}
                    />
                    {description}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full flex-row items-center justify-end gap-8">
          <p className="font-mont-semi text-grey-base">
            El seguro para mascotas de BCI seguros{' '}
            <span className="text-yellow-base">es tu mejor opción</span>
          </p>
          <a
            href={BCI_SEGUROS}
            target="_blank"
            className="rounded-md bg-pink-base px-8 py-1.5 text-white"
            rel="noreferrer"
          >
            Contratar
          </a>
        </div>
      </div>
      <div className="space-y-8">
        <div className="grid gap-8 text-center sm:grid-cols-2">
          {others.map((item, index) => (
            <div key={index} className="flex flex-col gap-4 text-left">
              <h5 className="font-mont-semi text-xs uppercase text-grey-base">
                {item.title}
              </h5>
              <hr className="border-grey-soft" />
              <div className="grid gap-2">
                {item.descriptions.map((description, index) => (
                  <p key={index} className="text-xs text-grey-base">
                    <FaChevronRight
                      className="mr-1 inline text-pink-base"
                      size={12}
                    />
                    {description}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-4 text-left">
          <h5 className="font-mont-semi text-xs uppercase text-grey-base">
            Denuncio de siniestros
          </h5>
          <hr className="border-grey-soft" />
          <p className="text-xs text-grey-base">
            Para realizar requerimiento del uso de asistencia, denuncia de
            siniestros o consultas,{' '}
            <span className="text-pink-base">
              el cliente deberá contactar en forma inmediata y antes de utilizar
              algún servicio con nuestros ejecutivos especialistas,
            </span>{' '}
            que atenderán sus requerimientos junto a un veterinario en linea
            para encontrar la solución más rápida y efectiva para tu mascota.
          </p>
          <div className="border-l-2 border-pink-base font-mont-bold pl-2">
            0226799200
          </div>
        </div>
        <div className="space-y-1 text-[0.7rem] text-grey-base">
          <p className='font-mont-semi'>Nota</p>
          <hr className="border-grey-soft" />
          <p>El valor del seguro es UF0,26. El precio de $9.770 es referencial y fue calculado al 31-07-2024. Promoción válida hasta el 31 de agosto de 2024. </p>
        </div>
      </div>
    </div>
  )
}

export default Bci
