import PropType from 'prop-types'
import { useState } from 'react'
import SitterMainInfo from './SitterMainInfo'
import TrafficLight from './TrafficLight'
import SitterPhotos from './SitterPhotos'
import AboutSitterHouse from './AboutSitterHouse'

function SitterSummary({ sitter }) {
  const [showMore, setShowMore] = useState(false)

  return (
    <div className="my-4 flex h-full flex-col gap-6 overflow-y-auto rounded-xl px-3 md:m-0 md:bg-white md:p-6 md:shadow-xl">
      <SitterMainInfo sitter={sitter} />
      <div className="flex flex-col items-start justify-center gap-2">
        <h2 className="font-mont-semi md:text-lg">Sobre {sitter.name}</h2>
        <p
          className={`w-full break-words text-sm text-grey-base ${
            showMore ? '' : 'line-clamp-2'
          }`}
        >
          {sitter.description}
        </p>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-xs text-grey-soft underline md:text-sm"
        >
          {showMore ? 'Ver menos' : 'Ver más'}
        </button>
      </div>
      <hr />
      <TrafficLight stats={sitter.stats} />
      <hr />
      <SitterPhotos data={sitter} />
      <hr />
      <AboutSitterHouse
        data={sitter.aboutSitterHouse}
        sitterName={sitter.name}
      />
    </div>
  )
}

SitterSummary.propTypes = {
  sitter: PropType.object.isRequired,
}

export default SitterSummary
