const unselectedContainerStyle = (firstCondition, secondCondition) => {
  const style = `flex flex-col gap-4 items-center w-full sm:w-[90%] justify-center p-4 ${
    firstCondition
      ? ''
      : secondCondition
        ? 'transform scale-100 duration-300'
        : 'transform scale-[75%] duration-300 hover:bg-grey-soft rounded-xl cursor-pointer'
  }`
  return style
}

const selectedContainerStyle = (firstCondition, secondCondition) => {
  const style = `flex flex-row gap-8 sm:gap-16 items-center justify-center p-4 ${
    firstCondition
      ? ''
      : secondCondition
        ? 'transform scale-100 duration-300'
        : 'transform scale-[75%] duration-300 hover:bg-grey-soft rounded-xl cursor-pointer'
  }`
  return style
}

export { selectedContainerStyle, unselectedContainerStyle }
