import PropTypes from 'prop-types'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from 'lucide-react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

export default function CalendarControls({
  currentDate,
  viewType,
  goToToday,
  changeDate,
  setViewType,
  setIsOpen,
}) {
  return (
    <div className="mb-4 flex flex-col items-center justify-between space-y-2 md:flex-row md:space-y-0">
      <h2 className="font-tex text-lg capitalize text-gray-800">
        {viewType === 'month'
          ? format(currentDate, 'MMMM yyyy', { locale: es })
          : viewType === 'week'
            ? `${format(currentDate, 'MMMM yyyy', { locale: es })} - Semana ${format(currentDate, 'w', { locale: es })}`
            : `${format(currentDate, 'MMM d', { locale: es })}`}
      </h2>
      <div className="flex items-center space-x-4">
        {/* Botón para retroceder en las fechas */}
        <button
          onClick={() => changeDate('prev')}
          className="rounded-full p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Previous"
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>

        {/* Botón para ir a la fecha actual */}
        <button
          onClick={goToToday}
          className="rounded border px-3 py-1 text-xs text-gray-700 hover:bg-gray-100 sm:text-sm"
        >
          Hoy
        </button>

        {/* Botón para avanzar en las fechas */}
        <button
          onClick={() => changeDate('next')}
          className="rounded-full p-2 text-gray-600 hover:bg-gray-100"
          aria-label="Next"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>

        {/* Menú para cambiar el tipo de vista (Mes, Semana, Día) */}
        <Menu as="div" className="relative z-50">
          <MenuButton
            type="button"
            className="flex flex-row items-center gap-2 rounded border px-2 py-1 text-xs text-gray-700 hover:bg-gray-100 sm:text-sm"
          >
            {viewType === 'month' && 'Mes'}
            {viewType === 'week' && 'Semana'}
            {viewType === 'day' && 'Día'}
            <ChevronDownIcon
              className="-mr-1 h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </MenuButton>

          <MenuItems className="absolute right-0 z-10 mt-2 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <MenuItem>
                <a
                  onClick={() => setViewType('month')}
                  className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100"
                >
                  Mes
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  onClick={() => setViewType('week')}
                  className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100"
                >
                  Semana
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  onClick={() => setViewType('day')}
                  className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100"
                >
                  Día
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>

        {/* Botón para abrir el modal de disponibilidad */}
        <button
          onClick={() => setIsOpen(true)}
          className="transform rounded bg-blue-ter px-2 py-1 text-xs font-medium text-white duration-200 hover:scale-105 hover:bg-blue-base sm:text-sm"
        >
          Disponibilidad
        </button>
      </div>
    </div>
  )
}

CalendarControls.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  viewType: PropTypes.string.isRequired,
  goToToday: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  setViewType: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}
