import { useState } from 'react'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import PlanForm from '@common/forms/PlanForm'
import {
  numberRenderer,
  textCenterRenderer,
} from '@common/helpers/tableRenderers'
import { useGetPlans } from '@services/plan'

const PlanList = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)

  const { isPending, isError, error, data } = useGetPlans()

  const openModal = (plan) => {
    setIsOpenModal(true)
    setSelectedPlan(plan)
  }

  const filteredData = data
    ? data.filter(
        (plan) =>
          plan.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          plan.price.toString().includes(searchTerm) ||
          plan.sitter?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          plan.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          plan.address?.street.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : null

  if (isPending) return <Loading />

  const tableProps = [
    {
      key: 'userName',
      header: { name: 'Usuario', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
    {
      key: 'type',
      header: { name: 'Tipo', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
    {
      key: 'price',
      header: { name: 'Precio', style: { textAlign: 'center' } },
      renderer: numberRenderer,
    },
    {
      key: 'sitterName',
      header: { name: 'Sitter', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
    {
      key: 'address',
      header: { name: 'Dirección', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
    {
      key: 'createdAt',
      header: { name: 'Fecha de Creación', style: { textAlign: 'center' } },
      renderer: textCenterRenderer,
    },
    {
      key: 'updatedAt',
      header: {
        name: 'Fecha de Actualización',
        style: { textAlign: 'center' },
      },
      renderer: textCenterRenderer,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((plan) => ({
      ...plan,
      createdAt: new Date(plan.createdAt).toLocaleDateString(),
      updatedAt: new Date(plan.updatedAt).toLocaleDateString(),
      sitterName: `${plan.sitter.name} ${plan.sitter.lastName}`,
      userName: `${plan.user.name} ${plan.user.lastName}`,
      address: `${plan.address.street}, ${plan.address.commune}`,
    }))
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Buscar plan'}
      />
      <CustomTable
        data={tableData}
        tableProps={tableProps}
        rowClickHandler={(plan) => openModal(plan)}
      />
      <CustomModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        header={
          <ModalHeader
            title="Editar Plan"
            subtitle={
              'Actualiza el Plan de ' +
              selectedPlan?.user.name +
              ' ' +
              selectedPlan?.user.lastName
            }
          />
        }
        body={
          <PlanForm
            onSubmit={() => setIsOpenModal(false)}
            plan={selectedPlan}
          />
        }
      />
      {isError ? <Error message={error.message} /> : null}
    </div>
  )
}

export default PlanList
