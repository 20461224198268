import { FaPlus } from 'react-icons/fa'
import { FaCirclePlus } from 'react-icons/fa6'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import PetCard from './PetCard'
import Loading from '@common/utils/Loading'
import { useGetByUser } from '@services/pet'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import PetForm from '@common/forms/PetForm'

function Pets() {
  const [isOpen, setIsOpen] = useState(false)
  const { isPending, data: pets } = useGetByUser()

  if (isPending)
    return (
      <div className="flex max-h-96 flex-grow flex-col rounded-xl bg-white p-8 shadow-lg">
        <Loading />
      </div>
    )

  return (
    <div className="space-y-4 rounded-lg bg-white px-4 pb-4 pt-2 drop-shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <h2 className="font-mont-semi text-2xl text-slate-900">Mascotas</h2>
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="transform text-yellow-base transition duration-200 hover:scale-110 hover:text-yellow-sec"
        >
          <FaCirclePlus size={20} />
        </button>
      </div>

      <div className="flex w-full flex-row items-center justify-center gap-8">
        {pets.length == 0 ? (
          <div className="flex h-full min-h-96 flex-col items-center justify-center gap-2 text-center">
            <p className="font-mont-semi text-xl text-grey-base">
              ¡Oh no! Aún no has agregado ninguna mascota.
            </p>
            <p className="text-base text-grey-soft">
              Para poder contratar servicios en el futuro, por favor agrega tus
              mascotas.
            </p>
            <button
              onClick={() => setIsOpen(true)}
              className="mt-4 flex transform flex-row items-center gap-2 rounded-full bg-blue-ter px-4 py-2 text-xs text-white transition duration-200 hover:scale-110 hover:bg-blue-base"
            >
              <FaPlus /> Agrega una mascota 🐶
            </button>
          </div>
        ) : (
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {pets.map((pet, index) => (
              <SwiperSlide key={index}>
                <PetCard pet={pet} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Agrega una mascota"
            subtitle="Indícanos los datos de tu mascota 🐾"
          />
        }
        body={<PetForm onSubmit={() => setIsOpen(false)} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default Pets
