import PropTypes from 'prop-types'
import { MdPerson, MdOutlinePersonOff } from 'react-icons/md'
import { FaStar } from 'react-icons/fa'

function TrafficLight({ stats }) {
  return (
    <>
      <div className="flex flex-row items-center justify-between space-x-2">
        <div
          className={`${
            stats?.statusLightColor == 0
              ? 'bg-red-base'
              : 'bg-red-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats?.statusLightColor == 1
              ? 'bg-[#f97316]'
              : 'bg-orange-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats?.statusLightColor == 2
              ? 'bg-yellow-base'
              : 'bg-yellow-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats?.statusLightColor == 3
              ? 'bg-[#dff77b]'
              : 'bg-[#ecfccb] bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            stats?.statusLightColor == 4
              ? 'bg-green-base'
              : 'bg-green-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
      </div>

      <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
        <div className="flex flex-col items-center justify-center rounded-xl border border-grey-soft p-4 text-center text-grey-base">
          <p className="mb-1 text-xl">{stats?.deniedPercentage}%</p>
          <p className="text-sm">Tasa de rechazo</p>
        </div>
        <div className="flex flex-col items-center justify-center rounded-xl border border-grey-soft p-4 text-center text-grey-base">
          <p className="mb-1">
            {stats?.profileColor == 2 ? (
              <MdPerson size={24} />
            ) : (
              <MdOutlinePersonOff size={24} />
            )}
          </p>
          <p className="text-sm">
            {stats?.profileColor == 2
              ? 'Perfil actualizado'
              : 'Perfil incompleto'}
          </p>
        </div>
        {stats.starsColor == 2 && (
          <div className="flex flex-col items-center justify-center rounded-xl border border-grey-soft p-4 text-center text-grey-base">
            <p className="mb-1">
              <FaStar size={24} />
            </p>
            <p className="text-sm">Excelente servicio</p>
          </div>
        )}
        {stats.color != 2 && (
          <div className="flex flex-col items-center justify-center rounded-xl border border-grey-soft p-4 text-center text-grey-base">
            <p className="mb-1 text-xl">{stats?.finished ?? 0}</p>
            <p className="text-sm">Servicios realizados</p>
          </div>
        )}
      </div>
    </>
  )
}

TrafficLight.propTypes = {
  stats: PropTypes.object.isRequired,
}

export default TrafficLight
