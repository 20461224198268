import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { AuthContext } from '@common/context/AuthContext'
import { useContext } from 'react'

const AdminRoutes = () => {
  const { getAuthToken } = useContext(AuthContext)
  const user = useSelector((state) => state.user)
  return getAuthToken() && user.isAdmin ? <Outlet /> : <Navigate to="/" />
}

export default AdminRoutes
