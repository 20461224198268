'use client'

import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import { ChevronDown, X } from 'lucide-react'

export default function MultiSelect({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.some((item) => item.value === option.value)
        ? prevSelected.filter((item) => item.value !== option.value)
        : [...prevSelected, option],
    )
    setSearchTerm('')
    inputRef.current?.focus()
    setHighlightedIndex(-1)
  }

  const removeOption = (option, e) => {
    e.stopPropagation()
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((item) => item.value !== option.value),
    )
    inputRef.current?.focus()
  }

  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedOptions.some((selected) => selected.value === option.value),
  )

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault()
      toggleOption(filteredOptions[highlightedIndex])
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0,
      )
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1,
      )
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
        setHighlightedIndex(-1)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex cursor-text flex-wrap items-center rounded-md border border-gray-300 bg-white p-1.5"
        onClick={() => {
          setIsOpen(true)
          inputRef.current?.focus()
        }}
      >
        {selectedOptions.map((option) => (
          <span
            key={option.value}
            className="mb-2 mr-2 flex transform items-center rounded-full bg-blue-ter px-3 py-1 text-xs font-medium text-white transition-all duration-300 hover:scale-105"
          >
            {option.label}
            <X
              className="ml-1 h-4 w-4 cursor-pointer"
              onClick={(e) => removeOption(option, e)}
            />
          </span>
        ))}
        <input
          ref={inputRef}
          type="text"
          className="flex-grow border-none bg-transparent text-sm outline-none focus:ring-0 placeholder:text-grey-soft"
          placeholder={selectedOptions.length === 0 ? placeholder : ''}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setIsOpen(true)
            setHighlightedIndex(0)
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
        />
        <ChevronDown className="ml-auto h-5 w-5 text-gray-400" />
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {filteredOptions.map((option, index) => (
            <li
              key={option.value}
              className={`flex cursor-pointer items-center p-2 text-sm text-grey-base ${
                index === highlightedIndex ? 'bg-gray-200' : 'hover:bg-gray-100'
              }`}
              onClick={() => toggleOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

MultiSelect.defaultProps = {
  placeholder: 'Selecciona opciones...'
}