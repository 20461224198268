import PropType from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FaRegLightbulb, FaCheckCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import { saveFourthStep } from '@store/reducers/sitterRequest'

function QuestionTemplate({
  title,
  options,
  isImageQuestion,
  isVerifying,
  reduxKey,
}) {
  const { fourthStep } = useSelector((state) => state.sitterRequest)
  const [lastVerifiedOption, setLastVerifiedOption] = useState(
    fourthStep[reduxKey],
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (isVerifying) {
      setLastVerifiedOption(fourthStep[reduxKey])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerifying])

  return (
    <div className="container mx-auto">
      <div className="mb-4 flex flex-row items-center gap-4 font-mont-semi text-base sm:text-lg">
        <p className="w-[90%]">{title}</p>
        <p className="w-[10%]">
          {' '}
          {lastVerifiedOption && lastVerifiedOption.correct ? (
            <FaCheckCircle className="text-3xl text-green-500" />
          ) : lastVerifiedOption && !lastVerifiedOption.correct ? (
            <FaCircleXmark className="text-3xl text-red-500" />
          ) : null}
        </p>
      </div>

      <div
        className={`flex ${
          isImageQuestion
            ? 'grid grid-cols-2 gap-4 sm:flex sm:flex-row sm:justify-between'
            : 'flex-col gap-4'
        }`}
      >
        {options.map((option, index) => (
          <label className="flex flex-row items-center gap-1" key={index}>
            <input
              type="radio"
              className={`appearance-none ${
                isImageQuestion ? 'h-0 w-0 opacity-0' : 'mr-2'
              }`}
              value={isImageQuestion ? option.image : option.text}
              checked={
                isImageQuestion
                  ? fourthStep[reduxKey] &&
                    fourthStep[reduxKey].image === option.image
                  : fourthStep[reduxKey] &&
                    fourthStep[reduxKey].text === option.text
              }
              onChange={() =>
                dispatch(
                  saveFourthStep({
                    key: reduxKey,
                    value: option,
                  }),
                )
              }
            />
            {isImageQuestion ? (
              <div
                className={`relative cursor-pointer ${
                  fourthStep[reduxKey] &&
                  fourthStep[reduxKey].image === option.image
                    ? 'rounded-lg border-2 border-blue-500 p-1'
                    : ''
                }`}
              >
                <img
                  src={option.image}
                  alt={'altDog'}
                  className="w-32 rounded-md object-contain shadow-lg sm:w-44"
                />
              </div>
            ) : (
              <p className="cursor-pointer text-sm sm:text-base">
                {option.text}
              </p>
            )}
          </label>
        ))}
      </div>
      {lastVerifiedOption && lastVerifiedOption.response && (
        <div className="z-10 mt-4 flex flex-row items-center justify-start gap-4 rounded-lg border-l-8 border-yellow-base bg-white-base p-4 text-yellow-base shadow-lg">
          <FaRegLightbulb size={20} />
          <h1 className="text-sm text-slate-900">
            {lastVerifiedOption.response}
          </h1>
        </div>
      )}
    </div>
  )
}

QuestionTemplate.propTypes = {
  title: PropType.string.isRequired,
  options: PropType.array.isRequired,
  isImageQuestion: PropType.bool.isRequired,
  isVerifying: PropType.bool.isRequired,
  reduxKey: PropType.string.isRequired,
}

export default QuestionTemplate
