import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userSlice'
import addressReducer from './reducers/addressSlice'
import petReducer from './reducers/petSlice'
import requestReducer from './reducers/requestSlice'
import eventReducer from './reducers/eventSlice'
import filterReducer from './reducers/filterSlice'
import sitterSlice from './reducers/sitterSlice'
import sitterRequest from './reducers/sitterRequest'
import paymentReducer from './reducers/paymentSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'user',
  storage,
}

const rootReducer = combineReducers({
  user: persistReducer(persistConfig, userReducer),
  address: addressReducer,
  pet: petReducer,
  request: requestReducer,
  event: eventReducer,
  filter: filterReducer,
  sitter: sitterSlice,
  sitterRequest: sitterRequest,
  payment: paymentReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
})

const persistor = persistStore(store)

export { store, persistor }
