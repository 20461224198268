import { useSelector } from 'react-redux'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import ServicesSwipper from '@common/service_records/others/ServicesSwipper'
import ServiceSummary from '@common/service_records/others/ServiceSummary'
import Chat from '@common/service_records/chat/Chat'
import VoidServices from '@common/service_records/others/VoidServices'
import useSelectedService from '@common/service_records/hooks/useSelectedService'

function ServiceRecords() {
  const ROLE = 'sitter'
  const user = useSelector((state) => state.user)
  const {
    selectedService,
    setSelectedService,
    isPending,
    isError,
    error,
    data,
  } = useSelectedService(user, ROLE)

  if (isError) return <Error message={error.message} />

  if (isPending) return <Loading />

  if (data && data.length === 0) return <VoidServices role={ROLE} />

  if (!selectedService) return <Loading />

  return (
    <div className="container m-2 flex flex-col gap-4 md:min-h-screen lg:m-8 lg:mx-auto lg:w-[75%] lg:flex-row lg:justify-between">
      <div className="space-y-4 lg:w-[60%]">
        <ServicesSwipper
          initialSlide={data.findIndex(
            (service) => service.record.id === selectedService.record.id,
          )}
          data={data}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          role={ROLE}
        />
        <ServiceSummary service={selectedService} role={ROLE} />
      </div>
      <div className="lg:w-[40%]">
        {' '}
        <Chat
          record={selectedService.record}
          headerUser={selectedService.user}
        />
      </div>
    </div>
  )
}

export default ServiceRecords
