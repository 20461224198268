import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import {
  userTypeRenderer,
  isActiveUserRenderer,
} from '@common/helpers/tableRenderers'

const UserList = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['adminUsers'],
    queryFn: () => getData('user/'),
  })

  const filteredData = data
    ? data.filter(
        (user) =>
          user.id.toString().includes(searchTerm) ||
          (user.name + ' ' + user.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          ('sitter'.includes(searchTerm.toLowerCase()) && user.isSitter) ||
          ('usuario'.includes(searchTerm.toLowerCase()) && !user.isSitter) ||
          ('activo'.includes(searchTerm.toLowerCase()) && user.isActive),
      )
    : null

  if (isPending) return <Loading />

  if (isError) return <Error error={error.message} />

  const tableProps = [
    {
      key: 'userFullName',
      header: { name: 'Nombre' },
    },
    {
      key: 'email',
      header: { name: 'Email' },
    },
    {
      key: 'phone',
      header: { name: 'Teléfono' },
    },
    {
      key: 'isSitter',
      header: {
        name: 'Tipo de usuario',
        style: { textAlign: 'center' },
      },
      renderer: userTypeRenderer,
    },
    {
      key: 'isActive',
      header: {
        name: 'Estado',
        style: { textAlign: 'center' },
      },
      renderer: isActiveUserRenderer,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((request) => {
      return {
        ...request,
        userFullName: `${request.name} ${request.lastName}`,
      }
    })
  }

  return (
    <div>
      <SearchInput
        value={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Buscar usuario..."
      />
      <CustomTable
        data={tableData}
        tableProps={tableProps}
        onClick={(id) => navigate(`/user/${id}`)}
      />
    </div>
  )
}

export default UserList
