import { useEffect, useState } from 'react'
import { useGetPlan } from '@services/plan'
import { useParams } from 'react-router-dom'
import { useSessionStorage } from '@common/context/sessionStorageContext'
import MethodSelection from '@pages/hire_sitter/components/payment/MethodSelection'

import Loading from '@common/utils/Loading'
import ErrorNoCard from '@common/error/ErrorNoCard'

const PlanPaymentPage = () => {
  const { planId } = useParams()
  // eslint-disable-next-line no-unused-vars
  const [planInfo, setPlanInfo] = useSessionStorage('planInfo', null)
  const { data: planData, isLoading, isError, isSuccess } = useGetPlan(planId)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      setPlanInfo(planData)
    }
  }, [isSuccess, planData, setPlanInfo])

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return (
      <ErrorNoCard
        message={'Ocurrio un error al intentar obtener los detalles del Plan.'}
      />
    )
  }

  const handlePaymentButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const maxPlanBilling = planData.billings.reduce((prev, current) =>
    prev.servicesUntil > current.servicesUntil ? prev : current,
  )

  if (maxPlanBilling.paidAt !== null) {
    return (
      <div className="mx-auto my-8 h-2/3 max-w-md rounded-lg bg-white p-6 shadow-lg">
        El plan ya esta pagado
      </div>
    )
  }

  return (
    <div className="mx-auto my-8 h-2/3 max-w-md rounded-lg bg-white p-6 shadow-lg">
      <h1 className="mb-4 text-2xl font-bold text-gray-800">
        Resumen del Plan
      </h1>
      {planData && (
        <div className="space-y-3">
          <div
            className={`text-center ${
              planData.type == 'dogWalking'
                ? 'text-yellow-base'
                : planData.type == 'visit'
                  ? 'text-blue-base'
                  : status == 'dayCare'
                    ? 'text-purple-base'
                    : null
            }`}
          >
            <p className="text-xl font-semibold">
              {planData.type == 'dogWalking'
                ? 'Paseo'
                : planData.type == 'visit'
                  ? 'Visita'
                  : planData.type == 'dayCare'
                    ? 'Alojamiento'
                    : null}
            </p>
          </div>

          <p className="text-lg">
            <span className="font-semibold text-gray-700">Valor del plan:</span>{' '}
            CLP ${planData.price.toLocaleString()}
          </p>
          <p className="text-lg">
            <span className="font-semibold text-gray-700">Dirección:</span>{' '}
            {`${planData.address.street}, ${planData.address.commune}`}
          </p>
          <p className="text-lg">
            <span className="font-semibold text-gray-700">Usuario:</span>{' '}
            {`${planData.user.name} ${planData.user.lastName}`}
          </p>
          <p className="text-lg">
            <span className="font-semibold text-gray-700">Cuidador:</span>{' '}
            {`${planData.sitter.name} ${planData.sitter.lastName}`}
          </p>
        </div>
      )}
      <button
        className={`mt-6 w-full rounded px-4 py-2 text-white transition-colors ${
          maxPlanBilling.paidAt !== null
            ? 'cursor-not-allowed bg-gray-400'
            : 'bg-blue-base hover:bg-blue-sec'
        }`}
        onClick={handlePaymentButtonClick}
        disabled={maxPlanBilling.paidAt !== null}
      >
        Continuar con el pago
      </button>

      <MethodSelection
        isOpen={isModalOpen}
        onOpen={handlePaymentButtonClick}
        onClose={handleCloseModal}
      />
    </div>
  )
}

export default PlanPaymentPage
