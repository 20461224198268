import PropType from 'prop-types'

const TableCell = ({ value, columnRenderer, formatFunction }) => {
  const renderColumnContent = () => {
    if (formatFunction) value = formatFunction(value)
    if (columnRenderer) return columnRenderer(value)

    return (
      <td className="whitespace-nowrap px-6 py-4 text-xs">
        {typeof value === 'object' && value !== null
          ? renderObjectValues(value)
          : value}
      </td>
    )
  }

  const renderObjectValues = (object) =>
    Object.entries(object).map(([key, val], i) => (
      <span key={i}>
        {key}: {val}{' '}
      </span>
    ))

  return renderColumnContent()
}

const renderHeaders = (tableProps) =>
  tableProps.map(({ header: { name, style = {} } }, index) => (
    <th
      key={index}
      scope="col"
      className={`capitalize font-mont-semi px-6 py-3 text-${style.textAlign || 'left'}`}
    >
      {name}
    </th>
  ))

const CustomTable = ({ data, tableProps, rowClickHandler }) => {
  data = data || []
  return (
    <div className="max-h-screen overflow-x-auto bg-white">
      <table className="w-full overflow-y-hidden text-left text-sm text-slate-700 rtl:text-right">
        <thead className="sticky top-0 z-10 bg-white text-sm uppercase text-slate-900">
          <tr>{renderHeaders(tableProps)}</tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-slate-900">
          {data.map((value, index) => {
            const rowProps = rowClickHandler
              ? {
                  onClick: () => rowClickHandler(value),
                  className:
                    'bg-white border-b hover:bg-gray-50 cursor-pointer',
                }
              : {
                  className: 'bg-white border-b',
                }
            return (
              <tr key={index} {...rowProps}>
                {tableProps.map((prop, index) => {
                  const { key } = prop
                  return (
                    <TableCell
                      key={index}
                      value={value[key]}
                      columnRenderer={prop.renderer}
                      formatFunction={prop.formatter}
                    />
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

CustomTable.propTypes = {
  data: PropType.array.isRequired,
  tableProps: PropType.array.isRequired,
  rowClickHandler: PropType.func,
}

export default CustomTable
