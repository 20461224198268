const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api'

const downloadExcel = async (token, path, title) => {
  const date = new Date()
  const formattedDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`

  try {
    const response = await fetch(`${apiUrl}/${path}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
    const blob = await response.blob()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Reporte ${title} ${formattedDate}.xlsx`) // Nombre del archivo de descarga
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  } catch (error) {
    console.error('Error downloading Excel file:', error)
  }
}

export default downloadExcel
