import post1 from './assets/post1.jpg'
import post2 from './assets/post2.jpg'
import post3 from './assets/post3.jpg'
import post4 from './assets/post4.png'
import post5 from './assets/post5.jpg'
import post6 from './assets/post6.png'
import post7 from './assets/post7.jpg'
import post8 from './assets/post8.jpg'
import post9 from './assets/post9.jpg'
import post10 from './assets/post10.jpg'
import post11 from './assets/post11.jpg'
import post12 from './assets/post12.jpg'
import post13 from './assets/post13.jpg'
import post14 from './assets/post14.jpg'
import post15 from './assets/post15.jpg'
import post16 from './assets/post16.jpg'
import post17 from './assets/post17.png'
import post18 from './assets/post18.png'
import post19 from './assets/post19.png'
import JM from '@assets/images/people/JM.jpg'
import PD from '@assets/images/people/PD.jpeg'
import SR from '@assets/images/people/SR.jpg'

const posts = [
  {
    id: 2,
    title: 'Solo en Casa: Versión Perruna',
    href: '/blog/post2',
    description:
      'Descubre cómo dejar a tu perro solo en casa de manera segura y adaptada a sus necesidades, con consejos sobre preparación, ejercicio y cuidados personalizados.',
    imageUrl: post2,
    date: 'Jun 05, 2024',
    datetime: '2024-06-05',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post2',
      imageUrl: PD,
    },
  },
  {
    id: 1,
    title:
      '¿Últimamente notas que tu perro se rasca mucho?, podría tener alergias',
    href: '/blog/post1',
    description:
      'Aprende a identificar los síntomas de alergias en tu perro y cómo manejarlas con nuestros consejos. Mantén a tu mascota sana y feliz con los cuidados personalizados de Pets Okey. ¡Contáctanos para más información y asegúrate de que tu perro reciba la atención que merece!',
    imageUrl: post1,
    date: 'Jun 15, 2024',
    datetime: '2024-06-15',
    category: { title: 'Salud', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post2',
      imageUrl: PD,
    },
  },
  {
    id: 3,
    title: '¿Tu perro no quiere comer? Te contamos qué hacer.',
    href: '/blog/post3',
    description:
      'Descubre qué hacer si tu perro no quiere comer, los factores que influyen en su apetito y cuándo acudir al veterinario. Aprende a identificar signos de deshidratación y malnutrición, además de consejos prácticos para mantener una alimentación adecuada y garantizar el bienestar de tu mascota.',
    imageUrl: post3,
    date: 'Jul 02, 2024',
    datetime: '2024-07-02',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Javier Maturana',
      role: 'Founder / COO',
      href: '/blog/post3',
      imageUrl: JM,
    },
  },
  {
    id: 4,
    title: 'Perros y Pepinos: ¿Son compatibles?',
    href: '/blog/post4',
    description:
      'Descubre si los pepinos son un snack seguro para tu perro y cómo ofrecerlos de manera adecuada. Conoce los beneficios y precauciones para mantener a tu mascota saludable con la ayuda de Pets-Okey.',
    imageUrl: post4,
    date: 'Jul 15, 2024',
    datetime: '2024-07-15',
    category: { title: 'Curiosidades', href: '' },
    author: {
      name: 'Sebastián Rodríguez',
      role: 'Founder / CTO',
      href: '/blog/post4',
      imageUrl: SR,
    },
  },
  {
    id: 5,
    title: 'Cómo entrenar a tu perro: guía completa para el éxito',
    href: '/blog/post5',
    description:
      'Entrena a tu perro con técnicas efectivas y prácticas que mejorarán su conducta. En Pets-Okey, te ayudamos con el entrenamiento y los paseos para que tu mascota esté en las mejores manos.',
    imageUrl: post5,
    date: 'Jul 25, 2024',
    datetime: '2024-07-25',
    category: { title: 'Entrenamiento', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post5',
      imageUrl: PD,
    },
  },
  {
    id: 10,
    title:
      '¿Por qué ladran los perros y cómo enseñarles a controlar sus ladridos?',
    href: '/blog/post10',
    description:
      'Aprende sobre las razones detrás de los ladridos de los perros y cómo enseñarles a controlarlos para mejorar la convivencia. Este artículo ofrece consejos prácticos para tutores y paseadores, ayudándote a comprender y manejar mejor el comportamiento de tu perro, creando un entorno más tranquilo y armonioso.',
    imageUrl: post10,
    date: 'Jul 29, 2024',
    datetime: '2024-07-10',
    category: { title: 'Curiosidades', href: '' },
    author: {
      name: 'Javier Maturana',
      role: 'Founder / COO',
      href: '/blog/post3',
      imageUrl: JM,
    },
  },
  {
    id: 6,
    title: '¿Está obeso mi perro?',
    href: '/blog/post6',
    description:
      'La obesidad en perros es un problema que afecta su salud y bienestar. Aprende a identificarlo y descubre consejos para mantener a tu mascota en forma y feliz.',
    imageUrl: post6,
    date: 'Jul 30, 2024',
    datetime: '2024-07-30',
    category: { title: 'Salud', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post6',
      imageUrl: PD,
    },
  },
  {
    id: 7,
    title: 'Cuidados para tu Perro en Invierno:',
    href: '/blog/post7',
    description:
      'Prepárate para cuidar a tu perro durante el invierno con nuestros consejos esenciales. Desde protección contra el frío hasta cuidados especiales para sus patas, te ofrecemos las mejores recomendaciones para mantener a tu mascota feliz y saludable en los meses más fríos del año.',
    imageUrl: post7,
    date: 'Ago 02, 2024',
    datetime: '2024-08-02',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Sebastián Rodríguez',
      role: 'Founder / CTO',
      href: '/blog/post7',
      imageUrl: SR,
    },
  },
  {
    id: 8,
    title: 'Cómo afectan los ruidos fuertes a tu peludo y cómo cuidarlos',
    href: '/blog/post8',
    description:
      'Explora cómo la sensibilidad auditiva de los perros en entornos urbanos afecta su bienestar. Descubre cómo protegerlos de ruidos fuertes, como fuegos artificiales y sirenas, con consejos prácticos para reducir su estrés y ansiedad, mejorando su calidad de vida.',
    imageUrl: post8,
    date: 'Ago 05, 2024',
    datetime: '2024-08-05',
    category: { title: 'Bienestar Animal', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post8',
      imageUrl: PD,
    },
  },
  {
    id: 9,
    title: '¿Por qué los perros inclinan la cabeza?',
    href: '/blog/post9',
    description:
      'Explora por qué los perros inclinan la cabeza y descubre las razones detrás de este adorable gesto. Desde curiosidad natural hasta comunicación y posibles problemas médicos, este artículo te ayudará a entender mejor el comportamiento de tu peludo amigo.',
    imageUrl: post9,
    date: 'Ago 10, 2024',
    datetime: '2024-08-10',
    category: { title: 'Curiosidades', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post9',
      imageUrl: PD,
    },
  },
  {
    id: 11,
    title: 'Cómo mantener a tu perro seguro en un viaje en auto',
    href: '/blog/post11',
    description:
      'Asegura la seguridad de tu perro durante viajes en auto con estos consejos esenciales. Desde el uso de arneses hasta la planificación de paradas regulares, descubre cómo hacer que los trayectos sean seguros y cómodos para tu mascota. Sigue la normativa y garantiza un viaje placentero para todos.',
    imageUrl: post11,
    date: 'Ago 15, 2024',
    datetime: '2024-08-15',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post11',
      imageUrl: PD,
    },
  },
  {
    id: 12,
    title:
      'Beneficios del Aceite de Oliva para Perros: Todo lo que Necesitas Saber',
    href: '/blog/post12',
    description:
      'Descubre cómo el aceite de oliva puede beneficiar a tu perro, desde mejorar su salud hasta cuidar su pelaje, y conoce las dosis adecuadas y alternativas recomendadas por expertos.',
    imageUrl: post12,
    date: 'Ago 20, 2024',
    datetime: '2024-08-20',
    category: { title: 'Curiosidades', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post12',
      imageUrl: PD,
    },
  },
  {
    id: 13,
    title:
      'Cómo preparar tu hogar para la llegada de un nuevo gatito: consejos para que tu pequeño felino se sienta seguro y feliz',
    href: '/blog/post13',
    description:
      'La llegada de un nuevo gatito a casa es emocionante, pero también requiere planificación. En este artículo, aprenderás cómo preparar tu hogar para que tu felino se sienta seguro y feliz desde el primer día, con consejos sobre espacios seguros, alimentación y juguetes.',
    imageUrl: post13,
    date: 'Sep 06, 2024',
    datetime: '2024-09-06',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post13',
      imageUrl: PD,
    },
  },
  {
    id: 14,
    title:
      'Cuidados esenciales para tu gatito: mantén a tu compañero siempre sano y feliz',
    href: '/blog/post14',
    description:
      'Descubre los cuidados esenciales para mantener a tu gatito sano y feliz. Desde vacunas y desparasitaciones hasta el cuidado de su peso, pelaje y salud dental, este artículo te ofrece consejos prácticos para el bienestar de tu felino.',
    imageUrl: post14,
    date: 'Sep 08, 2024',
    datetime: '2024-09-08',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post14',
      imageUrl: PD,
    },
  },
  {
    id: 15,
    title:
      'Recomendaciones para cuidar a las mascotas durante Fiestas Patrias 2024',
    href: '/blog/post15',
    description:
      'Las Fiestas Patrias son una celebración importante en Chile. Asegúrate de que tus mascotas estén seguras y felices durante estas festividades con nuestros consejos sobre alimentación, seguridad, y cuidado en general.',
    imageUrl: post15,
    date: 'Sep 12, 2024',
    datetime: '2024-09-12',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post15',
      imageUrl: PD,
    },
  },
  {
    id: 16,
    title:
      'Por qué perfumar a tu mascota no es una buena idea (Y qué debes hacer en su lugar)',
    href: '/blog/post16',
    description:
      'Aunque algunos ven esto como una forma de consentir a sus mascotas, expertos advierten sobre los riesgos de perfumar a los perros. En este artículo, exploramos por qué es mejor evitar perfumes en perros y gatos y cómo demostrarles nuestro respeto cuidando su bienestar natural.',
    imageUrl: post16,
    date: 'Sep 16, 2024',
    datetime: '2024-09-16',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post16',
      imageUrl: PD,
    },
  },
  {
    id: 17,
    title: 'Consejos para mantener a tu peludo feliz y saludable',
    href: '/blog/post17',
    description:
      'Con la llegada del calor, es esencial cuidar a nuestras mascotas para garantizar su bienestar. PetsOkey recomienda mantener hidratación, protección solar, evitar paseos en horas calurosas, controlar parásitos, y prevenir golpes de calor. Estos cuidados permitirán que tu mascota disfrute de primavera y verano de manera segura y saludable.',
    imageUrl: post17,
    date: 'Oct 12, 2024',
    datetime: '2024-10-12',
    category: { title: 'Consejos', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post17',
      imageUrl: PD,
    },
  },
  {
    id: 18,
    title:
      'Descubre por qué los paseos de tu mejor amigo son imprescindibles para su salud y bienestar (y el tuyo)',
    href: '/blog/post18',
    description:
      'Los paseos de perros son fundamentales para su salud física y mental, brindándoles ejercicio, socialización, y estímulos ambientales. Además, reducen el estrés, fortalecen el vínculo con el dueño y benefician la salud física y emocional de ambos.',
    imageUrl: post18,
    date: 'Oct 15, 2024',
    datetime: '2024-10-15',
    category: { title: 'Bienestar Animal', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post18',
      imageUrl: PD,
    },
  },
  {
    id: 19,
    title:
      'Cuidados para tu Perro en Halloween: Seguridad y Diversión Sin Riesgos',
    href: '/blog/post19',
    description:
      'Para Halloween, protege a tu perro de los dulces tóxicos, asegúralo en un espacio tranquilo, evita decoraciones peligrosas y usa identificación actualizada. Si decides disfrazarlo, elige opciones cómodas y observa su reacción para evitar incomodidades.',
    imageUrl: post19,
    date: 'Oct 20, 2024',
    datetime: '2024-10-20',
    category: { title: 'Cuidados', href: '' },
    author: {
      name: 'Paola Donoso',
      role: 'Periodista',
      href: '/blog/post19',
      imageUrl: PD,
    },
  },
]

export default function Blog() {
  return (
    <div className="bg-white py-12 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts
            .slice()
            .reverse()
            .map((post) => (
              <article
                key={post.id}
                className="flex flex-col items-start justify-between"
              >
                <a href={post.href} className="relative w-full">
                  <img
                    alt=""
                    src={post.imageUrl}
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </a>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <a
                      href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </a>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                      {post.description}
                    </p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <img
                      alt=""
                      src={post.author.imageUrl}
                      className="h-10 w-10 rounded-full bg-gray-100"
                    />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a href={post.author.href}>
                          <span className="absolute inset-0" />
                          {post.author.name}
                        </a>
                      </p>
                      <p className="text-gray-600">{post.author.role}</p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
        </div>
      </div>
    </div>
  )
}
