import PropTypes from 'prop-types'
import profilePhoto from '@assets/images/others/profile.png'
import { FaStar } from 'react-icons/fa'
import { formatDateReview } from '@utils/formatFunctions'
import StarsRating from '@common/utils/StarsRating'

const ReviewList = ({ isOpen, onClose, reviews }) => {
  const modalClass = isOpen ? 'flex' : 'hidden'

  if (!reviews || reviews.length === 0)
    return (
      <div
        className={`fixed inset-0 flex items-center justify-center bg-black/80 ${modalClass} z-50`}
      >
        <div className="relative z-10 w-4/5 overflow-y-auto rounded-xl bg-white p-10 shadow-lg lg:w-3/5 xl:w-1/2">
          <button
            onClick={onClose}
            className="absolute right-0 top-0 p-4 text-3xl text-grey-base focus:outline-none"
          >
            &times;
          </button>
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-bold text-slate-800">Reseñas</h2>
            <div className="flex flex-col items-center justify-center gap-4">
              <div className="flex items-center gap-2">
                <FaStar className="text-4xl text-yellow-400" />
                <p className="text-lg font-bold text-slate-800">
                  No hay reseñas disponibles en este momento.
                </p>
              </div>
              <p className="text-center text-slate-400">
                Parece que aún nadie ha realizado reseñas sobre este Sitter.
                ¡Pero no te preocupes! Si tienes alguna pregunta, no dudes en
                ponerte en contacto con nosotros.
              </p>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black/80 ${modalClass} z-50`}
    >
      <div className="relative z-10 m-4 h-[60%] overflow-y-auto rounded-xl bg-white px-4 pt-10 shadow-lg lg:w-3/5">
        <button
          onClick={onClose}
          className="absolute right-0 top-0 p-4 text-3xl text-grey-base focus:outline-none"
        >
          &times;
        </button>

        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-bold text-slate-800">Reseñas</h2>
          <div className="flex flex-col gap-4">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="mb-2 flex flex-col gap-4 rounded-lg p-2 hover:bg-gray-100"
              >
                <div className="flex items-center">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={
                      review.user.photoUrl ? review.user.photoUrl : profilePhoto
                    }
                    alt="Profile photo"
                  />
                  <div className="flex flex-col pl-4">
                    <div className="txt-sm font-bold text-slate-800 sm:text-lg">
                      {`${review.user.name} ${review.user.lastName}`}
                    </div>
                    <div className="sm:text-md text-xs font-bold text-gray-500">
                      {formatDateReview(review.createdAt)}
                    </div>
                  </div>
                  <div className="ml-auto">
                    <StarsRating
                      count={5}
                      value={review.stars}
                      size={16}
                      color={'#D9D9D9'}
                      activeColor="#ffd700"
                      isHalf={true}
                    />
                  </div>
                </div>
                <div className="bg-grey-slate-10 h-full w-full rounded-md p-2 text-sm text-grey-base">
                  {review.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

ReviewList.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  reviews: PropTypes.array,
}

export default ReviewList
