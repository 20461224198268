function formatDate(dateString) {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const formattedDate = date.toLocaleDateString('es-CL', options)
  if (formattedDate === 'Invalid Date') {
    return 'Fecha no disponible'
  }
  return formattedDate
}

function longDateFormat(dateString) {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const formattedDate = date.toLocaleDateString('es-CL', options)
  if (formattedDate === 'Invalid Date') {
    return 'Fecha no disponible'
  }
  return formattedDate
}

function formatPrice(price) {
  return `$ ${price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
}

function formatId(id) {
  return id?.slice(0, 8)
}

function formatHour(hour) {
  try {
    if (hour < 1 || hour > 24) {
      throw new Error('La hora debe estar entre 1 y 24')
    }
    const date = new Date()
    date.setHours(hour, 0, 0)
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
  } catch (error) {
    return null
  }
}

function formatDateReview(dateString) {
  const date = new Date(dateString)
  const today = new Date()
  const diffTime = Math.abs(today - date)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  const diffWeeks = Math.ceil(diffDays / 7)
  const diffMonths =
    today.getMonth() -
    date.getMonth() +
    12 * (today.getFullYear() - date.getFullYear())

  if (diffDays === 0) {
    return 'Hoy'
  } else if (diffDays === 1) {
    return 'Ayer'
  } else if (diffDays < 7) {
    return `${diffDays} días`
  } else if (diffWeeks < 4) {
    return `${diffWeeks} semanas`
  } else if (diffMonths < 12) {
    return `${diffMonths} meses`
  } else {
    return date.toLocaleDateString()
  }
}

function formatStatus(status) {
  switch (status) {
    case 'pending':
      return 'Pendiente'
    case 'accepted':
      return 'Aceptado'
    case 'rejected':
      return 'Rechazado'
    case 'finished':
      return 'Finalizado'
    case 'cancelled':
      return 'Cancelado'
    case 'waiting':
      return 'En espera'
    default:
      return 'Estado no disponible'
  }
}

function formatType(type) {
  switch (type) {
    case 'dogWalking':
      return 'Paseo'
    case 'visit':
      return 'Visita'
    case 'dayCare':
      return 'Alojamiento'
    default:
      return 'Tipo no disponible'
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export {
  formatDate,
  formatPrice,
  formatId,
  longDateFormat,
  formatHour,
  formatDateReview,
  formatStatus,
  formatType,
  classNames,
}
