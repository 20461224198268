import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses(state, action) {
      return action.payload
    },
    saveAddress(state, action) {
      return state.push(action.payload)
    },
  },
})

export const { setAddresses, saveAddress } = addressSlice.actions
export default addressSlice.reducer
