import PropTypes from 'prop-types'
import CountUp from 'react-countup'

function DeniedCard({ deniedPercentage, deniedColor }) {
  return (
    <div className="flex h-min flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between space-x-4">
        <div
          className={`${
            deniedColor == 0 ? 'bg-red-base' : 'bg-red-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            deniedColor == 1 ? 'bg-yellow-base' : 'bg-yellow-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
        <div
          className={`${
            deniedColor == 2 ? 'bg-green-base' : 'bg-green-100 bg-opacity-80'
          } h-3 w-1/3 rounded-sm`}
        ></div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-lg">Porcentaje de rechazo</p>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-end gap-1 text-center font-mont-semi text-slate-900">
            <CountUp
              end={deniedPercentage}
              decimals={1}
              duration={3}
              className="text-5xl"
            />
            <p className="text-lg">%</p>
          </div>
          <p className="flex items-end text-grey-sec">servicios rechazados</p>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-sm">
        <p>
          {deniedColor == 0
            ? '¡Cuidado!'
            : deniedColor == 1
              ? '¡Mejoremos!'
              : '¡Bien Hecho!'}
        </p>
        <p>
          Tu puntuación de rechazos es{' '}
          <span className="font-mont-semi">
            {deniedColor == 0
              ? 'roja.'
              : deniedColor == 1
                ? 'amarilla.'
                : 'verde.'}{' '}
          </span>
          {deniedColor == 0
            ? '¡Evita rechazar solicitudes de ordenes de trabajo!'
            : deniedColor == 1
              ? '¡Mantén tu calendario actualizado para que no te lleguen solicitudes fuera de tus horarios!'
              : '¡Sigue así, estas haciendo un gran trabajo!'}
        </p>
      </div>
    </div>
  )
}

DeniedCard.propTypes = {
  deniedPercentage: PropTypes.number,
  deniedColor: PropTypes.number.isRequired,
}

export default DeniedCard
