import SignInForm from '@common/forms/SignInForm'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function SignIn() {
  return (
    <>
      <ToastContainer position='top-center'/>
      <div className="container mx-auto my-16 max-w-96 rounded-xl bg-white p-4 shadow-lg">
        <SignInForm />
      </div>
    </>
  )
}
