import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SignUpForm from '@common/forms/SignUpForm'

function SignUp() {
  return (
    <>
      <ToastContainer position="top-center" />
      <div className="container mx-auto my-16 max-w-96 rounded-xl bg-white p-4 shadow-lg">
        <SignUpForm />
      </div>
    </>
  )
}

export default SignUp
