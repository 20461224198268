import { useLocation } from 'react-router-dom'
import Voucher from './components/payment/Voucher'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function CommitOcTransaction() {
  const location = useLocation()
  const tbkInfo = location.state.transbank

  return (
    <div className="w-full p-4 md:mx-auto md:my-8 md:max-w-md">
      <ToastContainer />
      <Voucher tbkInfo={tbkInfo} />
    </div>
  )
}

export default CommitOcTransaction
