import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { FaSearch, FaHeart, FaPaw } from 'react-icons/fa'
import { FaNewspaper } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import navbarLogo from '@assets/images/logos/navbarLogo.png'
import { useSelector } from 'react-redux'
import profilePhoto from '@assets/images/others/profile.png'
import NotificationDropdown from '@common/notification/NotificationDropdown'
import { AuthContext } from '@common/context/AuthContext'
import { useContext } from 'react'
import { useFilterSitters } from '@services/availability'
import { classNames } from '@utils/formatFunctions'
import useFilter from '@zustand/filter'

export default function Navbar() {
  const user = useSelector((state) => state.user)
  const { getAuthToken, removeAuthToken } = useContext(AuthContext)
  const { mutate } = useFilterSitters()
  const {selectedAddress} = useFilter()

  const handleFilterSitters = async () => {
    const body = {
      type: null,
      address: selectedAddress?.geometry.location,
      dates: [],
    }
    mutate(body)
  }

  return (
    <Disclosure as="nav" className="z-50 bg-white shadow-2xl">
      {({ open }) => (
        <>
          <div className="mx-auto w-[90%] lg:w-[85%] xl:w-[75%]">
            <div className="flex h-20 justify-between">
              <div className="flex flex-shrink-0 items-center">
                <Link className="flex flex-row items-center gap-4" to="/">
                  <img
                    className="h-10 w-auto"
                    src={navbarLogo}
                    alt="Pets-Okey"
                  />
                  <h1 className="text-xl text-blue-ter">Pets-Okey</h1>
                </Link>
              </div>

              <div className="hidden space-x-2 font-mont-semi sm:ml-12 sm:flex lg:space-x-6">
                <button
                  onClick={() => handleFilterSitters()}
                  className="inline-flex items-center gap-2 border-b-2 border-transparent px-1 pt-1 text-[12px] font-medium text-grey-base hover:text-blue-ter"
                >
                  <FaSearch size={14} />
                  Buscar Sitters
                </button>
                <Link
                  to="/sitter-request"
                  className="inline-flex items-center gap-2 border-b-2 border-transparent px-1 pt-1 text-[12px] font-medium text-grey-base hover:text-blue-ter"
                >
                  <FaHeart size={14} />
                  Únete Como Sitter
                </Link>
                <Link
                  to="/pet-education"
                  className="inline-flex items-center gap-2 border-b-2 border-transparent px-1 pt-1 text-[12px] font-medium text-grey-base hover:text-blue-ter"
                >
                  <FaPaw size={14} />
                  Educación Canina
                </Link>
                <Link
                  to="/blog"
                  className="inline-flex items-center gap-2 border-b-2 border-transparent px-1 pt-1 text-[12px] font-medium text-grey-base hover:text-blue-ter"
                >
                  <FaNewspaper size={14} />
                  Blog
                </Link>
              </div>

              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                {getAuthToken() && user.id ? (
                  <Menu as="div" className="relative ml-3 flex">
                    <div className="flex">
                      <NotificationDropdown />
                    </div>
                    <div>
                      <Menu.Button className="relative flex items-center justify-center gap-4 rounded-lg px-4 py-2 text-xs hover:bg-white-base lg:text-sm">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.photoUrl ? user.photoUrl : profilePhoto}
                          alt="Foto de perfil"
                        />
                        <p className="font-mont-semi text-sm capitalize text-grey-base">
                          {user.name} {user.lastName}
                        </p>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-lg bg-white-base p-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="flex items-center py-2">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full"
                              src={user.photoUrl ? user.photoUrl : profilePhoto}
                              alt="Foto de perfil"
                            />
                          </div>
                          <div className="ml-3">
                            <div className="font-mont-semi text-[0.75rem]">
                              {user.name} {user.lastName}
                            </div>
                            <div className="text-[0.5rem] font-medium text-gray-500">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        <div className="divider m-1"></div>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <Link
                                to={`/user/${user.id}`}
                                className={classNames(
                                  active ? 'rounded-md bg-yellow-base' : '',
                                  'block px-4 py-2 text-sm text-slate-700',
                                )}
                              >
                                Mi cuenta
                              </Link>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              {user.isAdmin ? (
                                <Link
                                  to={'/admin'}
                                  className={classNames(
                                    active ? 'rounded-md bg-yellow-base' : '',
                                    'block px-4 py-2 text-sm text-slate-700',
                                  )}
                                >
                                  Centro administrador
                                </Link>
                              ) : null}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <Link
                                to={'/user/service-records'}
                                className={classNames(
                                  active ? 'rounded-md bg-yellow-base' : '',
                                  'block px-4 py-2 text-sm text-slate-700',
                                )}
                              >
                                Mis Reservas
                              </Link>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              {user.isSitter && (
                                <Link
                                  to={'/sitter/service-records'}
                                  className={classNames(
                                    active ? 'rounded-md bg-yellow-base' : '',
                                    'block px-4 py-2 text-sm text-slate-700',
                                  )}
                                >
                                  Mis Servicios
                                </Link>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              {user.isSitter && (
                                <Link
                                  to={'/sitter/service-requests'}
                                  className={classNames(
                                    active ? 'rounded-md bg-yellow-base' : '',
                                    'block px-4 py-2 text-sm text-slate-700',
                                  )}
                                >
                                  Solicitudes de servicios
                                </Link>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              {user.isSitter && (
                                <Link
                                  to={'/sitter/offer-services'}
                                  className={classNames(
                                    active ? 'rounded-md bg-yellow-base' : '',
                                    'block px-4 py-2 text-sm text-slate-700',
                                  )}
                                >
                                  Ofrecer Servicios
                                </Link>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              {user.isSitter && (
                                <Link
                                  to={'/sitter/stats'}
                                  className={classNames(
                                    active ? 'rounded-lg bg-yellow-base' : '',
                                    'block px-4 py-2 text-sm text-slate-700',
                                  )}
                                >
                                  Mis estadísticas
                                </Link>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                        <div className="divider m-0"></div>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => removeAuthToken()}
                              className={classNames(
                                active
                                  ? 'rounded-md bg-red-base text-white-base'
                                  : '',
                                'mb-1 block w-full px-4 py-2 text-left text-sm text-gray-700',
                              )}
                            >
                              Cerrar sesión
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Menu as="div" className="relative ml-3 font-mont-semi">
                    <div className="flex flex-row gap-2">
                      <Menu.Button className="btn btn-ghost relative inline-flex items-center justify-center rounded-full px-4 py-0.5 text-[14px] font-medium text-grey-base hover:text-blue-ter">
                        <Link to="/signin">Inicia sesión</Link>
                      </Menu.Button>
                      <Menu.Button className="btn btn-ghost relative inline-flex items-center justify-center rounded-full bg-blue-ter px-5 py-1.5 text-[14px] font-medium text-white hover:bg-blue-sec">
                        <Link to="/signup">Regístrate</Link>
                      </Menu.Button>
                    </div>
                  </Menu>
                )}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                {getAuthToken() && user.id && (
                  <div className="flex pr-2">
                    <NotificationDropdown />
                  </div>
                )}
                <Disclosure.Button className="btn btn-ghost relative inline-flex items-center justify-center rounded-md p-2 text-grey-base focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-base">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="bg-white-base sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as="button"
                onClick={() => handleFilterSitters()}
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
              >
                Buscar Sitters
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/sitter-request"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
              >
                Únete Como Sitter
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/pet-education"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
              >
                Educación Canina
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/blog"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
              >
                Blog
              </Disclosure.Button>
            </div>
            {getAuthToken() && user.id ? (
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center border-l-4 border-transparent px-4 py-2 text-grey-base">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.photoUrl ? user.photoUrl : profilePhoto}
                      alt="Foto de perfil"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user.name} {user.lastName}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href={`/user/${user.id}`}
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                  >
                    Mi Cuenta
                  </Disclosure.Button>
                  {user.isAdmin ? (
                    <Disclosure.Button
                      as="a"
                      href="/admin"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                    >
                      Centro administrador
                    </Disclosure.Button>
                  ) : null}
                  <Disclosure.Button
                    as="a"
                    href="/user/service-records"
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                  >
                    Mis Reservas
                  </Disclosure.Button>

                  {user.isSitter ? (
                    <Disclosure.Button
                      as="a"
                      href="/sitter/service-records"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                    >
                      Mis Servicios
                    </Disclosure.Button>
                  ) : null}
                  {user.isSitter ? (
                    <Disclosure.Button
                      as="a"
                      href="/sitter/service-requests"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                    >
                      Solicitudes de servicios
                    </Disclosure.Button>
                  ) : null}
                  {user.isSitter ? (
                    <Disclosure.Button
                      as="a"
                      href="/sitter/offer-services"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                    >
                      Ofrecer Servicios
                    </Disclosure.Button>
                  ) : null}
                  {user.isSitter ? (
                    <Disclosure.Button
                      as="a"
                      href={'/sitter/stats'}
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                    >
                      Mis estadísticas
                    </Disclosure.Button>
                  ) : null}
                  <Disclosure.Button
                    as="button"
                    onClick={() => removeAuthToken()}
                    className="block w-full border-l-4 border-transparent py-2 pl-3 pr-4 text-start text-base font-medium text-grey-base hover:border-red-base hover:bg-white hover:text-slate-700"
                  >
                    Cerrar sesión
                  </Disclosure.Button>
                </div>
              </div>
            ) : (
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href="/signin"
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                  >
                    Inicia sesión
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/signup"
                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-grey-base hover:border-yellow-base hover:bg-white hover:text-slate-700"
                  >
                    Regístrate
                  </Disclosure.Button>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
