import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useMediaQuery } from 'react-responsive'
import { getData } from '@services/requests'
import { FaInbox, FaCalendar } from 'react-icons/fa'
import RequestCard from './components/service_requests/RequestCard'
import RequestsCalendar from './components/service_requests/RequestsCalendar'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import { useRef, useEffect, useState } from 'react'

function ServiceRequests() {
  const user = useSelector((state) => state.user)
  const calendarRef = useRef(null)
  const [calendarHeight, setCalendarHeight] = useState(0)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const [selectedDate, setSelectedDate] = useState(null)
  const [filteredData, setFilteredData] = useState({})

  const { isPending, isError, isSuccess, error, data } = useQuery({
    queryKey: ['requestedServices'],
    queryFn: () => getData(`service-record/sitter-service-requests/${user.id}`),
  })

  useEffect(() => {
    if (isSuccess) {
      const aux = selectedDate
        ? { [selectedDate]: data[selectedDate] || [] }
        : data
      setFilteredData(aux)
    }
  }, [data, selectedDate, isSuccess])

  useEffect(() => {
    if (calendarRef.current) {
      setCalendarHeight(calendarRef.current.offsetHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, calendarRef.current])

  if (isPending) return <Loading />

  if (isError) return <Error error={error.message} />

  return (
    <div className="m-4 space-y-4 md:min-h-screen">
      <div className="flex flex-col gap-4 sm:flex-row">
        <div
          className="flex flex-col gap-2 overflow-y-auto sm:w-[40%]"
          style={{ height: isSmallScreen ? '80vh' : calendarHeight }}
        >
          {Object.keys(filteredData).length === 0 ? (
            <div className="flex h-full flex-col items-center justify-center gap-1 text-grey-soft">
              <FaInbox className="text-6xl" />
              <p className="text-lg">No tienes solicitudes pendientes</p>
            </div>
          ) : (
            Object.keys(filteredData).map((date) => (
              <div key={date} className="flex h-full flex-col gap-2">
                {!data[date]?.length ? (
                  <div className="flex h-full flex-col items-center justify-center gap-2 text-grey-soft">
                    <FaCalendar className="text-6xl" />
                    <p className="text-lg">No hay solicitudes para este día</p>
                  </div>
                ) : (
                  data[date]?.map((service) => (
                    <RequestCard
                      key={service.record.id}
                      record={service.record}
                      user={service.user}
                      address={service.address}
                      pets={service.pets}
                    />
                  ))
                )}
              </div>
            ))
          )}
        </div>
        <div className="hidden sm:flex sm:w-[60%]">
          <RequestsCalendar
            calendarRef={calendarRef}
            data={data}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
      </div>
    </div>
  )
}

export default ServiceRequests
