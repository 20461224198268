/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import TypeInput from './TypeInput'
import PetInput from './PetInput'
import AddressInput from './AddressInput'
import CalendarInput from './CalendarInput'
import MoreInfoInput from './MoreInfoInput'
import PaymentButton from './PaymentButton'
import MethodSelection from '../payment/MethodSelection'
import { useSessionStorage } from '@common/context/sessionStorageContext'
import { useCalculatePrice } from '@services/payment'

function ServiceForm({ sitter, isSummary }) {
  const user = useSelector((state) => state.user)
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const [paymentButtonVisible, setPaymentButtonVisible] = useState(true)
  const [price, setPrice] = useState(0)
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState(0)
  const [step, setStep] = useState(0)
  const [selectedType, setSelectedType] = useState(null)
  const [serviceDates, setServiceDates] = useState([])
  const [selectedPets, setSelectedPets] = useState([])
  const [userAddress, setUserAddress] = useState(null)
  const [selectedMoreInfo, setSelectedMoreInfo] = useState(null)
  const formRef = useRef(null)
  const [openSelectPaymentMethod, setOpenSelectPaymentMethod] = useState(false)
  const { mutate: calculatePrice } = useCalculatePrice()
  // eslint-disable-next-line no-unused-vars
  const [serviceRecordInfo, setServiceRecordInfo] = useSessionStorage(
    'serviceRecordInfo',
    null,
  )

  const handleSubmit = async () => {
    setStep(6)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const dates = serviceDates.map((date) => date.date)
    const serviceRecord = {
      type: selectedType,
      dateArray: dates,
      price: price,
      priceBeforeDiscount: priceBeforeDiscount,
      petsId: selectedPets,
      addressId: userAddress.id,
      message: selectedMoreInfo,
      sitterId: sitter.id,
      userId: user.id,
    }
    setServiceRecordInfo(serviceRecord)
    setOpenSelectPaymentMethod(true)
    setPaymentButtonVisible(false)
    document.body.classList.add('overflow-hidden')
  }

  const onModalClose = () => {
    setOpenSelectPaymentMethod(false)
    setPaymentButtonVisible(true)
    document.body.classList.remove('overflow-hidden')
  }

  useEffect(() => {
    if (!isSummary) {
      const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      scrollToRef(formRef)
    }
  }, [step, isMediumScreen])

  useEffect(() => {
    if (isSummary) {
      setPaymentButtonVisible(false)
    } else {
      setPaymentButtonVisible(true)
    }
  }, [isSummary])

  useEffect(() => {
    if (selectedType && serviceDates.length > 0 && selectedPets.length > 0) {
      const dates = serviceDates.map((date) => date.date)
      calculatePrice(
        {
          sitterId: sitter.id,
          userId: user.id,
          type: selectedType,
          dateArray: dates,
          petsId: selectedPets,
        },
        {
          onSuccess: (data) => {
            setPrice(data.price)
            setPriceBeforeDiscount(data.priceBeforeDiscount)
          },
        },
      )
    }
  }, [selectedType, serviceDates, selectedPets])

  useEffect(() => {
    setServiceDates([])
  }, [selectedType])

  return (
    <>
      <MethodSelection
        isOpen={openSelectPaymentMethod}
        onOpen={() => setOpenSelectPaymentMethod(true)}
        onClose={onModalClose}
      />

      <div className="container my-4 flex h-full flex-col items-center justify-center gap-4 md:m-0">
        <h1 className="font-tex-bold text-3xl text-slate-800 md:text-4xl">
          Contrata un servicio
        </h1>
        <div
          ref={formRef}
          className="flex w-full flex-col items-center justify-between md:h-[80%] md:overflow-y-auto"
        >
          <TypeInput
            prices={sitter.prices}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            step={step}
            setStep={setStep}
          />
          <CalendarInput
            selectedType={selectedType}
            serviceDates={serviceDates}
            setServiceDates={setServiceDates}
            step={step}
            setStep={setStep}
            services={sitter.services}
          />
          <PetInput
            selectedPets={selectedPets}
            setSelectedPets={setSelectedPets}
            step={step}
            setStep={setStep}
          />
          <AddressInput
            selectedType={selectedType}
            userAddress={userAddress}
            setUserAddress={setUserAddress}
            step={step}
            setStep={setStep}
          />
          <MoreInfoInput
            sitter={sitter}
            selectedMoreInfo={selectedMoreInfo}
            setSelectedMoreInfo={setSelectedMoreInfo}
            step={step}
            setStep={setStep}
          />
        </div>

        <PaymentButton
          handleSubmit={handleSubmit}
          disabled={
            !selectedType ||
            serviceDates.length === 0 ||
            selectedPets.length === 0 ||
            !userAddress
          }
          price={price}
          visible={paymentButtonVisible}
          pets={selectedPets}
          dates={serviceDates}
          type={selectedType}
          isSummary={isSummary}
        />
      </div>
    </>
  )
}

ServiceForm.propTypes = {
  sitter: PropTypes.object.isRequired,
  isSummary: PropTypes.bool,
}

export default ServiceForm
