'use client'

import { useState } from 'react'
import { useCalculatePrice } from '@services/payment'
import { useSessionStorage } from '@common/context/sessionStorageContext'
import { toast } from 'react-toastify'

export default function DiscountButton() {
  const [isExpanded, setIsExpanded] = useState(false)
  const [discountCode, setDiscountCode] = useState('')
  const { mutate: calculatePrice } = useCalculatePrice()
  const [serviceRecordInfo, setServiceRecordInfo] = useSessionStorage(
    'serviceRecordInfo',
    null,
  )

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    calculatePrice(
      {
        ...serviceRecordInfo,
        discountCode,
      },
      {
        onSuccess: (data) => {
          setServiceRecordInfo({
            ...serviceRecordInfo,
            price: data.price,
            priceBeforeDiscount: data.priceBeforeDiscount,
            discountCode,
          })
          toast.success('Descuento aplicado correctamente')
        },
      },
    )
    setDiscountCode('')
    setIsExpanded(false)
  }

  return (
    <div className="mt-4 flex w-full flex-col items-center gap-4">
      <button
        type="button"
        className="cursor-pointer border-none bg-transparent p-0 font-normal text-grey-sec underline"
        onClick={handleToggle}
      >
        Usar código de descuento
      </button>
      {isExpanded && (
        <form onSubmit={handleSubmit} className="mt-2 w-full space-y-4">
          <input
            type="text"
            placeholder="Ingresa tu código de descuento"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          />
          <button
            type="button"
            onClick={handleSubmit}
            className="w-full rounded-md bg-blue-ter px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-base focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Aplicar descuento
          </button>
        </form>
      )}
    </div>
  )
}
