export const WPP = '+56944018066'
export const PAGE_LINK = 'https://www.pets-okey.com/'
export const EMAIL = 'contacto@pets-okey.com'
export const INSTAGRAM = 'https://www.instagram.com/pets.okey/'
export const YOUTUBE = 'https://www.youtube.com/@Pets-Okey'
export const FACEBOOK = 'https://www.facebook.com/PetsOkey'
export const FORM_SERVICE =
  'https://docs.google.com/forms/d/e/1FAIpQLSdtyt62mfSXnM0uX-cPi9Dl82phj52Zko930Y9mgvOxaOm6Jg/viewform?usp=sf_link'
export const FORM_SITTER =
  'https://docs.google.com/forms/d/e/1FAIpQLScZ9bAQ1hhXPZcnHOR_Bd1C-MOb0hQsm3Azf50tQXfKpR9XbA/viewform?usp=sf_link'
export const BCI_SEGUROS =
  'https://cotizadormascotas.bciseguros.cl/inicio/6503939D9D0E649861EA256E8B8474C9'
export const CAT_BREEDS = [
  'Persa',
  'Siamés',
  'Maine Coon',
  'Bengalí',
  'Gato doméstico de pelo corto',
  'Ragdoll',
  'Abisinio',
  'Sphynx',
  'British Shorthair',
  'Scottish Fold',
]
export const TYPES = ['Perro', 'Gato', 'Otro']
export const BANKS = [
  'Banco BCI - MACH',
  'Banco Bice',
  'Banco Consorcio',
  'Banco de Chile - Edwards - Citi',
  'Banco Falabella',
  'Banco Internacional',
  'Banco Ripley',
  'Banco Santander Santiago',
  'Banco Security',
  'BancoEstado',
  'BBVA',
  'COOPEUCH',
  'CorpBanca',
  'Global66',
  'HSBC Bank Chile',
  'Itau Chile',
  'Mercado Pago',
  'Prepago Los Heroes',
  'Scotiabank',
  'TAPP Caja los Andes',
  'Tenpo Prepago',
]
export const PET_DESCRIPTIONS = [
  { label: 'Reacción con personas', value: 'Reacción con personas' },
  { label: 'Reacción con Autos / Motos', value: 'Reacción con Autos / Motos' },
  { label: 'Reacción con otros perros', value: 'Reacción con otros perros' },
  { label: 'Alergias o enfermedades', value: 'Alergias o enfermedades' },
  { label: 'Tira la correa', value: 'Tira la correa' },
  { label: 'Agresividad', value: 'Agresividad' },
]
export const PET_WALKING_PRICES = [
  {
    id: 0,
    name: 'No ofrecer este servicio',
    value: 0,
  },
  { id: 1, name: '$ 6.000', value: 6000 },
  { id: 2, name: '$ 8.000', value: 8000 },
  { id: 3, name: '$ 10.000', value: 10000 },
]
export const PET_VISIT_PRICES = [
  {
    id: 0,
    name: 'No ofrecer este servicio',
    value: 0,
  },
  { id: 1, name: '$ 12.000', value: 12000 },
  { id: 2, name: '$ 15.000', value: 15000 },
  { id: 3, name: '$ 18.000', value: 18000 },
]
export const PET_DAY_CARE_PRICES = [
  {
    id: 0,
    name: 'No ofrecer este servicio',
    value: 0,
  },
  { id: 1, name: '$ 30.000', value: 30000 },
  { id: 2, name: '$ 35.000', value: 35000 },
  { id: 3, name: '$ 40.000', value: 40000 },
]

export const REGIONS = [
  {
    id: 0,
    value: 'Región Metropolitana',
    communes: [
      'Cerrillos',
      'Cerro Navia',
      'Conchalí',
      'El Bosque',
      'Estación Central',
      'Huechuraba',
      'Independencia',
      'La Cisterna',
      'La Florida',
      'La Granja',
      'La Pintana',
      'La Reina',
      'Las Condes',
      'Lo Barnechea',
      'Lo Espejo',
      'Lo Prado',
      'Macul',
      'Maipú',
      'Ñuñoa',
      'Pedro Aguirre Cerda',
      'Peñalolén',
      'Providencia',
      'Pudahuel',
      'Quilicura',
      'Quinta Normal',
      'Recoleta',
      'Renca',
      'Santiago',
      'San Joaquín',
      'San Miguel',
      'San Ramón',
      'Vitacura',
      'Puente Alto',
      'Pirque',
      'San José de Maipo',
      'Colina',
      'Lampa',
      'Tiltil',
      'San Bernardo',
      'Buin',
      'Calera de Tango',
      'Paine',
      'Melipilla',
      'Alhué',
      'Curacaví',
      'María Pinto',
      'San Pedro',
      'Talagante',
      'El Monte',
      'Isla de Maipo',
      'Padre Hurtado',
      'Peñaflor',
    ],
  },
  {
    id: 1,
    value: 'Arica y Parinacota',
    communes: ['Arica', 'Camarones', 'Putre', 'General Lagos'],
  },
  {
    id: 2,
    value: 'Tarapacá',
    communes: [
      'Iquique',
      'Alto Hospicio',
      'Pozo Almonte',
      'Camiña',
      'Colchane',
      'Huara',
      'Pica',
    ],
  },
  {
    id: 3,
    value: 'Antofagasta',
    communes: [
      'Antofagasta',
      'Mejillones',
      'Sierra Gorda',
      'Taltal',
      'Calama',
      'Ollagüe',
      'San Pedro de Atacama',
      'Tocopilla',
      'María Elena',
    ],
  },
  {
    id: 4,
    value: 'Atacama',
    communes: [
      'Copiapó',
      'Caldera',
      'Tierra Amarilla',
      'Chañaral',
      'Diego de Almagro',
      'Vallenar',
      'Alto del Carmen',
      'Freirina',
      'Huasco',
    ],
  },
  {
    id: 5,
    value: 'Coquimbo',
    communes: [
      'La Serena',
      'Coquimbo',
      'Andacollo',
      'La Higuera',
      'Paiguano',
      'Vicuña',
      'Illapel',
      'Canela',
      'Los Vilos',
      'Salamanca',
      'Ovalle',
      'Combarbalá',
      'Monte Patria',
      'Punitaqui',
      'Río Hurtado',
    ],
  },
  {
    id: 6,
    value: 'Valparaíso',
    communes: [
      'Valparaíso',
      'Casablanca',
      'Concón',
      'Juan Fernández',
      'Puchuncaví',
      'Quintero',
      'Viña del Mar',
      'Isla de Pascua',
      'Los Andes',
      'Calle Larga',
      'Rinconada',
      'San Esteban',
      'La Ligua',
      'Cabildo',
      'Papudo',
      'Petorca',
      'Zapallar',
      'Quillota',
      'Calera',
      'Hijuelas',
      'La Cruz',
      'Nogales',
      'San Antonio',
      'Algarrobo',
      'Cartagena',
      'El Quisco',
      'El Tabo',
      'Santo Domingo',
      'San Felipe',
      'Catemu',
      'Llaillay',
      'Panquehue',
      'Putaendo',
      'Santa María',
      'Quilpué',
      'Limache',
      'Olmué',
      'Villa Alemana',
    ],
  },
  {
    id: 7,
    value: 'Región del Libertador Gral. Bernardo O’Higgins',
    communes: [
      'Rancagua',
      'Codegua',
      'Coinco',
      'Coltauco',
      'Doñihue',
      'Graneros',
      'Las Cabras',
      'Machalí',
      'Malloa',
      'Mostazal',
      'Olivar',
      'Peumo',
      'Pichidegua',
      'Quinta de Tilcoco',
      'Rengo',
      'Requínoa',
      'San Vicente',
      'Pichilemu',
      'La Estrella',
      'Litueche',
      'Marchihue',
      'Navidad',
      'Paredones',
      'San Fernando',
      'Chépica',
      'Chimbarongo',
      'Lolol',
      'Nancagua',
      'Palmilla',
      'Peralillo',
      'Placilla',
      'Pumanque',
      'Santa Cruz',
    ],
  },
  {
    id: 8,
    value: 'Región del Maule',
    communes: [
      'Talca',
      'Constitución',
      'Curepto',
      'Empedrado',
      'Maule',
      'Pelarco',
      'Pencahue',
      'Río Claro',
      'San Clemente',
      'San Rafael',
      'Cauquenes',
      'Chanco',
      'Pelluhue',
      'Curicó',
      'Hualañé',
      'Licantén',
      'Molina',
      'Rauco',
      'Romeral',
      'Sagrada Familia',
      'Teno',
      'Vichuquén',
      'Linares',
      'Colbún',
      'Longaví',
      'Parral',
      'Retiro',
      'San Javier',
      'Villa Alegre',
      'Yerbas Buenas',
    ],
  },
  {
    id: 9,
    value: 'Región de Ñuble',
    communes: [
      'Cobquecura',
      'Coelemu',
      'Ninhue',
      'Portezuelo',
      'Quirihue',
      'Ránquil',
      'Treguaco',
      'Bulnes',
      'Chillán Viejo',
      'Chillán',
      'El Carmen',
      'Pemuco',
      'Pinto',
      'Quillón',
      'San Ignacio',
      'Yungay',
      'Coihueco',
      'Ñiquén',
      'San Carlos',
      'San Fabián',
      'San Nicolás',
    ],
  },
  {
    id: 10,
    value: 'Región del Biobío',
    communes: [
      'Concepción',
      'Coronel',
      'Chiguayante',
      'Florida',
      'Hualqui',
      'Lota',
      'Penco',
      'San Pedro de la Paz',
      'Santa Juana',
      'Talcahuano',
      'Tomé',
      'Hualpén',
      'Lebu',
      'Arauco',
      'Cañete',
      'Contulmo',
      'Curanilahue',
      'Los Álamos',
      'Tirúa',
      'Los Ángeles',
      'Antuco',
      'Cabrero',
      'Laja',
      'Mulchén',
      'Nacimiento',
      'Negrete',
      'Quilaco',
      'Quilleco',
      'San Rosendo',
      'Santa Bárbara',
      'Tucapel',
      'Yumbel',
      'Alto Biobío',
    ],
  },
  {
    id: 11,
    value: 'Región de la Araucanía',
    communes: [
      'Temuco',
      'Carahue',
      'Cunco',
      'Curarrehue',
      'Freire',
      'Galvarino',
      'Gorbea',
      'Lautaro',
      'Loncoche',
      'Melipeuco',
      'Nueva Imperial',
      'Padre las Casas',
      'Perquenco',
      'Pitrufquén',
      'Pucón',
      'Saavedra',
      'Teodoro Schmidt',
      'Toltén',
      'Vilcún',
      'Villarrica',
      'Cholchol',
      'Angol',
      'Collipulli',
      'Curacautín',
      'Ercilla',
      'Lonquimay',
      'Los Sauces',
      'Lumaco',
      'Purén',
      'Renaico',
      'Traiguén',
      'Victoria',
    ],
  },
  {
    id: 12,
    value: 'Región de Los Ríos',
    communes: [
      'Valdivia',
      'Corral',
      'Lanco',
      'Los Lagos',
      'Máfil',
      'Mariquina',
      'Paillaco',
      'Panguipulli',
      'La Unión',
      'Futrono',
      'Lago Ranco',
      'Río Bueno',
    ],
  },
  {
    id: 13,
    value: 'Región de Los Lagos',
    communes: [
      'Puerto Montt',
      'Calbuco',
      'Cochamó',
      'Fresia',
      'Frutillar',
      'Los Muermos',
      'Llanquihue',
      'Maullín',
      'Puerto Varas',
      'Castro',
      'Ancud',
      'Chonchi',
      'Curaco de Vélez',
      'Dalcahue',
      'Puqueldón',
      'Queilén',
      'Quellón',
      'Quemchi',
      'Quinchao',
      'Osorno',
      'Puerto Octay',
      'Purranque',
      'Puyehue',
      'Río Negro',
      'San Juan de la Costa',
      'San Pablo',
      'Chaitén',
      'Futaleufú',
      'Hualaihué',
      'Palena',
    ],
  },
  {
    id: 14,
    value: 'Región Aisén del Gral. Carlos Ibáñez del Campo',
    communes: [
      'Coihaique',
      'Lago Verde',
      'Aisén',
      'Cisnes',
      'Guaitecas',
      'Cochrane',
      'O’Higgins',
      'Tortel',
      'Chile Chico',
      'Río Ibáñez',
    ],
  },
  {
    id: 15,
    value: 'Región de Magallanes y de la Antártica Chilena',
    communes: [
      'Punta Arenas',
      'Laguna Blanca',
      'Río Verde',
      'San Gregorio',
      'Cabo de Hornos (Ex Navarino)',
      'Antártica',
      'Porvenir',
      'Primavera',
      'Timaukel',
      'Natales',
      'Torres del Paine',
    ],
  },
]
export const DOG_BREEDS = [
  'Affenpinscher',
  'Afgano',
  'Akita Inu',
  'Akita Americano',
  'Alano Español',
  'American Foxhound',
  'American Pit Bull Terrier',
  'American Staffordshire Terrier',
  'Antiguo perro de muestra Danés',
  'Australian Silky Terrier',
  'Azawakh',
  'Barbet',
  'Basenji',
  'Basset Hound',
  'Basset artesiano de Normandia',
  'Basset azul de Gascuña',
  'Basset leonado de Bretaña',
  'Beagle',
  'Beagle-Harrier',
  'Bedlington Terrier',
  'Berger de Picardie',
  'Bichón Maltés',
  'Bichón Boloñés',
  'Bichón Frisé',
  'Bichón Habanero',
  'Billy',
  'Black And Tan Coonhound',
  'Bloodhound',
  'Bobtail',
  'Border Collie',
  'Border Terrier',
  'Borzoi (Galgo Ruso)',
  'Boston Terrier',
  'Boyero Australiano',
  'Boyero de Flandes',
  'Boyero de las Ardenas',
  'Braco San Germain',
  'Braco alemán de pelo corto',
  'Braco alemán de pelo duro',
  'Braco de Auvernia',
  'Braco de Weimar',
  'Braco del Ariège',
  'Braco del Bourbonnais',
  'Braco francés, tipo Gascuña, talla grande.',
  'Braco francés, tipo Pirineos talla pequeña',
  'Braco italiano',
  'Briard',
  'Brittany',
  'Broholmer',
  'Buhund Noruego',
  'Bull Terrier',
  'Bull Terrier Miniatura',
  'Bulldog',
  'Bulldog Francés',
  'Bull Mastín',
  'Bóxer',
  'Cairn Terrier',
  'Cane Corso',
  'Caniche (o Poodle)',
  'Carlino',
  'Chihuahua',
  'Chin Japonés',
  'Chow Chow',
  'Cirneco Del Etna',
  'Clumber Spaniel',
  'Cocker Americano',
  'Cocker Spaniel',
  'Collie',
  'Collie Barbudo',
  'Collie Smooth',
  'Corgi Galés Cárdigan',
  'Corgi Galés Pembroke',
  'Cotón de Tuléar',
  'Curly Coated Retriever (de pelo rizado)',
  'Dandie Dinmont Terrier',
  'Deerhound Escocés',
  'Doberman',
  'Dogo Argentino',
  'Dogo Mallorquín',
  'Dogo de Burdeos',
  'Drever',
  'Dálmata',
  'Elkhound Noruego',
  'Epagneul breton',
  'Eurasier',
  'Faraón Hound',
  'Field Spaniel',
  'Fila Brasileiro',
  'Fila de San Miguel',
  'Fox Terrier Pelo Duro',
  'Fox Terrier Toy',
  'Fox Terrier de Pelo Liso',
  'Foxhound Americano',
  'Foxhound Inglés',
  'Galgo Español',
  'Galgo Italiano',
  'Galés Terrier Galés',
  'Gascon Saintongeois',
  'Glen Of Imaal Terrier',
  'Golden Retriever',
  'Gran Basset Grifón vendeano',
  'Gran Danés',
  'Greyhound',
  'Grifón Belga',
  'Grifón de muestra eslovaco de pelo duro',
  'Grifón de Bruselas',
  'Grifón de muestra Korthals de pelo duro',
  'Grifón de muestra bohemio de pelo duro',
  'Hamiltonstovare',
  'Harrier',
  'Hokkaïdo',
  'Hovawart',
  'Husky Siberiano',
  'Irish Soft Coated Wheaten Terrier',
  'Jack Russell Terrier',
  'Jamthund',
  'Kai',
  'Karjalankarhukoira',
  'Keeshond',
  'Kerry Blue Terrier',
  'Kishu',
  'Komondor',
  'Korea Jinco Dog',
  'Kromfohrländer',
  'Kuvasz',
  'Labrador Retriever',
  'Lakeland Terrier',
  'Landseer',
  'Laïka Ruso-Europeo',
  'Laïka de Siberia oriental',
  'Lebrel Húngaro',
  'Leonberger',
  'Lhasa Apso',
  'Lundehund noruego',
  'Löwchen',
  'Malamute de Alaska',
  'Mastín',
  'Mastín Napolitano',
  'Mastín Tibetano',
  'Mudi',
  'Münsterländer grande',
  'Münsterländer pequeño',
  'Otterhound',
  'Papillon',
  'Parson Russell Terrier',
  'Pastor Alemán',
  'Pastor Australiano',
  'Pastor Belga',
  'Pastor Belga Malinois',
  'Pastor Catalán',
  'Pastor Polaco de Tierras Bajas',
  'Pastor de Anatolia',
  'Pastor de Beauce',
  'Pastor de Los Pirineos',
  'Pastor de Shetland',
  'Pastor yugoslavo de Charplanina',
  'Pequeño Basset Grifón vendeano',
  'Pequeño Brabanzón',
  'Pequeño perro holandés para la caza acuática',
  'Pequinés',
  'Perdiguero de Burgos',
  'Perdiguero de Drente',
  'Perdiguero portugués',
  'Perro Crestado Chino',
  'Perro De Agua Español',
  'Perro Esquimal Americano',
  'Perro Esquimal Canadiense',
  'Perro Lobo Checoslovaco',
  'Perro Pastor Croato',
  'Perro Pastor Mallorquín',
  'Perro Smous holandés',
  'Perro boyero de Entlebuch Entlebucher',
  'Perro de Agua Americano',
  'Perro de Aguas Portugués',
  'Perro de Canaan',
  'Perro de Castro Laboreiro',
  'Perro de Groenlandia',
  'Perro de Montaña Appenzell',
  'Perro de Montaña Bernés',
  'Perro de Montaña Gran Suizo',
  'Perro de Montaña de Los Pirineos',
  'Perro de Montaña de la Sierra de la Estrella',
  'Perro de Montaña del Atlas',
  'Perro de Pastor islandés',
  'Perro de muestra alemán de pelo cerdoso',
  'Perro de pastor bergamasco',
  'Perro de pastor de Asia Central',
  'Perro de pastor de Karst',
  'Perro de pastor de Rusia Meridional',
  'Perro de pastor del Cáucaso',
  'Perro de pastor polaco de Podhale',
  'Perro de pastor polaco de las Llanuras',
  'Perro de pastor portugués',
  'Perro lobo de Saarloos',
  'Perro pastor Maremmano-Abruzzese',
  'Perro sin Pelo del Perú',
  'Perro sin pelo Mexicano',
  'Pinscher',
  'Pinscher Miniatura',
  'Pinscher austriaco',
  'Plott Hound',
  'Podenco Canario',
  'Podenco Ibicenco',
  'Podenco Portugués',
  'Pointer',
  'Pointer Alemán de Pelo Corto',
  'Pointer Alemán de Pelo Duro',
  'Poitevin',
  'Pomerania',
  'Porcelaine',
  'Pudelpointer',
  'Puli',
  'Pumi',
  'Rafeiro del Alentejo',
  'Rastreador de Hannover',
  'Rastreador montañés de Baviera',
  'Retriever de La Bahía de Chesapeake',
  'Retriever de Nueva Escocia',
  'Retriever de Pelo Liso',
  'Rodesiano',
  'Rottweiler',
  'Sabueso Artesiano',
  'Sabueso Estirio de pelo duro',
  'Sabueso Halden',
  'Sabueso Italiano (de pelo corto)',
  'Sabueso Italiano (de pelo duro)',
  'Sabueso Schiller',
  'Sabueso alemán',
  'Sabueso austriaco negro y fuego',
  'Sabueso de Bosnia de pelo cerdoso',
  'Sabueso de Hygen',
  'Sabueso de Smaland',
  'Sabueso de Transilvania',
  'Sabueso de las Montañas de Montenegro',
  'Sabueso del Tirol',
  'Sabueso eslovaco',
  'Sabueso español',
  'Sabueso finlandés',
  'Sabueso francés tricolor',
  'Sabueso noruego',
  'Sabueso polaco',
  'Sabueso serbio',
  'Sabueso suizo',
  'Sabueso tricolor serbio',
  'Saluki (Galgo Persa)',
  'Samoyedo',
  'San Bernardo',
  'Schapendoes neerlandés',
  'Schipperke',
  'Schnauzer',
  'Schnauzer Gigante',
  'Schnauzer Miniatura',
  'Setter Gordon',
  'Setter Inglés',
  'Setter Irlandés',
  'Setter irlandés rojo',
  'Setter irlandés rojo y blanco',
  'Shar Pei',
  'Shiba',
  'ShihTzu',
  'Shikoku',
  'Silky Terrier',
  'Sloughi (galgo árabe)',
  'Spaniel Cavalier King Charles',
  'Spaniel Picardo',
  'Spaniel Tibetano',
  'Spaniel continental enano',
  'Spaniel de Agua Irlandés',
  'Spaniel de Pont-Audemer',
  'Spaniel de Sussex',
  'Spinone Italiano',
  'Spitz Alemán',
  'Spitz Finlandés',
  'Spitz Japonés',
  'Springer Spaniel Galés',
  'Springer Spaniel Inglés',
  'Spitz de Norrbotten',
  'Stabyhoun',
  'Staffordshire Bull Terrier',
  'Tchuvatch eslovaco',
  'Teckel',
  'Tejonero alpino',
  'Tejonero de Westfalia',
  'Terranova',
  'Terrier Australiano',
  'Terrier Brasileño',
  'Terrier Cesky',
  'Terrier Escocés',
  'Terrier Irlandés',
  'Terrier Sealyham Terrier',
  'Terrier Tibetano',
  'Terrier West Highland',
  'Terrier cazador alemán',
  'Terrier de Airedale',
  'Terrier de Norfolk',
  'Terrier de Norwich',
  'Terrier de Skye',
  'Terrier japonés',
  'Thai Ridgeback Dog',
  'Tosa Inu',
  'Valhund Sueco',
  'Vizsla',
  'Volpino Italiano',
  'Wetterhound',
  'Wheaten Terrier',
  'Whippet',
  'Wolfhound Irlandés',
  'Yorkshire terrier',
  'American Bully',
  'Bodeguero Andaluz',
  'Bulldog americano',
  'Dogo Canario',
  'Pastor Blanco Suizo',
  'Otro',
]

export const PET_SEX = ['Macho', 'Hembra', 'Otro']

export const CARDS = [
  {
    name: 'Visa',
    logoSrc:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png',
    className: 'w-16 h-8',
  },
  {
    name: 'AmericanExpress',
    logoSrc:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/220px-American_Express_logo_%282018%29.svg.png',
    className: 'w-12 h-12',
  },
  {
    name: 'MasterCard',
    logoSrc:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/200px-Mastercard_2019_logo.svg.png',
    className: 'w-16 h-8',
  },
  {
    name: 'Diners',
    logoSrc:
      'https://upload.wikimedia.org/wikipedia/commons/a/a6/Diners_Club_Logo3.svg',
    className: 'w-16 h-8',
  },
  {
    name: 'Magna',
    logoSrc:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Magna.png/1200px-Magna.png',
    className: 'w-16 h-10',
  },
  {
    name: 'RedCompra',
    logoSrc: 'https://iconape.com/wp-content/files/fs/209126/svg/209126.svg',
    className: 'w-20 h-10',
  },
  {
    name: 'Prepago',
    logoSrc:
      'https://www.transnews.cl/resources/images/ediciones/n_281/redcompra-prepago/Logo-Redcompra-Prepago.png',
    className: 'w-20 h-10',
  },
]
