import { MdOutlinePets } from 'react-icons/md'

const Loading = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex animate-pulse flex-col items-center space-y-2 text-gray-300">
        <div className="flex h-16 w-16 items-center justify-center rounded-full bg-grey-soft">
          <MdOutlinePets size={32} className="text-gray-400" />
        </div>
        <h1 className="text-lg font-tex text-gray-300">Cargando...</h1>
      </div>
    </div>
  )
}

export default Loading
