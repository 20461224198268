import PropTypes from 'prop-types'
import { FaExclamationTriangle } from 'react-icons/fa'
import { putData } from '@services/requests'
import Button from '@common/buttons/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ErrorNoCard from '@common/error/ErrorNoCard'

function MakeSuperSitterModal({ isOpen, onClose, sitter }) {
  const modalClass = isOpen
    ? 'fixed inset-0 flex items-center justify-center'
    : 'hidden'
  const queryClient = useQueryClient()

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateSitter,
  } = useMutation({
    mutationFn: async () => {
      const data = await putData(`user/${sitter.id}`, {
        isSuperSitter: !sitter.isSuperSitter,
      })
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminSitter', sitter.id] })
      onClose()
    },
  })

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black/80 ${modalClass} z-50`}
    >
      <div className="relative z-10 max-w-md overflow-y-auto rounded-xl bg-white p-4 shadow-lg">
        <button
          onClick={onClose}
          className="absolute right-0 top-0 p-4 text-3xl text-grey-base focus:outline-none"
        >
          &times;
        </button>
        <div className="mt-4 flex flex-col gap-8 text-center">
          {sitter.isSuperSitter ? (
            <h2 className="text-xl font-bold">
              ¿Estas seguro que quieres eliminar a {sitter.name} como super
              sitter?
            </h2>
          ) : (
            <h2 className="text-xl font-bold">
              ¿Estas seguro que quieres ascender a {sitter.name} a supper
              sitter?
            </h2>
          )}

          <div className="flex justify-center text-yellow-base">
            <FaExclamationTriangle size={52} />
          </div>
          {sitter.isSuperSitter ? (
            <p className="text-center text-sm leading-loose text-grey-sec">
              Eliminar a <strong>{sitter.name}</strong> como super sitter
              implica <strong>reducir</strong> la exposición de {sitter.name}{' '}
              frente a los clientes en comparación al resto de lo sitters.
            </p>
          ) : (
            <p className="text-center text-sm leading-loose text-grey-sec">
              Ascender a super sitter implica mejorar la{' '}
              <strong>exposición</strong> de {sitter.name} frente a los clientes
              en comparación al resto de lo sitters.
            </p>
          )}
          <Button
            onClick={() => updateSitter()}
            bgColor="yellow-base"
            textColor="white-base"
            hoverColor="opacity-80"
            text={
              sitter.isSuperSitter ? 'Eliminar Super Sitter' : 'Ascender Sitter'
            }
            circleColor="yellow-base"
            fillColor="red-soft"
            loading={updateIsPending}
          />
          {updateIsError ? <ErrorNoCard message={updateError.message} /> : null}
        </div>
      </div>
    </div>
  )
}

MakeSuperSitterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sitter: PropTypes.object.isRequired,
}

export default MakeSuperSitterModal
