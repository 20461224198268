import catHomeOne from '@assets/images/home/catHomeOne.png'
import dogHomeOne from '@assets/images/home/dogHomeOne.png'

function HomeOne() {
  return (
    <>
      <div className="flex w-[85%] flex-col items-center justify-center gap-4 sm:w-[50%] sm:gap-16">
        <h1 className="text-center font-tex-bold text-2xl leading-normal text-grey-base md:text-2xl lg:text-3xl xl:text-[2.5rem]">
          {' '}
          Los paseos y cuidados que tu{' '}
          <span className="leading-normal text-yellow-base">
            mascota necesita
          </span>
          , en las mejores manos
        </h1>
        <h2 className="w-[90%] text-center text-base font-light leading-normal text-grey-base md:text-xl lg:text-2xl xl:text-2xl">
          Encuentra cuidadores y paseadores de confianza para tu perro, gato o
          mascota en tu barrio.
        </h2>
      </div>
      <img
        src={catHomeOne}
        alt="catHomeOne"
        className="absolute left-[7%] top-[25%] hidden sm:inline-block md:h-[15rem] lg:h-[16rem]"
      />
      <img
        src={dogHomeOne}
        alt="dogHomeOne"
        className="absolute top-[50%] h-[16rem] md:right-[-32%] md:top-[10%] md:h-[22rem] lg:right-[-25%] lg:top-[12%] lg:h-[26rem] xl:right-[-12%]"
      />
    </>
  )
}

export default HomeOne
