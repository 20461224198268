import PropTypes from 'prop-types'
import { useState } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { classNames } from '@utils/formatFunctions'
import ProfilePhoto from '@assets/images/others/profile.png'
import UploadPhotosModal from './UploadPhotosModal'
function ChatHeader({ record, headerUser, chatId }) {
  const [modal, setModal] = useState(false)

  const openModal = () => {
    setModal(true)
    document.body.classList.add('overflow-hidden')
  }

  const closeModal = () => {
    setModal(false)
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <div className="flex items-center gap-4 border-b border-grey-soft p-4">
      <img
        src={headerUser.photoUrl ? headerUser.photoUrl : ProfilePhoto}
        alt="Profile Picture"
        className="h-12 w-12 rounded-full"
      />
      <div>
        <h2 className="text-lg font-semibold">
          {headerUser.name} {headerUser.lastName}
        </h2>
        <p className="text-xs text-grey-soft">+56 {headerUser.phone}</p>
      </div>
      <div className="ml-auto flex items-center gap-2">
        <button
          onClick={openModal}
          className={classNames(
            record.type == 'dogWalking' &&
              'text-yellow-sec hover:bg-yellow-100',
            record.type == 'visit' && 'text-blue-ter hover:bg-blue-100',
            record.type == 'dayCare' && 'text-purple-base hover:bg-purple-100',
            'rounded-full p-2',
          )}
        >
          <CiImageOn className="h-8 w-8" />
        </button>
      </div>
      <UploadPhotosModal
        isOpen={modal}
        onClose={closeModal}
        headerUser={headerUser}
        record={record}
        chatId={chatId}
      />
    </div>
  )
}

ChatHeader.propTypes = {
  record: PropTypes.object.isRequired,
  headerUser: PropTypes.object.isRequired,
  chatId: PropTypes.string.isRequired,
}

export default ChatHeader
