/* eslint-disable quotes */
import { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useForm } from '@tanstack/react-form'
import FormButton from '@common/buttons/FormButton'
import FormInput from '@common/inputs/FormInput'
import FormComboBox from '@common/inputs/FormComboBox'
import FormMultipleComboBox from '@common/inputs/FormMultipleComboBox'
import { useCreatePlan, useUpdatePlan } from '@services/plan'
import { getData } from '@services/requests'
import { useQuery } from '@tanstack/react-query'
import { useGetUserAddresses, useGetUserPets } from '@services/admin'
import PlanDaysHoursForm from './PlanDaysHoursForm'

const validations = {
  sitterId: yup
    .string()
    .required('Se debe incluir el ID del Sitter')
    .uuid('El ID del cuidador debe ser un UUID válido'),
  userId: yup
    .string()
    .required('Se debe incluir el ID del usuario')
    .uuid('El ID del usuario debe ser un UUID válido'),
  addressId: yup
    .string()
    .required('Se debe incluir el ID de la dirección')
    .uuid('El ID de la dirección debe ser un UUID válido'),
  type: yup
    .string()
    .required('Se debe incluir el tipo de plan')
    .oneOf(
      ['dogWalking', 'visit', 'dayCare'],
      "El tipo de plan debe ser 'dogWalking', 'visit' o 'dayCare'",
    ),
  price: yup
    .number()
    .required('Se debe incluir el precio del plan')
    .typeError('El precio del plan debe ser un número'),
  pets: yup
    .array()
    .of(yup.string().uuid('Cada mascota debe ser un UUID válido'))
    .min(1, 'Se debe incluir al menos una mascota')
    .required('Se debe incluir al menos una mascota'),
}

const PlanForm = ({ onSubmit, plan }) => {
  const defaultDaysHours =
    plan?.planDates?.length > 0
      ? plan.planDates?.map((date) => ({
          day: date.day,
          hour: date.hour,
        }))
      : [{ day: '', hour: '' }]

  const [daysHours, setDaysHours] = useState(defaultDaysHours)

  const { isPending: isPostPending, mutate: createPlan } = useCreatePlan()

  const { isPending: isPutPending, mutate: updatePlan } = useUpdatePlan()

  const form = useForm({
    onSubmit: ({ value }) => {
      const values = {
        ...value,
        daysHours,
      }
      plan ? updatePlan({ id: plan.id, body: values }) : createPlan(values)
      onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      sitterId: plan?.sitter?.id || '',
      userId: plan?.user?.id || '',
      addressId: plan?.addressId || '',
      type: plan?.type || '',
      price: plan?.price || '',
      pets: plan?.petAssociations?.map((as) => as.pet.id) || [],
    },
  })

  const { data: usersData } = useQuery({
    queryKey: ['adminUsers'],
    queryFn: () => getData('user/'),
  })

  const { data: sittersData } = useQuery({
    queryKey: ['adminSitters'],
    queryFn: () => getData('user/sitters'),
  })

  const selectedUser = form.useStore((state) => state.values.userId)

  const { data: addressesData } = useGetUserAddresses(selectedUser)
  const { data: petsData } = useGetUserPets(selectedUser)

  const usersOptions =
    usersData?.map((user) => ({
      value: user.id,
      label: `${user.name} ${user.lastName}`,
    })) || []

  const sittersOptions =
    sittersData?.map((sitter) => ({
      value: sitter.id,
      label: `${sitter.name} ${sitter.lastName}`,
    })) || []

  const serviceTypeOptions = [
    { value: 'dogWalking', label: 'Pet Walking' },
    { value: 'visit', label: 'Visit' },
    { value: 'dayCare', label: 'Day Care' },
  ]

  const addressOptions =
    addressesData?.map((address) => ({
      value: address.id,
      label: `${address.street}, ${address.commune}`,
    })) || []

  const petsOptions =
    petsData?.map((pet) => ({
      value: pet.id,
      label: `${pet.name} (${pet.breed}) `,
    })) || []

  return (
    <form
      className="flex flex-col"
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="w-full">
          <form.Field name="sitterId" clas>
            {(field) => (
              <FormComboBox
                data={sittersOptions || []}
                field={field}
                name="sitterId"
                label="Sitter"
              />
            )}
          </form.Field>
        </div>

        <div className="w-full">
          <form.Field name="userId">
            {(field) => (
              <FormComboBox
                data={usersOptions || []}
                field={field}
                name="userId"
                label="Usuario"
              />
            )}
          </form.Field>
        </div>

        <div className="w-full">
          <form.Field name="addressId">
            {(field) => (
              <FormComboBox
                data={addressOptions || []}
                field={field}
                name="addressId"
                label="Dirección"
              />
            )}
          </form.Field>
        </div>

        <div className="w-full">
          <form.Field name="type">
            {(field) => (
              <FormComboBox
                data={serviceTypeOptions || []}
                field={field}
                name="type"
                label="Tipo de servicio"
              />
            )}
          </form.Field>
        </div>

        <div className="w-full">
          <form.Field name="price">
            {(field) => <FormInput field={field} name="price" label="Precio" />}
          </form.Field>
        </div>
      </div>

      <form.Field name="pets" validators={{ onChange: validations.pets }}>
        {(field) => (
          <FormMultipleComboBox
            data={petsOptions}
            field={field}
            name="pets"
            label="Mascotas"
            placeholder="Selecciona las mascotas"
            isActiveAddOption={false}
          />
        )}
      </form.Field>

      <div className="w-1/7 h-full">
        <PlanDaysHoursForm daysHours={daysHours} setDaysHours={setDaysHours} />
      </div>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPostPending || isPutPending}
            isDisabled={!canSubmit}
            text={plan ? 'Actualizar Plan' : 'Crear Plan'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

PlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  plan: PropTypes.object,
}

export default PlanForm
