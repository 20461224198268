/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Menu, Transition } from '@headlessui/react'
import ReactStars from 'react-rating-stars-component'
import { FaStar, FaStarHalf, FaExclamationCircle } from 'react-icons/fa'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'

import { putData, getData } from '@services/requests'
import { classNames } from '@utils/formatFunctions'
import profilePhoto from '@assets/images/others/profile.png'
import SitterSummary from '@pages/hire_sitter/components/hire_sitter/SitterSummary'
import Button from '@common/buttons/Button'
import ErrorNoCard from '@common/error/ErrorNoCard'
import Loading from '@common/utils/Loading'
import useFilter from '@zustand/filter'

const typeStyle = {
  dogWalking: 'text-yellow-sec',
  visit: 'text-blue-ter',
  dayCare: 'text-purple-base',
}
function SitterChange({ record }) {
  const user = useSelector((state) => state.user)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { selectedAddress } = useFilter()

  const {
    isPending,
    isError,
    error,
    data: sitter,
  } = useQuery({
    queryKey: ['hireSitter', record.sitterId],
    queryFn: () =>
      getData(
        `user/sitter-info/${record.sitterId}?selectedAddress=${selectedAddress.geometry.location.lat},${selectedAddress.geometry.location.lng}`,
      ),
  })

  const closeModal = () => {
    setIsModalOpen(false)
    document.body.style.overflow = 'auto'
  }

  const openModal = () => {
    setIsModalOpen(true)
    document.body.style.overflow = 'hidden'
  }

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateService,
  } = useMutation({
    mutationFn: async (body) => {
      const data = await putData(
        `service-record/check-sitter-change-acceptance/${record.id}`,
        body,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userServiceRecords', user.id],
      })
    },
  })

  if (isPending) return <Loading />

  return (
    <div className="space-y-4">
      <p className="flex flex-row items-center gap-4 text-[0.6rem] text-grey-base sm:text-xs">
        <FaExclamationCircle size={28} className={typeStyle[record.type]} />
        Hubo un inconveniente con el sitter seleccionado. Sin embargo,{' '}
        {sitter?.name} está disponible y dispuesto a atender tu solicitud.
      </p>

      <div className="relative flex flex-row items-center gap-4 rounded-full bg-gray-100 py-2 pl-2 pr-12 shadow-xl">
        <img
          className="h-16 w-16 rounded-full"
          src={sitter?.photoUrl ? sitter?.photoUrl : profilePhoto}
          alt={sitter?.name}
        />
        <div className="flex flex-col py-2 text-base">
          <p>
            {sitter?.name} {sitter?.lastName}
          </p>
          <ReactStars
            count={5}
            value={sitter?.stars}
            size={20}
            isHalf={true}
            edit={false}
            filledIcon={<FaStar />}
            halfIcon={<FaStarHalf />}
            emptyIcon={<FaStar />}
            color={'#D9D9D9'}
          />
        </div>

        <Menu
          as="div"
          className="absolute right-4 top-1/2 z-50 -translate-y-1/2 transform"
        >
          <div>
            <Menu.Button className="flex items-center rounded-lg py-2 hover:bg-grey-soft">
              <span className="sr-only"></span>
              <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openModal}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                  >
                    Ver perfil
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          text="Aceptar"
          bgColor="green-base"
          textColor="white"
          hoverColor="opacity-80"
          circleColor="white"
          fillColor="green-base"
          onClick={() => updateService({ accepted: true })}
          loading={updateIsPending}
        />
        <Button
          text="Rechazar"
          bgColor="red-base"
          textColor="white"
          hoverColor="opacity-80"
          circleColor="white"
          fillColor="red-base"
          onClick={() => updateService({ accepted: false })}
          loading={updateIsPending}
        />
      </div>
      <SitterInfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        sitter={sitter}
      />
      {updateIsError && <ErrorNoCard message={updateError.message} />}
      {isError && <ErrorNoCard message={error.message} />}
    </div>
  )
}

function SitterInfoModal({ isOpen, onClose, sitter }) {
  const modalRef = useRef(null)

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose()
    }
  }
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black/80 ${isOpen ? 'flex' : 'hidden'}`}
    >
      <div
        ref={modalRef}
        className="relative z-10 h-[90%] overflow-y-auto rounded-xl bg-white p-2 sm:h-[70%] sm:w-1/2 sm:bg-transparent sm:p-0"
      >
        <button
          type="button"
          onClick={onClose}
          className="ghost absolute right-0 top-0 p-4 text-3xl text-grey-sec focus:outline-none"
        >
          &times;
        </button>
        <SitterSummary sitter={sitter} />
      </div>
    </div>
  )
}

SitterChange.propTypes = {
  recordType: PropTypes.string,
}

export default SitterChange
