import { TiDelete } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import { REGIONS } from '@assets/constants'
import { saveFirstStep } from '@store/reducers/sitterRequest'
import ComboBox from '@common/inputs/ComboBox'

function FirstStep() {
  const { firstStep } = useSelector((state) => state.sitterRequest)
  const dispatch = useDispatch()

  const handleCommuneChange = (commune, index, action) => {
    if (action === 'add') {
      dispatch(
        saveFirstStep({
          communes: [...firstStep.communes, commune],
          description: firstStep.description,
        }),
      )
    } else if (action === 'delete') {
      const updatedCommunes = [...firstStep.communes]
      updatedCommunes.splice(index, 1)
      dispatch(
        saveFirstStep({
          communes: updatedCommunes,
          description: firstStep.description,
        }),
      )
    }
  }

  return (
    <div className="flex flex-col items-center gap-4 p-4 text-center">
      <div className="flex flex-col gap-6 text-left">
        <h1 className="text-center font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
          Información inicial
        </h1>
        <div className="flex h-full flex-col gap-4">
          <ComboBox
            elements={REGIONS.reduce((acm, region) => {
              return acm.concat(region.communes)
            }, [])}
            title={'Comunas donde puedes trabajar'}
            onSelect={(commune) => handleCommuneChange(commune, null, 'add')}
            placeholder={'Selecciona una comuna'}
          />
          {firstStep?.communes.length !== 0 ? (
            <div className="flex flex-wrap gap-4 rounded-lg border border-slate-500 p-4 text-sm">
              {firstStep?.communes.map((commune, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-4 rounded-lg bg-blue-base p-4 text-white"
                >
                  <p>{commune}</p>
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleCommuneChange(null, index, 'delete')}
                    className="text-lg"
                  >
                    <TiDelete size={24} color={'#F8F6F2'} />
                  </button>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className="flex h-full flex-col gap-2">
          <label
            htmlFor="description"
            className="ml-1 block font-mont-semi text-sm leading-6 text-grey-base"
          >
            {'Tu experiencia con mascotas'}
          </label>
          <textarea
            id="description"
            name="description"
            value={firstStep?.description}
            placeholder='Ej: "Soy amante de los animales, tengo experiencia cuidando perros y gatos, me encanta pasearlos y jugar con ellos"'
            onChange={(e) =>
              dispatch(
                saveFirstStep({
                  communes: firstStep?.communes,
                  description: e.target.value,
                }),
              )
            }
            rows="6"
            cols="50"
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          ></textarea>
        </div>
      </div>
    </div>
  )
}

export default FirstStep
