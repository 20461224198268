import React from 'react'
import { useParams } from 'react-router-dom'
import Loading from '@common/utils/Loading'

const BlogPost = () => {
  const { slug } = useParams()

  const components = {
    post1: React.lazy(() => import('./articles/Post1')),
    post2: React.lazy(() => import('./articles/Post2')),
    post3: React.lazy(() => import('./articles/Post3')),
    post4: React.lazy(() => import('./articles/Post4')),
    post5: React.lazy(() => import('./articles/Post5')),
    post6: React.lazy(() => import('./articles/Post6')),
    post7: React.lazy(() => import('./articles/Post7')),
    post8: React.lazy(() => import('./articles/Post8')),
    post9: React.lazy(() => import('./articles/Post9')),
    post10: React.lazy(() => import('./articles/Post10')),
    post11: React.lazy(() => import('./articles/Post11')),
    post12: React.lazy(() => import('./articles/Post12')),
    post13: React.lazy(() => import('./articles/Post13')),
    post14: React.lazy(() => import('./articles/Post14')),
    post15: React.lazy(() => import('./articles/Post15')),
    post16: React.lazy(() => import('./articles/Post16')),
    post17: React.lazy(() => import('./articles/Post17')),
    post18: React.lazy(() => import('./articles/Post18')),
    post19: React.lazy(() => import('./articles/Post19')),
  }

  const ArticleComponent = components[slug] || null

  return (
    <div>
      {ArticleComponent ? (
        <React.Suspense fallback={<Loading />}>
          <ArticleComponent />
        </React.Suspense>
      ) : (
        <div>Article not found</div>
      )}
    </div>
  )
}

export default BlogPost
