import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useForm } from '@tanstack/react-form'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useUpdatePassword } from '@services/user'
import FormPassword from '../inputs/FormPassword'
import FormButton from '@common/buttons/FormButton'

const validations = {
  password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .required('La contraseña es obligatoria'),
}

const ChangePassword = ({ onSubmit, token }) => {
  const { isPending, mutate: updatePassword } = useUpdatePassword()
  const form = useForm({
    onSubmit: ({ value }) => {
      updatePassword({ value, token })
      if (onSubmit) onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  })

  return (
    <form
      className="min-w-[400px] space-y-4"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <form.Field
        name="password"
        validators={{ onChange: validations.password }}
      >
        {(field) => (
          <FormPassword
            field={field}
            name="password"
            label="Contraseña"
            placeholder="Escribe tu contraseña"
            type="password"
          />
        )}
      </form.Field>
      <form.Field
        name="confirm_password"
        validators={{
          onChangeListenTo: ['password'],
          onChange: ({ value, fieldApi }) => {
            if (value !== fieldApi.form.getFieldValue('password')) {
              return 'Las contraseñas deben coincidir'
            }
            return undefined
          },
        }}
      >
        {(field) => (
          <FormPassword
            field={field}
            name="confirm_password"
            label="Confirmar contraseña"
            placeholder="Confirma tu contraseña"
            type="password"
          />
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPending}
            isDisabled={!canSubmit}
            text={'Actualizar'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

ChangePassword.propTypes = {
  onSubmit: PropTypes.func,
  token: PropTypes.string,
}

export default ChangePassword
