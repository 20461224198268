import { putData, postData } from '@services/requests'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const usePostDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body) => {
      const data = await postData('discount', body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminDiscounts'] })
      notifySuccess('Descuento creado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdateDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, body }) => {
      const data = await putData(`discount/${id}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminDiscounts'] })
      notifySuccess('Descuento actualizado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
