import PropTypes from 'prop-types'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@tanstack/react-query'
import { FaPaw } from 'react-icons/fa'
import { getData } from '@services/requests'
import profilePhoto from '@assets/images/others/profile.png'
import SuperSitterBadge from '@common/tooltip/SupperSitterBadge'
import InfoToolTip from '@common/tooltip/InfoToolTip'
import ReviewList from '@common/modals/ReviewList'
import StarsRating from '@common/utils/StarsRating'

function SitterMainInfo({ sitter }) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' })
  const [openReviews, setOpenReviews] = useState(false)

  const { isSuccess, data } = useQuery({
    queryKey: ['reviewsMainInfo', sitter.id],
    queryFn: () => getData(`review/${sitter.id}`),
  })

  const handleOpenReviews = () => {
    setOpenReviews(true)
    document.body.classList.add('overflow-hidden')
  }

  const handleCloseReviews = () => {
    setOpenReviews(false)
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <div className="flex flex-row items-center gap-4">
        <img
          className="object-fit h-28 w-28 rounded-full sm:h-36 sm:w-36"
          src={sitter.photoUrl ? sitter.photoUrl : profilePhoto}
          alt="Foto de sitter"
        />
        <div className="space-y-2">
          <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
            <h1 className="font-tex-bold text-xl text-slate-900 sm:text-2xl">
              {sitter.name ? sitter.name : null}{' '}
              {sitter.lastName ? sitter.lastName : null}
            </h1>
            {sitter.isSuperSitter && (
              <InfoToolTip
                InfoToShow={<SuperSitterBadge />}
                text="Super Sitter"
                icon={<FaPaw />}
                style={
                  'flex flex-row gap-2 items-center text-yellow-700 bg-yellow-base text-sm px-2 py-1 rounded-full'
                }
              />
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            <StarsRating
              count={5}
              value={sitter.stars}
              size={isSmallScreen ? 16 : 24}
              color={'#D9D9D9'}
              activeColor="#ffd700"
              isHalf={true}
            />
            {isSuccess && (
              <button
                onClick={handleOpenReviews}
                className="text-xs text-grey-soft underline sm:text-sm"
              >
                Basado en {data.length} reviews
              </button>
            )}
          </div>
          <p className="font-mont-semi text-sm text-grey-base sm:text-base">
            {sitter.stats.finished && sitter.stats.finished} Servicios
            completados
          </p>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 p-2">
        {sitter.communes &&
          sitter.communes.length > 0 &&
          sitter.communes.map((commune, index) => (
            <p
              key={index}
              className="rounded-full bg-blue-ter px-2 py-1 text-xs text-white-base"
            >
              {commune}
            </p>
          ))}
      </div>
      <ReviewList
        isOpen={openReviews}
        onClose={handleCloseReviews}
        reviews={data}
      />
    </div>
  )
}

SitterMainInfo.propTypes = {
  sitter: PropTypes.object.isRequired,
}

export default SitterMainInfo
