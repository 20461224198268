import { useQuery } from '@tanstack/react-query'
import { getData } from './requests'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const notifyPendingReviews = (pendingReviewsCount, navigate) => {
  toast.info(`Tienes ${pendingReviewsCount} reviews pendientes`, {
    position: 'top-center',
    onClick: () => navigate('/user/pending-reviews'),
    autoClose: false,
    closeOnClick: true,
    draggable: true,
    style: { cursor: 'pointer' },
  })
}

export const useGetPendingReviews = () => {
  const navigate = useNavigate()

  const { data, isSuccess } = useQuery({
    queryKey: ['pendingReviews'],
    queryFn: () => getData('review/pending'),
  })

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      notifyPendingReviews(data.length, navigate)
    }
  }, [data, isSuccess, navigate])

  return { data }
}
