import { useQuery} from '@tanstack/react-query'
import { getData } from '@services/requests'

export const useGetUserPets = (userId) => {
  return useQuery({
    queryKey: ['pets', userId],
    queryFn: () => getData(`pet/${userId}`),
    enabled: !!userId,
  })
}

export const useGetUserAddresses = (userId) => {
  return useQuery({
    queryKey: ['addresses', userId],
    queryFn: () => getData(`address/${userId}`),
    enabled: !!userId,
  })
}
