import { useState } from 'react'
import Loading from '@common/utils/Loading'
import ErrorNoCard from '@common/error/ErrorNoCard'
import { useGetAddresses, useDeleteAddress } from '@services/address'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import AddressForm from '@common/forms/AddressForm'

function Addresses() {
  const [address, setAddress] = useState(null)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const {
    data: addresses,
    isSuccess,
    isPending,
    isError,
    error,
  } = useGetAddresses()

  const { mutate: deleteAddress } = useDeleteAddress()

  return (
    <>
      <div>
        <h2 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
          Direcciones
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          📍 Lugares dónde cuidaremos a tus Mascotas
        </p>

        {isPending && <Loading />}

        <ul
          role="list"
          className="mt-6 divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6"
        >
          {isSuccess &&
            addresses.map((address) => (
              <li
                key={address.id}
                className="flex justify-between gap-x-6 py-6"
              >
                <div className="line-clamp-2 w-1/2 font-medium text-gray-900 lg:max-w-md">
                  {address.formattedAddress},{' '}
                  {address.department}
                </div>
                <div className="space-x-6 space-y-2">
                  <button
                    type="button"
                    onClick={() => {
                      setAddress(address)
                      setOpenUpdateModal(true)
                    }}
                    className="font-semibold text-blue-soft hover:text-blue-600"
                  >
                    Actualizar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      deleteAddress(address.id)
                    }}
                    className="font-semibold text-red-base hover:text-red-600"
                  >
                    Eliminar
                  </button>
                </div>
              </li>
            ))}
        </ul>

        <div className="flex border-t border-gray-100 pt-6">
          <button
            type="button"
            onClick={() => setOpenCreateModal(true)}
            className="text-sm font-semibold leading-6 text-blue-soft hover:text-blue-600"
          >
            <span aria-hidden="true">+</span> Agrega una dirección
          </button>
        </div>
        {isError && <ErrorNoCard error={error} />}
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualiza tu dirección"
            subtitle="Indícanos dónde quieres que cuidemos a tus mascotas 🏡"
          />
        }
        body={
          <AddressForm
            address={address}
            onSubmit={() => setOpenUpdateModal(false)}
          />
        }
        isOpen={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
      />
      <CustomModal
        header={
          <ModalHeader
            title="Agrega una dirección"
            subtitle="Indícanos dónde quieres que cuidemos a tus mascotas 🏡"
          />
        }
        body={<AddressForm onSubmit={() => setOpenCreateModal(false)} />}
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
      />
    </>
  )
}

export default Addresses
