import PropTypes from 'prop-types'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale-subtle.css'
import { Tooltip } from 'react-tippy'

function InfoToolTip({ InfoToShow, icon, text, style }) {
  return (
    <Tooltip
      html={InfoToShow}
      position="bottom"
      trigger="mouseenter"
      animation="scale-subtle"
    >
      <button className={style}>
        {text}
        {icon}
      </button>
    </Tooltip>
  )
}

InfoToolTip.propTypes = {
  InfoToShow: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.string,
}

export default InfoToolTip
