import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setRequests(state, action) {
      return action.payload
    },
  },
})

export const { setRequests } = requestSlice.actions
export default requestSlice.reducer
