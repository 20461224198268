import PropType from 'prop-types'
import { FaExclamationCircle } from 'react-icons/fa'

function Error({ message }) {
  return (
    <div className="z-10 mt-4 flex flex-row items-center justify-start gap-4 rounded-lg border-l-8 border-red-base bg-white p-4 text-red-base shadow-lg">
      <FaExclamationCircle size={20} />
      <h1 className="text-sm text-slate-900">{message}</h1>
    </div>
  )
}

Error.propTypes = {
  message: PropType.string.isRequired,
}

export default Error
