import PropType from 'prop-types'

function ServiceInfoCard({ icon, title, mainText, secondaryText }) {
  return (
    <div className="flex flex-row items-center justify-center gap-8 font-mont-semi sm:gap-10">
      {icon}
      <div className="items-right flex w-full flex-col gap-2 sm:w-[65%]">
        <h3 className="text-base font-medium text-grey-base sm:text-xl">
          {title}
        </h3>
        <p className="text-sm text-grey-base sm:text-sm">
          {mainText}{' '}
          <span className="text-sm text-yellow-base sm:text-sm">
            {secondaryText}
          </span>
        </p>
      </div>
    </div>
  )
}

ServiceInfoCard.propTypes = {
  icon: PropType.element,
  title: PropType.string,
  mainText: PropType.string,
  secondaryText: PropType.string,
}

export default ServiceInfoCard
