import HomeOne from './components/HomeOne'
import HomeTwo from './components/HomeTwo'
import HomeThree from './components/HomeThree'
import HomeFour from './components/HomeFour'
import StaticFilter from './components/StaticFilter'
import StickyFilter from './components/StickyFilter'
import BciBanner from './components/BciBanner'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Home() {
  return (
    <>
      <ToastContainer position="top-center" />
      <div className="overflow-hidden">
        <div className="z-40 flex flex-col items-center gap-32 overflow-visible bg-white-base pb-64 pt-12 drop-shadow-2xl sm:justify-center sm:pb-40 sm:pt-32">
          <HomeOne />
          <StaticFilter />
        </div>
        <StickyFilter />
        <HomeTwo />
        <BciBanner />
        <HomeThree />
        <HomeFour />
      </div>
    </>
  )
}

export default Home
