import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser(state, action) {
      return { ...state, ...action.payload }
    },
    resetUser() {
      return initialState
    },
    updateAttribute(state, action) {
      if ('photoUrl' in action.payload) {
        return { ...state, photoUrl: action.payload.photoUrl }
      }
      return { ...state, [action.payload.attribute]: action.payload.value }
    },
  },
})

export const { saveUser, resetUser, updateAttribute } = userSlice.actions
export default userSlice.reducer
