import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  discountCoude: '',
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    addCoupon(state, action) {
      const { discountCode } = action.payload
      state.discountCode = discountCode
    },
    removeCoupon(state) {
      state.discountCode = ''
    },
  },
})

export const { addCoupon, removeCoupon } = paymentSlice.actions
export default paymentSlice.reducer
