import { useState, useContext } from 'react'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import ChangePassword from '@common/forms/ChangePassword'
import { AuthContext } from '@common/context/AuthContext'
import { useSelector } from 'react-redux'
import { useDeleteUser, useUpdateUser } from '@services/user'

function Security() {
  const { removeAuthToken } = useContext(AuthContext)
  const user = useSelector((state) => state.user)
  const [isPasswordOpen, setIsPasswordOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isSitterOpen, setIsSitterOpen] = useState(false)
  const { mutate: updateUser } = useUpdateUser()
  const { mutate: deleteUser } = useDeleteUser()

  return (
    <>
      <div>
        <h3 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
          Contraseña
        </h3>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Cambia tu contraseña. 🔐
        </p>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Contraseña
            </dt>
            <dd className="mt-1 flex items-center justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">* * * * * * * *</div>
              <button
                type="button"
                onClick={() => setIsPasswordOpen(true)}
                className="font-semibold text-blue-soft hover:text-blue-600"
              >
                Actualizar
              </button>
            </dd>
          </div>
        </dl>
        <h2 className="mt-16 font-tex-bold text-base font-semibold leading-7 text-gray-900">
          Sesión
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Cierra la sesión en el dispositivo actual. 📱💻
        </p>
        <div className="mt-6 space-y-6 divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6">
          <button
            type="button"
            onClick={() => removeAuthToken()}
            className="mt-4 rounded-md bg-red-base px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
          >
            Cerrar sesión
          </button>
        </div>
        <h2 className="mt-16 font-tex-bold text-base font-semibold leading-7 text-gray-900">
          Gestión de cuenta
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Si deseas eliminar tu cuenta, puedes hacerlo aquí. 😔
        </p>
        <div className="mt-6 space-x-4 border-t border-gray-300 text-sm leading-6">
          <button
            type="button"
            onClick={() => setIsDeleteOpen(true)}
            className="mt-4 rounded-md border-2 border-red-base px-3 py-2 text-sm font-semibold text-red-base shadow-sm hover:border-red-600 hover:bg-gray-200 hover:text-red-600"
          >
            Eliminar cuenta
          </button>
          {user.isSitter && (
            <button
              type="button"
              onClick={() => setIsSitterOpen(true)}
              className="mt-4 rounded-md border-2 border-blue-soft px-3 py-2 text-sm font-semibold text-blue-soft shadow-sm hover:border-blue-600 hover:bg-gray-200 hover:text-blue-600"
            >
              Darme de baja como Sitter
            </button>
          )}
        </div>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualizar contraseña"
            subtitle="Cambia tu contraseña por una nueva 🔐"
          />
        }
        body={<ChangePassword onSubmit={() => setIsPasswordOpen(false)} />}
        isOpen={isPasswordOpen}
        onClose={() => setIsPasswordOpen(false)}
      />
      <CustomModal
        header={
          <ModalHeader
            title="Eliminar cuenta"
            subtitle="¿Estás seguro de que quieres eliminar tu cuenta? 😔"
          />
        }
        body={<DeleteModalBody deleteUser={deleteUser} />}
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
      />
      <CustomModal
        header={
          <ModalHeader
            title="Darme de baja como Sitter"
            subtitle="¿Estás seguro de que quieres darte de baja como Sitter? 😔"
          />
        }
        body={<IsSitterModalBody updateUser={updateUser} />}
        isOpen={isSitterOpen}
        onClose={() => setIsSitterOpen(false)}
      />
    </>
  )
}

// eslint-disable-next-line react/prop-types
const DeleteModalBody = ({ deleteUser }) => {
  return (
    <div className="text-base leading-6 text-grey-base">
      <p>Todos tus datos serán eliminados y no podrás recuperarlos.</p>
      <p> ¡Esta acción no se puede deshacer!</p>
      <button
        type="button"
        onClick={() => deleteUser()}
        className="mt-12 rounded-md bg-red-base px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
      >
        Si, quiero eliminar la cuenta
      </button>
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const IsSitterModalBody = ({ updateUser }) => {
  return (
    <div className="text-base leading-6 text-grey-base">
      <p>¿Estás seguro de que quieres darte de baja como Sitter?</p>
      <p>¡Esta acción no se puede deshacer!</p>
      <button
        type="button"
        onClick={() => updateUser({ isSitter: false })}
        className="mt-12 rounded-md bg-blue-soft px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
      >
        Si, quiero darme de baja como Sitter
      </button>
    </div>
  )
}

export default Security
