import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { FaPaw, FaSun } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import ServiceToolTip from '@common/tooltip/ServiceToolTip'
import WeightToolTip from '@common/tooltip/WeightToolTip'
import ServiceInfo from '@common/tooltip/ServiceInfo'
import smGrey from '@assets/images/home/sitter_card/smGrey.png'
import smBlue from '@assets/images/home/sitter_card/smBlue.png'
import mdGrey from '@assets/images/home/sitter_card/mdGrey.png'
import mdBlue from '@assets/images/home/sitter_card/mdBlue.png'
import lgGrey from '@assets/images/home/sitter_card/lgGrey.png'
import lgBlue from '@assets/images/home/sitter_card/lgBlue.png'
import xlGrey from '@assets/images/home/sitter_card/xlGrey.png'
import xlBlue from '@assets/images/home/sitter_card/xlBlue.png'

function ExpandedInfo({ sitter }) {
  const navigate = useNavigate()

  const handleClick = () => {
    localStorage.setItem('sitterData', JSON.stringify(sitter))
    navigate(`/hire-sitter/${sitter.id}`)
  }

  return (
    <>
      <div className="mx-1 flex flex-row items-end justify-start gap-4 text-2xl sm:mx-4 sm:gap-8 sm:text-4xl">
        <ServiceToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Paseo']}
              bgColor={`${
                sitter.prices.dogWalking != 0 ? 'bg-blue-ter' : 'bg-grey-soft'
              }`}
            />
          }
          icon={<FaPaw />}
          style={`${
            sitter.prices.dogWalking != 0 ? 'text-blue-ter' : 'text-grey-soft'
          }`}
        />
        <ServiceToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Visita']}
              bgColor={`${
                sitter.prices.visit != 0 ? 'bg-blue-ter' : 'bg-grey-soft'
              }`}
            />
          }
          icon={<FaLocationDot />}
          style={`${
            sitter.prices.visit != 0 ? 'text-blue-ter' : 'text-grey-soft'
          }`}
        />
        <ServiceToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Guardería']}
              bgColor={`${
                sitter.prices.dayCare != 0 ? 'bg-blue-ter' : 'bg-grey-soft'
              }`}
            />
          }
          icon={<FaSun />}
          style={`${
            sitter.prices.dayCare != 0 ? 'text-blue-ter' : 'text-grey-soft'
          }`}
        />
      </div>
      <div className="mx-4 flex flex-row items-end justify-start gap-2 sm:gap-4">
        <WeightToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Entre 0-6 Kg']}
              bgColor={sitter?.petSize?.sm ? 'bg-blue-ter' : 'bg-grey-soft'}
            />
          }
          image={
            <img
              className="h-5 object-contain sm:h-6"
              src={sitter?.petSize?.sm ? smBlue : smGrey}
              alt="Small"
            />
          }
        />
        <WeightToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Entre 7-18 Kg']}
              bgColor={sitter?.petSize?.md ? 'bg-blue-ter' : 'bg-grey-soft'}
            />
          }
          image={
            <img
              className="h-7 object-contain sm:h-8"
              src={sitter?.petSize?.md ? mdBlue : mdGrey}
              alt="Medium"
            />
          }
        />
        <WeightToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Entre 19-45 Kg']}
              bgColor={sitter?.petSize?.lg ? 'bg-blue-ter' : 'bg-grey-soft'}
            />
          }
          image={
            <img
              className="h-8 object-contain sm:h-10"
              src={sitter?.petSize?.lg ? lgBlue : lgGrey}
              alt="Large"
            />
          }
        />
        <WeightToolTip
          InfoToShow={
            <ServiceInfo
              texts={['Más de 45 Kg']}
              bgColor={sitter?.petSize?.xl ? 'bg-blue-ter' : 'bg-grey-soft'}
            />
          }
          image={
            <img
              className="h-10 object-contain sm:h-12"
              src={sitter?.petSize?.xl ? xlBlue : xlGrey}
              alt="Extra Large"
            />
          }
        />
      </div>
      <div className="flex w-full items-end justify-end xl:w-1/3">
        <button
          onClick={handleClick}
          type="button"
          className="w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Contactar
        </button>
      </div>
    </>
  )
}

ExpandedInfo.propTypes = {
  sitter: PropTypes.object.isRequired,
}

export default ExpandedInfo
