/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { classNames } from '@utils/formatFunctions'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import 'dayjs/locale/es'

function ServiceCalendarInput({ selectedDates, setSelectedDates }) {
  dayjs.locale('es')
  const [isModalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <label
          htmlFor="serviceType"
          className="block pl-1 text-start font-mont-semi text-sm font-medium leading-6 text-grey-base"
        >
          Fechas
        </label>
        <input
          id="serviceType"
          placeholder="Selecciona fechas"
          value={
            selectedDates.length > 2
              ? `${selectedDates.slice(0, 2).join(', ')}...`
              : selectedDates.join(', ')
          }
          readOnly
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          onClick={openModal}
        />
      </div>
      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        header={
          <ModalHeader
            title="📅 Fechas"
            subtitle="Selecciona las fechas que necesitas"
          />
        }
        body={
          <ModalBody
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
        }
      />
    </>
  )
}

function ModalBody({ onClose, selectedDates, setSelectedDates }) {
  dayjs.locale('es')
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'))
  const [days, setDays] = useState([])

  useEffect(() => {
    const startOfMonth = currentMonth.startOf('month').startOf('week')
    const endOfMonth = currentMonth.endOf('month').endOf('week')
    const daysArray = []
    let day = startOfMonth

    while (day.isBefore(endOfMonth)) {
      daysArray.push({
        date: day.format('YYYY-MM-DD'),
        isCurrentMonth: day.month() === currentMonth.month(),
        isToday: day.isSame(dayjs(), 'day'),
        isSelected: selectedDates.includes(day.format('YYYY-MM-DD')),
      })
      day = day.add(1, 'day')
    }
    setDays(daysArray)
  }, [currentMonth, selectedDates])

  const handlePreviousMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'))
  }

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'))
  }

  const handleDayClick = (date) => {
    // Obtén el array anterior y determina si la fecha ya está seleccionada
    const updatedDates = selectedDates.includes(date)
      ? selectedDates.filter((d) => d !== date)
      : [...selectedDates, date]

    // Establece el nuevo array
    setSelectedDates(updatedDates)
  }

  const handleResetDates = () => {
    setSelectedDates([])
    onClose()
  }

  return (
    <div className="min-w-max space-y-4 text-center sm:min-w-96">
      <div className="text-left">
        <div className="rounded-lg bg-white p-4">
          <div className="flex items-center">
            <h2 className="flex-auto text-sm font-semibold text-grey-base">
              {currentMonth.format('MMMM YYYY')}
            </h2>
            <button
              type="button"
              onClick={handlePreviousMonth}
              className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Mes anterior</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={handleNextMonth}
              className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Mes siguiente</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>L</div>
            <div>M</div>
            <div>M</div>
            <div>J</div>
            <div>V</div>
            <div>S</div>
            <div>D</div>
          </div>
          <div className="mt-2 grid grid-cols-7 text-sm">
            {days.map((day, dayIdx) => (
              <div
                key={day.date}
                className={classNames(
                  dayIdx > 6 && 'border-t border-gray-200',
                  'py-2',
                )}
              >
                <button
                  type="button"
                  onClick={() => handleDayClick(day.date)}
                  className={classNames(
                    selectedDates?.includes(day.date) &&
                      'bg-grey-base text-white',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.isCurrentMonth && 'text-grey-base',
                    !day.isSelected &&
                      !day.isToday &&
                      !day.isCurrentMonth &&
                      'text-grey-base',
                    day.isSelected && 'bg-grey-base',
                    !day.isSelected && 'hover:bg-grey-soft',
                    (day.isSelected || day.isToday) && 'font-semibold',
                    'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                  )}
                >
                  <div className="gap-0.2 flex flex-col">
                    <time dateTime={day.date}>
                      {day.date.split('-').pop().replace(/^0/, '')}
                    </time>
                    {day.isToday && (
                      <span className="px-0.1 rounded-full bg-grey-soft py-0.5"></span>
                    )}
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <button
          onClick={onClose}
          className="rounded-md bg-blue-500 px-3.5 py-2.5 text-white hover:bg-blue-600"
        >
          Listo
        </button>
        <button
          onClick={handleResetDates}
          className="rounded-lg text-sm text-grey-base underline"
        >
          Limpiar filtro
        </button>
      </div>
    </div>
  )
}

ServiceCalendarInput.propTypes = {
  selectedDates: PropTypes.array,
  setSelectedDates: PropTypes.func,
}

export default ServiceCalendarInput
