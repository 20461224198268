/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { FaLocationArrow } from 'react-icons/fa6'

import { putData } from '@services/requests'
import { useQueryClient } from '@tanstack/react-query'
import { classNames } from '@utils/formatFunctions'
import { useSearchParams } from 'react-router-dom'
import Pets from './Pets'
import SitterChange from './SitterChange'
import Review from './Review'
import ErrorNoCard from '@common/error/ErrorNoCard'
import Button from '@common/buttons/Button'
import 'dayjs/locale/es'

function ServiceSummary({ service, role = 'user' }) {
  dayjs.locale('es')
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const user = useSelector((state) => state.user)

  const updateServiceFn = async (newStatus) => {
    if (newStatus === 'finished') {
      return putData(`service-record/finish/${service.record.id}/${user.id}`)
    }
    return putData(`service-record/cancel/${service.record.id}/${user.id}`)
  }

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateService,
  } = useMutation({
    mutationFn: async (newStatus) => {
      const data = await updateServiceFn(newStatus)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`${role}ServiceRecords`, user.id],
      })
      setSearchParams({ recordId: service.record.id })
    },
  })

  return (
    <div
      className={classNames(
        'space-y-4 rounded-lg border p-4',
        service.record.type == 'dogWalking' &&
          'border-yellow-sec bg-yellow-100',
        service.record.type == 'visit' && 'border-blue-ter bg-blue-100',
        service.record.type == 'dayCare' && 'border-purple-base bg-purple-100',
      )}
    >
      <Header service={service} role={role} />

      <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
        <Details service={service} role={role} />
        <Actions
          service={service}
          role={role}
          updateService={updateService}
          isPending={updateIsPending}
        />
      </div>

      {service.record.status == 'waiting' && role == 'user' && (
        <SitterChange sitter={service.sitter} record={service.record} />
      )}
      {service.record.status == 'finished' &&
        role == 'user' &&
        !service.review && <Review service={service} />}
      {updateIsError && <ErrorNoCard message={updateError.message} />}
    </div>
  )
}

function Header({ service, role }) {
  return (
    <>
      <div className="flex flex-row justify-between text-[0.6rem] sm:text-sm">
        {service.record.status !== 'waiting' && role == 'user' && (
          <p>
            {' '}
            {service.record.type == 'dogWalking' && 'Paseo'}
            {service.record.type == 'visit' && 'Visita'}
            {service.record.type == 'dayCare' && 'Alojamiento'}{' '}
            {role == 'user' && 'con,'}
            {role == 'sitter' && 'para'}{' '}
            {role == 'user' && (
              <span className="font-mont-semi">
                {service.sitter?.name} {service.sitter?.lastName}
              </span>
            )}
            {role == 'sitter' && (
              <span className="font-mont-semi">
                {service.user?.name} {service.user?.lastName}
              </span>
            )}
          </p>
        )}

        <div className="flex flex-row items-center gap-1">
          Estado del servicio,{' '}
          <span className="font-mont-semi">
            {service.record.status == 'pending' && 'pendiente'}
            {service.record.status == 'denied' && 'rechazado'}
            {service.record.status == 'accepted' && 'aceptado'}
            {service.record.status == 'cancelled' && 'cancelado'}
            {service.record.status == 'waiting' && 'esperando al cliente'}
            {service.record.status == 'finished' && 'completado'}
          </span>
          <p
            className={classNames(
              'h-2 w-2 rounded-full',
              service.record.status == 'pending' && 'bg-yellow-sec',
              service.record.status == 'denied' && 'bg-red-base',
              service.record.status == 'accepted' && 'bg-green-base',
              service.record.status == 'cancelled' && 'bg-red-base',
              service.record.status == 'waiting' && 'bg-yellow-sec',
              service.record.status == 'finished' && 'bg-blue-ter',
            )}
          ></p>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <p
          className={classNames(
            'rounded-full px-2 py-1 text-sm text-white',
            service.record.type === 'dogWalking' && 'bg-yellow-sec text-sm',
            service.record.type === 'visit' && 'bg-blue-ter text-sm',
            service.record.type === 'dayCare' && 'bg-purple-base text-sm',
          )}
        >
          {dayjs(service.record.date).format('DD [de] MMMM')}
        </p>
        <p
          className={classNames(
            'rounded-full px-2 py-1 text-sm text-white',
            service.record.type === 'dogWalking' && 'bg-yellow-sec text-sm',
            service.record.type === 'visit' && 'bg-blue-ter text-sm',
            service.record.type === 'dayCare' && 'bg-purple-base text-sm',
          )}
        >
          {dayjs(service.record.date).format('HH:mm')} -{' '}
          {dayjs(service.record.date).add(1, 'hour').format('HH:mm')}
        </p>
      </div>
      {service.address && (
        <div className="flex items-center text-xs">
          <FaLocationArrow
            className={classNames(
              'mr-2',
              service.record.type == 'dogWalking' && 'text-yellow-base',
              service.record.type == 'visit' && 'text-blue-ter',
              service.record.type == 'dayCare' && 'text-purple-base',
            )}
          />{' '}
          <p>
            {[service.address?.formattedAddress, service.address?.department]
              .filter(Boolean)
              .join(', ')}{' '}
          </p>
        </div>
      )}
    </>
  )
}

function Details({ service, role }) {
  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-2">
        <p className="font-mont-semi">Mascotas</p>
        <div className="flex w-80 flex-wrap gap-4">
          <Pets
            pets={service.pets}
            recordType={service.record.type}
            role={role}
          />
        </div>
      </div>
      {service.record.message && role == 'sitter' && (
        <p className="text-sm">
          <span className="font-mont-semi">Detalles: </span>
          {service.record.message}
        </p>
      )}
    </div>
  )
}

function Actions({ service, role, updateService, isPending }) {
  if (service.record.status == 'accepted' && role == 'sitter')
    return (
      <div className="flex flex-row justify-end gap-2 text-sm sm:flex-col">
        <Button
          text="Finalizar servicio"
          bgColor="blue-soft"
          textColor="white"
          hoverColor="blue-600"
          circleColor="white"
          fillColor="blue-soft"
          onClick={() => updateService('finished')}
          loading={isPending}
        />
      </div>
    )
  if (service.record.status == 'pending' && role == 'user')
    return (
      <div className="flex flex-row justify-end gap-2 text-sm sm:flex-col">
        <Button
          text="Cancelar servicio"
          bgColor="red-base"
          textColor="white"
          hoverColor="red-sec"
          circleColor="white"
          fillColor="red-base"
          onClick={() => updateService('cancelled')}
          loading={isPending}
        />
      </div>
    )
}

ServiceSummary.propTypes = {
  service: PropTypes.object.isRequired,
  role: PropTypes.string,
}

export default ServiceSummary
