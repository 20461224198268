import PropTypes from 'prop-types'
import { useState } from 'react'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import FieldError from '@common/error/FieldError'

export default function FormComboBox({
  data,
  field,
  name,
  label,
  placeholder,
}) {
  const [query, setQuery] = useState('')
  const filteredData =
    query === ''
      ? data
      : data.filter((item) => {
          return item.value.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <>
      <Combobox
        as="div"
        value={field.state.value}
        onChange={(item) => {
          event.preventDefault()
          event.stopPropagation()
          field.handleChange(item?.value)
          setQuery('')
        }}
      >
        <label
          htmlFor={name}
          className="block font-mont-semi text-sm leading-6 text-grey-base"
        >
          {label}
        </label>
        <div className="relative mt-2">
          <ComboboxInput
            id={name}
            name={name}
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-xs text-grey-base placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
            onChange={(event) => setQuery(event?.target?.value)}
            displayValue={(selectedItem) => {
              return (
                data.find((item) => item.value === selectedItem)?.label ||
                selectedItem ||
                ''
              )
            }}
            placeholder={placeholder}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>

          {filteredData?.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData.map((item, index) => (
                <ComboboxOption
                  key={item.id || index}
                  value={item}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-soft data-[focus]:text-white"
                >
                  <span className="block truncate group-data-[selected]:font-semibold">
                    {item?.label || item?.value}
                  </span>

                  <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-blue-soft group-data-[selected]:flex group-data-[focus]:text-white">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
      <div>
        {field.state.meta.isTouched && field.state.meta.errors.length ? (
          <FieldError message={field.state.meta.errors.join(', ')} />
        ) : null}
      </div>
    </>
  )
}

FormComboBox.propTypes = {
  data: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

FormComboBox.defaultProps = {
  placeholder: '',
}
