import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import profilePhoto from '@assets/images/others/profile.png'
import { useUpdateRecords } from '@services/servicerecord'
import { FaWhatsapp } from 'react-icons/fa'
import { useGetSitters } from '@services/user'
import { toast } from 'react-toastify'
function ChangeSitter({ params, onClose }) {
  const [searchTerm, setSearchTerm] = useState('')
  const { mutate } = useUpdateRecords()
  const { data, isError } = useGetSitters()
  const filteredSitters = data?.filter((sitter) =>
    `${sitter.name} ${sitter.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  useEffect(() => {
    if (isError) {
      toast.error('Error al obtener los sitters 🚨')
    }
  }, [isError])

  const handleChangeSitter = (sitter) => {
    if (!sitter || !params.data.id) {
      toast.error('Seleccione un sitter válido 🚨')
      return
    }
    mutate({ id: params.data.id, body: { sitterId: sitter.id } })
    onClose()
  }

  return (
    <div className="mt-2 flex flex-col sm:w-96">
      <input
        type="text"
        placeholder="Buscar sitter..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 w-full rounded-md border border-gray-300 p-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {filteredSitters?.map((sitter) => (
        <div
          onClick={() => handleChangeSitter(sitter)}
          key={sitter.id}
          className="mb-3 flex cursor-pointer items-center rounded-lg bg-white-base p-4 shadow-sm transition-all hover:bg-gray-100"
        >
          <img
            src={sitter.photoUrl ? sitter.photoUrl : profilePhoto}
            alt={`${sitter.name} ${sitter.lastName}`}
            className="mr-4 size-16 rounded-full object-cover"
          />
          <div className="flex-grow">
            <h3 className="text-sm text-grey-base">{`${sitter.name} ${sitter.lastName}`}</h3>
            <p className="text-xs text-grey-sec">{sitter.email}</p>
            <p className="text-xs text-grey-sec">+56 {sitter.phone}</p>
          </div>
          <a
            href={`https://wa.me/56${sitter.phone}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4 rounded-full bg-green-500 p-2 text-white hover:bg-green-600"
            onClick={(e) => e.stopPropagation()}
          >
            <FaWhatsapp size={20} />
          </a>
        </div>
      ))}
    </div>
  )
}

ChangeSitter.propTypes = {
  params: PropTypes.object,
  onClose: PropTypes.func,
}

export default ChangeSitter
