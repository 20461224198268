import PropTypes from 'prop-types'
import { MdOutlineFileUpload } from 'react-icons/md'

function PetPhotoInput({ name, field }) {
  return (
    <div className="relative mt-4 flex flex-col items-center">
      <label htmlFor={name} className="block cursor-pointer">
        <input
          type="file"
          id={name}
          name={name}
          style={{ display: 'none' }}
          onChange={(e) => field.handleChange(e.target.files[0])}
        />
        {field.state.value ? (
          <img
            className="mb-2 h-44 w-64 cursor-pointer rounded-md object-cover hover:opacity-80"
            src={URL.createObjectURL(field.state.value) ?? ''}
            alt="Foto de mascota"
          />
        ) : (
          <div className="mx-auto mb-2 flex h-44 w-64 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-gray-400 bg-gray-100 p-2 text-center">
            <div>
              <MdOutlineFileUpload size={32} className="text-slate-700" />
            </div>
            <p className="mb-2 text-lg font-bold tracking-tight text-gray-700">
              Sube una imagen
            </p>
            <p className="text-xs font-normal text-gray-400">
              Tiene que ser en <b className="text-gray-600">JPG, PNG o GIF</b>{' '}
              formato.
            </p>
            <span
              id="filename"
              className="z-50 bg-gray-200 text-gray-500"
            ></span>
          </div>
        )}
      </label>
    </div>
  )
}

PetPhotoInput.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.object,
}

export default PetPhotoInput
