import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import TestimonialCard from './TestimonialCard'
import dogHomeThree from '@assets/images/home/dogHomeThree.png'
import adolfoMunizaga from '@assets/images/home/adolfoMunizaga.jpeg'
import vicenteReyes from '@assets/images/home/vicenteReyes.jpeg'
import IG from '@assets/images/people/IG.jpeg'
import naiomiRonda from '@assets/images/home/naiomiRonda.jpeg'
import javierBermeosolo from '@assets/images/home/javierBermeosolo.jpeg'

const testimonials = [
  {
    key: '1',
    body: 'Pets-Okey es, sin duda, el mejor descubrimiento del 2024. Su trato hacia Bongo durante casi dos semanas fue increíble. Demostraron gran preocupación y se nota que realmente disfrutan lo que hacen. Lo recomiendo al 100%.',
    image: adolfoMunizaga,
    name: 'Adolfo Munizaga',
    username: '@adolfo_munizaga',
  },
  {
    key: '2',
    body: 'Hemos usado Pets-Okey cuatro veces para el cuidado de nuestro perro Tyson y ha Sido increíble. Los cuidadores nunca han fallado, siempre envían reportes de cómo está nuestro perro y cuando volvemos el Tyson está feliz y la casa limpia. Pets-Okey ha sido la solución para poder salir de nuestra casa tranquilos.',
    image: vicenteReyes,
    name: 'Vicente Reyes',
    username: '@vicente_reyes',
  },
  {
    key: '3',
    body: 'Hace un tiempo que estoy acudiendo a Pets-Okey para que puedan pasear a mi perro y lo recomiendo 100%. Son responsables, puntuales y te envían detalle con fotos y videos de como estuvo todo. La Pame es excelente sitter, Django esta feliz cuando llega a buscarlo y sus paseos son increibles.',
    image: IG,
    name: 'Isabel García',
    username: '@isabel_garcia',
  },
  {
    key: '4',
    body: 'He tenido una excelente experiencia en Pets-Okey, Pepa y Naby son los más agradecidos ya que sus paseos son tranquilos y agradables, y donde disfrutan de la caminata junto a su paseador que los acompaña preocupado de ellos en cada momento. Por mi parte me siento muy segura cuando ellos están de paseo, además envían fotografías para respaldar que el paseo se lleva de la mejor forma y con todos los cuidados necesarios. Recomendados 100%.',
    image: naiomiRonda,
    name: 'Náiomi Ronda',
    username: '@naiomi_ronda',
  },
  {
    key: '5',
    body: 'Fue para mi una suerte que me recomendaran Pets-Okey. Contar con ellos ha sido un gran apoyo en el cuidado de Pascual, con total compromiso, responsabilidad y preocupación. Se ve el cariño que ponen en lo que hacen!',
    image: javierBermeosolo,
    name: 'Javier Bermeosolo',
    username: '@javier_bermeosolo',
  },
]

export default function App() {
  return (
    <div className="h-full w-full bg-white py-16 drop-shadow-2xl sm:py-32">
      <div className="relative flex h-full flex-col items-center gap-12 sm:justify-center sm:gap-28">
        <h2 className="w-[90%] text-center font-tex-bold text-2xl text-grey-base sm:w-full sm:text-3xl">
          ¿Qué opinan{' '}
          <span className="text-yellow-base">nuestros clientes</span>?
        </h2>
        <div className="w-full sm:w-[70%]">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            freeMode={true}
            scrollbar={{ draggable: true }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 70,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 70,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.key}>
                <TestimonialCard
                  body={testimonial.body}
                  image={testimonial.image}
                  name={testimonial.name}
                  username={testimonial.username}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <img
          src={dogHomeThree}
          alt="dogHomeThree"
          className="absolute bottom-[-5%] right-[-10%] z-10 h-[12rem] sm:bottom-[-10%] sm:left-[20%] sm:h-[16rem]"
        />
      </div>
    </div>
  )
}
