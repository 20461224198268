import PropTypes from 'prop-types'
import { FaPaw, FaLocationDot, FaSun } from 'react-icons/fa6'
import { FaExclamationCircle } from 'react-icons/fa'
import {
  selectedContainerStyle,
  unselectedContainerStyle,
} from '@assets/css/styles'
import { classNames } from '@utils/formatFunctions'

function ServiceTypeInput({
  prices,
  selectedType,
  setSelectedType,
  step,
  setStep,
}) {
  const handleSelectType = (type) => {
    setSelectedType(type)
    setStep(1)
  }

  const handleClick = () => {
    if (step !== 0) {
      setStep(0)
      setSelectedType(null)
    }
  }

    if (step !== 0)
        return (
            <div
                onClick={handleClick}
                className={selectedContainerStyle(false, step === 0)}
            >
                <h2 className="text-2xl text-grey-base font-mont-semi">
                    Servicio
                </h2>
                {!selectedType && (
                    <div
                        className={classNames(
                            'flex flex-row items-center gap-4 text-sm sm:text-lg',
                            step > 0 ? 'text-red-base' : 'text-gray-400'
                        )}
                    >
                        <FaExclamationCircle
                            className={step < 0 && 'hidden'}
                            size={16}
                        />
                        <p> Selecciona un servicio</p>
                    </div>
                )}
                {selectedType && (
                    <div
                        className={classNames(
                            'flex flex-row gap-4 items-center px-3 py-2 rounded-full text-lg sm:text-xl text-white',
                            selectedType === 'dogWalking' &&
                                'bg-yellow-sec',
                            selectedType === 'visit' &&
                                'bg-blue-ter',
                            selectedType === 'dayCare' &&
                                'bg-purple-base'
                        )}
                    >
                        {selectedType === 'dogWalking' ? (
                            <FaPaw />
                        ) : selectedType === 'visit' ? (
                            <FaLocationDot />
                        ) : selectedType === 'dayCare' ? (
                            <FaSun />
                        ) : null}
                        <p>
                            {selectedType === 'dogWalking'
                                ? 'Paseo'
                                : selectedType === 'visit'
                                ? 'Visita'
                                : selectedType === 'dayCare'
                                ? 'Alojamiento'
                                : ''}
                        </p>
                    </div>
                )}
            </div>
        )

    return (
        <div
            onClick={handleClick}
            className={unselectedContainerStyle(false, step === 0)}
        >
            <h2 className="text-2xl text-grey-base font-mont-semi">
                Servicios
            </h2>
            <div className="flex flex-row gap-4 sm:gap-8 w-full text-center">
                <div
                    onClick={() => {
                        if (prices.dogWalking !== 0) {
                            handleSelectType('dogWalking')
                        }
                    }}
                    className={`${
                        prices.dogWalking !== 0
                            ? 'text-slate-900 bg-yellow-100 hover:bg-yellow-200 cursor-pointer border-4 border-yellow-base'
                            : 'text-grey-soft'
                    } flex flex-col items-center justify-between gap-2 p-4 rounded-lg w-1/3`}
                >
                    <p>Paseo</p>
                    <FaPaw
                        className={`text-3xl ${
                            prices.dogWalking !== 0 && 'text-yellow-sec'
                        }`}
                    />
                    <p>
                        $
                        {prices.dogWalking
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                </div>
                <div
                    onClick={() => {
                        if (prices.visit !== 0) {
                            handleSelectType('visit')
                        }
                    }}
                    className={`${
                        prices.visit !== 0
                            ? 'text-slate-900 bg-blue-100 hover:bg-blue-200 cursor-pointer border-4 border-blue-ter'
                            : 'text-grey-soft'
                    } flex flex-col items-center justify-between gap-2 p-4 rounded-lg w-1/3`}
                >
                    <p>Visita</p>
                    <FaLocationDot
                        className={`text-3xl ${
                            prices.visit !== 0 && 'text-blue-ter'
                        }`}
                    />
                    <p>
                        $
                        {prices.visit
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                </div>
                <div
                    onClick={() => {
                        if (prices.dayCare !== 0) {
                            handleSelectType('dayCare')
                        }
                    }}
                    className={`${
                        prices.dayCare !== 0
                            ? 'text-slate-900 bg-purple-100 hover:bg-purple-200 cursor-pointer border-4 border-purple-base'
                            : 'text-grey-soft'
                    } flex flex-col items-center justify-between gap-2 p-4 rounded-lg w-1/3`}
                >
                    <p>Alojamiento</p>
                    <FaSun
                        className={`text-3xl ${
                            prices.dayCare !== 0 && 'text-purple-base'
                        }`}
                    />
                    <p>
                        $
                        {prices.dayCare
                            ? prices.dayCare
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                            : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

ServiceTypeInput.propTypes = {
  prices: PropTypes.object.isRequired,
  selectedType: PropTypes.any,
  setSelectedType: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default ServiceTypeInput
