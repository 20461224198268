import { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import PropType from 'prop-types'

const PieChart = ({ data }) => {
  const chartRef = useRef(null)
  let myChart = null

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      myChart = echarts.init(chartRef.current)

      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '93%',
          left: 'center',
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '18',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
        ],
      }

      myChart.setOption(option)
    }

    return () => {
      if (myChart != null) {
        myChart.dispose()
      }
    }
  }, [])

  return <div ref={chartRef} className="h-64 w-full" />
}

PieChart.propTypes = {
  data: PropType.arrayOf(
    PropType.shape({
      value: PropType.number,
      name: PropType.string,
    }),
  ),
}

export default PieChart
