import PropTypes from 'prop-types'
import ReactStars from 'react-rating-stars-component'
import { FaStar, FaStarHalf } from 'react-icons/fa'

const CardComponent = ({ cardRef }) => {
  return (
    <div ref={cardRef} className="container cursor-pointer rounded-2xl p-1.5">
      <div className="flex flex-col items-center gap-4 rounded-xl bg-white p-4 shadow-md">
        <div className="flex w-full animate-pulse space-x-4">
          <div className="flex w-full justify-between">
            <div className="flex w-full flex-col gap-4 sm:w-auto sm:flex-row">
              <div className="h-24 w-24 rounded-full bg-slate-200 sm:h-32 sm:w-32"></div>

              <div className="flex flex-col gap-2">
                <div className="h-2 w-16 rounded bg-slate-200"></div>
                <div className="flex flex-col gap-2">
                  <ReactStars
                    count={5}
                    value={5}
                    size={24}
                    edit={false}
                    filledIcon={<FaStar />}
                    halfIcon={<FaStarHalf />}
                    emptyIcon={<FaStar />}
                  />
                  <div className="h-2 w-16 rounded bg-slate-200"></div>
                  <div className="flex h-12 w-full flex-row items-center justify-between space-x-4 rounded-lg bg-gray-200 p-2 sm:w-80"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CardComponent.propTypes = {
  cardRef: PropTypes.object.isRequired,
}

export default CardComponent
