import { deleteData, getData, postData, putData } from '@services/requests'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useGetAddresses = () => {
  return useQuery({
    queryKey: ['addresses'],
    queryFn: () => getData('address'),
  })
}

export const useDeleteAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const data = await deleteData(`address/${id}`)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] })
      notifySuccess('Dirección eliminada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const usePostAddress = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData('address', body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] })
      notifySuccess('Dirección creada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const usePostAdminAddress = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ userId, body }) => {
      const data = await postData(`address/${userId}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] })
      notifySuccess('Dirección creada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdateAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, body }) => {
      const data = await putData(`address/${id}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] })
      notifySuccess('Dirección actualizada correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
