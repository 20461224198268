import { useState } from 'react'
import PropTypes from 'prop-types'
import FieldError from '@common/error/FieldError'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Link } from 'react-router-dom'
function FormPassword({ field, name, label, placeholder, forgotPassword }) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="relative space-y-2">
      <div className="flex flex-row justify-between items-baseline">
        <label
          htmlFor={name}
          className="block font-mont-semi text-sm leading-6 text-grey-base"
        >
          {label}
        </label>
        {forgotPassword && (
          <Link
            to="/forget-password"
            className="text-blue-soft hover:text-blue-600 text-xs"
          >
            ¿Olvidaste tu contraseña?
          </Link>
        )}
      </div>

      <div className="relative">
        <input
          className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-xs placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          id={name}
          name={name}
          value={field.state.value}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => field.handleChange(e.target.value)}
          placeholder={placeholder}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 -translate-y-1/2 transform text-grey-soft"
        >
          {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        </button>
      </div>

      <div>
        {field.state.meta.isTouched && field.state.meta.errors.length ? (
          <FieldError message={field.state.meta.errors.join(', ')} />
        ) : null}
      </div>
    </div>
  )
}

FormPassword.propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  forgotPassword: PropTypes.bool,
}

FormPassword.defaultProps = {
  forgotPassword: false,
}

export default FormPassword
