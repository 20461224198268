import { useSelector, useDispatch } from 'react-redux'
import { saveThirdStep } from '@store/reducers/sitterRequest'
import { BANKS } from '@assets/constants'

function ThirdStep() {
  const dispatch = useDispatch()
  const { thirdStep } = useSelector((state) => state.sitterRequest)

  const textColorBank =
    thirdStep.bank === '' ? 'text-grey-soft' : 'text-slate-900'
  const textColorType =
    thirdStep.type === '' ? 'text-grey-soft' : 'text-slate-900'

  return (
    <div className="flex flex-col items-center gap-4 p-4 text-center">
      <div className="flex flex-col gap-6 text-left">
        <h1 className="text-center font-tex-bold text-2xl text-grey-base lg:text-3xl xl:text-4xl">
          Datos bancarios
        </h1>
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor={'bank'}
            className="ml-1 block font-mont-semi text-sm leading-6 text-grey-base"
          >
            Banco
          </label>
          <select
            name="bank"
            id="bank"
            value={thirdStep.bank}
            onChange={(e) =>
              dispatch(
                saveThirdStep({
                  bank: e.target.value,
                  type: thirdStep.type,
                  accountNumber: thirdStep.accountNumber,
                }),
              )
            }
            className={`border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6 ${textColorBank}`}
          >
            <option value="" disabled hidden>
              Selecciona un banco
            </option>
            {BANKS.map((bank, index) => (
              <option key={index} value={bank} className="text-grey-base">
                {bank}
              </option>
            ))}
          </select>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor={'type'}
            className="ml-1 block font-mont-semi text-sm leading-6 text-grey-base"
          >
            Tipo de cuenta
          </label>
          <select
            name="type"
            id="type"
            value={thirdStep.type}
            onChange={(e) =>
              dispatch(
                saveThirdStep({
                  bank: thirdStep.bank,
                  type: e.target.value,
                  accountNumber: thirdStep.accountNumber,
                }),
              )
            }
            className={`border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 pr-10 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6 ${textColorType}`}
          >
            <option value="" disabled hidden>
              Selecciona el tipo de cuenta
            </option>
            <option className="text-grey-base" value="Cuenta Corriente">
              Cuenta Corriente
            </option>
            <option className="text-grey-base" value="Cuenta Vista">
              Cuenta Vista
            </option>
            <option className="text-grey-base" value="Cuenta de Ahorro">
              Cuenta de Ahorro
            </option>
          </select>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor={'number'}
            className="ml-1 block font-mont-semi text-sm leading-6 text-grey-base"
          >
            Número de cuenta
          </label>
          <input
            id="number"
            type="number"
            placeholder="Ingresa tu número de cuenta"
            value={thirdStep.accountNumber}
            onChange={(e) =>
              dispatch(
                saveThirdStep({
                  bank: thirdStep.bank,
                  type: thirdStep.type,
                  accountNumber: e.target.value,
                }),
              )
            }
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 text-sm placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          />
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
