import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { putData, deleteData, getData, postData } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveUser } from '@store/reducers/userSlice'
import { toast } from 'react-toastify'
import { AuthContext } from '@common/context/AuthContext'
import { md5 } from 'js-md5'
import { decodeJwt } from 'jose'
import useFilter from '@zustand/filter'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

const useUserId = () => {
  const user = useSelector((state) => state.user)
  return user.id
}

export const useUpdateUser = () => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (values) => {
      const data = await putData('user', values)
      return data
    },
    onSuccess: (data) => {
      dispatch(saveUser(data))
      notifySuccess('Datos actualizados correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdatePassword = () => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async ({ value, token }) => {
      const hashedNewPassword = md5(value.password)
      const body = {
        password: hashedNewPassword,
      }
      const headers = token ? { Authorization: `Bearer ${token}` } : {}
      const data = await putData('user', body, headers)
      return data
    },
    onSuccess: (data) => {
      dispatch(saveUser(data))
      notifySuccess('Contraseña actualizada correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useForgetPassword = () => {
  return useMutation({
    mutationFn: async (values) => {
      const data = await putData('user/forget-password', values)
      return data
    },
    onSuccess: () => {
      notifySuccess('Correo enviado correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useDeleteUser = () => {
  const userId = useUserId()
  const { removeAuthToken } = useContext(AuthContext)
  return useMutation({
    mutationFn: async () => {
      const data = await deleteData(`user/${userId}`)
      return data
    },
    onSuccess: () => {
      removeAuthToken()
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetProfilePhotos = (id) => {
  return useQuery({
    queryKey: ['profilePhotos'],
    queryFn: () => getData(`user/get-profile-photos/${id}`),
  })
}

export const useUpdateProfilePhotos = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body) =>
      await postData('user/update-profile-photos', body, {
        'Content-Type': 'multipart/form-data',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profilePhotos'] })
    },
  })
}

export const useUpdateFavoriteSitter = () => {
  const userId = useUserId()
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async (values) => {
      const data = await putData(`user/change-favorite/${userId}`, {
        sitter: values.id,
      })
      return data
    },
    onSuccess: (data) => {
      dispatch(saveUser(data))
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetSitters = () => {
  return useQuery({
    queryKey: ['sitters'],
    queryFn: () => getData('user/sitters'),
  })
}

export const useGetSitterInfo = (sitterId) => {
  const { selectedAddress } = useFilter()
  return useQuery({
    queryKey: ['sitterData', sitterId],
    enabled: !!sitterId,
    queryFn: () =>
      getData(
        `user/sitter-info/${sitterId}?selectedAddress=${selectedAddress?.geometry?.location?.lat},${selectedAddress?.geometry?.location?.lng}`,
      ),
  })
}

export const useSignIn = () => {
  const dispatch = useDispatch()
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (values) => {
      values.email = values.email.toLowerCase()
      const hashedPassword = md5(values.password)
      const body = { ...values, password: hashedPassword }
      return await postData('user/signin', body)
    },
    onSuccess: (response) => {
      const { userForToken, token } = response
      setAuthToken(token)
      dispatch(saveUser(userForToken))
      navigate(location.state?.from || '/')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGoogleSignIn = (onCompleteRegistration) => {
  const dispatch = useDispatch()
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (credentialResponse) => {
      const { credential } = credentialResponse
      const payload = credential ? decodeJwt(credential) : null
      if (!payload) {
        throw new Error('Error al iniciar sesión con Google')
      }
      const data = await postData('user/google-auth', {
        email: payload.email.toLowerCase(),
      })
      return { data, payload }
    },
    onSuccess: ({ data, payload }) => {
      if (data.isNewUser) {
        onCompleteRegistration(payload)
        return
      }
      const { userForToken, token } = data
      setAuthToken(token)
      dispatch(saveUser(userForToken))
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useSignUp = () => {
  const dispatch = useDispatch()
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (values) => {
      values.email = values.email.toLowerCase()
      const hashedPassword = md5(values.password)
      const body = {
        ...values,
        password: hashedPassword,
        isGoogleSignUp: false,
      }
      const data = await postData('user/signup', body)
      return data
    },
    onSuccess: (data) => {
      const { userForToken, token } = data
      setAuthToken(token)
      dispatch(saveUser(userForToken))
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (values) => {
      values.email = values.email.toLowerCase()
      const hashedPassword = md5(values.password)
      const body = {
        ...values,
        password: hashedPassword,
        isGoogleSignUp: false,
      }
      const data = await postData('user/signup', body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      notifySuccess('Usuario creado correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGoogleSignUp = () => {
  const dispatch = useDispatch()
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (values) => {
      const hashedPassword = md5(Math.random().toString(36).slice(-12))
      const body = {
        ...values,
        email: values.email.toLowerCase(),
        name: values.given_name,
        lastName: values.family_name,
        password: hashedPassword,
        isGoogleSignUp: true,
      }
      const data = await postData('user/signup', body)
      return data
    },
    onSuccess: (data) => {
      const { userForToken, token } = data
      setAuthToken(token)
      dispatch(saveUser(userForToken))
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
